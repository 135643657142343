/**
 * @flow
 * @relayHash 2e1023d227e5c984f2255a2e6f4b828e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQueryVariables = {|
  ResourcesId: $ReadOnlyArray<?string>
|};
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnosityTagHierarchyRef: ?string,
      +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagObjectiveName: ?string,
        +Criteria: ?string,
        +ConditionRule: ?string,
        +TagType: ?string,
      |}>,
    |}>
  |}
|};
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery = {|
  variables: FocusSubfocusGetResourceLearnosityTagHierarchyNameQueryVariables,
  response: FocusSubfocusGetResourceLearnosityTagHierarchyNameQueryResponse,
|};
*/


/*
query FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery(
  $ResourcesId: [String]!
) {
  resourceSearch(ResourceId: $ResourcesId) {
    Resources {
      LearnosityTagHierarchyRef
      LearnositySuggestionMapping {
        TagName
        TagObjectiveName
        Criteria
        ConditionRule
        TagType
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourcesId",
    "type": "[String]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourcesId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnosityTagHierarchyRef",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LearnositySuggestionMapping",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLearnositySuggestionMapping",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagObjectiveName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Criteria",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ConditionRule",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagType",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "id": null,
    "text": "query FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery(\n  $ResourcesId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourcesId) {\n    Resources {\n      LearnosityTagHierarchyRef\n      LearnositySuggestionMapping {\n        TagName\n        TagObjectiveName\n        Criteria\n        ConditionRule\n        TagType\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06548371a9b0886278a4c3b2217ee9c8';
module.exports = node;
