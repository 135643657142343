import React, { Component, useEffect, useState } from "react";
import { Trans } from "react-i18next";
// import Loading from "../../../../../Loading/Loading";
import Loading from "../../../../../Loading/PEC/Loading";
// import ResourceIconMapping from "../../../../../Feature/Function/ResourceIconMapping";
import ResourceIconMapping from "../../../../../Feature/Function/ResourceIconMappingPEC";
import openIcon from "../../../../../../assets/img/open-in-new-24-px.svg";
import VidoeLightBox from "../../../../../Feature/VideoLightBox";
import ImageLightBox from "../../../../../Feature/ImageLightBox";
import MessageLightBox from "../../../../../Feature/MessageLightBox";
import arrow_accordion from "../../../../../../assets/img/pec/all-images/arrow-accordion.svg";
import external_link from "../../../../../../assets/img/pec/all-images/external-link.svg";
import web_link from "../../../../../../assets/img/pec/all-images/web-link.svg";
import { isSafari } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import GetEPSToken from "../../../../../../Services/api/GraphQL/Query/GetEPSToken";
import GetLearnositySuggestionMapping from "../../../../../../Services/api/GraphQL/Query/GetLearnositySuggestionMapping";

const messageBoxInitState = {
  isOpen: false,
  title: "",
  message: "",
  onConfirm: () => { },
  onCancel: () => { },
  customButton: null,
};

const index = (props) => {
  const {
    t,
    standalone,
    itemTagsData,
    resourceData,
    bookData,
    // resourceIdGa,
    // resourceIngestionIdGa,
    B2C,
    isInappBrowser,
    openState,
    handleOpenTab,
    action,
    activity,
    reportData,
  } = props;

  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] = useState(
    false
  );
  const [VideoLightBoxOpenState, setVideoLightBoxOpenState] = useState(null);
  const [ImageLightBoxOpenState, setImageLightBoxOpenState] = useState(null);
  const [messageLightboxData, setmessageLightboxData] = useState(
    messageBoxInitState
  );
  const [
    LearnositySuggestionMappingList,
    setLearnositySuggestionMappingList,
  ] = useState(null);
  const [hidden, sethidden] = useState(null);

  useEffect(() => {
    if (props.action == "all_result") {
      getLearnosityTeacherReport();
    }
    if (props.action == "teacher_view_student") {
      if (props.reportData) {
        getLearnosityTeacherReport();
      } else {
        setloading(true);
      }
    }
  }, [props.reportData]);

  const getEPSTokenForDownload = (resId, options, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: [resId],
    };
    try {
      GetEPSToken(value).then((data) => {
        console.log(data);
        if (options.platform == "Web") {
          forWebAction(data, options, callback);
        }
        if (options.platform == "App") {
          callback(data);
          setfetchingEPSResourceToken(false);
        }
      });
    } catch (e) {
      setfetchingEPSResourceToken(false);
      console.log(e);
      window.alert(e.message);
    }
  };

  const forWebAction = (data, options, callback) => {
    if (!isSafari) {
      try {
        switch (options.restype) {
          case "IMAGE":
            if (options.actionType == "download") {
              var a = document.createElement("a");
              a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
              a.target = "_blank";
              a.download = options.actionType;
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else {
              console.log("open");
              callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            }
            setfetchingEPSResourceToken(false);
            break;
          default:
            console.log("download");
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            document.body.appendChild(a);
            console.log(a);
            a.click();
            a.remove();

            setfetchingEPSResourceToken(false);
            break;
        }
      } catch (e) {
        setfetchingEPSResourceToken(false);
        console.log("Featch EPS doanload error", e);
        window.alert(e.message);
      }
    } else {
      let res = data.resourceSearch.Resources[0];
      let url = res.ResourceAccessUrl;

      // this.setState({
      setfetchingEPSResourceToken(false);
      setmessageLightboxData({
        isOpen: true,
        title:
          res.ResourceLangs[lang == res.ResourceLangs.Lang ? 0 : 1]
            .ResourceName,
        message: "",
        onConfirm: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        onCancel: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        customButton: () => {
          return (
            <a
              className="btn btn-primary"
              onClick={() => {
                setmessageLightboxData(messageBoxInitState);
              }}
              href={url}
              target="_blank"
            >
              {t("Open")}
            </a>
          );
        },
      });
    }
  };

  const getLearnosityTeacherReport = () => {
    var value;

    if (standalone) {
      value = {
        ResourceId: [props.match.params.resId.split("&")[0]],
      };
    } else {
      if (!isInappBrowser) {
        value = {
          ResourceId: [props.match.params.resId.split("&")[0]],
        };
      } else {
        value = {
          ResourceId: [props.inappData.resourceId],
        };
      }
    }

    GetLearnositySuggestionMapping(value).then((data) => {
      console.log(data, reportData, itemTagsData);
      var preSetData = [],
        questionTagData = [];
      if (data.resourceSearch.Resources == null) {
        return;
      }
      data.resourceSearch.Resources[0].LearnositySuggestionMapping &&
        data.resourceSearch.Resources[0].LearnositySuggestionMapping.map(
          (obj) => {
            var exercise = [],
              materials = [];
            obj.MappedResources.map((robj) => {
              if (robj.Resource.ResourceType == "LEARNOSITY") {
                exercise.push(robj);
              } else {
                materials.push(robj);
              }
            });
            preSetData.push({
              ConditionRule: obj.ConditionRule,
              Criteria: obj.Criteria,
              TagName: obj.TagName,
              TagType: obj.TagType,
              TagObjectiveName: obj.TagObjectiveName,
              Materials: materials.sort(function (a, b) {
                return a.DisplayOrder - b.DisplayOrder;
              }),
              Exercises: exercise.sort(function (a, b) {
                return a.DisplayOrder - b.DisplayOrder;
              }),
            });
          }
        );
      if (action != "all_result") {
        reportData.items.map((obj, index) => {
          if (itemTagsData) {
            console.log("[itemTagsData]", itemTagsData, obj.item_reference);
            if (
              itemTagsData
                .map((i) => {
                  return i.reference;
                })
                .indexOf(obj.item_reference) != -1
            ) {
              questionTagData.push({
                ...obj,
                ...itemTagsData[
                  itemTagsData
                    .map((i) => {
                      return i.reference;
                    })
                    .indexOf(obj.item_reference)
                ].tags,
              });
            }
          } else {
            questionTagData.push({ ...obj });
          }
        });
        console.log(questionTagData, "questionTagData", preSetData);
        var tagsScore = [];
        preSetData.map((obj, index) => {
          questionTagData.map((qobj, qindex) => {
            if (!qobj[obj.TagType]) return;
            if (qobj[obj.TagType].indexOf(obj.TagName) != -1) {
              if (tagsScore.length != 0) {
                var count = 0;
                tagsScore.map((tobj, tindex) => {
                  console.log(
                    tobj.TagName,
                    qobj[obj.TagType][qobj[obj.TagType].indexOf(obj.TagName)],
                    qobj[obj.TagType].indexOf(obj.TagName),
                    obj.TagType,
                    obj.TagName
                  );

                  if (
                    tobj.TagName ==
                    qobj[obj.TagType][qobj[obj.TagType].indexOf(obj.TagName)]
                  ) {
                    tagsScore[tindex].QuestionStatus.push({
                      max_score: qobj.max_score,
                      score: qobj.score == null ? 0 : qobj.score,
                    });
                    count++;
                  }
                });
                if (count == 0) {
                  tagsScore.push({
                    TagName: obj.TagName,
                    QuestionStatus: [
                      {
                        max_score: qobj.max_score,
                        score: qobj.score == null ? 0 : qobj.score,
                      },
                    ],
                  });
                }
              } else {
                console.log({
                  TagName: obj.TagName,
                  QuestionStatus: [
                    {
                      max_score: qobj.max_score,
                      score: qobj.score == null ? 0 : qobj.score,
                    },
                  ],
                });
                tagsScore.push({
                  TagName: obj.TagName,
                  QuestionStatus: [
                    {
                      max_score: qobj.max_score,
                      score: qobj.score == null ? 0 : qobj.score,
                    },
                  ],
                });
              }
            }
          });
        });

        console.log(tagsScore, "tagsScore");

        preSetData.map((obj, index) => {
          var totalScore = 0,
            maxScoreForaTag = 0;
          tagsScore.map((tobj) => {
            if (obj.TagName == tobj.TagName) {
              tobj.QuestionStatus.map((qobj) => {
                totalScore = totalScore + qobj.score;
                maxScoreForaTag = maxScoreForaTag + qobj.max_score;
              });
            }
          });
          preSetData[index].totalScore = totalScore;
          preSetData[index].maxScoreForaTag = maxScoreForaTag;
          var topercent = 0;
          if (obj.maxScoreForaTag != 0)
            topercent = (obj.totalScore / obj.maxScoreForaTag) * 100;
          preSetData[index].totalScorePercent = topercent;
        });

        preSetData.map((obj, index) => {
          switch (obj.ConditionRule) {
            case "<":
              if (obj.totalScorePercent < obj.Criteria) {
                preSetData[index].show = true;
              } else {
                preSetData[index].show = false;
              }
              break;

            case "=":
              if (obj.totalScorePercent == obj.Criteria) {
                preSetData[index].show = true;
              } else {
                preSetData[index].show = false;
              }
              break;

            case ">=":
              if (obj.totalScorePercent >= obj.Criteria) {
                preSetData[index].show = true;
              } else {
                preSetData[index].show = false;
              }
              break;

            case ">":
              if (obj.totalScorePercent > obj.Criteria) {
                preSetData[index].show = true;
              } else {
                preSetData[index].show = false;
              }
              break;

            case "<=":
              if (obj.totalScorePercent <= obj.Criteria) {
                preSetData[index].show = true;
              } else {
                preSetData[index].show = false;
              }
              break;
          }
        });

        var countToHidden = 0,
          hidden = false;
        preSetData.map((obj) => {
          if (obj.show) {
            countToHidden = countToHidden + 1;
          }
        });
        if (countToHidden == 0) {
          hidden = true;
        }
      }
      console.log(preSetData, "preSetData", hidden);
      setLearnositySuggestionMappingList(preSetData);
      setloading(false);
      sethidden(hidden);
    });
  };

  const ConditionRuleMapping = (value, percent) => {
    switch (value) {
      case "<":
        return "with score below "+ percent + "%";
      case "=":
        return "is";
      case ">":
        return "with score above "+ percent + "%";
      case ">=":
        return percent +"%  and above";
      case "<=":
        return percent +"%  and below";
    }
  };

  const ConditionRuleMappingBk = (value) => {
    switch (value) {
      case "<":
        return t("less than");
      case "=":
        return t("equals");
      case ">":
        return t("greater than");
      case ">=":
        return t("greater than or equal to");
      case "<=":
        return t("less than or equal to");
    }
  };

  const downloadResource = (data) => {
    var appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
          if (data.Resource.FileSource == "eps") {
            getEPSTokenForDownload(
              data.Resource.ResourceId,
              {
                actionType: "open",
                restype: data.Resource.ResourceType,
                platform: "App",
              },
              (path) => {
                //new tab
                var newdata = { ...data };
                newdata.Resource.ResourceAccessUrl =
                  path.resourceSearch.Resources[0].ResourceAccessUrl; //replace the latest token url
                localStorage.setItem("callbackdata", JSON.stringify(newdata));
                console.log("[appReceiver] Find - start passing data");
                return appReceiver.click();
              }
            );
          } else {
            window.open(data.Resource.ResourceAccessUrl, "_blank");
          }
          break;
      }
    } else {
      if (data.Resource.FileSource == "eps") {
        getEPSTokenForDownload(
          data.Resource.ResourceId,
          {
            actionType: "open",
            restype: data.Resource.ResourceType,
            platform: "Web",
          },
          (path) => {
            window.open(path, "_blank");
          }
        );
      } else {
        window.open(data.Resource.ResourceAccessUrl, "_blank");
      }
    }
  };

  const openResource = (data) => {
    var appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    try {
      // var userType = "";
      // if (!isInappBrowser) {
      //   userType = JSON.parse(localStorage.getItem("userData")).UserType;
      // } else {
      //   userType = localStorage.getItem("role") == "teacher" ? "T" : "S";
      // }
      // if (!B2C) {
      //   var book =
      //       studyGroupData.BookSeries.Books &&
      //       studyGroupData.BookSeries.Books.length > 0
      //         ? studyGroupData.BookSeries.Books[0].BookName[0].BookName
      //         : "NoData",
      //     chapter =
      //       studyGroupData.BookSeries.Books &&
      //       studyGroupData.BookSeries.Books.length > 0
      //         ? studyGroupData.BookSeries.Books[0].Units[0].UnitName[0].UnitName
      //         : "NoData";
      // }

      gaTrack(
        "send",
        "event",
        "Assessment",
        "Click suggestion resource",
        "Resource:" +
        data.Resource.ResourceLangs[0].ResourceName +
        ", Resource ID:" +
        data.Resource.IngestionId +
        ", Assessment:" +
        resourceData.ResourceLangs[0].ResourceName +
        ", Resource ID:" +
        resourceData.IngestionId +
        ", Level:" +
        (B2C || standalone
          ? "NoData"
          : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
        ", Book Series:" +
        bookData.BookSeriesName[0].BookSeriesName +
        ", Book:" +
        (standalone ? "NoData" : bookData.Books[0].BookName[0].BookName) +
        ", Chapter:" +
        (bookData.Books && bookData.Books.length > 0
          ? bookData.Books[0].Units[0].UnitName[0].UnitName
          : "NoData") +
        ", Role:" +
        userType +
        `, From:${B2C ? "Supplementary" : "My Class"}`
      );
    } catch (e) {
      console.log(e, "Check answer GA Error");
    }
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
        case "YOUTUBE":
        case "VIMEO":
          console.log("[appReceiver] Find - but open as light box");
          break;
        case "VIDEO":
        case "AUDIO":
        case "WORKSHEET":
        case "TEACHER_GUIDE":
        case "IACTIVITY":
        case "INTERACTIVE":
        case "OPE":
        case "WEB_LINK":
        case "LEARNOSITY":
        case "DOCUMENT":
        case "PACK":
        default:
          localStorage.setItem("callbackdata", JSON.stringify(data));
          console.log("[appReceiver] Find - start passing data");
          return appReceiver.click();
      }
    }
    console.log("[appReceiver] Not Find - start open as web");
    //No EPS 'EBOOK4','IACTIVITY','ILESSON','OPE','LEARNOSITY'
    //'DOCUMENT','WORKSHEET','TEACHER_GUIDE','AUDIO','VIDEO','WEB_LINK','INTERACTIVE','IMAGE','PACK'
    switch (data.Resource.ResourceType) {
      case "WEB_LINK":
      case "VIDEO":
      case "DOCUMENT":
      case "WORKSHEET":
      case "TEACHER_GUIDE":
      case "AUDIO":
      case "PACK":
      case "INTERACTIVE":
        if (data.Resource.FileSource == "eps") {
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: "Web",
            },
            (path) => {
              //new tab
              window.open(path, "_blank");
            }
          );
        } else {
          window.open(data.Resource.ResourceAccessUrl, "_blank");
        }
        break;
      case "IMAGE":
        if (data.Resource.FileSource == "eps") {
          console.log("[image] eps");
          // eps download or open
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: "Web",
            },
            (path) => {
              //imagebox
              setImageLightBoxOpenState({
                id: data.Resource.ResourceId,
                image: path,
                obj: data,
              });
              setfetchingEPSResourceToken(false);
            }
          );
        } else {
          console.log("[image] not eps");
          setImageLightBoxOpenState({
            id: data.Resource.ResourceId,
            image: data.Resource.ResourceAccessUrl,
            obj: data,
          });
          setfetchingEPSResourceToken(false);
        }

        break;
      case "EBOOK4":
      case "IACTIVITY":
      case "OPE":
        window.open(data.Resource.ResourceAccessUrl, "_blank");
        break;
      case "LEARNOSITY":
        console.log(props.location, props.match);
        let newURL;

        if (standalone) {
          dispatch({
            type: "SET_FROM",
            layout: "b2b",
          });
          newURL = window.location.pathname.replace(
            props.match.url + "/result",
            "/activity/" + data.Resource.ResourceId + "/start"
          );
        } else {
          newURL = window.location.pathname.replace(
            props.match.url,
            `${B2C ? "/c/myresview/" : "/a/studygroup/"}` +
            props.match.params.id +
            "/assessment/" +
            props.match.params.bookNunitId +
            "/" +
            data.Resource.ResourceId +
            "/start"
          );
        }

        window.open(newURL, "_blank");
        // if (!window.cordova) {
        //   window.open(newURL, "_blank");
        //   }
        //   if (window.cordova) {
        //     window.open(newURL, "_system");
        //   }
        break;
      case "YOUTUBE":
        handleVideoBox({
          ...data.Resource,
          type: "youtube",
          obj: data.Resource,
        });
        break;
      case "VIMEO":
        handleVideoBox({
          ...data.Resource,
          type: "vimeo",
          obj: data.Resource,
        });
        break;
      default:
        window.open(data.Resource.ResourceAccessUrl, "_blank");
        break;
    }
  };

  const handleVideoBox = (data) => {
    console.log(data);
    setVideoLightBoxOpenState(
      data ? { type: data.type, videoPath: data.Linkage, isOpen: true } : null
    );
  };

  const handleImageBox = (id, path) => {
    setImageLightBoxOpenState(id ? { name: id, image: path } : id);
  };

  if (loading) {
    return <Loading type="normal" />;
  }
  console.log("Suggestions",LearnositySuggestionMappingList);
  return (
    <>
      {fetchingEPSResourceToken && <Loading />}
      <VidoeLightBox
        handleVideoBox={handleVideoBox}
        VideoLightBoxOpenState={VideoLightBoxOpenState}
      />
      <ImageLightBox
        isOpen={ImageLightBoxOpenState ? true : false}
        handleImageBox={handleImageBox}
        ImageLightBoxOpenState={ImageLightBoxOpenState}
        downloadImage={(data) => downloadResource(data)}
      />
      <MessageLightBox
        isOpen={messageLightboxData.isOpen}
        type="messageBox"
        title={messageLightboxData.title}
        message={messageLightboxData.message}
        onClose={() => {
          messageLightboxData.onCancel();
        }}
        customButton={() => {
          if (messageLightboxData.customButton == null) return null;
          return messageLightboxData.customButton();
        }}
      />

      {LearnositySuggestionMappingList &&
        LearnositySuggestionMappingList.length != 0 &&
        !hidden && (
          <section class="suggestion__container">
            <details open class="suggestion">
              <summary class="suggestion__label">
                Suggestion
              </summary>
              <div class="suggestion__content-body">
                {LearnositySuggestionMappingList &&
                  LearnositySuggestionMappingList.map((obj, index) => {
                      return (
                        <details open class="suggestion__details">
                          <summary class="suggestion__summary">
                            <h3 class="suggestion__summary-heading">{obj.TagObjectiveName}</h3>
                          </summary>
                          {obj.Materials.length != 0 && (
                            <div class="suggestion__content">
                     <h4 class="suggestion__details-head">
                      {/* <Trans i18nKey="Follow-up materials for" count={2}>*/}
                      {/*  {{*/}
                      {/*    title: obj.TagObjectiveName,*/}
                      {/*    rule: ConditionRuleMapping(obj.ConditionRule, obj.Criteria),*/}
                      {/*  }}*/}
                      {/*</Trans>*/}
                       {`Follow-up materials for '${obj.TagObjectiveName}' ${ConditionRuleMapping(obj.ConditionRule, obj.Criteria)}`}
                     </h4>
                              <h4 class="suggestion__materials">Learning Materials</h4>
                              {obj.Materials.map((mobj, mindex) => {
                                return (
                                  <p class="suggestion__video">
                                    <img
                                    className="suggestion__asset_img"
                                      src={ResourceIconMapping(
                                        mobj.Resource.ResourceType,
                                        "small",
                                        mobj.Resource.Linkage
                                      )}
                                      onClick={() => {
                                        openResource(mobj);
                                      }}
                                    />
                                    {mobj.Resource.ResourceLangs[mobj.Resource
                                      .ResourceLangs[0].Lang ==
                                      "en-US"
                                      ? 0
                                      : 1
                                    ].ResourceName
                                    }
                                    <a onClick={() => {
                                      openResource(mobj);
                                    }} class="suggestion__external-link">
                                      <img src={external_link} alt="external link icon" /></a>


                                  </p>
                                )


                              })}






                            </div>)}

                          {obj.Exercises.length != 0 && (
                            <div class="suggestion__content">
                              <h4 class="suggestion__materials">{t("Follow-up Exercises")}</h4>
                              {obj.Exercises.map((eobj, eindex) => {
                                return (
                                  <p class="suggestion__video">
                                    <img
                                    className="suggestion__asset_img"
                                      src={ResourceIconMapping(
                                        eobj.Resource.ResourceType,
                                        "small",
                                        eobj.Resource.Linkage
                                      )}
                                      onClick={() => {
                                        openResource(eobj);
                                      }}
                                    />
                                    {eobj.Resource.ResourceLangs[eobj.Resource
                                      .ResourceLangs[0].Lang ==
                                      "en-US"
                                      ? 0
                                      : 1
                                    ].ResourceName
                                    }
                                    <a onClick={() => {
                                      openResource(eobj);
                                    }} class="suggestion__external-link">
                                      <img src={external_link} alt="external link icon" /></a>


                                  </p>
                                )


                              })}






                            </div>)}






                        </details>

                      )

                  })}
              </div>

            </details>
          </section>

        )}
    </>
  );
};

export default index;
