import {graphql} from "relay-runtime";
export const UNIT_PAGE_GET_EPS_TOKEN_QUERY = graphql`
    query GraphQLQueryGetEPSTokenQuery($ResourceId: [String]!) {
      resourceSearch(ResourceId: $ResourceId) {
        Resources {
          ResourceDownloadUrl
          ResourceFileNameIfAny
          ResourceAccessUrl
          EpsAccessToken
        }
      }
    }
 `;

export const UNIT_PAGE_GET_EPS_TOKEN_STUDENT_QUERY = graphql`
    query GraphQLQueryGetEPSTokenStudentQuery(
      $ResourceId: [String]!
      $StudyGroupId: String!
      $SchoolYearId: String!
    ) {
      resourceSearch(
        ResourceId: $ResourceId
        StudyGroupId: $StudyGroupId
        SchoolYearId: $SchoolYearId
      ) {
        Resources {
          ResourceDownloadUrl
          ResourceFileNameIfAny
          ResourceAccessUrl
          EpsAccessToken
        }
      }
    }
  `;

export const UNIT_PAGE_STRUCTURE_RESOURCES_LIST_QUERY = graphql`
    query GraphQLQueryStructureResourcesListQuery(
      $StudyGroupId: String!
      $StructureId: String!
      $SchoolYearId: String!
      $UserId: String!
      $PageSize: Int!
      $PageNo: Int!
      $StudyGroupIds: [String]!
      $Credential: String
    ) {
      
      resourceCenterStructure(StructureId: $StructureId) {
        StructureLang {
          Lang
          StructureTitle
        }
        SummaryData(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)
        RelatedResources(
          StudyGroupId: $StudyGroupId
          PageSize: $PageSize
          PageNo: $PageNo
          SchoolYearId: $SchoolYearId
        ) {
          Resources {
            IngestionId
            ResourceVisibilityStatus(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            )
            AssignementInvloved(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              AssignmentName
            }
            MappedLevels {
              LevelName {
                LevelName
              }
            }
            Thumbnail {
              FilePath
            }
            SelfLearnConfiguration(
              StudyGroupId: $StudyGroupIds
              SchoolYearId: $SchoolYearId
            ) {
              ConfigName
              ConfigValue
            }

            SelfLearnAttemptHistory(UserId: $UserId) {
              IsCompleted
              Score
              MaxScore
              AttemptDate
              LearnositySessionRawResult
              LearnositySessionResult(Credential: $Credential)
              LastAttempt
            }
            ResourceReadStatus(
              StructureId: $StructureId
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              id
              StructureId
              ResourceId
              SchoolYearId
              UserId
              Status
              StatusTime
            }

            SelfLearnAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              Score
              MaxScore
              LearnositySessionRawResult
            }
            SelfLearnLastAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              Score
              MaxScore
              LearnositySessionResult
              LearnositySessionRawResult
            }
            VisibleByStudent
            ShareableToStudent
            ResourceId
            RelatedId
            ResourceType
            FileSource
            ResourceLangs {
              Lang
              ResourceName
            }
            Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
          }
          TotalNumberOfRecord
        }
      }
    }
  `;

export const UNIT_PAGE_STRUCTURE_LIST_QUERY = graphql`
    query GraphQLQueryStructureListQuery(
      $UserId: String!
      $BookSeriesIds: [String]
      $BookSeriesId: String
      $SchoolYearId: String!
      $StudyGroupId: String!
    ) {
      user(UserId: $UserId) {
        AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
          BookSeriesId
          DisplaySequence
          AdoptedBooks(SchoolYearId: $SchoolYearId) {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
            ResourceCenterStructure(
              SchoolYearId: $SchoolYearId
              StudyGroupId: $StudyGroupId
            ) {
              StructureId
              SummaryData(
                StudyGroupId: $StudyGroupId
                SchoolYearId: $SchoolYearId
              )
              StructureLang {
                Lang
                StructureTitle
              }
              ParentStructureId
              DisplaySequence
              ChildStructure {
                SummaryData(
                  StudyGroupId: $StudyGroupId
                  SchoolYearId: $SchoolYearId
                )
                StructureId
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructureId
                DisplaySequence
              }
            }
          }
          Books {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
            
            
          }
          BookSeriesName {
            BookSeriesName
            Lang
          }
          
        }
        BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
          StudyGroup {
            StudyGroupId
            StudyGroupName {
              StudyGroupName
              Lang
            }
            Level {
              LevelName {
                LevelName
              }
            }
          }
        }
        School {
          StudyGroups(
            BookSeriesId: $BookSeriesIds
            SchoolYearId: $SchoolYearId
            FilterOnboarded: false
          ) {
            StudyGroupId
            StudyGroupName {
              StudyGroupName
              Lang
            }
            Level {
              LevelName {
                LevelName
              }
            }
          }
        }
      }
    }
  `;

export const SCHOOL_BOOK_LIST_QUERY = graphql`
query GraphQLQuerySchoolBookListQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    AdoptedBookSeries {
      BookSeriesId
      BookSeriesName {
        Lang
        BookSeriesName
      }
      IsB2CBookSeries
      BookSeriesImage {
        FilePath
      }
      BookSeriesBGImage {
        FilePath
      }
      AdoptedBooks(SchoolYearId: $SchoolYearId) {
        id
        BookId
        BookName {
          Lang
          BookName
        }
        BookImage {
          FilePath
        }
        UsefulTools {
          id
          ToolsCode
          ToolsType
          ApplicableRole
          ToolsIconFile {
            FileName
            FilePath
            LastUpdateDate
          }
          UsefulToolsLangs {
            Lang
            ToolsName
            Description
            UsefulToolsId
          }
          WebURL
          WebURLChi
          IOSAppId
          IOSAppURL
          IOSAppURLChi
          IOSAppName
          Subject {
            SubjectCode
            SubjectName {
              Lang
              SubjectName
            }
            DisplaySequence
          }
          BookSeriesRel {
            id
            BookSeriesId
            UsefulToolsId
            DisplaySequence
            BookSeries {
              id
              BookSeriesId
              BookSeriesColorTone
              BookSeriesImage {
                FilePath
              }
              BookSeriesBGImage {
                FilePath
              }
              BookSeriesName {
                Lang
                BookSeriesName
              }
              DisplaySequence
            }
          }

          SubjectId
          AndroidAppId
          AndroidAppURL
          AndroidAppURLChi
          WebSupported
          TabletSupported
          MobileSupported
          PCSupported
          ApplicableRole
          EnterDate
          LastUpdateDate
          DisplaySequence
        }

        PearsonLevelId
        PearsonLevel {
          PearsonLevelId
          LevelName {
            PearsonLevelId
            Lang
            LevelName
          }
        }
      }
      Books {
        id
        BookId
        BookName {
          Lang
          BookName
        }
        BookImage {
          FilePath
        }
        UsefulTools {
          id
          ToolsCode
          ToolsType
          ApplicableRole
          ToolsIconFile {
            FileName
            FilePath
            LastUpdateDate
          }
          UsefulToolsLangs {
            Lang
            ToolsName
            Description
            UsefulToolsId
          }
          WebURL
          WebURLChi
          IOSAppId
          IOSAppURL
          IOSAppURLChi
          IOSAppName
          Subject {
            SubjectCode
            SubjectName {
              Lang
              SubjectName
            }
            DisplaySequence
          }
          BookSeriesRel {
            id
            BookSeriesId
            UsefulToolsId
            DisplaySequence
            BookSeries {
              id
              BookSeriesId
              BookSeriesColorTone
              BookSeriesImage {
                FilePath
              }
              BookSeriesBGImage {
                FilePath
              }
              BookSeriesName {
                Lang
                BookSeriesName
              }
              DisplaySequence
            }
          }

          SubjectId
          AndroidAppId
          AndroidAppURL
          AndroidAppURLChi
          WebSupported
          TabletSupported
          MobileSupported
          PCSupported
          ApplicableRole
          EnterDate
          LastUpdateDate
          DisplaySequence
        }

        PearsonLevelId
        PearsonLevel {
          PearsonLevelId
          LevelName {
            PearsonLevelId
            Lang
            LevelName
          }
        }
      }
    }
    School {
      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
        BookSeriesId
        DisplaySequence
        BookSeriesName {
          Lang
          BookSeriesName
        }
        LeapBookSeriesId
        IngestionId
        IsB2CBookSeries
        BookSeriesImage {
          FilePath
        }
        BookSeriesBGImage {
          FilePath
        }
        UsefulTools {
          id
          ToolsCode
          ToolsType
          ApplicableRole
          ToolsIconFile {
            FileName
            FilePath
            LastUpdateDate
          }
          UsefulToolsLangs {
            Lang
            ToolsName
            Description
            UsefulToolsId
          }
          WebURL
          WebURLChi
          IOSAppId
          IOSAppURL
          IOSAppURLChi
          IOSAppName
          Subject {
            SubjectCode
            SubjectName {
              Lang
              SubjectName
            }
            DisplaySequence
            BookSeries {
              BookSeriesName {
                Lang
                BookSeriesName
              }
            }
          }
          SubjectId
          AndroidAppId
          AndroidAppURL
          AndroidAppURLChi
          WebSupported
          TabletSupported
          MobileSupported
          PCSupported
          ApplicableRole
          EnterDate
          LastUpdateDate
          DisplaySequence
        }
        AdoptedBooks(SchoolYearId: $SchoolYearId) {
          id
          BookId
          BookName {
            Lang
            BookName
          }
          BookImage {
            FilePath
          }
          UsefulTools {
            id
            ToolsCode
            ToolsType
            ApplicableRole
            ToolsIconFile {
              FileName
              FilePath
              LastUpdateDate
            }
            UsefulToolsLangs {
              Lang
              ToolsName
              Description
              UsefulToolsId
            }
            WebURL
            WebURLChi
            IOSAppId
            IOSAppURL
            IOSAppURLChi
            IOSAppName
            Subject {
              SubjectCode
              SubjectName {
                Lang
                SubjectName
              }
              DisplaySequence
            }
            BookSeriesRel {
              id
              BookSeriesId
              UsefulToolsId
              DisplaySequence
              BookSeries {
                id
                BookSeriesId
                BookSeriesColorTone
                BookSeriesImage {
                  FilePath
                }
                BookSeriesBGImage {
                  FilePath
                }
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
                DisplaySequence
              }
            }

            SubjectId
            AndroidAppId
            AndroidAppURL
            AndroidAppURLChi
            WebSupported
            TabletSupported
            MobileSupported
            PCSupported
            ApplicableRole
            EnterDate
            LastUpdateDate
            DisplaySequence
          }

          PearsonLevelId
          PearsonLevel {
            PearsonLevelId
            LevelName {
              PearsonLevelId
              Lang
              LevelName
            }
          }
        }
        Books {
          id
          BookId
          BookName {
            Lang
            BookName
          }
          BookImage {
            FilePath
          }
          UsefulTools {
            id
            ToolsCode
            ToolsType
            ApplicableRole
            ToolsIconFile {
              FileName
              FilePath
              LastUpdateDate
            }
            UsefulToolsLangs {
              Lang
              ToolsName
              Description
              UsefulToolsId
            }
            WebURL
            WebURLChi
            IOSAppId
            IOSAppURL
            IOSAppURLChi
            IOSAppName
            Subject {
              SubjectCode
              SubjectName {
                Lang
                SubjectName
              }
              DisplaySequence
            }
            BookSeriesRel {
              id
              BookSeriesId
              UsefulToolsId
              DisplaySequence
              BookSeries {
                id
                BookSeriesId
                BookSeriesColorTone
                BookSeriesImage {
                  FilePath
                }
                BookSeriesBGImage {
                  FilePath
                }
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
                DisplaySequence
              }
            }

            SubjectId
            AndroidAppId
            AndroidAppURL
            AndroidAppURLChi
            WebSupported
            TabletSupported
            MobileSupported
            PCSupported
            ApplicableRole
            EnterDate
            LastUpdateDate
            DisplaySequence
          }

          PearsonLevelId
          PearsonLevel {
            PearsonLevelId
            LevelName {
              PearsonLevelId
              Lang
              LevelName
            }
          }
        }
      }
    }
  }
}
`;
