/**
 * @flow
 * @relayHash 4232b1064ca9a15f56d8d8c6dd999d8c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReadResourceAssignmentMutationVariables = {|
  ResourceAssignId: string
|};
export type ReadResourceAssignmentMutationResponse = {|
  +readResourceAssignment: ?string
|};
export type ReadResourceAssignmentMutation = {|
  variables: ReadResourceAssignmentMutationVariables,
  response: ReadResourceAssignmentMutationResponse,
|};
*/


/*
mutation ReadResourceAssignmentMutation(
  $ResourceAssignId: String!
) {
  readResourceAssignment(ResourceAssignId: $ResourceAssignId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "readResourceAssignment",
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceAssignId",
        "variableName": "ResourceAssignId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReadResourceAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReadResourceAssignmentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReadResourceAssignmentMutation",
    "id": null,
    "text": "mutation ReadResourceAssignmentMutation(\n  $ResourceAssignId: String!\n) {\n  readResourceAssignment(ResourceAssignId: $ResourceAssignId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae6caebd911727b8f2425e0088bdf7cf';
module.exports = node;
