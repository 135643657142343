//Main
import React, { Component } from "react";
//Component
import "./Footer.scss";
import useInAppBrowser from "../../../hooks/useInAppBrowser";
//Css

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
    };
  }


  onClickOpenLink = (link) => {
    // return;
    if (window.cordova) {
      window.cordova.InAppBrowser.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };
  render() {
    const { year } = this.state;

    return (
      <>
        {/* <footer className="footer1 font-small blue bottom-footer">
          <div className="footer-copyright1 text-center py-2">
            <div className="footer-part1">
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  Pearson Hong Kong
                </a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>Legal Notice</a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  Universal Terms of Service
                </a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  End User license agreement
                </a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>privacy notice</a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  acceptable use policy
                </a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  personal information collection statement
                </a>
                <span>|</span>
              </p>
            </div>
            <div className="footer-part2">
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  infringement notification
                </a>
                <span>|</span>
              </p>
              <p>
                <a onClick={() => this.onClickOpenLink("#")}>
                  technical support
                </a>
              </p>
            </div>
          </div>
          <div className="footers-copyright1">
            <span style={{ whiteSpace: "nowrap" }}>
              © Pearson Education Asia Limited {year}. All rights reserved.
            </span>
          </div>
        </footer> */}
        {/* <div className="updated-footers">
          <div className="new-footer">
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              Pearson Hong Kong
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              Legal Notice
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              Universal Terms of Service
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              End User license agreement
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              privacy notice
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              acceptable use policy
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              personal information collection statement
            </a>
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              infringement notification
            </a>
            |
            <a onClick={() => this.onClickOpenLink("#")} href="#">
              technical support
            </a>
          </div>
          <div className="copyright">
            © Pearson Education Asia Limited {year}. All rights reserved.
          </div>
        </div> */}
        <div className="updated-footers">
          <div className="new-footer">
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/end-user-license-agreement.html")} >
              End User License Agreement
            </a>
            |
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/universal-terms-of-service.html")} >
              Universal Terms of Service
            </a>
            |
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/acceptable-use-policy.html")} >
              Acceptable Use Policy
            </a>
            |
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/privacy-policy.html")} >
              Privacy Policy
            </a>
            |
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/personal-information-collection-statement.html")} >
              Personal Information Collection Statement
            </a>
            |
            <a onClick={() => this.onClickOpenLink("https://www.pearson.com.hk/en_GB/image-credits.html")} >
              Image Credits
            </a>
          </div>
          <div className="copyright">
            Copyright © {year} Pearson Education Asia Limited. All rights
            reserved.
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
