/**
 * @flow
 * @relayHash 0c8023932e824d75c39dbc77a81355a8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type B2CAssessmentInfoQueryVariables = {|
  UserId: string,
  BookSeriesId?: ?string,
  BookId: string,
  ResourceId?: ?$ReadOnlyArray<?string>,
|};
export type B2CAssessmentInfoQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +RelatedId: ?string,
      +ResourceId: ?string,
      +IngestionId: ?string,
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +ResourceName: ?string,
      |}>,
    |}>
  |},
  +user: ?{|
    +UserName: ?string,
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesName: ?string,
        +Lang: ?string,
        +BookLabel: ?string,
        +UnitLabel: ?string,
        +ChapterLabel: ?string,
      |}>,
      +IngestionId: ?string,
      +Books: ?$ReadOnlyArray<?{|
        +DisplaySequence: ?number,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +Units: ?$ReadOnlyArray<?{|
          +UnitId: ?string,
          +UnitName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +UnitName: ?string,
            +UnitTitle: ?string,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
export type B2CAssessmentInfoQuery = {|
  variables: B2CAssessmentInfoQueryVariables,
  response: B2CAssessmentInfoQueryResponse,
|};
*/


/*
query B2CAssessmentInfoQuery(
  $UserId: String!
  $BookSeriesId: String
  $BookId: String!
  $ResourceId: [String]
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      RelatedId
      ResourceId
      IngestionId
      ResourceLangs {
        Lang
        ResourceName
      }
      id
    }
  }
  user(UserId: $UserId) {
    UserName
    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
      BookSeriesId
      BookSeriesName {
        BookSeriesName
        Lang
        BookLabel
        UnitLabel
        ChapterLabel
      }
      IngestionId
      Books(BookId: $BookId) {
        DisplaySequence
        BookId
        BookName {
          Lang
          BookName
        }
        Units {
          UnitId
          UnitName {
            Lang
            UnitName
            UnitTitle
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "RelatedId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "String"
  }
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ChapterLabel",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String"
  }
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UnitId",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UnitName",
  "storageKey": null,
  "args": null,
  "concreteType": "unitLang",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitTitle",
      "args": null,
      "storageKey": null
    }
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "B2CAssessmentInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v12/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "B2CAssessmentInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v12/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ]
                  },
                  (v18/*: any*/)
                ]
              },
              (v18/*: any*/)
            ]
          },
          (v18/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "B2CAssessmentInfoQuery",
    "id": null,
    "text": "query B2CAssessmentInfoQuery(\n  $UserId: String!\n  $BookSeriesId: String\n  $BookId: String!\n  $ResourceId: [String]\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      RelatedId\n      ResourceId\n      IngestionId\n      ResourceLangs {\n        Lang\n        ResourceName\n      }\n      id\n    }\n  }\n  user(UserId: $UserId) {\n    UserName\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      BookSeriesName {\n        BookSeriesName\n        Lang\n        BookLabel\n        UnitLabel\n        ChapterLabel\n      }\n      IngestionId\n      Books(BookId: $BookId) {\n        DisplaySequence\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        Units {\n          UnitId\n          UnitName {\n            Lang\n            UnitName\n            UnitTitle\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b4ff5864e70b149742bf32c7050dedd';
module.exports = node;
