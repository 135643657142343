/**
 * @flow
 * @relayHash 36363f224f2e7ad1d582ef0eeab62490
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetUserDataQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type GetUserDataQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +UserName: ?string,
    +UserType: ?string,
    +UserDisplayName: ?$ReadOnlyArray<?{|
      +Lang: ?string,
      +UserName: ?string,
    |}>,
    +Preferences: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +AdoptedSchoolYear: ?$ReadOnlyArray<?{|
      +SchoolYearId: ?string,
      +SchoolYearName: ?string,
      +IsEffective: ?string,
      +EffectiveStartDate: ?string,
      +EffectiveEndDate: ?string,
    |}>,
    +PreferencesWithYear: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +School: ?{|
      +SchoolCode: ?string,
      +SchoolAdoptedBookSeries: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +LeapBookSeriesId: ?string,
        +IngestionId: ?string,
      |}>,
    |},
  |}
|};
export type GetUserDataQuery = {|
  variables: GetUserDataQueryVariables,
  response: GetUserDataQueryResponse,
|};
*/


/*
query GetUserDataQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    UserId
    UserName
    UserType
    UserDisplayName {
      Lang
      UserName
    }
    Preferences {
      PreferenceName
      PreferenceValue
      SchoolYearId
      id
    }
    AdoptedSchoolYear {
      SchoolYearId
      SchoolYearName
      IsEffective
      EffectiveStartDate
      EffectiveEndDate
      id
    }
    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {
      PreferenceName
      PreferenceValue
      SchoolYearId
      id
    }
    School {
      SchoolCode
      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
        BookSeriesId
        LeapBookSeriesId
        IngestionId
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/)
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PreferenceName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PreferenceValue",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearId",
  "args": null,
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsEffective",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EffectiveStartDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EffectiveEndDate",
  "args": null,
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  }
],
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolCode",
  "args": null,
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapBookSeriesId",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v21 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v20/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetUserDataQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Preferences",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v9/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedSchoolYear",
            "storageKey": null,
            "args": null,
            "concreteType": "schoolYear",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "PreferencesWithYear",
            "name": "Preferences",
            "storageKey": null,
            "args": (v14/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v9/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SchoolAdoptedBookSeries",
                "storageKey": null,
                "args": (v16/*: any*/),
                "concreteType": "bookSeries",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GetUserDataQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Preferences",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v21/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedSchoolYear",
            "storageKey": null,
            "args": null,
            "concreteType": "schoolYear",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v20/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "PreferencesWithYear",
            "name": "Preferences",
            "storageKey": null,
            "args": (v14/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v21/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SchoolAdoptedBookSeries",
                "storageKey": null,
                "args": (v16/*: any*/),
                "concreteType": "bookSeries",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ]
              },
              (v20/*: any*/)
            ]
          },
          (v20/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GetUserDataQuery",
    "id": null,
    "text": "query GetUserDataQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    UserName\n    UserType\n    UserDisplayName {\n      Lang\n      UserName\n    }\n    Preferences {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    AdoptedSchoolYear {\n      SchoolYearId\n      SchoolYearName\n      IsEffective\n      EffectiveStartDate\n      EffectiveEndDate\n      id\n    }\n    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    School {\n      SchoolCode\n      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {\n        BookSeriesId\n        LeapBookSeriesId\n        IngestionId\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5049d17299908b9e1748faf76a383e1';
module.exports = node;
