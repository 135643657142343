import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetUserDataQuery = graphql`
  query GetUserDataQuery($UserId: String!, $SchoolYearId: String!) {
    user(UserId: $UserId) {
      UserId
      UserName
      UserType
      UserDisplayName {
        Lang
        UserName
      }
      Preferences {
        PreferenceName
        PreferenceValue
        SchoolYearId
      }
      AdoptedSchoolYear {
        SchoolYearId
        SchoolYearName
        IsEffective
        EffectiveStartDate
        EffectiveEndDate
      }
      PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {
        PreferenceName
        PreferenceValue
        SchoolYearId
      }
      School {
        SchoolCode
        SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
          BookSeriesId
          LeapBookSeriesId
          IngestionId
        }
      }
    }
  }
`;

const GetUserData = (callback, from) => {
  try {
    const variables = {
      UserId:
        // from == "App"
        localStorage.getItem("in-app-browser-data-userid") ||
        JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: JSON.parse(localStorage.getItem("effectiveSchoolYear"))
        .SchoolYearId,
    };

    fetchQuery(environment, GetUserDataQuery, variables).then((data) => {
      console.log(data);

      return callback(JSON.stringify(data.user));
    });
  } catch (e) {
    console.log("GetUserData Error");
  }

  return null;
};

export default GetUserData;
