/**
 * @flow
 * @relayHash 9888b0e6597723ea08aee17745f464c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetSmartClassTokenQueryVariables = {||};
export type GetSmartClassTokenQueryResponse = {|
  +smartClassSSOToken: ?string
|};
export type GetSmartClassTokenQuery = {|
  variables: GetSmartClassTokenQueryVariables,
  response: GetSmartClassTokenQueryResponse,
|};
*/


/*
query GetSmartClassTokenQuery {
  smartClassSSOToken
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "smartClassSSOToken",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetSmartClassTokenQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GetSmartClassTokenQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "GetSmartClassTokenQuery",
    "id": null,
    "text": "query GetSmartClassTokenQuery {\n  smartClassSSOToken\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79e431607f3ec5e605c279781dfdd5fd';
module.exports = node;
