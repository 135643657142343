/**
 * @flow
 * @relayHash fb8aecec4748510e0a9a9ac06217f983
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewAllNotificationSubjectListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type ViewAllNotificationSubjectListQueryResponse = {|
  +user: ?{|
    +AssignmentBookSeriesList: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +DisplaySequence: ?number,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +BookSeriesName: ?string,
      |}>,
    |}>
  |}
|};
export type ViewAllNotificationSubjectListQuery = {|
  variables: ViewAllNotificationSubjectListQueryVariables,
  response: ViewAllNotificationSubjectListQueryResponse,
|};
*/


/*
query ViewAllNotificationSubjectListQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {
      BookSeriesId
      DisplaySequence
      BookSeriesName {
        BookSeriesId
        BookSeriesName
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewAllNotificationSubjectListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentBookSeriesList",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewAllNotificationSubjectListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentBookSeriesList",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewAllNotificationSubjectListQuery",
    "id": null,
    "text": "query ViewAllNotificationSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {\n      BookSeriesId\n      DisplaySequence\n      BookSeriesName {\n        BookSeriesId\n        BookSeriesName\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ddd42d9410397cfeea8a3e431bbedb0';
module.exports = node;
