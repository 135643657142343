import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { fetchQuery, graphql } from "relay-runtime";
import moment from "moment";
import environment from "../../Environment";
import Loading from "../../Components/Loading/PEC/Loading";
import "./index.scss";
import DashboardStudyGroup from "../../Services/api/GraphQL/Query/DashboardStudyGroup";
import Content from "./Body";
//import {useSelector, useDispatch} from "react-redux";

// import AdoptedBookSeriesInfo from "../../Services/api/GraphQL/Query/AdoptedBookSeriesInfo";

const index = (props) => {
  const { t, afterLogout, B2C } = props;
  // const dispatch = useDispatch();
  // const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  // const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const role = userData?.UserType;
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const selectOptionsSDL = useSelector(
    (state) => state.EntryOfResourceReducer.selectOptions
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);

  // const [levelLoading, setlevelLoading] = useState(false);

  const [selectedBookId, setselectedBookId] = useState(
    selectOptionsSDL.selectedBookId
  );
  const [selectedBookName, setselectedBookName] = useState(
    selectOptionsSDL.selectedBookName
  );
  const [selectedBookSeriesId, setselectedBookSeriesId] = useState(
    selectOptionsSDL.selectedBookSeriesId ? selectOptionsSDL.selectedBookSeriesId:props.location.pathname.split("/")[3]
  );
  const [selectedBookSeriesName, setselectedBookSeriesName] = useState(
    selectOptionsSDL.selectedBookSeriesName
  );
  // const [selectedBookId, setselectedBookId] = useState(
  //   props.location.state.bookIdArg ? props.location.state.bookIdArg: selectOptionsSDL.selectedBookId
  // );
  // const [selectedBookName, setselectedBookName] = useState(
  //   props.location.state.bookNameArg ? props.location.state.bookNameArg : selectOptionsSDL.selectedBookName
  // );
  // const [selectedBookSeriesId, setselectedBookSeriesId] = useState(
  //   props.location.state.bookSeriesIdArg ? props.location.state.bookSeriesIdArg : selectOptionsSDL.selectedBookSeriesId
  // );
  // const [selectedBookSeriesName, setselectedBookSeriesName] = useState(
  //   props.location.state.bookSeriesNameArg ? props.location.state.bookSeriesNameArg: selectOptionsSDL.selectedBookSeriesName
  // );
  const [levelLoading, setlevelLoading] = useState(false);

  const [studyGroupList, setstudyGroupList] = useState([]);

  const [allBookData, setallBookData] = useState([]);
  const [bookData, setbookData] = useState([]);
  const [studentClassId, setStudentClassId] = useState([]);

  const ClassViewLayoutPECGetSchoolBookSeriesListPecQuery = graphql`
    query ClassViewLayoutPECGetSchoolBookSeriesListPecQuery(
      $UserId: String!
      $SchoolYearId: String!
    ) {
      user(UserId: $UserId) {
        AdoptedBookSeries {
          BookSeriesId
          DisplaySequence
          IsB2CBookSeries
          BookSeriesImage {
            FilePath
          }
          BookSeriesBGImage {
            FilePath
          }
          AdoptedBooks(SchoolYearId: $SchoolYearId) {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
            UsefulTools {
              id
              ToolsCode
              ToolsType
              ApplicableRole
              ToolsIconFile {
                FileName
                FilePath
                LastUpdateDate
              }
              UsefulToolsLangs {
                Lang
                ToolsName
                Description
                UsefulToolsId
              }
              WebURL
              WebURLChi
              IOSAppId
              IOSAppURL
              IOSAppURLChi
              IOSAppName
              Subject {
                SubjectCode
                SubjectName {
                  Lang
                  SubjectName
                }
                DisplaySequence
              }
              BookSeriesRel {
                id
                BookSeriesId
                UsefulToolsId
                DisplaySequence
                BookSeries {
                  id
                  BookSeriesId
                  BookSeriesColorTone
                  BookSeriesImage {
                    FilePath
                  }
                  BookSeriesBGImage {
                    FilePath
                  }
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                  DisplaySequence
                }
              }

              SubjectId
              AndroidAppId
              AndroidAppURL
              AndroidAppURLChi
              WebSupported
              TabletSupported
              MobileSupported
              PCSupported
              ApplicableRole
              EnterDate
              LastUpdateDate
              DisplaySequence
            }

            PearsonLevelId
            PearsonLevel {
              PearsonLevelId
              LevelName {
                PearsonLevelId
                Lang
                LevelName
              }
            }
          }
          Books {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
            UsefulTools {
              id
              ToolsCode
              ToolsType
              ApplicableRole
              ToolsIconFile {
                FileName
                FilePath
                LastUpdateDate
              }
              UsefulToolsLangs {
                Lang
                ToolsName
                Description
                UsefulToolsId
              }
              WebURL
              WebURLChi
              IOSAppId
              IOSAppURL
              IOSAppURLChi
              IOSAppName
              Subject {
                SubjectCode
                SubjectName {
                  Lang
                  SubjectName
                }
                DisplaySequence
              }
              BookSeriesRel {
                id
                BookSeriesId
                UsefulToolsId
                DisplaySequence
                BookSeries {
                  id
                  BookSeriesId
                  BookSeriesColorTone
                  BookSeriesImage {
                    FilePath
                  }
                  BookSeriesBGImage {
                    FilePath
                  }
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                  DisplaySequence
                }
              }

              SubjectId
              AndroidAppId
              AndroidAppURL
              AndroidAppURLChi
              WebSupported
              TabletSupported
              MobileSupported
              PCSupported
              ApplicableRole
              EnterDate
              LastUpdateDate
              DisplaySequence
            }

            PearsonLevelId
            PearsonLevel {
              PearsonLevelId
              LevelName {
                PearsonLevelId
                Lang
                LevelName
              }
            }
          }
        }
        School {
          SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
            BookSeriesId
            DisplaySequence
            BookSeriesName {
              Lang
              BookSeriesName
            }
            LeapBookSeriesId
            IngestionId
            IsB2CBookSeries
            BookSeriesImage {
              FilePath
            }
            BookSeriesBGImage {
              FilePath
            }
            UsefulTools {
              id
              ToolsCode
              ToolsType
              ApplicableRole
              ToolsIconFile {
                FileName
                FilePath
                LastUpdateDate
              }
              UsefulToolsLangs {
                Lang
                ToolsName
                Description
                UsefulToolsId
              }
              WebURL
              WebURLChi
              IOSAppId
              IOSAppURL
              IOSAppURLChi
              IOSAppName
              Subject {
                SubjectCode
                SubjectName {
                  Lang
                  SubjectName
                }
                DisplaySequence
                BookSeries {
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
              SubjectId
              AndroidAppId
              AndroidAppURL
              AndroidAppURLChi
              WebSupported
              TabletSupported
              MobileSupported
              PCSupported
              ApplicableRole
              EnterDate
              LastUpdateDate
              DisplaySequence
            }
            AdoptedBooks(SchoolYearId: $SchoolYearId) {
              id
              BookId
              BookName {
                Lang
                BookName
              }
              BookImage {
                FilePath
              }
              UsefulTools {
                id
                ToolsCode
                ToolsType
                ApplicableRole
                ToolsIconFile {
                  FileName
                  FilePath
                  LastUpdateDate
                }
                UsefulToolsLangs {
                  Lang
                  ToolsName
                  Description
                  UsefulToolsId
                }
                WebURL
                WebURLChi
                IOSAppId
                IOSAppURL
                IOSAppURLChi
                IOSAppName
                Subject {
                  SubjectCode
                  SubjectName {
                    Lang
                    SubjectName
                  }
                  DisplaySequence
                }
                BookSeriesRel {
                  id
                  BookSeriesId
                  UsefulToolsId
                  DisplaySequence
                  BookSeries {
                    id
                    BookSeriesId
                    BookSeriesColorTone
                    BookSeriesImage {
                      FilePath
                    }
                    BookSeriesBGImage {
                      FilePath
                    }
                    BookSeriesName {
                      Lang
                      BookSeriesName
                    }
                    DisplaySequence
                  }
                }

                SubjectId
                AndroidAppId
                AndroidAppURL
                AndroidAppURLChi
                WebSupported
                TabletSupported
                MobileSupported
                PCSupported
                ApplicableRole
                EnterDate
                LastUpdateDate
                DisplaySequence
              }

              PearsonLevelId
              PearsonLevel {
                PearsonLevelId
                LevelName {
                  PearsonLevelId
                  Lang
                  LevelName
                }
              }
            }
            Books {
              id
              BookId
              BookName {
                Lang
                BookName
              }
              BookImage {
                FilePath
              }
              UsefulTools {
                id
                ToolsCode
                ToolsType
                ApplicableRole
                ToolsIconFile {
                  FileName
                  FilePath
                  LastUpdateDate
                }
                UsefulToolsLangs {
                  Lang
                  ToolsName
                  Description
                  UsefulToolsId
                }
                WebURL
                WebURLChi
                IOSAppId
                IOSAppURL
                IOSAppURLChi
                IOSAppName
                Subject {
                  SubjectCode
                  SubjectName {
                    Lang
                    SubjectName
                  }
                  DisplaySequence
                }
                BookSeriesRel {
                  id
                  BookSeriesId
                  UsefulToolsId
                  DisplaySequence
                  BookSeries {
                    id
                    BookSeriesId
                    BookSeriesColorTone
                    BookSeriesImage {
                      FilePath
                    }
                    BookSeriesBGImage {
                      FilePath
                    }
                    BookSeriesName {
                      Lang
                      BookSeriesName
                    }
                    DisplaySequence
                  }
                }

                SubjectId
                AndroidAppId
                AndroidAppURL
                AndroidAppURLChi
                WebSupported
                TabletSupported
                MobileSupported
                PCSupported
                ApplicableRole
                EnterDate
                LastUpdateDate
                DisplaySequence
              }

              PearsonLevelId
              PearsonLevel {
                PearsonLevelId
                LevelName {
                  PearsonLevelId
                  Lang
                  LevelName
                }
              }
            }
          }
        }
      }
    }
  `;
  useEffect(() => {
    //setselectedBookSeriesId(props.location.pathname.split("/")[3]);
    myBookData();
    if (role == "T") {
      if (selectedBookSeriesId != "") {
      handleStudyGroup();}
    }
    if (role == "S") {
      if (selectedBookSeriesId != "") {
        handleStudyGroupStudent();
      }
    }
    // if (role == "T") {
    //   handleStudyGroup();
    // }
  }, []);

  // useEffect(() => {
  //   //alert(selectedBookSeriesId)
  //   //alert("book");
    
  // }, [selectedBookSeriesId]);

  const updateBookData = (bookIdParam) => {
    // setlevelLoading(true)
    dispatch({
      type: "SET_SELECTED_BOOK",
      selectOptions: {
        selectedClassLevelId : "",
        selectedClassLevelName: "",
       },
    });
    setselectedBookId(bookIdParam);
    var bookDataCur = allBookData.filter((data) => {
      return data.BookId == bookIdParam; //781,activeMenu
    });
    setbookData(bookDataCur[0]);
    setselectedBookName(bookDataCur[0].BookName[0].BookName);


    // setlevelLoading(false)
  };

  const handleStudyGroupStudent = () => {
    const variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      FilterOnboarded: false,
    };

    DashboardStudyGroup(variables, role).then((data) => {
      const {
        user: { BelongsToStudyGroup = [{}] },
      } = data || {};

      let bookseries = [];
      BelongsToStudyGroup.map((obj, i) => {
        if (
          bookseries
            .map((b) => b.BookSeriesId)
            .indexOf(obj.StudyGroup.BookSeries.BookSeriesId) == -1
        ) {
          bookseries.push(obj.StudyGroup.BookSeries);
        }
      });
      let filterList = bookseries.filter(
        (o) => o.IsB2CBookSeries == false && o.IngestionId != null
      );

      if (selectedBookSeriesId != "") {
        let defaultGroup = BelongsToStudyGroup.filter((obj) => {
          return (
            (obj.StudyGroup.BookSeries.IngestionId != null ||
              obj.StudyGroup.BookSeries.IngestionId != "") &&
            obj.StudyGroup.BookSeries.BookSeriesId == selectedBookSeriesId
          );
        });

        setStudentClassId(defaultGroup[0].StudyGroupId);
      }
    });
  };

  const handleStudyGroup = () => {
    setlevelLoading(true);
    const variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      FilterOnboarded: false,
    };
    DashboardStudyGroup(variables, role).then((data) => {
      const { user: { School: { StudyGroups } = {} } = {} } = data || {};

      let defaultClass;
      const studyGroupList = StudyGroups.reduce((acc, current) => {
        const {
          StudyGroupId,
          StudyGroupName,
          BookSeries,
          Level,
          StudyGroupInSameLevel,
        } = current;

        defaultClass = [
          {
            StudyGroupId: "null",
            StudyGroupName: [
              {
                StudyGroupName: "--Please Select--",
                Lang: "en-US",
              },
              {
                StudyGroupName: "我的班別",
                Lang: "zh-HK",
              },
            ],
            bookseriesId: BookSeries.BookSeriesId,
            level: Level,
            BookSeriesName: BookSeries.BookSeriesName,
          },
        ];

        acc.push({
          StudyGroupId: StudyGroupId,
          StudyGroupName: StudyGroupName,
          bookseriesId: BookSeries.BookSeriesId,
          level: Level,
          BookSeriesName: BookSeries.BookSeriesName,
        });

        return acc;
      }, []);
      studyGroupList.sort(function (a, b) {
        return a.StudyGroupName[
          a.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName.localeCompare(
          b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
            .StudyGroupName
        );
      });
      const filteredArr = studyGroupList.reduce((acc, current) => {
        const x = acc.find(
          (item) => item.StudyGroupId === current.StudyGroupId
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const findSameBookId = filteredArr.filter((data) => {
        return data.bookseriesId == selectedBookSeriesId; //781,activeMenu
      });

      const finalList = [...findSameBookId];

      // getStudyGroupName(findSameBookId);
      //setnonfilterstudyGroupList(filteredArr);

      setstudyGroupList(finalList);
      // setClassId(finalList[0]?.StudyGroupId);

      // setSelectGroupClass(
      //   finalList[0]?.StudyGroupName[[0].Lang == lang ? 0 : 1]
      //     .StudyGroupName
      // );
    });
  };
  const myBookData = () => {
    var values = {
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    };
    fetchQuery(
      environment,
      ClassViewLayoutPECGetSchoolBookSeriesListPecQuery,
      values
    ).then((data) => {
      setLoadingLogo(true);
      var listArray = [];
      data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
        if (obj.IngestionId == null || obj.IngestionId == "") return;
        listArray.push(obj);
      });
      listArray.sort(function (a, b) {
        return a.DisplaySequence - b.DisplaySequence;
      });

      if (selectedBookId != "") {
        let booksBelongsToSeries = listArray.filter((data) => {
          return data.BookSeriesId == selectedBookSeriesId; //781,activeMenu
        });
        setallBookData(booksBelongsToSeries[0].AdoptedBooks);
        var bookDataCur = booksBelongsToSeries[0].AdoptedBooks.filter(
          (data) => {
            return data.BookId == selectedBookId; //781,activeMenu
          }
        );
        setbookData(bookDataCur[0]);
      } else {
        let booksBelongsToSeries = listArray.filter((data) => {
          return data.BookSeriesId == props.location.pathname.split("/")[3]; //781,activeMenu
        });
        setallBookData(booksBelongsToSeries[0].AdoptedBooks);
        //setselectedBookSeriesId(props.location.pathname.split("/")[3]);
        setselectedBookSeriesName(
          booksBelongsToSeries[0].BookSeriesName[0].BookSeriesName
        );
        setbookData(booksBelongsToSeries[0].AdoptedBooks[0]);
        setselectedBookId(booksBelongsToSeries[0].AdoptedBooks[0].BookId);
        setselectedBookName(
          booksBelongsToSeries[0].AdoptedBooks[0].BookName[0].BookName
        );
      }

      setLoadingLogo(false);
    });
  };

  if (!userData) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {loadingLogo ? (
        <div className="logo-image-loading">
          <Loading type={`normal`} />
        </div>
      ) : (
        <>
          {UserType == "T" &&
            !B2C && ( //teacher enter study group from dashboard
              <Switch>
                <Route
                  path="/s/studygroup/:id"
                  component={(p) => (
                    <Content
                      {...p}
                      bookData={bookData}
                      allBookData={allBookData}
                      selectedBookSeriesId={selectedBookSeriesId}
                      selectedBookName={selectedBookName}
                      selectedBookId={selectedBookId}
                      selectedBookSeriesName={selectedBookSeriesName}
                      updateBookData={updateBookData}
                      studyGroupList={studyGroupList}
                      studentClassId={studentClassId}
                      //selectClass={selectClass}
                      //selectGroupClass={selectGroupClass}
                      //ClassId={ClassId}
                      // setClassId={(data) => {

                      //   setClassId(data);
                      // }}
                      // setSelectClass={(data) => {

                      //   setSelectClass(data);
                      // }}
                      // setSelectGroupClass={(data) => {

                      //   setSelectGroupClass(data);
                      // }}

                      // levelLoading={levelLoading}
                      //setlevelLoading = {(val)=> setlevelLoading(val)}
                      //afterLogout={this.props.afterLogout}
                      //jsonData={jsonData}
                      t={t}
                    />
                  )}
                />

                {window.cordova ? (
                  <Redirect exact from="/a" to="/m/myschool" />
                ) : (
                  <Redirect exact from="/a" to="/m/myschool" />
                )}
                <Redirect exact from="/s/studygroup" to="/m/myschool" />
                <Redirect exact from="/s" to="/errorcode=404" />
              </Switch>
            )}

          {UserType == "S" &&
            !B2C && ( //student enter study group from dashboard
              <Switch>
                <Route
                  path="/s/studygroup/:id"
                  component={(p) => (
                    <Content
                      {...p}
                      //afterLogout={this.props.afterLogout}
                      //jsonData={jsonData}
                      t={t}
                      bookData={bookData}
                      allBookData={allBookData}
                      selectedBookSeriesId={selectedBookSeriesId}
                      selectedBookName={selectedBookName}
                      selectedBookId={selectedBookId}
                      studentClassId={studentClassId}
                      selectedBookSeriesName={selectedBookSeriesName}
                      updateBookData={updateBookData}
                      studyGroupList={studyGroupList}

                      // selectClass={selectClass}
                      // selectGroupClass={selectGroupClass}
                      // ClassId={ClassId}
                      // setSelectClass={(data) => {

                      //   setSelectClass(data);
                      // }}
                      // setSelectGroupClass={(data) => {

                      //   setSelectGroupClass(data);
                      // }}
                    />
                  )}
                />

                {window.cordova ? (
                  <Redirect exact from="/a" to="/m/myschool" />
                ) : (
                  <Redirect exact from="/a" to="/m/myschool" />
                )}
                <Redirect exact from="/s/studygroup" to="/m/myschool" />
                <Redirect exact from="/s" to="/errorcode=404" />
              </Switch>
            )}
        </>
      )}
    </>
  );
};

export default index;
