/**
 * @flow
 * @relayHash 2aea5555d9266bfad3edc61ad2cfcacd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardStudyGroupTeacherQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
  FilterOnboarded: boolean,
|};
export type DashboardStudyGroupTeacherQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
            +PearsonLevelId: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
        +LeapBookSeriesID: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IngestionId: ?string,
          +DisplaySequence: ?number,
          +BookSeriesImage: ?{|
            +FilePath: ?string
          |},
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +BookSeriesName: ?string,
            +Lang: ?string,
          |}>,
          +Subject: ?{|
            +SubjectName: ?$ReadOnlyArray<?{|
              +SubjectName: ?string,
              +Lang: ?string,
            |}>
          |},
        |},
        +LeapLevelId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
        +StudyGroupCode: ?string,
        +StudyGroupTheme: ?{|
          +ColorTone: ?string,
          +BookSeriesImage: ?{|
            +FilePath: ?string
          |},
        |},
        +StudyGroupInSameLevel: ?$ReadOnlyArray<?{|
          +StudyGroupId: ?string,
          +StudyGroupName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +StudyGroupName: ?string,
          |}>,
        |}>,
      |}>
    |},
  |}
|};
export type DashboardStudyGroupTeacherQuery = {|
  variables: DashboardStudyGroupTeacherQueryVariables,
  response: DashboardStudyGroupTeacherQueryResponse,
|};
*/


/*
query DashboardStudyGroupTeacherQuery(
  $UserId: String!
  $SchoolYearId: String!
  $FilterOnboarded: Boolean!
) {
  user(UserId: $UserId) {
    UserId
    School {
      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {
        StudyGroupId
        Level {
          PearsonLevelId
          LevelName {
            Lang
            LevelName
            PearsonLevelId
          }
          DisplaySequence
          id
        }
        LeapBookSeriesID
        BookSeries {
          BookSeriesId
          IngestionId
          DisplaySequence
          BookSeriesImage {
            FilePath
            id
          }
          BookSeriesName {
            BookSeriesName
            Lang
          }
          Subject {
            SubjectName {
              SubjectName
              Lang
            }
            id
          }
          id
        }
        LeapLevelId
        StudyGroupName {
          StudyGroupName
          Lang
        }
        StudyGroupCode
        StudyGroupTheme {
          ColorTone
          BookSeriesImage {
            FilePath
            id
          }
          id
        }
        StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
          StudyGroupId
          StudyGroupName {
            Lang
            StudyGroupName
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v4 = [
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PearsonLevelId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LevelName",
  "storageKey": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LevelName",
      "args": null,
      "storageKey": null
    },
    (v6/*: any*/)
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapBookSeriesID",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesImage",
  "storageKey": null,
  "args": null,
  "concreteType": "systemFile",
  "plural": false,
  "selections": [
    (v13/*: any*/)
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/)
  ]
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SubjectName",
  "storageKey": null,
  "args": null,
  "concreteType": "subjectLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "SubjectName",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/)
  ]
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapLevelId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v18/*: any*/),
    (v7/*: any*/)
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupCode",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ColorTone",
  "args": null,
  "storageKey": null
},
v22 = [
  (v3/*: any*/)
],
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v18/*: any*/)
  ]
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesImage",
  "storageKey": null,
  "args": null,
  "concreteType": "systemFile",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v24/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardStudyGroupTeacherQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupTheme",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeriesLevelDisplayOption",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v14/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupInSameLevel",
                    "storageKey": null,
                    "args": (v22/*: any*/),
                    "concreteType": "StudyGroup",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v23/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardStudyGroupTeacherQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v24/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v25/*: any*/),
                      (v15/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v24/*: any*/)
                        ]
                      },
                      (v24/*: any*/)
                    ]
                  },
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupTheme",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeriesLevelDisplayOption",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v25/*: any*/),
                      (v24/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupInSameLevel",
                    "storageKey": null,
                    "args": (v22/*: any*/),
                    "concreteType": "StudyGroup",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/)
                    ]
                  },
                  (v24/*: any*/)
                ]
              },
              (v24/*: any*/)
            ]
          },
          (v24/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardStudyGroupTeacherQuery",
    "id": null,
    "text": "query DashboardStudyGroupTeacherQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    UserId\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        Level {\n          PearsonLevelId\n          LevelName {\n            Lang\n            LevelName\n            PearsonLevelId\n          }\n          DisplaySequence\n          id\n        }\n        LeapBookSeriesID\n        BookSeries {\n          BookSeriesId\n          IngestionId\n          DisplaySequence\n          BookSeriesImage {\n            FilePath\n            id\n          }\n          BookSeriesName {\n            BookSeriesName\n            Lang\n          }\n          Subject {\n            SubjectName {\n              SubjectName\n              Lang\n            }\n            id\n          }\n          id\n        }\n        LeapLevelId\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        StudyGroupCode\n        StudyGroupTheme {\n          ColorTone\n          BookSeriesImage {\n            FilePath\n            id\n          }\n          id\n        }\n        StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {\n          StudyGroupId\n          StudyGroupName {\n            Lang\n            StudyGroupName\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d624ce084517484a464f5c775be938c';
module.exports = node;
