/**
 * @flow
 * @relayHash 72462c8e843f2663bafb9dbba0d05bfb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MyResourceReportQueryVariables = {|
  UserId: string,
  PearsonLevelId: string,
|};
export type MyResourceReportQueryResponse = {|
  +user: ?{|
    +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
      +ForReport: ?string,
      +id: ?string,
      +DisplaySequence: ?string,
      +HierarchyReference: ?string,
      +HierarchyReferenceLabelEn: ?string,
      +ReferenceLevelScore: ?$ReadOnlyArray<?{|
        +id: ?string,
        +HierarchyReference: ?string,
        +MyScore: ?string,
        +MyMaxScore: ?string,
        +AllScore: ?string,
        +AllMaxScore: ?string,
        +PearsonLevelId: ?string,
        +LastUpdateDate: ?string,
        +TagType: ?string,
        +TagName: ?string,
      |}>,
      +RelatedTagType: ?$ReadOnlyArray<?{|
        +id: ?string,
        +TagType: ?string,
        +EnterDate: ?string,
        +Tags: ?$ReadOnlyArray<?{|
          +id: ?string,
          +DisplaySequence: ?string,
          +TagName: ?string,
          +TagLongName: ?string,
          +TagDisplayNameEn: ?string,
          +TagDisplayNameChi: ?string,
        |}>,
        +ParentTagType: ?{|
          +TagType: ?string
        |},
      |}>,
    |}>
  |}
|};
export type MyResourceReportQuery = {|
  variables: MyResourceReportQueryVariables,
  response: MyResourceReportQueryResponse,
|};
*/


/*
query MyResourceReportQuery(
  $UserId: String!
  $PearsonLevelId: String!
) {
  user(UserId: $UserId) {
    AdoptedTagHierarchy {
      ForReport
      id
      DisplaySequence
      HierarchyReference
      HierarchyReferenceLabelEn
      ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {
        id
        HierarchyReference
        MyScore
        MyMaxScore
        AllScore
        AllMaxScore
        PearsonLevelId
        LastUpdateDate
        TagType
        TagName
      }
      RelatedTagType {
        id
        TagType
        EnterDate
        Tags {
          id
          DisplaySequence
          TagName
          TagLongName
          TagDisplayNameEn
          TagDisplayNameChi
        }
        ParentTagType {
          TagType
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "PearsonLevelId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ForReport",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReference",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReferenceLabelEn",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagType",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ReferenceLevelScore",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "PearsonLevelId",
      "variableName": "PearsonLevelId",
      "type": "String!"
    }
  ],
  "concreteType": "LearnosityTagLevelScore",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "MyScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "MyMaxScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AllScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AllMaxScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "PearsonLevelId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LastUpdateDate",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/)
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EnterDate",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "Tags",
  "storageKey": null,
  "args": null,
  "concreteType": "LearnosityTagHierarchyTagType",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagLongName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagDisplayNameEn",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "TagDisplayNameChi",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MyResourceReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedTagHierarchy",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchy",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "RelatedTagType",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "ParentTagType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchyTagTypeType",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyResourceReportQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedTagHierarchy",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchy",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "RelatedTagType",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "ParentTagType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchyTagTypeType",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MyResourceReportQuery",
    "id": null,
    "text": "query MyResourceReportQuery(\n  $UserId: String!\n  $PearsonLevelId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedTagHierarchy {\n      ForReport\n      id\n      DisplaySequence\n      HierarchyReference\n      HierarchyReferenceLabelEn\n      ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {\n        id\n        HierarchyReference\n        MyScore\n        MyMaxScore\n        AllScore\n        AllMaxScore\n        PearsonLevelId\n        LastUpdateDate\n        TagType\n        TagName\n      }\n      RelatedTagType {\n        id\n        TagType\n        EnterDate\n        Tags {\n          id\n          DisplaySequence\n          TagName\n          TagLongName\n          TagDisplayNameEn\n          TagDisplayNameChi\n        }\n        ParentTagType {\n          TagType\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f37387a96728a39642f6f354b697f00c';
module.exports = node;
