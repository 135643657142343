/**
 * @flow
 * @relayHash a2a9c09afa18431226ee7def1c37015b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewAllNotificationTeacherSubjectListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
  FilterOnboarded: boolean,
|};
export type ViewAllNotificationTeacherSubjectListQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +BookSeries: ?{|
          +IngestionId: ?string,
          +BookSeriesId: ?string,
          +DisplaySequence: ?number,
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +BookSeriesId: ?string,
            +BookSeriesName: ?string,
          |}>,
          +Subject: ?{|
            +DisplaySequence: ?number,
            +SubjectId: ?string,
            +SubjectName: ?$ReadOnlyArray<?{|
              +SubjectName: ?string,
              +Lang: ?string,
            |}>,
          |},
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
      |}>
    |},
  |}
|};
export type ViewAllNotificationTeacherSubjectListQuery = {|
  variables: ViewAllNotificationTeacherSubjectListQueryVariables,
  response: ViewAllNotificationTeacherSubjectListQueryResponse,
|};
*/


/*
query ViewAllNotificationTeacherSubjectListQuery(
  $UserId: String!
  $SchoolYearId: String!
  $FilterOnboarded: Boolean!
) {
  user(UserId: $UserId) {
    UserId
    School {
      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {
        StudyGroupId
        BookSeries {
          IngestionId
          BookSeriesId
          DisplaySequence
          BookSeriesName {
            BookSeriesId
            BookSeriesName
          }
          Subject {
            DisplaySequence
            SubjectId
            SubjectName {
              SubjectName
              Lang
            }
            id
          }
          id
        }
        StudyGroupName {
          StudyGroupName
          Lang
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SubjectId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SubjectName",
  "storageKey": null,
  "args": null,
  "concreteType": "subjectLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "SubjectName",
      "args": null,
      "storageKey": null
    },
    (v10/*: any*/)
  ]
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    },
    (v10/*: any*/)
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewAllNotificationTeacherSubjectListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v12/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewAllNotificationTeacherSubjectListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v13/*: any*/)
                        ]
                      },
                      (v13/*: any*/)
                    ]
                  },
                  (v12/*: any*/),
                  (v13/*: any*/)
                ]
              },
              (v13/*: any*/)
            ]
          },
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewAllNotificationTeacherSubjectListQuery",
    "id": null,
    "text": "query ViewAllNotificationTeacherSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    UserId\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        BookSeries {\n          IngestionId\n          BookSeriesId\n          DisplaySequence\n          BookSeriesName {\n            BookSeriesId\n            BookSeriesName\n          }\n          Subject {\n            DisplaySequence\n            SubjectId\n            SubjectName {\n              SubjectName\n              Lang\n            }\n            id\n          }\n          id\n        }\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '961018ebf231a5f97bd819297c00564b';
module.exports = node;
