/**
 * @flow
 * @relayHash 4db4c6d33fe26fc6cd3966271caa0818
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginPlatformEnumType = "MOBILE" | "TABLET" | "WEB" | "%future added value";
export type PwdTypeEnumType = "IAMKey" | "PASSWORD" | "%future added value";
export type LoginSAMMutationVariables = {|
  userName: string,
  password: string,
  platform: LoginPlatformEnumType,
  passwordType: PwdTypeEnumType,
|};
export type LoginSAMMutationResponse = {|
  +login: ?{|
    +SAMAssessToken: ?string,
    +SAMRefreshToken: ?string,
    +SAMSsoToken: ?string,
    +SessionId: ?string,
    +SAMExpiresIn: ?string,
    +User: ?{|
      +UserId: ?string,
      +UserType: ?string,
      +AdoptedSchoolYear: ?$ReadOnlyArray<?{|
        +SchoolYearId: ?string,
        +SchoolYearName: ?string,
        +IsEffective: ?string,
        +EffectiveStartDate: ?string,
        +EffectiveEndDate: ?string,
      |}>,
    |},
  |}
|};
export type LoginSAMMutation = {|
  variables: LoginSAMMutationVariables,
  response: LoginSAMMutationResponse,
|};
*/


/*
mutation LoginSAMMutation(
  $userName: String!
  $password: String!
  $platform: LoginPlatformEnumType!
  $passwordType: PwdTypeEnumType!
) {
  login(userName: $userName, password: $password, platform: $platform, passwordType: $passwordType) {
    SAMAssessToken
    SAMRefreshToken
    SAMSsoToken
    SessionId
    SAMExpiresIn
    User {
      UserId
      UserType
      AdoptedSchoolYear {
        SchoolYearId
        SchoolYearName
        IsEffective
        EffectiveStartDate
        EffectiveEndDate
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "platform",
    "type": "LoginPlatformEnumType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "passwordType",
    "type": "PwdTypeEnumType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "passwordType",
    "variableName": "passwordType",
    "type": "PwdTypeEnumType"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform",
    "type": "LoginPlatformEnumType!"
  },
  {
    "kind": "Variable",
    "name": "userName",
    "variableName": "userName",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMAssessToken",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMRefreshToken",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMSsoToken",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SessionId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMExpiresIn",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserType",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsEffective",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EffectiveStartDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EffectiveEndDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginSAMMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "login",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "User",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedSchoolYear",
                "storageKey": null,
                "args": null,
                "concreteType": "schoolYear",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginSAMMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "login",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "User",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedSchoolYear",
                "storageKey": null,
                "args": null,
                "concreteType": "schoolYear",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginSAMMutation",
    "id": null,
    "text": "mutation LoginSAMMutation(\n  $userName: String!\n  $password: String!\n  $platform: LoginPlatformEnumType!\n  $passwordType: PwdTypeEnumType!\n) {\n  login(userName: $userName, password: $password, platform: $platform, passwordType: $passwordType) {\n    SAMAssessToken\n    SAMRefreshToken\n    SAMSsoToken\n    SessionId\n    SAMExpiresIn\n    User {\n      UserId\n      UserType\n      AdoptedSchoolYear {\n        SchoolYearId\n        SchoolYearName\n        IsEffective\n        EffectiveStartDate\n        EffectiveEndDate\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83ec923195c938894d337712f840a5d4';
module.exports = node;
