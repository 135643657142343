import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import AssessmentGetLearnosityObjectMutation from "./AssessmentGetLearnosityObjectMutation";
import CompleteSelfLearnMutation from "./CompleteSelfLearnMutation";
import CompleteAssignmentMutation from "../../../../../Services/api/GraphQL/Mutation/CompleteAssignment";
import {Prompt} from "react-router-dom";
import {config} from "../../../../../Config/config";
import Loading from "../../../../../Components/Loading/PEC/Loading";
import Moment from "react-moment";
import GetPrizmPecData from "../../../../../Services/api/GraphQL/Query/GetPrizmPecData";
import {animateScroll as scroll} from "react-scroll";
import AttemptAssignmentMutation from "../../../../../Services/api/GraphQL/Mutation/AttemptAssignment";
import StudentAttemptyHistory from "../../../../../Services/api/GraphQL/Query/StudentAttemptyHistory";
import {activityLayoutTab} from "../../../../../Services/Common/common";
import ActivityPopUp from "../../../../../Layouts/ClassViewLayoutPEC/Body/ActivityPopUp";
import useEncryption from "../../../../../hooks/useEncryption";
import {isIOS} from "react-device-detect";

var itemApp;
var LearnositySession_id = null;
var submited = "";
var submitAssessmentResult = null;
const index = (props) => {
    const {
        t,
        isReadyToRender,
        resourceData,
        bookData,
        match,
        B2C,
        activity,
        entry,
        standalone,
        isInappBrowser,
        prizmBookId
    } = props;
    // console.log(resData);
    console.log(props.location);
    const params = props.standalone ? match.params.resId.split("&") : null;
    const userData = useSelector((state) => state.UserReducer.user);
    const lang = useSelector((state) => state.SystemReducer.lang);
    const selectedSchoolYear = useSelector(
        (state) => state.SystemReducer.selectedSchoolYear
    );
    const userType = userData?.UserType;
    const UserDisplayName = userData?.UserDisplayName;
    const [showLoader, setShowLoader] = useState(false);
    const [showPrizmLoader, setshowPrizmLoader] = useState(true);
    const [showActivityPopUp, setShowActivityPopUp] = useState(false);
    const [activityName, setActivityName] = useState('');
    const [loading, setloading] = useState(true);
    const [initReady, setinitReady] = useState(false);
    const [LeaveAlert, setLeaveAlert] = useState(true);
    const [isFinish, setisFinish] = useState(false);
    const [showResultAfterSubmit, setshowResultAfterSubmit] = useState(false);
    const [removeStart, setremoveStart] = useState(false);
    const [isRedoBlock, setisRedoBlock] = useState(false);
    const [isPrizm, setisPrizm] = useState(false);
    const [openPrizmSubmission, setopenPrizmSubmission] = useState(false);
    const [prizmURL, setprizmURL] = useState("");
    const {encrypt, decryptToLocal} = useEncryption();
    const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;
    var acknowledgeFlag = true;
    useEffect(() => {
//console.log("NowCheck",props.location.pathname.indexOf("/start"));
        if (props.match.params.bookNunitId.split("&")[0] == "prizm" && props.location.pathname.indexOf("/start")!=-1) {
            window.addEventListener('message', (event) => {
                console.log("HERE IS MESSAGE", event.data.type)
                // alert('AM IN LMS inside window event')
                // if (event.origin !== 'https://PEPA-Domain.com') return;
                if (event.data.type == 'SUBMIT_BTN_ACK' && acknowledgeFlag) {
                    //alert("coming")
                    // const iframeRef = document.getElementById('iframe_id').contentWindow;
                    //   iframeRef.postMessage({
                    //     type: 'SUBMIT_BTN', payload:
                    //     { submitBtn: true }
                    //   }, '*');
                    //console.log("prizm", prizmURL)
                    // setShowLoader(true)
                    //resultCheckPrizm
                    // alert('AM IN LMS : Recived SUBMIT ACK')
                    console.log('AM IN LMS : Recived SUBMIT ACK');
                    //setShowLoader(true);
                    acknowledgeFlag=false;
                    resultCheckPrizm()
                    // setTimeout(() => {
                    //   resultCheckPrizm()
                    // }, 3000);

                    // use try again functionality here // Handle acknowledgment for submit // When you receive this, the API call might not have completed // Might have to wait for some time to get it reflected

                } else if (event.data.type == 'SAVE_BTN_ACK') {
                    console.log('AM IN LMS : Recived SAVE ACK');
                    // Handle acknowledgment for save // When you receive this, the API call might not have completed // Might have to wait for some time to get it reflected
                } else if (event.data.type == 'ICPLAYER_READY') {
                    console.log('AM IN LMS : Recived ICPLAYER_READY');
                    setshowPrizmLoader(false);
                    // var actionButtons = document.getElementById('actionButtons');
                    // actionButtons.style.display = 'block';
                    setInterval(() => {
                        const iframeRef = document.getElementById('iframe_id').contentWindow;
                        iframeRef.postMessage({
                            type: 'SAVE_BTN', payload:
                                {saveBtn: true}
                        }, '*');
                    }, 30000);
                } else if (
                    event.data.type === 'PLAYER_ERROR'
                ) {
                    setshowPrizmLoader(false)
                    alert("Player Initialization Error, ErrorType:" + event.data.type);
                }else if (
                    event.data.type === 'USER_ERROR' 
                   
                ) {
                    setshowPrizmLoader(false)
                    alert("There is some mismatch in User Account Configuration Or Token Expired, ErrorType:" + event.data.type);
                }else if (
                    event.data.type === 'RESOLVER_ERROR'  
                ) {
                    setshowPrizmLoader(false)
                    alert("There is some issue with Resource ShortLink Or Token Issue, ErrorType:" + event.data.type);
                }else if (event.data.type === 'ATTEMPT_ERROR' ||
                event.data.type === 'STATEMENT_ERROR'){
                    setshowPrizmLoader(false)
                    //setActivityName("");
                    //setShowActivityPopUp(true);
                    alert("There is some issue with Attempts, ErrorType:" + event.data.type);
          
                }
                else {
                    // console.log('no condition satisfied')
                    setTimeout(() => {
                        setshowPrizmLoader(false)
                    }, 30000);
                }
            }, false);
        }else if (props.match.params.bookNunitId.split("&")[0] == "prizm" && props.location.pathname.indexOf("/submission")!=-1) {
            window.addEventListener('message', (event) => {
                console.log("HERE IS MESSAGE", event.data.type)
                 if (event.data.type == 'ICPLAYER_READY') {
                    console.log('AM IN LMS : Recived ICPLAYER_READY');
                    setshowPrizmLoader(false);
                    // var actionButtons = document.getElementById('actionButtons');
                    // actionButtons.style.display = 'block';
                    
                } else if (
                    event.data.type === 'PLAYER_ERROR'
                ) {
                    setshowPrizmLoader(false)
                    alert("Player Initialization Error, ErrorType:" + event.data.type);
                }
                else {
                    // console.log('no condition satisfied')
                    setTimeout(() => {
                        setshowPrizmLoader(false)
                    }, 30000);
                }
            }, false);
        }else{
            setshowPrizmLoader(false)
        }
        //alert("coming");

        console.log("[Start page]", props);
        if (props.match.params.bookNunitId != "prizm") {
            activityLayoutTab({disable: true, isInappBrowser});
        }

        if (isReadyToRender) {
            switch (userType) {
                case "T":
                    var checkItemScriptIsReady = setInterval(() => {
                        if (window.LearnosityItems) {
                            console.log(
                                "[checkItemScriptIsReady]  Find window.LearnosityItems"
                            );
                            clearInterval(checkItemScriptIsReady);
                            if (props.match.params.bookNunitId.split("&")[0] == "prizm") {
                                setisPrizm(true);
                                if (props.location.pathname.indexOf("/submission") != -1) {
                                    setopenPrizmSubmission(true)
                                } else {

                                    getPrizmObject();
                                }


                            } else {
                                getLearnosityObject();
                            }
                        }
                    }, 1000);
                    break;
                case "S":
                    if (props.match.params.bookNunitId.split("&")[0] == "prizm") {
                        setisPrizm(true);

                        if (props.location.pathname.indexOf("/submission") != -1) {
                            setopenPrizmSubmission(true)
                        } else {

                            getPrizmObject();
                        }

                    } else {
                        checkStudentHaveDoneAssessmet();
                    }
                    break;
            }
        }
    }, []);

    const resultCheckPrizm = async () => {
 
        //alert("function")
        setShowLoader(true);
        var dataSet = {
            Lang: lang == "en-US" ? "EN" : "TC",
            ResourceId: !isInappBrowser
                ? props.match.params.resId
                : props.inappData.resourceId,
            StudyGroupId: !isInappBrowser
                ? props.location.state?.group_id || ""
                : props.inappData.groupId,
            SchoolYearId: selectedSchoolYear.SchoolYearId,
            Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
        };

        const result = async (callback) => {

            console.log(callback, "result");
            console.log(callback.attemptSelfLearn);

            //alert("coming");
            console.log("love", callback.attemptSelfLearn)
            const urlParams = new URLSearchParams(callback.attemptSelfLearn);
            console.log("love", urlParams)
            const myParam = urlParams.get('lrid');
            var value = {
                SessionId: myParam,
            };

            let respData;
            var callingTimes = 0;
            do {
                respData = await GetPrizmPecData(value).then((data) => {
                    callingTimes = callingTimes + 1;
                    console.log("resultCheckPrizm callingTimes: ", callingTimes);
                    //console.log();
                    if (!!data.learnositySelfLearnResult.LearnositySessionRawResult) {
                        //alert("cominh");
                        setShowLoader(false);
                        setActivityName("save");
                        setShowActivityPopUp(true);
                        return 0;

                    } else {
                        //alert("cominhelse");
                        if (callingTimes == 20) {
                            setShowLoader(false);
                            setActivityName("");
                            setShowActivityPopUp(true);
                            acknowledgeFlag=false;
                            return 0
                        }

                        return 1;
                    }


                    // var objectData = JSON.parse(
                    //   data.learnositySelfLearnResult.LearnositySessionResult
                    // );
                });

                await new Promise(r => setTimeout(r, 1000));
                //alert(respData)
            } while (respData > 0)

            // try {
            //   GetPrizmPecData(value).then((data) => {
            //   console.log(data);

            //   });
            //   } catch (e) {
            //     console.log(e);
            //     window.alert(e.message);
            //   }
        };

        AssessmentGetLearnosityObjectMutation(dataSet, result);
    }

    const checkStudentHaveDoneAssessmet = () => {
        var variables;
//console.log("conflicts resolved");
        if (standalone) {
            if (activity) {
                variables = {
                    ResourceId: props.match.params.resId,
                    UserId: userData?.UserId,
                    IncludeAssignmentRecord: !B2C ? true : false,
                };
            } else {
                variables = {
                    ResourceAssignId: params[2],
                    UserId: userData?.UserId,
                };
            }

            StudentAttemptyHistory(variables, activity ? false : true).then(
                (data) => {
                    console.log(data);

                    if (activity) {
                        console.log(data);
                        if (data.user.SelfLearnAttemptHistory.length != 0) {
                            var allCompleted = data.user.SelfLearnAttemptHistory.length;
                            data.user.SelfLearnAttemptHistory.map((item) => {
                                if (!item.IsCompleted) {
                                    allCompleted = allCompleted - 1;
                                }
                            });
                            console.log(allCompleted, "history completed");
                            if (allCompleted != 0) {
                                //have history
                                activityLayoutTab({disable: false, isInappBrowser});
                                var checkItemScriptIsReady = setInterval(() => {
                                    if (window.LearnosityItems) {
                                        clearInterval(checkItemScriptIsReady);
                                        console.log(
                                            "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                        );

                                        getLearnosityObject(true);
                                    }
                                }, 1000);
                            } else {
                                var checkItemScriptIsReady = setInterval(() => {
                                    if (window.LearnosityItems) {
                                        console.log(
                                            "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                        );
                                        clearInterval(checkItemScriptIsReady);
                                        getLearnosityObject(false);
                                    }
                                }, 1000);
                            }
                        } else {
                            var checkItemScriptIsReady = setInterval(() => {
                                if (window.LearnosityItems) {
                                    clearInterval(checkItemScriptIsReady);
                                    console.log(
                                        "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                    );

                                    getLearnosityObject(false);
                                }
                            }, 1000);
                        }
                    } else {
                        let targetRes = data.resourceAssignment.Resources.filter((o, i) => {
                            return o.ResourceAssignResId == params[1];
                        });
                        console.log(targetRes);
                        targetRes = targetRes[0];
                        if (targetRes.LearnosityAttemptHistory.length != 0) {
                            var allCompleted = targetRes.LearnosityAttemptHistory.length;
                            targetRes.LearnosityAttemptHistory.map((item) => {
                                if (!item.IsCompleted) {
                                    allCompleted = allCompleted - 1;
                                }
                            });
                            console.log(allCompleted, "history completed");
                            if (allCompleted != 0) {
                                //have history
                                activityLayoutTab({disable: false, isInappBrowser});
                                var checkItemScriptIsReady = setInterval(() => {
                                    if (window.LearnosityItems) {
                                        clearInterval(checkItemScriptIsReady);
                                        console.log(
                                            "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                        );

                                        getLearnosityObject(true);
                                    }
                                }, 1000);
                            } else {
                                var checkItemScriptIsReady = setInterval(() => {
                                    if (window.LearnosityItems) {
                                        console.log(
                                            "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                        );
                                        clearInterval(checkItemScriptIsReady);
                                        getLearnosityObject(false);
                                    }
                                }, 1000);
                            }
                        } else {
                            var checkItemScriptIsReady = setInterval(() => {
                                if (window.LearnosityItems) {
                                    clearInterval(checkItemScriptIsReady);
                                    console.log(
                                        "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                    );

                                    getLearnosityObject(false);
                                }
                            }, 1000);
                        }
                    }
                }
            );
        } else {
            // if (!isInappBrowser) {
            variables = {
                ResourceId: !isInappBrowser
                    ? props.match.params.resId
                    : props.inappData.resourceId,
                UserId: JSON.parse(localStorage.getItem("userData")).UserId,
            };
            // } else {
            //   variables = {
            //     ResourceId: localStorage.getItem("in-app-browser-data-resourceid"),
            //     UserId: localStorage.getItem("in-app-browser-data-userid"),
            //   };
            // }
            StudentAttemptyHistory(variables, false).then((data) => {
                console.log(data);
                if (data.user.SelfLearnAttemptHistory.length != 0) {
                    var allCompleted = data.user.SelfLearnAttemptHistory.length;
                    data.user.SelfLearnAttemptHistory.map((item) => {
                        if (!item.IsCompleted) {
                            allCompleted = allCompleted - 1;
                        }
                    });
                    console.log(allCompleted, "history completed");
                    if (allCompleted != 0) {
                        //have history
                        activityLayoutTab({disable: false, isInappBrowser});
                        var checkItemScriptIsReady = setInterval(() => {
                            if (window.LearnosityItems) {
                                clearInterval(checkItemScriptIsReady);
                                console.log(
                                    "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                );

                                getLearnosityObject(true);
                            }
                        }, 1000);
                    } else {
                        var checkItemScriptIsReady = setInterval(() => {
                            if (window.LearnosityItems) {
                                console.log(
                                    "[checkItemScriptIsReady]  Find window.LearnosityItems"
                                );
                                clearInterval(checkItemScriptIsReady);
                                getLearnosityObject(false);
                            }
                        }, 1000);
                    }
                } else {
                    var checkItemScriptIsReady = setInterval(() => {
                        if (window.LearnosityItems) {
                            clearInterval(checkItemScriptIsReady);
                            console.log(
                                "[checkItemScriptIsReady]  Find window.LearnosityItems"
                            );

                            getLearnosityObject(false);
                        }
                    }, 1000);
                }
            });
        }
    };

    const getPrizmObject = () => {
        let dataSet;
        submitAssessmentResult = null;

        if (B2C) {
            dataSet = {
                Lang: lang == "en-US" ? "EN" : "TC",
                ResourceId: !isInappBrowser
                    ? props.match.params.resId
                    : props.inappData.resourceId,
                SchoolYearId: selectedSchoolYear.SchoolYearId,
                Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
            };
        } else {
            dataSet = {
                Lang: lang == "en-US" ? "EN" : "TC",
                ResourceId: !isInappBrowser
                    ? props.match.params.resId
                    : props.inappData.resourceId,
                StudyGroupId: !isInappBrowser
                    ? props.location.state?.group_id || ""
                    : props.inappData.groupId,
                SchoolYearId: selectedSchoolYear.SchoolYearId,
                Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
            };
        }
        const result = (callback) => {
            console.log(callback, "result");
            console.log(callback.attemptSelfLearn);

            let testURL = "";
            switch (window.location.hostname) {
                case "localhost":
                    const url = new URL(callback.attemptSelfLearn);
                    url.searchParams.delete("isMobileApp");
                    // url.searchParams.delete("authorization");
                    //url.searchParams.delete("userId");
                    testURL = url.href
                    break;
                default:
                    break;
            }

            //console.log(url.href);
            if (testURL != "") {
                setprizmURL(testURL);
            } else {
                setprizmURL(callback.attemptSelfLearn);
            }


            if (userType == "T") {
                setloading(false);
                // startPrizm(JSON.parse(callback.attemptSelfLearn), true);
            } else {
                setloading(false);
            }
        };

        AssessmentGetLearnosityObjectMutation(dataSet, result);
    };
    const getLearnosityObject = (studentHaveDoneAssessment) => {
        if (standalone) {
            console.log("[standalone] getLearnosityObject");
            let dataSet;
            submitAssessmentResult = null;
            const callback = (data, error) => {
                console.log(data);
                let _learnosityObj = activity
                    ? data.attemptSelfLearn
                    : data.attemptAssignment;
                console.log(_learnosityObj);
                console.log(studentHaveDoneAssessment);

                if (userType == "S") {
                    if (studentHaveDoneAssessment) {
                        if (_learnosityObj == "redoBlocked") {
                            setloading(false);
                            setisFinish(true);
                            setLeaveAlert(false);
                            setisRedoBlock(true);
                        } else {
                            setloading(false);
                            setisFinish(true);
                            setLeaveAlert(false);
                            setisRedoBlock(false);
                        }
                    } else {
                        // setLearnosityObject(JSON.parse(_learnosityObj));
                        LearnositySession_id =
                            JSON.parse(_learnosityObj).request.session_id;
                        console.log(
                            JSON.parse(_learnosityObj).request.session_id,
                            "=-=-----=-=-="
                        );
                        setLeaveAlert(true);
                        setloading(false);
                        setTimeout(
                            () => startLearnosityInit(JSON.parse(_learnosityObj), true),
                            100
                        );
                    }
                }
                if (userType == "T") {
                    if (_learnosityObj == "failed") {
                        setloading(false);
                        setinitReady(true);
                        activityLayoutTab({disable: false, isInappBrowser});
                        return;
                    }
                    // setLearnosityObject(JSON.parse(_learnosityObj));
                    LearnositySession_id = JSON.parse(_learnosityObj).request.session_id;
                    console.log(
                        JSON.parse(_learnosityObj).request.session_id,
                        "=-=-----=-=-="
                    );
                    setloading(false);
                    startLearnosityInit(JSON.parse(_learnosityObj), true);
                }
            };
            if (activity) {
                dataSet = {
                    Lang: lang == "en-US" ? "EN" : "TC",
                    ResourceId: props.match.params.resId,
                    SchoolYearId: selectedSchoolYear.SchoolYearId,
                    Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
                };
                AssessmentGetLearnosityObjectMutation(dataSet, callback);
            } else {
                dataSet = {
                    Lang: lang == "en-US" ? "EN" : "TC",
                    Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken"),
                    ResourceAssignResId: params[1],
                };
                AttemptAssignmentMutation(dataSet, callback);
            }
        } else {
            let dataSet;
            submitAssessmentResult = null;

            if (B2C) {
                dataSet = {
                    Lang: lang == "en-US" ? "EN" : "TC",
                    ResourceId: !isInappBrowser
                        ? props.match.params.resId
                        : props.inappData.resourceId,
                    SchoolYearId: selectedSchoolYear.SchoolYearId,
                    Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
                };
            } else {
                dataSet = {
                    Lang: lang == "en-US" ? "EN" : "TC",
                    ResourceId: !isInappBrowser
                        ? props.match.params.resId
                        : props.inappData.resourceId,
                    StudyGroupId: !isInappBrowser
                        ? props.location.state?.group_id || ""
                        : props.inappData.groupId,
                    SchoolYearId: selectedSchoolYear.SchoolYearId,
                    Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
                };
            }
            const result = (callback) => {
                console.log(callback, "result");
                console.log(callback.attemptSelfLearn);
                if (userType == "S") {
                    if (studentHaveDoneAssessment) {
                        if (
                            callback.attemptSelfLearn == "redoBlocked" ||
                            callback.attemptAssignment == "redoBlocked"
                        ) {
                            setloading(false);
                            setisFinish(true);
                            setLeaveAlert(false);
                            setisRedoBlock(true);
                        } else {
                            setloading(false);
                            setisFinish(true);
                            setLeaveAlert(false);
                            setisRedoBlock(false);
                        }
                    } else {
                        // setLearnosityObject(JSON.parse(callback.attemptSelfLearn));
                        LearnositySession_id = JSON.parse(callback.attemptSelfLearn).request
                            .session_id;
                        console.log(
                            JSON.parse(callback.attemptSelfLearn).request.session_id,
                            "=-=-----=-=-="
                        );
                        setLeaveAlert(true);
                        setloading(false);
                        setTimeout(
                            () =>
                                startLearnosityInit(
                                    JSON.parse(callback.attemptSelfLearn),
                                    true
                                ),
                            100
                        );
                    }
                }
                if (userType == "T") {
                    // setLearnosityObject(JSON.parse(callback.attemptSelfLearn));
                    LearnositySession_id = JSON.parse(callback.attemptSelfLearn).request
                        .session_id;
                    console.log(
                        JSON.parse(callback.attemptSelfLearn).request.session_id,
                        "=-=-----=-=-="
                    );
                    setloading(false);
                    startLearnosityInit(JSON.parse(callback.attemptSelfLearn), true);
                }
            };

            AssessmentGetLearnosityObjectMutation(dataSet, result);
        }
    };

    const submit = (itemIndex) => {
        config.mode == "dev" && console.log("[learnosity test:submit]");

        activityLayoutTab({disable: true, isInappBrowser});
        console.log(LearnositySession_id);
        submitAssessment();
    };

    const stopAudioFunc = () => {
        if (window.document.querySelector(".lrn_btn.lrn_pause_playback")) {
            window.document.querySelector(".lrn_btn.lrn_pause_playback").click();
        }
    };
    //this is learnosity
    const startLearnosityInit = (object, LeaveAlert) => {
        console.log("[Start init learnosity]");
        var callbacks = {
            errorListener: errorListener,
            readyListener: () => readyListener("assessment"),
        };
        console.log(LeaveAlert);
        if (LeaveAlert) {
            if (standalone) {
                if (activity) {
                    itemApp = window.LearnosityItems.init(object, callbacks);
                    itemApp.on("test:start", () => {
                        try {
                            gaTrack(
                                "send",
                                "event",
                                "Assessment",
                                "Start",
                                "Assessment:" +
                                resourceData.ResourceLangs[0].ResourceName +
                                ", Resource ID:" +
                                resourceData.IngestionId +
                                ", Book Series:" +
                                bookData.BookSeriesName[0].BookSeriesName +
                                ", Book:NoData" +
                                ", Chapter:NoData" +
                                ", Role:" +
                                userType
                            );
                        } catch (e) {
                            console.log("GA Error");
                        }
                        if (isIOS) {
                            const videoIframe = document.querySelectorAll("mediaelementwrapper iframe")?.[0]
                            if (videoIframe) {
                                videoIframe.src = "https://" + videoIframe.src.split("://")[1]
                            }
                        }
                    });
                    itemApp.on("test:submit", submit);
                    itemApp.on("test:pause", stopAudioFunc);
                    itemApp.on("test:finished:submit", (itemIndex) => {
                        itemApp.audio.stop();
                        config.mode == "dev" &&
                        console.log("[learnosity test:finished:submit]");
                        itemApp.reset();
                        props.history.push(that.state.backPath);
                    });
                    itemApp.on('test:proctor:exitAndSubmit', function () {
                        console.log('This code executes when the proctor has exited and submitted the assessment.');
                        itemApp.reset();
                        props.history.push(that.state.backPath);
                    });

                    itemApp.on("item:changed", (itemIndex) => {
                        scroll.scrollToTop();
                    });

                    itemApp.on("test:submit:success", (itemIndex) => {
                        setLeaveAlert(false);
                        config.mode == "dev" &&
                        console.log("[learnosity test:submit:success]");
                        if (userType == "S") {
                            setinitReady(false);
                            var myCheck = setInterval(() => {
                                if (!submitAssessmentResult && submited != "DONE") {
                                    console.log(submited, "lopping");
                                    return;
                                }
                                clearInterval(myCheck);
                                submited = "WAITE";
                                props.history.push(
                                    props.location.pathname.replace("/start", "/result")
                                );
                            });
                        } else {
                            setshowResultAfterSubmit(true);
                            setisFinish(true);
                            setLeaveAlert(false);

                            // window.document.getElementById("redo_assessment") &&
                            window.document
                                .getElementById("redo_assessment")
                                ?.classList.add("dim-button");

                            var myCheck = setInterval(() => {
                                if (!submitAssessmentResult && submited != "DONE") {
                                    console.log(submited, "lopping");
                                    return;
                                }
                                console.log(submited, "lopping stop");
                                console.log(submitAssessmentResult);
                                callbacks = {
                                    errorListener: errorListener,
                                    readyListener: () => readyListener("report"),
                                };

                                clearInterval(myCheck);
                                setTimeout(() => {
                                    // that.setState({
                                    //   submited: "WAITE",
                                    // });
                                    submited = "WAITE";
                                    itemApp = window.LearnosityReports.init(
                                        submitAssessmentResult,
                                        callbacks
                                    );
                                }, 500);
                            }, 500);
                        }
                    });

                    return;
                } else {
                    try {
                        itemApp = window.LearnosityItems.init(object, callbacks);
                        // console.log(itemApp, window.LearnosityItems);
                        itemApp.on("test:start", () => {
                            try {
                                gaTrack(
                                    "send",
                                    "event",
                                    "Assessment",
                                    "Start",
                                    "Assessment:" +
                                    resourceData.ResourceLangs[0].ResourceName +
                                    ", Resource ID:" +
                                    resourceData.IngestionId +
                                    ", Book Series:" +
                                    bookData.BookSeriesName[0].BookSeriesName +
                                    ", Book:NoData" +
                                    ", Chapter:NoData" +
                                    ", Role:" +
                                    userType
                                );
                            } catch (e) {
                                console.log("GA Error");
                            }
                            if (isIOS) {
                                const videoIframe = document.querySelectorAll("mediaelementwrapper iframe")?.[0]
                                if (videoIframe) {
                                    videoIframe.src = "https://" + videoIframe.src.split("://")[1]
                                }
                            }
                        });
                        itemApp.on("test:submit", submit);
                        itemApp.on("test:pause", stopAudioFunc);
                        itemApp.on("test:finished:submit", (itemIndex) => {
                            config.mode == "dev" &&
                            console.log("[learnosity test:finished:submit]");
                            itemApp.audio.stop();
                            itemApp.reset();
                            props.history.push(that.state.backPath);
                        });

                        itemApp.on("item:changed", (itemIndex) => {
                            scroll.scrollToTop();
                        });

                        itemApp.on("test:submit:success", (itemIndex) => {
                            setLeaveAlert(false);
                            config.mode == "dev" &&
                            console.log("[learnosity test:submit:success]");
                            if (userType == "S") {
                                setinitReady(false);
                                var myCheck = setInterval(() => {
                                    if (!submitAssessmentResult && submited != "DONE") {
                                        console.log(submited, "lopping");
                                        return;
                                    }
                                    clearInterval(myCheck);
                                    submited = "WAITE";
                                    props.history.push(
                                        props.location.pathname.replace("/start", "/result")
                                    );
                                });
                            } else {
                                setshowResultAfterSubmit(true);
                                setisFinish(true);
                                setLeaveAlert(false);

                                window.document.getElementById("redo_assessment") &&
                                window.document
                                    .getElementById("redo_assessment")
                                    .classList.add("dim-button");

                                var myCheck = setInterval(() => {
                                    if (!submitAssessmentResult && submited != "DONE") {
                                        console.log(submited, "lopping");
                                        return;
                                    }
                                    console.log(submited, "lopping stop");
                                    console.log(submitAssessmentResult);
                                    callbacks = {
                                        errorListener: errorListener,
                                        readyListener: () => readyListener("report"),
                                    };

                                    clearInterval(myCheck);
                                    setTimeout(() => {
                                        // that.setState({
                                        //   submited: "WAITE",
                                        // });
                                        submited = "WAITE";
                                        itemApp = window.LearnosityReports.init(
                                            submitAssessmentResult,
                                            callbacks
                                        );
                                    }, 500);
                                }, 500);
                            }
                        });
                    } catch (e) {
                        console.log("trycatch", e);
                    }
                }
            } else {
                try {
                    itemApp = window.LearnosityItems.init(object, callbacks);
                    console.log(itemApp, window.LearnosityItems);
                    itemApp.on("test:start", () => {
                        const {resourceData, bookData} = props;
                        var _userType = "";
                        if (isInappBrowser) {
                            _userType = userType;
                        } else {
                            _userType = JSON.parse(localStorage.getItem("userData")).UserType;
                        }

                        console.log(resourceData, bookData);

                        try {
                            gaTrack(
                                "send",
                                "event",
                                "Assessment",
                                "Start",
                                "Assessment:" +
                                resourceData.ResourceLangs[0].ResourceName +
                                ", Resource ID:" +
                                resourceData.IngestionId +
                                ", Book Series:" +
                                bookData.BookSeriesName[0].BookSeriesName +
                                ", Book:" +
                                bookData.Books[0].BookName[0].BookName +
                                ", Chapter:" +
                                (B2C
                                    ? "NoData"
                                    : bookData.Books && bookData.Books.length > 0
                                        ? bookData.Books[0].Units[0].UnitName[0].UnitName
                                        : "NoData") +
                                ", Role:" +
                                _userType +
                                `, From:${B2C ? "Supplementary" : "My School"}`
                            );
                        } catch (e) {
                            console.log("GA Error");
                        }
                        if (isIOS) {
                            const videoIframe = document.querySelectorAll("mediaelementwrapper iframe")?.[0]
                            if (videoIframe) {
                                videoIframe.src = "https://" + videoIframe.src.split("://")[1]
                            }
                        }
                    });
                    itemApp.on("test:submit", submit);

                    itemApp.on("test:pause", stopAudioFunc);
                    itemApp.on("test:finished:submit", (itemIndex) => {
                        config.mode == "dev" &&
                        console.log("[learnosity test:finished:submit]");
                        itemApp.audio.stop();
                        itemApp.reset();
                        props.history.push(that.state.backPath);
                    });
                    itemApp.on('test:proctor:exitAndSubmit', function () {
                        console.log('This code executes when the proctor has exited and submitted the assessment.');
                        itemApp.reset();
                        props.history.push(that.state.backPath);
                    });
                    itemApp.on("item:changed", (itemIndex) => {
                        scroll.scrollToTop();
                    });

                    itemApp.on("test:submit:success", (itemIndex) => {
                        setLeaveAlert(false);
                        config.mode == "dev" &&
                        console.log("[learnosity test:submit:success]");
                        if (userType == "S") {
                            setinitReady(false);
                            var myCheck = setInterval(() => {
                                if (!submitAssessmentResult && submited != "DONE") {
                                    console.log(submited, "lopping");
                                    return;
                                }
                                clearInterval(myCheck);
                                submited = "WAITE";
                                props.history.push(
                                    props.location.pathname.replace("/start", "/result")
                                );
                            });
                        } else {
                            setshowResultAfterSubmit(true);
                            setisFinish(true);
                            setLeaveAlert(false);

                            window.document
                                .getElementById("redo_assessment")
                                ?.classList.add("dim-button");

                            var myCheck = setInterval(() => {
                                if (!submitAssessmentResult && submited != "DONE") {
                                    console.log(submited, "lopping");
                                    return;
                                }
                                console.log(submited, "lopping stop");
                                console.log(submitAssessmentResult);
                                callbacks = {
                                    errorListener: errorListener,
                                    readyListener: () => readyListener("report"),
                                };

                                clearInterval(myCheck);
                                // setisFinish(true);
                                setTimeout(() => {
                                    submited = "WAITE";
                                    itemApp = window.LearnosityReports.init(
                                        submitAssessmentResult,
                                        callbacks
                                    );
                                }, 500);
                            }, 500);
                        }
                    });
                } catch (e) {
                    console.log("trycatch", e);
                }
            }
        }
    };

    const readyListener = (type) => {
        console.log("Learnosity Items API is ready", "TYPE:", type);
        switch (type) {
            case "report":
                setinitReady(true);
                var report = itemApp.getReport("session-detail");
                report.on("load:data", (data) => {
                    activityLayoutTab({disable: false, isInappBrowser});
                    window.document
                        .getElementById("redo_assessment")
                        ?.classList.remove("dim-button");
                });
                break;
            case "assessment":
                handleHints();
                handleDistractor(() => {
                    setinitReady(true);
                    activityLayoutTab({disable: false, isInappBrowser});
                });
                break;

            default:
                break;
        }
    };

    const errorListener = (e) => {
        // Adds a listener to all error codes.
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
        console.log(props.match.url + "/start", props.location.pathname);
    };

    const renderDistractor = (id, content) => {
        console.log(id, content);
        var template;
        if (document.getElementById(id + "_distractor")) {
            fadeIn(document.getElementById(id + "_distractor"), 500);
            document.getElementById(id + "_distractor").innerHTML = content;
        } else {
            template = document.createElement("div");
            template.id = id + "_distractor";
            template.className = "distractor-rationale alert";
            template.innerHTML = content;
            document.getElementById(id).append(document.createElement("br"));
            document.getElementById(id).append(template);
        }
        // renderMath() Renders all Latex or MathML elements on the page with MathJax.
        itemApp.questionsApp().renderMath();
    };

    const removeDistractor = (id) => {
        fadeOut(document.getElementById(id + "_distractor"), 500);
    };

    const fadeIn = (el, time) => {
        if (el.style.opacity == 1) return;
        el.style.display = "block";

        var last = +new Date();
        var tick = () => {
            el.style.opacity = +el.style.opacity + (new Date() - last) / time;
            last = +new Date();

            if (+el.style.opacity < 1) {
                (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
                setTimeout(tick, 16);
            }
        };

        tick();
    };

    const fadeOut = (elem, ms) => {
        // var that = this;
        if (!elem) return;
        if (elem.style.opacity == 0) return;
        if (removeStart) return;
        if (ms) {
            setremoveStart(true);
            var opacity = 1;
            var timer = setInterval(() => {
                opacity -= 50 / ms;
                if (opacity <= 0) {
                    clearInterval(timer);
                    elem.style.display = "none";
                    opacity = 0;
                    setremoveStart(false);
                }
                elem.style.opacity = opacity;
            }, 50);
        } else {
            elem.style.opacity = 0;
        }
    };

    const handleDistractor = (callback) => {
        let questions = itemApp.questions();
        console.log(questions);
        Object.keys(questions).map((index) => {
            questions[index].on("validated", () => {
                var outputHTML = "";
                var map, qid;
                try {
                    if (standalone) {
                        gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Check answer",
                            "Question:" +
                            questions[index].getQuestion().response_id +
                            "Assessment:" +
                            resourceData.ResourceLangs[0].ResourceName +
                            ", Resource ID:" +
                            resourceData.IngestionId +
                            ", Book Series:" +
                            bookData.BookSeriesName[0].BookSeriesName +
                            ", Book:NoData" +
                            ", Chapter:NoData" +
                            ", Role:" +
                            userType
                        );
                    } else {
                        const {resourceData, bookData} = props;

                        gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Check answer",
                            "Question:" +
                            questions[index].getQuestion().response_id +
                            ", Assessment:" +
                            resourceData.ResourceLangs[0].ResourceName +
                            ", Resource ID:" +
                            resourceData.IngestionId +
                            ", Book Series:" +
                            bookData.BookSeriesName[0].BookSeriesName +
                            ", Book:" +
                            bookData.Books[0].BookName[0].BookName +
                            ", Chapter:" +
                            (bookData.Books && bookData.Books.length > 0
                                ? bookData.Books[0].Units[0].UnitName[0].UnitName
                                : "NoData") +
                            ", Role:" +
                            userType +
                            `, From:${B2C ? "Supplementary" : "My School"}`
                        );
                    }
                } catch (e) {
                    console.log("Check answer GA Error", e);
                }

                if (
                    questions[index].mapValidationMetadata(
                        "distractor_rationale_response_level"
                    ) != false
                ) {
                    map = questions[index].mapValidationMetadata(
                        "distractor_rationale_response_level"
                    );
                    var distractArr = [];
                    var correctArr = [];
                    var wrongArr = [];
                    console.log(map.incorrect);
                    map.incorrect.map((data, i) => {
                        //  Each item in the `map.incorrect` array is an object that contains a `value` property that
                        //    holds the response value; an `index` property that refers to the shared index of both the
                        //  response area and the metadata; and a `metadata` property containing the metadata value.
                        var distractor = data.metadata;
                        distractArr.push(data);
                        wrongArr.push(data.index);
                        //outputHTML += "<li>" + distractor + "</li>";
                    });
                    map.correct.map((data, i) => {
                        var distractor = data.metadata;
                        distractArr.push(data);
                        correctArr.push(data.index);
                    });
                    if (distractArr.length > 0) {
                        distractArr.sort(function (a, b) {
                            return parseFloat(a.index) - parseFloat(b.index);
                        });
                        distractArr.map((data, i) => {
                            console.log(data, correctArr, "!!!!!!!!!!!!!!!!!");
                            if (correctArr.indexOf(data.index) != -1) {
                                outputHTML +=
                                    '<div class="alert" style="margin-bottom:0px; background-color: #71cf1f; color: #000000; border-color: #387800;"><ul><li>' +
                                    data.metadata +
                                    "</li></ul></div>";
                            } else {
                                outputHTML +=
                                    '<div class="alert alert-danger" style="margin-bottom:0px;"><ul><li>' +
                                    data.metadata +
                                    "</li></ul></div>";
                            }
                        });
                    }
                } else if (questions[index].getMetadata().distractor_rationale) {
                    outputHTML = questions[index].getMetadata().distractor_rationale;
                }

                if (!outputHTML) {
                    outputHTML = "Have you answered all possible responses?";
                }
                qid = questions[index].getQuestion().response_id;
                renderDistractor(qid, outputHTML);
            });
            questions[index].on("changed", () => {
                removeDistractor(questions[index].getQuestion().response_id);
            });
        });

        callback();
    };

    const renderHint = (e) => {
        var question_id = e.target.id.slice(0, e.target.id.indexOf("__button"));
        const {t} = props;
        console.log(question_id, "on click ID");

        var metadata = getMetadata(question_id);
        var hints = metadata.hints ? metadata.hints : [];
        console.log(metadata, hints);
        console.log(question_id);

        try {
            if (standalone) {
                gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Hints",
                    "Question:" +
                    question_id +
                    "Assessment:" +
                    resourceData.ResourceLangs[0].ResourceName +
                    ", Resource ID:" +
                    resourceData.IngestionId +
                    ", Book Series:" +
                    bookData.BookSeriesName[0].BookSeriesName +
                    ", Book:NoData" +
                    ", Chapter:NoData" +
                    ", Role:" +
                    userType
                );
            } else {
                const {resourceData, bookData} = props;
                // const userType = JSON.parse(localStorage.getItem("userData")).UserType;

                gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Hints",
                    "Question:" +
                    question_id +
                    ", Assessment:" +
                    resourceData.ResourceLangs[0].ResourceName +
                    ", Resource ID:" +
                    resourceData.IngestionId +
                    ", Book Series:" +
                    bookData.BookSeriesName[0].BookSeriesName +
                    ", Book:" +
                    bookData.Books[0].BookName[0].BookName +
                    ", Chapter:" +
                    (bookData.Books && bookData.Books.length > 0
                        ? bookData.Books[0].Units[0].UnitName[0].UnitName
                        : "NoData") +
                    ", Role:" +
                    userType +
                    `, From:${B2C ? "Supplementary" : "My School"}`
                );
            }
        } catch (e) {
            console.log("Check answer GA Error");
        }

        if (document.getElementById(question_id + "_hints")) {
            document.getElementById(question_id + "_hints").innerHTML = "";
        } else {
            console.log(document.getElementById(question_id + "_hints"));
        }
        console.log(metadata, hints);
        console.log(question_id);
        // check how many times the hint button has been clicked..
        var hintsClicked = document
            .getElementById(question_id)
            .getAttribute("hintsClicked");
        if (hintsClicked == undefined) {
            document.getElementById(question_id).setAttribute("hintsClicked", 1);
        } else if (parseInt(hintsClicked) < hints.length) {
            document
                .getElementById(question_id)
                .setAttribute("hintsClicked", parseInt(hintsClicked) + 1);
        }
        console.log(
            hintsClicked,
            document.getElementById(question_id).getAttribute("hintsClicked")
        );
        // Add the hint(s) from questions metadata and render into the div#hints element
        for (
            var i = 0;
            i < document.getElementById(question_id).getAttribute("hintsClicked");
            i++
        ) {
            //hintsElem.addClass('alert alert-warning').append($.parseHTML(hints[i]));
            if (document.getElementById(question_id + "_hints")) {
                document.getElementById(question_id + "_hints").innerHTML +=
                    '<div class="hits-item" style="opacity: 0;" id="' +
                    question_id +
                    "_hint_" +
                    i +
                    '"><div>' +
                    hints[i] +
                    "</div></div>";
            } else {
                var template = document.createElement("div");
                template.id = question_id + "_hints";
                template.className = "distractor-rationale alert hits-box";
                template.innerHTML =
                    '<div class="hits-item" style="opacity: 0;" id="' +
                    (question_id + "_hint_" + i) +
                    '"><div>' +
                    hints[i] +
                    "</div></div>";
                document
                    .getElementById(question_id)
                    .closest("div.row")
                    .append(template);
                // Render any LaTeX that might have been in the hint
            }
        }

        console.log(
            document.getElementById(question_id + "__button"),
            question_id + "__button"
        );
        document.getElementById(question_id + "__button").innerHTML =
            t("Hint") +
            " (" +
            (hints.length -
                parseInt(
                    document.getElementById(question_id).getAttribute("hintsClicked")
                )) +
            " " +
            t("left") +
            ") ";
        try {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, question_id + "_hints"]);
        } catch {
            (e) => console.log(e);
        }

        setTimeout(() => {
            for (
                var i = 0;
                i < document.getElementById(question_id).getAttribute("hintsClicked");
                i++
            ) {
                console.log(document.getElementById(question_id + "_hint_" + i));
                document.getElementById(question_id + "_hint_" + i).style.opacity = "1";
            }
        }, 100);
    };

    const getMetadata = (key) => {
        var metadata;
        itemApp.getMetadata(function (obj) {
            metadata = obj;
        });
        if (typeof key === "undefined") {
            return metadata;
        } else {
            return metadata[key];
        }
    };

    const getObjectKeys = (obj) => {
        var keys = [];
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }
        return keys;
    };

    const handleHints = () => {
        // logic for hints starts
        const {t} = props;
        var metadata = getMetadata();
        var question_ids = getObjectKeys(metadata);
        // Render a button next to each question for users to see a hint (if available)
        console.log(metadata, question_ids, "[]");
        // for that question
        for (var i = 0; i < question_ids.length; i++) {
            var id = question_ids[i];
            var metadata = getMetadata(id);
            var hints = metadata.hints;
            console.log(i + "[" + id + "]", hints, metadata);
            if (hints != undefined && hints.length > 0) {
                var btnHint = document.createElement("div");
                var btn = document.createElement("button");
                console.log(question_ids[i], id);
                btn.onclick = (e) => renderHint(e);
                btn.innerText = t("Hint");
                btn.className = "lrn_btn hits-button";
                btn.id = id + "__button";
                btnHint.className = "col-xs-12";
                btnHint.append(btn);
                /*
                btnHint.innerHTML =
                  '<button type="button" class="btn btn-default btn-sm"' +
                  id +
                  ` onclick=${()=>this.renderHint(id)}`+">Hint</button>";*/
                document.getElementById(id).closest("div.row").append(btnHint);
            }
        }
        // logic for hints end
    };

    const stopAudio = () => {
        window.LearnosityItems.audio.stop();
        return t("Are you sure you want to leave?");
    };

    const submitAssessment = () => {
        submited = "SUBMITTING";
        config.mode == "dev" &&
        console.log(
            "[learnosity Start Call Finish Mutation]",
            LearnositySession_id
        );
        if (standalone) {
            if (activity) {
                var value;
                if (!isInappBrowser) {
                    value = {
                        Lang: localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC",
                        ResourceId: props.match.params.resId.split("&")[0],
                        SessionId: LearnositySession_id,
                        SchoolYearId: selectedSchoolYear.SchoolYearId,
                    };
                } else {
                    value = {
                        Lang:
                            localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC",
                        ResourceId: localStorage.getItem("in-app-browser-data-resourceid"),
                        SessionId: LearnositySession_id,
                        SchoolYearId: localStorage.getItem("selectedSchoolYear"),
                    };
                }
                const result = (e) => {
                    try {
                        if (standalone) {
                            try {
                                gaTrack(
                                    "send",
                                    "event",
                                    "Assessment",
                                    "Submit",
                                    "Assessment:" +
                                    resourceData.ResourceLangs[0].ResourceName +
                                    ", Resource ID:" +
                                    resourceData.IngestionId +
                                    ", Book Series:" +
                                    bookData.BookSeriesName[0].BookSeriesName +
                                    ", Book:NoData" +
                                    ", Chapter:NoData" +
                                    ", Role:" +
                                    userType
                                );
                            } catch (e) {
                                console.log("GA Error");
                            }
                        }
                    } catch (e) {
                        console.log("Check answer GA Error");
                    }
                    console.log(
                        "[learnosity CompleteSelfLearnMutation]",
                        e,
                        JSON.parse(e.completeSelfLearn)
                    );
                    submitAssessmentResult = JSON.parse(e.completeSelfLearn).reportObj;
                    // settagsData(JSON.parse(e.completeSelfLearn).tagData);
                    submited = "DONE";
                    console.log("[Tags data]", JSON.parse(e.completeSelfLearn).tagData);
                };
                CompleteSelfLearnMutation(value, result);
            } else {
                const value = {
                    Lang: lang == "en-US" ? "EN" : "TC",
                    ResourceAssignResId: resourceData.assign_id,
                    SessionId: LearnositySession_id,
                };
                console.log(value, "value");
                const result = (e) => {
                    console.log(
                        "[learnosity CompleteAssignmentMutation]",
                        e,
                        JSON.parse(e.completeAssignment)
                    );
                    try {
                        gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Submit",
                            "Assessment:" +
                            resourceData.ResourceLangs[0].ResourceName +
                            ", Resource ID:" +
                            resourceData.IngestionId +
                            ", Book Series:" +
                            bookData.BookSeriesName[0].BookSeriesName +
                            ", Book:NoData" +
                            ", Chapter:NoData" +
                            ", Role:" +
                            userType
                        );
                    } catch (e) {
                        console.log("GA Error");
                    }
                    submitAssessmentResult = JSON.parse(e.completeAssignment).reportObj;
                    // settagsData(JSON.parse(e.completeAssignment).tagData);
                    submited = "DONE";
                    console.log("[Tags data]", JSON.parse(e.completeAssignment).tagData);
                };
                CompleteAssignmentMutation(value, result);
            }
        } else {
            let value = {
                Lang: lang == "en-US" ? "EN" : "TC",
                ResourceId: !isInappBrowser
                    ? props.match.params.resId.split("&")[0]
                    : props.inappData.resourceId,
                SessionId: LearnositySession_id,
                SchoolYearId: selectedSchoolYear.SchoolYearId,
            };
            if (!B2C) {
                value = {
                    ...value,
                    StudyGroupId: props.location.state?.group_id || "",
                };
            }

            console.log(value, "value");
            const result = (e) => {
                try {
                    if (standalone) {
                        try {
                            gaTrack(
                                "send",
                                "event",
                                "Assessment",
                                "Submit",
                                "Assessment:" +
                                resourceData.ResourceLangs[0].ResourceName +
                                ", Resource ID:" +
                                resourceData.IngestionId +
                                ", Book Series:" +
                                bookData.BookSeriesName[0].BookSeriesName +
                                ", Book:NoData" +
                                ", Chapter:NoData" +
                                ", Role:" +
                                userType
                            );
                        } catch (e) {
                            console.log("GA Error");
                        }
                    } else {
                        const {resourceData, bookData} = props;
                        gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Submit",
                            "Assessment:" +
                            resourceData.ResourceLangs[0].ResourceName +
                            ", Resource ID:" +
                            resourceData.IngestionId +
                            ", Level:" +
                            (B2C
                                ? "NoData"
                                : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
                            ", Book Series:" +
                            bookData.BookSeriesName[0].BookSeriesName +
                            ", Book:" +
                            bookData.Books[0].BookName[0].BookName +
                            ", Chapter:" +
                            (bookData.Books && bookData.Books.length > 0
                                ? bookData.Books[0].Units[0].UnitName[0].UnitName
                                : "NoData") +
                            ", Role:" +
                            userType +
                            `, From:${B2C ? "Supplementary" : "My School"}`
                        );
                    }
                } catch (e) {
                    console.log("Check answer GA Error");
                }
                console.log(
                    "[learnosity CompleteSelfLearnMutation]",
                    e,
                    JSON.parse(e.completeSelfLearn)
                );
                submitAssessmentResult = JSON.parse(e.completeSelfLearn).reportObj;
                // settagsData(JSON.parse(e.completeSelfLearn).tagData);
                submited = "DONE";
                console.log("[Tags data]", JSON.parse(e.completeSelfLearn).tagData);
            };
            CompleteSelfLearnMutation(value, result);
        }
    };

    if (userType == "T") {
        if (!isPrizm) {
            return (
                <React.Fragment>
                    <Prompt
                        when={LeaveAlert}
                        message={(location, action) => {
                            console.log("test");
                            if (
                                window.document.querySelector(".lrn_btn.lrn_pause_playback")
                            ) {
                                window.document
                                    .querySelector(".lrn_btn.lrn_pause_playback")
                                    .click();
                            }
                            return t("Are you sure you want to leave?");
                        }}
                    />
                    {loading || !isReadyToRender ? (
                        ""
                    ) : !isFinish ? (
                        <>
                            <div
                                style={{
                                    height: `${!initReady ? "100px" : "0px"}`,
                                }}
                            >
                                <div
                                    className="learnosity_loading"
                                    style={{
                                        display: `${initReady ? "none" : "block"}`,
                                        position: "absolute",
                                        width: "100%",
                                    }}
                                >
                                    <div className="splash-container">
                                        <div className="loader mx-auto"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    visibility: `${initReady ? "visible" : "hidden"}`,
                                    height: `${initReady ? "auto" : "0px"}`,
                                    //position: `${initReady ? "relative" : "absolute"}`
                                }}
                            >
                                <div id="learnosity_assess"></div>
                            </div>
                        </>
                    ) : (
                        <div className="submited-report">
                            <div className="filter-row">
                                <div className="user-report">
                                    <h3>
                                        {
                                            // isInappBrowser
                                            //   ? UserDisplayName
                                            // :
                                            UserDisplayName.length != 2
                                                ? UserDisplayName[0].UserName
                                                : UserDisplayName[
                                                    lang == UserDisplayName[0].Lang ? 0 : 1
                                                    ].UserName
                                        }
                                    </h3>
                                    <h5>
                                        <Moment format="YYYY-MM-DD HH:mm">
                                            {/*8selectedValueId &&
                        HistoryArray[seletedValue].AttemptDate*/}
                                        </Moment>
                                    </h5>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    id={"redo_assessment"}
                                    onClick={() => {
                                        var userTypeGa = "";
                                        if (isInappBrowser) {
                                            userTypeGa =
                                                localStorage.getItem("role") == "teacher" ? "T" : "S";
                                        } else {
                                            userTypeGa = JSON.parse(
                                                localStorage.getItem("userData")
                                            ).UserType;
                                        }

                                        try {
                                            if (!standalone) {
                                                const {resourceData, bookData} = props;

                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Redo",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Level:" +
                                                    (B2C
                                                        ? "NoData"
                                                        : bookData.Books[0].PearsonLevel.LevelName[0]
                                                            .LevelName) +
                                                    ", Book Series:" +
                                                    bookData.BookSeriesName[0].BookSeriesName +
                                                    ", Book:" +
                                                    bookData.Books[0].BookName[0].BookName +
                                                    ", Chapter:" +
                                                    (bookData.Books && bookData.Books.length > 0
                                                        ? bookData.Books[0].Units[0].UnitName[0].UnitName
                                                        : "NoData") +
                                                    ", Role:" +
                                                    userTypeGa +
                                                    `, From:${B2C ? "Supplementary" : "My School"}`
                                                );
                                            } else {
                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Redo",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Level:NoData" +
                                                    ", Book Series:" +
                                                    bookData.BookSeriesName[0].BookSeriesName +
                                                    ", Book:NoData" +
                                                    ", Chapter:NoData" +
                                                    ", Role:" +
                                                    userType
                                                );
                                            }
                                        } catch (e) {
                                            console.log("Check answer GA Error", e);
                                        }

                                        activityLayoutTab({disable: true, isInappBrowser});
                                        setisFinish(false);
                                        setinitReady(false);
                                        setLeaveAlert(true);
                                        window.LearnosityItems.reset();
                                        setTimeout(() => getLearnosityObject(), 100);
                                    }}
                                >
                                    {t("Re-do Assessment")}
                                </button>
                            </div>
                            <div id="sessions-summary"></div>
                            <div id="session-detail"></div>
                        </div>
                    )}
                </React.Fragment>
            );
        } else {

            if (openPrizmSubmission) {
                return (
                    <React.Fragment>
                        <div class="prizmIframe">
                            {showPrizmLoader && <Loading/>}
                            <iframe
                                id="iframe_id"
                                src={props.location.state.previewURL}                                
                                frameBorder="0"
                                allow="microphone"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <div class="prizmIframe">
                            {showPrizmLoader && <Loading/>}
                            <iframe
                                id="iframe_id"
                                src={prizmURL}                               
                                frameBorder="0"
                                allow="microphone"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </React.Fragment>
                );
            }

        }
    }

    if (userType == "S") {
        console.log(B2C, isRedoBlock);
        if (!isPrizm) {
            return (
                <React.Fragment>
                    <Prompt
                        when={LeaveAlert}
                        message={(location, action) => {
                            if (
                                window.document.querySelector(".lrn_btn.lrn_pause_playback")
                            ) {
                                window.document
                                    .querySelector(".lrn_btn.lrn_pause_playback")
                                    .click();
                            }
                            return t("Are you sure you want to leave?");
                        }}
                    />
                    {loading || !isReadyToRender ? (
                        ""
                    ) : !isFinish ? (
                        <>
                            <div
                                style={{
                                    height: `${!initReady ? "100px" : "0px"}`,
                                }}
                            >
                                <div
                                    className="learnosity_loading"
                                    style={{
                                        display: `${initReady ? "none" : "block"}`,
                                        position: "absolute",
                                        width: "100%",
                                    }}
                                >
                                    <div className="splash-container">
                                        <div className="loader mx-auto"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    visibility: `${initReady ? "visible" : "hidden"}`,
                                    height: `${initReady ? "auto" : "0px"}`,
                                }}
                            >
                                <div id="learnosity_assess"></div>
                            </div>
                        </>
                    ) : showResultAfterSubmit ? (
                        <div className="submited-report">
                            <div className="filter-row">
                                <div className="user-report">
                                    <h3>
                                        {
                                            // isInappBrowser
                                            //   ? UserDisplayName
                                            // :
                                            UserDisplayName.length != 2
                                                ? UserDisplayName[0].UserName
                                                : UserDisplayName[
                                                    lang == UserDisplayName[0].Lang ? 0 : 1
                                                    ].UserName
                                        }
                                    </h3>
                                    <h5>
                                        <Moment format="YYYY-MM-DD HH:mm"></Moment>
                                    </h5>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    id={"redo_assessment"}
                                    onClick={() => {
                                        var userTypeGa = "";
                                        if (isInappBrowser) {
                                            userTypeGa =
                                                localStorage.getItem("role") == "teacher" ? "T" : "S";
                                        } else {
                                            userTypeGa = userType;
                                        }
                                        if (!B2C) {
                                            var book =
                                                    bookData.BookSeries.Books &&
                                                    bookData.BookSeries.Books.length > 0
                                                        ? bookData.BookSeries.Books[0].BookName[0].BookName
                                                        : "NoData",
                                                chapter =
                                                    bookData.BookSeries.Books &&
                                                    bookData.BookSeries.Books.length > 0
                                                        ? bookData.BookSeries.Books[0].Units[0].UnitName[0]
                                                            .UnitName
                                                        : "NoData";
                                        }

                                        try {
                                            if (standalone) {
                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Redo",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Book Series:" +
                                                    bookData.BookSeriesName[0].BookSeriesName +
                                                    ", Book:NoData" +
                                                    ", Chapter:NoData" +
                                                    ", Role:" +
                                                    userType
                                                );
                                            } else {
                                                const {resourceData, bookData} = props;

                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Redo",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Book Series:" +
                                                    (B2C
                                                        ? bookData.BookSeriesName[0].BookSeriesName
                                                        : bookData.BookSeries.BookSeriesName[0]
                                                            .BookSeriesName) +
                                                    ", Book:" +
                                                    (B2C
                                                        ? bookData.Books[0].BookName[0].BookName
                                                        : book) +
                                                    ", Chapter:" +
                                                    (B2C ? "NoData" : chapter) +
                                                    ", Role:" +
                                                    userType +
                                                    `, From:${B2C ? "Supplementary" : "My School"}`
                                                );
                                            }
                                        } catch (e) {
                                            console.log("Check answer GA Error");
                                        }
                                        activityLayoutTab({disable: true, isInappBrowser});
                                        setisFinish(false);
                                        setinitReady(false);
                                        setLeaveAlert(true);
                                        window.LearnosityItems.reset();
                                        setTimeout(() => getLearnosityObject(), 100);
                                    }}
                                >
                                    {t("Re-do Assessment")}
                                </button>
                            </div>
                            <div id="sessions-summary"></div>
                            <div id="session-detail"></div>
                        </div>
                    ) : (
                        <div className="submited-box">
                            <div className="submited-icon">
                                <i className="icon mdi mdi-check-circle"></i>
                            </div>
                            <div className="submited-text">
                                <h3>{t("You have done this.")}</h3>
                                <h5>
                                    {!isRedoBlock
                                        ? t(
                                            "Good! the assessment is done already. You may do it again."
                                        )
                                        : t("Good, the assessment is done already.")}
                                </h5>
                            </div>
                            <div className="submited-btn">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        try {
                                            if (standalone) {
                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Click view my result",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Level:NoData" +
                                                    ", Book Series:" +
                                                    bookData.BookSeriesName[0].BookSeriesName +
                                                    ", Book:NoData" +
                                                    ", Chapter:NoData" +
                                                    ", Role:" +
                                                    userType
                                                );
                                            } else {
                                                const {resourceData, bookData} = props;
                                                if (!B2C) {
                                                    var book =
                                                            bookData.BookSeries.Books &&
                                                            bookData.BookSeries.Books.length > 0
                                                                ? bookData.BookSeries.Books[0].BookName[0]
                                                                    .BookName
                                                                : "NoData",
                                                        chapter =
                                                            bookData.BookSeries.Books &&
                                                            bookData.BookSeries.Books.length > 0
                                                                ? bookData.BookSeries.Books[0].Units[0]
                                                                    .UnitName[0].UnitName
                                                                : "NoData";
                                                }

                                                var userTypeGa = "";
                                                if (isInappBrowser) {
                                                    userTypeGa =
                                                        localStorage.getItem("role") == "teacher"
                                                            ? "T"
                                                            : "S";
                                                } else {
                                                    userTypeGa = userType;
                                                }
                                                gaTrack(
                                                    "send",
                                                    "event",
                                                    "Assessment",
                                                    "Click view my result",
                                                    "Assessment:" +
                                                    resourceData.ResourceLangs[0].ResourceName +
                                                    ", Resource ID:" +
                                                    resourceData.IngestionId +
                                                    ", Level:" +
                                                    (B2C
                                                        ? "NoData"
                                                        : bookData.Level.LevelName[0].LevelName) +
                                                    ", Book Series:" +
                                                    (B2C
                                                        ? bookData.BookSeriesName[0].BookSeriesName
                                                        : bookData.BookSeries.BookSeriesName[0]
                                                            .BookSeriesName) +
                                                    ", Book:" +
                                                    (B2C
                                                        ? bookData.Books[0].BookName[0].BookName
                                                        : book) +
                                                    ", Chapter:" +
                                                    (B2C ? "NoData" : chapter) +
                                                    ", Role:" +
                                                    userTypeGa +
                                                    `, From:${B2C ? "Supplementary" : "My School"}`
                                                );
                                            }
                                        } catch (e) {
                                            console.log("Check answer GA Error");
                                        }
                                        if (!isInappBrowser) {
                                            props.history.push(
                                                props.location.pathname.replace("/start", "/result")
                                            );
                                        }

                                        if (isInappBrowser && standalone) {
                                            props.history.push(
                                                "/" +
                                                (entry === "assignment" ? "assignment" : "activity") +
                                                "/" +
                                                match.params.resId +
                                                "/result"
                                            );
                                        }
                                        if (isInappBrowser && !standalone) {
                                            props.history.push("/assessment=api/result");
                                        }
                                    }}
                                >
                                    {t("View Result")}
                                </button>
                                {(!isRedoBlock || B2C) && (
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            try {
                                                if (standalone) {
                                                    gaTrack(
                                                        "send",
                                                        "event",
                                                        "Assessment",
                                                        "Redo",
                                                        "Assessment:" +
                                                        resourceData.ResourceLangs[0].ResourceName +
                                                        ", Resource ID:" +
                                                        resourceData.IngestionId +
                                                        ", Book Series:" +
                                                        bookData.BookSeriesName[0].BookSeriesName +
                                                        ", Book:NoData" +
                                                        ", Chapter:NoData" +
                                                        ", Role:" +
                                                        userType
                                                    );
                                                } else {
                                                    const {resourceData, bookData} = props;

                                                    gaTrack(
                                                        "send",
                                                        "event",
                                                        "Assessment",
                                                        "Redo",
                                                        "Assessment:" +
                                                        resourceData.ResourceLangs[0].ResourceName +
                                                        ", Resource ID:" +
                                                        resourceData.IngestionId +
                                                        ", Book Series:" +
                                                        bookData.BookSeriesName[0].BookSeriesName +
                                                        ", Book:" +
                                                        bookData.Books[0].BookName[0].BookName +
                                                        ", Chapter:" +
                                                        (bookData.Books && bookData.Books.length > 0
                                                            ? bookData.Books[0].Units[0].UnitName[0]
                                                                .UnitName
                                                            : "NoData") +
                                                        ", Role:" +
                                                        userType +
                                                        `, From:${B2C ? "Supplementary" : "My School"}`
                                                    );
                                                }
                                            } catch (e) {
                                                console.log("Check answer GA Error", e);
                                            }
                                            activityLayoutTab({disable: true, isInappBrowser});

                                            setisFinish(false);
                                            setinitReady(false);
                                            window.LearnosityItems.reset();
                                            setTimeout(() => getLearnosityObject(), 100);
                                        }}
                                    >
                                        {t("Re-do Assessment")}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            );
        } else {
            if (openPrizmSubmission) {
                return (
                    <React.Fragment>
                        <div class="prizmIframe">
                            {showPrizmLoader && <Loading/>}
                            <iframe
                                id="iframe_id"
                                src={props.location.state.previewURL}                               
                                frameBorder="0"
                                allow="microphone"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </React.Fragment>
                );
            } else {
                return (
                    // for prizm start below ifram is loading

                    <React.Fragment>
                        <div class="prizmIframe">
                            {showPrizmLoader && <Loading/>}
                            <iframe
                                id="iframe_id"
                                src={prizmURL}                                
                                frameBorder="0"
                                allow="microphone"
                                allowFullScreen
                            ></iframe>
                        </div>
                        {showLoader && <Loading/>}
                        {showActivityPopUp && <ActivityPopUp
                            t={t}
                            {...props}
                            activityName={activityName}
                            setShowActivityPopUp={setShowActivityPopUp}
                            bookData={bookData}
                        />}
                    </React.Fragment>
                );
            }

        }
    }

};

export default index;
