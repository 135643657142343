import React, { useState, useEffect, useRef } from "react";
import "./ActivityPopUp.scss";
import { useDispatch, useSelector } from "react-redux";
const ActivityPopUp = (props) => {
  const { bookData, setShowActivityPopUp, showActivityPopUp, activityName, setActivityName, t,prizmBookId } = props;
  const dispatch = useDispatch();
  const [currentBookPopup, setcurrentBookPopup] = useState([]);
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  useEffect(() => {
    var listArray = bookData.Books.filter((o) => o.BookId == prizmBookId);
    //console.log("kkkk", prizmBookId, listArray);
    setcurrentBookPopup(listArray);
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowActivityPopUp(false);
      }
    };
    if (showActivityPopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showActivityPopUp]);
  const notification_popupref = useRef(null);

  const onClickBackSDL = () => {
    props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");

      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: currentBookPopup[0].BookId,
          selectedBookName: currentBookPopup[0].BookName[0].BookName,
          selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
          selectedBookSeriesId: bookData.BookSeriesId,
          selectedResourceName:selectOptions.selectedResourceName,
          selectedClassLevelId: selectOptions.selectedStudyGroupId,
          selectedClassLevelName: selectOptions.selectedStudyGroupName,
          selectecResourceTab:selectOptions.selectecResourceTab,
          seletctedTabSDL:selectOptions.seletctedTabSDL,
          selectedToggle: selectOptions.selectedToggle,

        },
      });
 
  };
  return (
    <div className="activity_notification__popup-overlay">
      <div className="activity_notification__popup" ref={notification_popupref}>
      {activityName != 'save' &&
        <>
          <label
            className="activity_notification__popup-top-heading"
          >
            {/* {activityName === 'save' ? t("Activity Submitted") : t("Activity is not submitted")} */}
            {activityName === 'save' ? '' : t("Submission failed.")}
          </label>
          <span class="activity_notification__popup-border"></span>
          </>
        }
        <h3 className={`activity_notification__popup-heading ${activityName == 'save' ? 'activity_notification__popup-save-heading' :''}`}>
          {/* {activityName === 'save' ? t("The activity is submitted.") : t("It seems there's a problem with your submission. Please try again.")} */}
          {/*{activityName === 'save' ? t("Successfully Submitted") : t("Please try again")}*/}
          {activityName === 'save' ? "Successfully submitted." : t("Please try again")}
        </h3>
        <span className="activity_notification__popup-border"></span>
        <div className="activity_notification__popup-bottom">
        {activityName === 'save' ?
          <button
            className="buttons btns activity_notification__popup-back-btn"
            onClick={() => {
              onClickBackSDL();
            }}
          >
            {/* {t("Back to SDL")} */}
            {t("OK")}
          </button> :
          <button
            className="buttons btns activity_notification__popup-ok-btn"
            onClick={() => {
              setShowActivityPopUp(false);
            }}
          >
            {t("OK")}
          </button>
        }
        </div>
      </div>
    </div>
  );
};

export default ActivityPopUp;
