
import React, { Component, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../i18n";
import { config } from "../../../../Config/config";
// import profileIcon from "../../../assets/img/person_24px.svg";
import GetNotificationData from "../../../Feature/Function/GetNotificationData";
import GetNotificationCount from "../../../Feature/Function/GetNotificationCount";
import ReadResourceSharingMutation from "../../../../Services/api/GraphQL/Mutation/ReadResourceSharingMutation";
import FullScreenModal from "../../../Feature/FullScreenModal";
import NotificationItem from "../NotificationItem";
import HandBookNoData from "../HandBookNoData";
import Loading from "../../../Loading/PEC/Loading";
import NotificationPopUp from "../../../../Layouts/DashboardLayout/FavouritePage/NotificationPopUp";
import { schemaDefinitionNotAloneMessage } from "graphql/validation/rules/LoneSchemaDefinition";
import useInAppBrowser from "../../../../hooks/useInAppBrowser";
// import MySchoolBookSeriesData from "../../../Services/api/GraphQL/Query/MySchoolBookSeriesData";

// import environment from "../../../Environment";
// import { fetchQuery, graphql } from "relay-runtime";

const User = (props) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const notiCount = useSelector(
    (state) => state.UserReducer.notification_count
  );
  const notificationData = useSelector(
    (state) => state.NotificationListReducer.handbookData
  );

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const [handbookPopup, sethandbookPopup] = useState(false);
  const [notificationTitle, setnotificationTitle] = useState("");
  //  const [notificationData, setnotificationData] = useState(
  //     notificationDataT.length > 0 ? notificationDataT : []
  //   );
  const [notificationCountLocal, setnotificationCountLocal] = useState("");
  const notification_dropdownRef = useRef(null);
  const help_dropdownRef = useRef(null);
  const setting_dropdownRef = useRef(null);
  const user_dropdownRef = useRef(null);
  const role = userData?.UserType;
  const { t, updateSchoolYear } = props;
  const { pathname } = useLocation();
  const [openSetting, setopenSetting] = useState(false);
  const [openUser, setopenUser] = useState(false);
  const [openHelp, setopenHelp] = useState(false);
  const [openNotification, setopenNotification] = useState(false);
  const [notificationLoading, setnotificationLoading] = useState(false);
  const [notificationResourceInfo, setnotificationResourceInfo] = useState([]);

  const [isOpenViewAll, setisOpenViewAll] = useState(false);

  const [assignmentDetailsData, setAssignmentDetailsData] = useState({
    isOpen: false,
    resId: 0,
    assignmentName: "",
  });
  const [isModalOpen, setisModalOpen] = useState(false);
  const { systemBrowserOpenLink } = useInAppBrowser();

  // const [{ notification_count }, dispatch] = useStateValue();
  // useEffect(() => {
  //   set
  // }, [lang])
  useEffect(() => {
    if (role == "S") updateBellCount(false);
  }, []);

  useEffect(() => {
    //alert(updateSchoolYear)
    if (updateSchoolYear) {
      if (role == "S") updateBellCount(true);
    }
  }, [updateSchoolYear]);

  // useEffect(() => {
  //   setnotificationUnreadCount(notiCount);
  // }, [notiCount]);

  // useEffect(() => {
  //   window.setnotificationUnreadCount = updateBellCount;
  // }, []);

  useEffect(() => {
    if (isOpenViewAll || assignmentDetailsData.isOpen) {
      setisModalOpen(true);
    } else {
      setisModalOpen(false);
    }
  }, [isOpenViewAll, assignmentDetailsData]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_dropdownRef.current !== null &&
        !notification_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Notification");
        // document.querySelector(
        //   ".ReactModal__Content.ReactModal__Content--after-open"
        // )
        //   ? (document.querySelector(
        //       ".ReactModal__Content.ReactModal__Content--after-open"
        //     ).style.overflow = "auto")
        //   : "";
        if(document.querySelector(
          ".ReactModal__Content.ReactModal__Content--after-open"
        )){
          document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
            el.setAttribute("style", "overflow: auto");    
          })
        }
      }
    };
    if (openNotification) {
      //setnotificationCountLocal(0);
      handleOpenNotification();
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openNotification]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        help_dropdownRef.current !== null &&
        !help_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Help");
      }
    };
    if (openHelp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openHelp]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        setting_dropdownRef.current !== null &&
        !setting_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Setting");
      }
    };
    if (openSetting) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openSetting]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        user_dropdownRef.current !== null &&
        !user_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("User");
      }
    };
    if (openUser) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openUser]);

  const handleOpenDropdownMenu = (type) => {
    switch (type) {
      case "Notification":
        setopenNotification(!openNotification);
        break;
      case "Setting":
        setopenSetting(!openSetting);
        break;
      case "Help":
        setopenHelp(!openHelp);
        break;
      case "User":
        setopenUser(!openUser);
        break;
      default:
        break;
    }
  };

  const refreshhandbook = (type) => {
    if (type) {
      window.location.reload();
    }
  };
  const updateBellCount = (refres) => {
    if (JSON.parse(localStorage.getItem("selectedSchoolYear")).IsEffective) {
      GetNotificationCount({
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
        SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
          .SchoolYearId,
      }).then((data, error) => {
        const {
          user: { NotificationCount = 0 },
        } = data || {};
        dispatch({
          type: "UPDATE_NOTIFICATION_COUNT",
          count: parseInt(NotificationCount),
        });
        setnotificationCountLocal(parseInt(NotificationCount));
         
      });
    }
  };

  const updateReadNotification = (dataArg) => {
    const callback = (result) => {
      console.log("Read",result)
      var outRes = JSON.parse(result.readResourceSharing)
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        count: parseInt(outRes.unreadCount),
      });
      setnotificationCountLocal(parseInt(outRes.unreadCount));
     console.log("ss",outRes.unreadCount) ;
    };
    //props.toggleNotificationPopUp("delete");
    //refreshhandbook(HandbookPopupFlag);
    ReadResourceSharingMutation(dataArg, callback);
  };

  const showNotifyDetail = (notifyData) => {

    if(role == "S"){
      var variables = {
        SharingId: notifyData.SharingObj.SharingId,
        SchoolYearId: selectedSchoolYear.SchoolYearId,
      };
      updateReadNotification(variables)
    }
    
    
    setnotificationTitle(
      notifyData.SharingObj.RelatedResourcesConfig[0].Resource.ResourceLangs[
        notifyData.SharingObj.RelatedResourcesConfig[0].Resource
          .ResourceLangs[0].Lang == lang
          ? 0
          : 1
      ].ResourceName
    );
    setnotificationResourceInfo(notifyData.SharingObj);
    setopenNotification(false);
    // document.querySelector(
    //   ".ReactModal__Content.ReactModal__Content--after-open"
    // )
    //   ? (document.querySelector(
    //       ".ReactModal__Content.ReactModal__Content--after-open"
    //     ).style.overflow = "inherit")
    //   : "";
    if(document.querySelector(
      ".ReactModal__Content.ReactModal__Content--after-open"
    )){
      document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
        el.setAttribute("style", "overflow: inherit");    
      })
    }
    sethandbookPopup(true);
  };

  const handleOpenNotification = async () => {
    // if (isOpen) {
    setnotificationLoading(true);
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
        .SchoolYearId,
      OrderBy: "LAST_MODIFY_DATE",
      PageNo: 1,
      PageSize: 10,
    };
    await GetNotificationData(variables, role, "all").then((data) => {
      if (role == "S") {
        // dispatch({
        //   type: "UPDATE_NOTIFICATION_COUNT",
        //   count: parseInt(0),
        // });
        dispatch({
          type: "SET_HANDBOOK_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.NotificationList.TotalNumberOfRecord || [],
        });

        dispatch({
          type: "SET_NOTIFICATION_LIST",
          handbookData: data.user.NotificationList.NotificationList || [],
        });
        dispatch({
          type: "SET_NOTIFICATION_LIST_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.AssignmentBelongsTo.TotalNumberOfRecord || [],
        });

        //setnotificationData(data.user.NotificationList.NotificationList);
      } else {
        dispatch({
          type: "SET_HANDBOOK_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.NotificationList.TotalNumberOfRecord || [],
        });
        dispatch({
          type: "SET_NOTIFICATION_LIST",
          handbookData: data.user.NotificationList.NotificationList || [],
        });
        dispatch({
          type: "SET_NOTIFICATION_LIST_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.NotificationList.TotalNumberOfRecord || [],
        });

        //setnotificationData(data.user.NotificationList.NotificationList);
      }

      // setnotificationUnreadCount(10);

      setnotificationLoading(false);
    });
    // this.props.handleOpenNotification();
    // }
  };

  const HandBookData = [
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Topic intro has been shown",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Book 1A is updated",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Warm-up is updated",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Example of assignment",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Topic intro has been shown",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Book 1A is updated",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Warm-up is updated",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Example of assignment",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Warm-up is updated",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
    {
      title: "Longman English Edge (Second Edition) - Class 1A",
      message: "Example of assignment",
      date: "2018-08-17",
      to: "Class 1A",
      from: "Chris Wong",
    },
  ];

  return (
    <>
    <div class={`${props.showFlag ? "" : "main__section-sdl-top"}`} onClick={() => {
                props.redirectToDashboard();
          }}>
        <i class="main__section-sdl-top-logo" >
        </i>
      </div>
      <div className="class-nb-buttons dash-nav" tabIndex="1">
        <div
          className={`${openNotification ? "navigation-overlay" : ""}`}
        ></div>
        <ul className="nav navbar-nav float-right be-icons-nav main-navigation-bar">
          {/* home page icon starts here */}
          <li className={`nav-item dropdown`}>
            <button
              // title={t("Home")}
              type="button"
              className="link-button nav-link dropdown-toggle dropdown-toggle-cursor-default"
              onClick={() => {
                props.redirectToDashboard();
              }}
            >
              {/* <i
                title={t("Home")}
                className={`icon ${
                  props.showFlag ? "home-icon" : "home-icon1"
                } mdi dropdown-toggle-cursor`}
              /> */}
              <span
                title={t("Home")}
                className={`icon ${
                  props.showFlag ? "" : "icon1"
                } mdi mdi-home dropdown-toggle-cursor`}
              />
            </button>
          </li>
          {/* home page icon ends here */}
          {/* Notification starts here */}
          <li className={`nav-item dropdown ${openNotification ? "show" : ""}`}>
            <button
              // title={t("Notifications")}
              type="button"
              className="link-button nav-link dropdown-toggle dropdown-toggle-cursor-default"
              onClick={() => {
                if (!openNotification) {
                  // document.querySelector(
                  //   ".ReactModal__Content.ReactModal__Content--after-open"
                  // )
                  //   ? (document.querySelector(
                  //       ".ReactModal__Content.ReactModal__Content--after-open"
                  //     ).style.overflow = "inherit")
                  //   : "";
                  if(document.querySelector(
                    ".ReactModal__Content.ReactModal__Content--after-open"
                  )){
                    document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
                      el.setAttribute("style", "overflow: inherit");    
                    })
                  }  
                }
                handleOpenDropdownMenu("Notification");
                // setnotificationLoading(true);
                // setTimeout(() => {
                //   setnotificationLoading(false);
                // }, 1300);
              }}
            >
              {/* Notification */}
              <span
                title={t("Handbook")}
                className={`icon ${
                  props.showFlag ? "" : "icon1"
                } mdi mdi-notifications-active dropdown-toggle-cursor`}
              />
              {notiCount != 0 && (
                <div className="Rectangle-5">
                  {notiCount > 99 ? "99+" : notiCount}
                </div>
              )}
            </button>
            {/* Notification window starts here */}
            {/* <div
              id="nav"
              className={`dropdown-menu be-notifications notification   ${
                openNotification ? "show" : ""
              } ${
                notificationLoading || notificationData.length == 0
                  ? "noti-loading"
                  : ""
              }`}
              aria-labelledby="dropdownSettings"
              ref={notification_dropdownRef}
            >
              <div className="topnav">
                <a className="active">{t("Latest")}</a>
              </div>

              <div className="tab-body">
                {notificationLoading && (
                  <div className="notification_loading">
                    <Loading type={`normal`} />
                  </div>
                )}
                {!notificationLoading && role == "S" && (
                  <NotificationItem
                    from={"Notificaiton menu"}
                    notificationData={notificationData}
                    t={t}
                    handleAssignmentDetails={(data) => {
                      document.querySelector("[data-modal]").dataset.modal =
                        parseInt(
                          document.querySelector("[data-modal]").dataset.modal
                        ) + 1;
                      setAssignmentDetailsData({
                        isOpen: data.isOpen,
                        resId: data.resId,
                        AssignmentName: data.AssignmentName,
                      });
                    }}
                  />
                )}
                {((!notificationLoading && notificationData.length == 0) ||
                  role == "T") && (
                  <div className="no-notification">
                    <span className="icon mdi mdi-notifications-active no-notification-img-div" />
                    <div className="no-notification-text">
                      {t("There is no notification yet.")}
                    </div>
                  </div>
                )}
              </div>
              {!notificationLoading && notificationData.length != 0 && (
                <div className="tab-footer">
                  <div className="notification-view-all">
                    <div
                      className="button-bg"
                      onClick={() => {
                        setisOpenViewAll(!isOpenViewAll);
                      }}
                    >
                      {t("View All")}
                    </div>
                  </div>
                </div>
              )}
            </div> */}

            {openNotification && (
              <div
                className={`notification__handbook ${
                  props.hanbookFlag ? "notification__handbook-position" : ""
                }`}
                ref={notification_dropdownRef}
              >
                {notificationLoading && (
                  <div className="notification_loading">
                    <Loading type={`normal`} />
                  </div>
                )}

                {!notificationLoading && notificationData.length > 0 && (
                  <div className="notification__handbook-content">
                    <div className="notification__handbook-head">
                      <h3 className="notification__handbook-head-heading">
                        {t("Handbook")}
                      </h3>
                      <label
                        className="notification__handbook-close"
                        onClick={() => {
                          setopenNotification(false);
                          // document.querySelector(
                          //   ".ReactModal__Content.ReactModal__Content--after-open"
                          // )
                          //   ? (document.querySelector(
                          //       ".ReactModal__Content.ReactModal__Content--after-open"
                          //     ).style.overflow = "auto")
                          //   : "";
                          if(document.querySelector(
                            ".ReactModal__Content.ReactModal__Content--after-open"
                          )){
                            document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
                              el.setAttribute("style", "overflow: auto");    
                            })
                          }
                        }}
                      >
                        &nbsp;
                      </label>
                    </div>

                    <NotificationItem
                      from={"Notificaiton menu"}
                      notificationData={notificationData}
                      doopenNotificationInfo={(notifyData) =>
                        showNotifyDetail(notifyData)
                      }
                      t={t}
                      handleAssignmentDetails={(data) => {
                        document.querySelector("[data-modal]").dataset.modal =
                          parseInt(
                            document.querySelector("[data-modal]").dataset.modal
                          ) + 1;
                        setAssignmentDetailsData({
                          isOpen: data.isOpen,
                          resId: data.resId,
                          AssignmentName: data.AssignmentName,
                        });
                      }}
                    />
                  </div>
                )}

                {!notificationLoading && notificationData.length == 0 && (
                  <div className="notification__handbook-content">
                    <div className="notification__handbook-head">
                      <h3 className="notification__handbook-head-heading">
                        {t("Handbook")}
                      </h3>
                      <label
                        className="notification__handbook-close"
                        onClick={() => {
                          setopenNotification(false);
                          // document.querySelector(
                          //   ".ReactModal__Content.ReactModal__Content--after-open"
                          // )
                          //   ? (document.querySelector(
                          //       ".ReactModal__Content.ReactModal__Content--after-open"
                          //     ).style.overflow = "auto")
                          //   : "";
                            if(document.querySelector(
                              ".ReactModal__Content.ReactModal__Content--after-open"
                            )){
                              document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
                                el.setAttribute("style", "overflow: auto");    
                              })
                            } 
                        }}
                      >
                        &nbsp;
                      </label>
                    </div>

                    <HandBookNoData t={t} notificationData={notificationData} />
                  </div>
                )}
                {!notificationLoading && notificationData.length > 0 && (
                  <div className="notification__handbook-viewbtn">
                    <button
                      className="btn buttons view-all-button"
                      onClick={() => setisOpenViewAll(!isOpenViewAll)}
                    >
                      {t("View All")}
                    </button>
                  </div>
                )}
              </div>
            )}
            {/* Notification window ends here */}
            <div
              id="iconbutton"
              className={`menu-overlay ${openNotification ? "show" : ""}`}
              onClick={() => {
                handleOpenDropdownMenu("Notification");
              }}
            />
          </li>
          {/* Notification ends here */}
          <li className={`nav-item dropdown ${openHelp ? "show" : ""}`}>
            <button
              // title={t("Help")}
              type="button"
              className="link-button nav-link dropdown-toggle dropdown-toggle-cursor-default"
              onClick={() => {
                handleOpenDropdownMenu("Help");
              }}
            >
              <span
                title={t("Help")}
                className={`icon ${
                  props.showFlag ? "" : "icon1"
                } mdi mdi-help dropdown-toggle-cursor`}
              />
            </button>
            <ul
              id="nav"
              className={`dropdown-menu be-notifications ${
                openHelp ? "show" : ""
              }`}
              aria-labelledby="dropdownSettings"
              styles="width:auto;"
              ref={help_dropdownRef}
            >
              <li>
                <button
                  title={t("Help")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    if (window.cordova) {
                      systemBrowserOpenLink(config.helpPage);
                    } else {
                      window.open(config.helpPage, "_blank");
                    }
                  }}
                >
                  <i className="icon mdi mdi-help menu-icon" />
                  {t("Help")}
                </button>
              </li>
              <li>
                <button
                  title={t("Download Pearson Apps")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    if (window.cordova) {
                      systemBrowserOpenLink(config.installPageURL);
                    } else {
                      window.open(config.installPageURL, "_blank");
                    }
                  }}
                >
                  <i className="icon mdi mdi-download menu-icon" />
                  {t("Download Pearson Apps")}
                </button>
              </li>
              <li>
                <button
                  title={t("Digital Support")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    if (window.cordova) {
                      lang == "en-US"
                        ? systemBrowserOpenLink(config.digitalSupport)
                        : systemBrowserOpenLink(config.digitalSupporthz);
                    } else {
                      lang == "en-US"
                        ? window.open(config.digitalSupport, "_blank")
                        : window.open(config.digitalSupporthz, "_blank");
                    }
                  }}
                >
                  <i className="icon mdi mdi-comment-alt-text menu-icon" />
                  {t("Digital Support")}
                </button>
              </li>
            </ul>
            <div
              id="iconbutton"
              className={`menu-overlay ${openHelp ? "show" : ""}`}
              onClick={() => {
                handleOpenDropdownMenu("Help");
              }}
            />
          </li>
          <li
            className={`nav-item dropdown ${openSetting ? "show" : ""}`}
            style={{ display: "none" }}
          >
            <button
              // title={t("Setting")}
              type="button"
              className="link-button nav-link dropdown-toggle dropdown-toggle-cursor-default"
              onClick={() => {
                handleOpenDropdownMenu("Setting");
              }}
            >
              <span
                title={t("Setting")}
                className={`icon ${
                  props.showFlag ? "" : "icon1"
                } mdi mdi-settings dropdown-toggle-cursor`}
              />
            </button>
            <ul
              id="nav"
              className={`dropdown-menu be-notifications ${
                openSetting ? "show" : ""
              }`}
              aria-labelledby="dropdownSettings"
              styles="width:auto;"
              ref={setting_dropdownRef}
            >
              {lang == "en-US" ? (
                <li>
                  <button
                    title={t("Chinese")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      dispatch({
                        type: "SET_LANG",
                        lang: "zh-HK",
                      });
                    }}
                  >
                    <i className="icon mdi mdi-globe menu-icon" />
                    {t("Chinese")}
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    title={t("English")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      dispatch({
                        type: "SET_LANG",
                        lang: "en-US",
                      });
                    }}
                  >
                    <i className="icon mdi mdi-globe menu-icon" />
                    {t("English")}
                  </button>
                </li>
              )}
            </ul>
            <div
              id="iconbutton"
              className={`menu-overlay ${openSetting ? "show" : ""}`}
              onClick={() => {
                handleOpenDropdownMenu("Setting");
              }}
            />
          </li>
          <li className={`nav-item dropdown ${openUser ? "show" : ""}`}>
            <button
              // title={t("Profile")}
              type="button"
              className="link-button nav-link dropdown-toggle dropdown-toggle-cursor-default"
              onClick={() => {
                console.log('inside profile click event', gaTrack)
                // gaTrack(
                //   "send",
                //   "event",
                //   "General",
                //   "Open profile menu",
                //   "Role:" +role
                //   );
                gaTrack('event', 'General - Open profile menu', {
                  'Role': role
                });
                handleOpenDropdownMenu("User");
              }}
            >
              <span
                title={t("Profile")}
                className={`icon ${
                  props.showFlag ? "" : "icon1"
                } mdi mdi-account dropdown-toggle-cursor`}
              />
            </button>
            <ul
              id="nav"
              className={`dropdown-menu be-notifications profile ${
                openUser ? "show" : ""
              }`}
              ref={user_dropdownRef}
            >
              <li className="title">
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    cursor: "default",
                  }}
                >
                  {t("Hello")}, &nbsp;
                  {/* <span className="signed-name">Teacher!</span> */}
                  <span className="signed-name">{`${
                    localStorage.getItem("i18nextLng") == "en-US"
                      ? userData.UserDisplayName[
                          userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                        ].UserName
                      : userData.UserDisplayName[
                          userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                        ].UserName != ""
                      ? userData.UserDisplayName[
                          userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                        ].UserName
                      : userData.UserDisplayName[
                          userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                        ].UserName
                  }`}</span>
                </div>
              </li>
              <li>
                <button
                  title={t("Logout")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    props.logout();
                  }}
                >
                  <i className="icon mdi mdi-lock-open menu-icon" />
                  {t("Logout")}
                </button>
              </li>
            </ul>
            <div
              id="iconbutton"
              className={`menu-overlay ${openUser ? "show" : ""}`}
              onClick={() => handleOpenDropdownMenu("User")}
            />
          </li>
        </ul>
      </div>
      {handbookPopup && (
        <NotificationPopUp
          toggleNotificationPopUp={() => sethandbookPopup(!handbookPopup)}
          selectClass={"1A"}
          notificationTitle={notificationTitle}
          notificationResourceInfo={notificationResourceInfo}
          HandbookPopupFlag={true}
          popUpType={"handbook"}
          showNotificatioPopUp={handbookPopup}
          setShowNotificatioPopUp={sethandbookPopup}
          t={t}
          refreshhandbook={refreshhandbook}
          notificationPopupOverlay={""}
          setNotificationPopupOverlay={sethandbookPopup}
        />
      )}
      <FullScreenModal
        type="viewAllNotification"
        isOpen={isOpenViewAll}
       
        refreshhandbook={refreshhandbook}
        onClose={() => setisOpenViewAll(!isOpenViewAll)}
        isModalOpen={isModalOpen}
        setAssignmentDetailsData={setAssignmentDetailsData}
        t={t}
        HandBookData={HandBookData}
      />
    </>
  );
};

export default User;
