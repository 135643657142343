/**
 * @flow
 * @relayHash c0710856a4da245cdbad1636b728184b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StandAloneAssignmentQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>,
  SchoolYearId: string,
|};
export type StandAloneAssignmentQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +ResourceType: ?string,
      +TagData: ?string,
      +RelatedId: ?string,
      +ResourceId: ?string,
      +IngestionId: ?string,
      +Book: ?{|
        +BookName: ?$ReadOnlyArray<?{|
          +BookName: ?string,
          +Lang: ?string,
        |}>,
        +Units: ?$ReadOnlyArray<?{|
          +UnitName: ?$ReadOnlyArray<?{|
            +UnitName: ?string,
            +Lang: ?string,
            +UnitTitle: ?string,
          |}>
        |}>,
      |},
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +ResourceName: ?string,
      |}>,
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +AssignmentName: ?string,
        +ResourceAssignId: ?string,
        +BookSeriesId: ?string,
      |}>,
    |}>
  |}
|};
export type StandAloneAssignmentQuery = {|
  variables: StandAloneAssignmentQueryVariables,
  response: StandAloneAssignmentQueryResponse,
|};
*/


/*
query StandAloneAssignmentQuery(
  $ResourceId: [String]!
  $SchoolYearId: String!
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      BookSeriesId
      ResourceType
      TagData
      RelatedId
      ResourceId
      IngestionId
      Book {
        BookName {
          BookName
          Lang
        }
        Units {
          UnitName {
            UnitName
            Lang
            UnitTitle
          }
          id
        }
        id
      }
      ResourceLangs {
        Lang
        ResourceName
      }
      AssignementInvloved(SchoolYearId: $SchoolYearId) {
        AssignmentName
        ResourceAssignId
        BookSeriesId
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagData",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "RelatedId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UnitName",
  "storageKey": null,
  "args": null,
  "concreteType": "unitLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitTitle",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AssignmentName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAssignId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StandAloneAssignmentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Book",
                "storageKey": null,
                "args": null,
                "concreteType": "book",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/)
                    ]
                  }
                ]
              },
              (v11/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v12/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StandAloneAssignmentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Book",
                "storageKey": null,
                "args": null,
                "concreteType": "book",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v15/*: any*/)
                    ]
                  },
                  (v15/*: any*/)
                ]
              },
              (v11/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v12/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v2/*: any*/),
                  (v15/*: any*/)
                ]
              },
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StandAloneAssignmentQuery",
    "id": null,
    "text": "query StandAloneAssignmentQuery(\n  $ResourceId: [String]!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      BookSeriesId\n      ResourceType\n      TagData\n      RelatedId\n      ResourceId\n      IngestionId\n      Book {\n        BookName {\n          BookName\n          Lang\n        }\n        Units {\n          UnitName {\n            UnitName\n            Lang\n            UnitTitle\n          }\n          id\n        }\n        id\n      }\n      ResourceLangs {\n        Lang\n        ResourceName\n      }\n      AssignementInvloved(SchoolYearId: $SchoolYearId) {\n        AssignmentName\n        ResourceAssignId\n        BookSeriesId\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e1f24aee8ceac497e6ba86bcf07884c';
module.exports = node;
