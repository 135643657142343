import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { isBrowser, isMobile } from "react-device-detect";
import { config } from "./Config/config";
import "./Config/language/en-US.json";
import "./Config/language/zh-HK.json";

const check = () => {
  i18n
    .use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        cache: false,
      whitelist: ["en-US", "zh-HK"],
      fallbackLng: "en-US",
      load: "currentOnly",
      backend: {
        // for all available options read the backend's repository readme file
        //loadPath: '/assets/config/' + '{{lng}}.json'
        loadPath:
          config.device == "Web"
            ? config.languageFileUrl + "{{lng}}.json"
            : `${
                window.location.origin +
                window.location.pathname.replace("/index.html", "")
              }` + "/assets/config/{{lng}}.json",
        /*
			loadPath:
				`${window.location.origin + window.location.pathname.replace('/index.html', '')}` +
				'/assets/config/{{lng}}.json'
			*/
      },
      //lng: 'en',
      debug: true,
      ns: ["translations"],
      saveMissing: false,
      keySeparator: false,

      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        nsMode: "fallback",
      },
    });
  return i18n;
};

export default check();
