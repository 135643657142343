/**
 * @flow
 * @relayHash cd29811cffe8cec13374fad9cc8b0423
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type CompleteSelfLearnMutationVariables = {|
  ResourceId: string,
  SessionId: string,
  Lang?: ?LangEnumType,
  StudyGroupId?: ?string,
  SchoolYearId: string,
|};
export type CompleteSelfLearnMutationResponse = {|
  +completeSelfLearn: ?string
|};
export type CompleteSelfLearnMutation = {|
  variables: CompleteSelfLearnMutationVariables,
  response: CompleteSelfLearnMutationResponse,
|};
*/


/*
mutation CompleteSelfLearnMutation(
  $ResourceId: String!
  $SessionId: String!
  $Lang: LangEnumType
  $StudyGroupId: String
  $SchoolYearId: String!
) {
  completeSelfLearn(ResourceId: $ResourceId, SessionId: $SessionId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SessionId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "completeSelfLearn",
    "args": [
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang",
        "type": "LangEnumType"
      },
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "SessionId",
        "variableName": "SessionId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupId",
        "variableName": "StudyGroupId",
        "type": "String"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteSelfLearnMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteSelfLearnMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CompleteSelfLearnMutation",
    "id": null,
    "text": "mutation CompleteSelfLearnMutation(\n  $ResourceId: String!\n  $SessionId: String!\n  $Lang: LangEnumType\n  $StudyGroupId: String\n  $SchoolYearId: String!\n) {\n  completeSelfLearn(ResourceId: $ResourceId, SessionId: $SessionId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7e79150a29a237d0773c2652466f8210';
module.exports = node;
