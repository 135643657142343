import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookseries_favorites } from "../../../Actions";
import GetNotificationData from "../../../Components/Feature/Function/GetNotificationData";
import Loading from "../../../Components/Loading/PEC/Loading";

const NotificationDetails = (props) => {
  const dispatch = useDispatch();
  const { noticationLoading, setNoticationLoading } = props;
  //   const [noticationLoading, setNoticationLoading] = useState(false);
  const [notificationsDetails, setNotificationsDetails] = useState([]);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);

  useEffect(() => {
    handleAssignmentList(parseInt(props.selectClass), parseInt(props.BookId));
    //   handleAssignmentList(27729, 701);
  }, []);

  // notification details starts here
  // BookId:"780", StudyGroupId: "27689"
  const handleAssignmentList = (StudyGroupId, BookId) => {
    setNoticationLoading(true);
    var favBookSeriesId = [];
    var preferencesObj = bookseries_favorites(userData.Preferences);
    var variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: StudyGroupId,
      BookId: BookId,
      OrderBy: "LAST_MODIFY_DATE",
      PageNo: 1,
      PageSize: 10,
    };
    GetNotificationData(variables, props.role).then((data) => {
      // dispatch({
      //   type: "SET_NOTIFICATION_LIST",
      //   handbookData:
      //     data.user[props.role == "S" ? "NotificationList" : "NotificationList"]
      //       .NotificationList || [],
      // });
      setNotificationsDetails(data);
      setNoticationLoading(false);
    });
  };
  // notification details ends here

  return noticationLoading ? (
    <Loading type={`normal`} />
  ) : notificationsDetails.length > 0 ? (
    notificationsDetails.map((el) => {
      return (
        <div className="favourite-notification-main">
          <label
            className="favourite-notification-title"
            onClick={() => {
              props.toggleNotificationPopUp(
                "yes",
                "Topic intro has been shown"
              );
            }}
          >
            {/* {props.intro} */}
            Topic intro has been shown
          </label>
          <label className="favourite-notification-details">
            {props.favBookData.BookName[0].BookName}
          </label>
          <span className="favourite-notification-lines"></span>
        </div>
      );
    })
  ) : (
    <p className="no-notification-message">There is no notification</p>
  );
};

export default NotificationDetails;
