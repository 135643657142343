/**
 * @flow
 * @relayHash 05a015d0d4ed03b061fabe803ac59ffc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OnBoardingMutationVariables = {|
  SchoolYearId: string,
  StudyGroupIds: $ReadOnlyArray<?string>,
|};
export type OnBoardingMutationResponse = {|
  +teacherOnboard: ?string
|};
export type OnBoardingMutation = {|
  variables: OnBoardingMutationVariables,
  response: OnBoardingMutationResponse,
|};
*/


/*
mutation OnBoardingMutation(
  $SchoolYearId: String!
  $StudyGroupIds: [String]!
) {
  teacherOnboard(SchoolYearId: $SchoolYearId, StudyGroupIds: $StudyGroupIds)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupIds",
    "type": "[String]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "teacherOnboard",
    "args": [
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupIds",
        "variableName": "StudyGroupIds",
        "type": "[String]!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OnBoardingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OnBoardingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OnBoardingMutation",
    "id": null,
    "text": "mutation OnBoardingMutation(\n  $SchoolYearId: String!\n  $StudyGroupIds: [String]!\n) {\n  teacherOnboard(SchoolYearId: $SchoolYearId, StudyGroupIds: $StudyGroupIds)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2389804da26d4df6d2cbc2184b5a1369';
module.exports = node;
