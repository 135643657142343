/**
 * @flow
 * @relayHash 20ef3e2f27caf791d65c240e71a25234
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GraphQLQueryGetEPSTokenStudentQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type GraphQLQueryGetEPSTokenStudentQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceDownloadUrl: ?string,
      +ResourceFileNameIfAny: ?string,
      +ResourceAccessUrl: ?string,
      +EpsAccessToken: ?string,
    |}>
  |}
|};
export type GraphQLQueryGetEPSTokenStudentQuery = {|
  variables: GraphQLQueryGetEPSTokenStudentQueryVariables,
  response: GraphQLQueryGetEPSTokenStudentQueryResponse,
|};
*/


/*
query GraphQLQueryGetEPSTokenStudentQuery(
  $ResourceId: [String]!
  $StudyGroupId: String!
  $SchoolYearId: String!
) {
  resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
    Resources {
      ResourceDownloadUrl
      ResourceFileNameIfAny
      ResourceAccessUrl
      EpsAccessToken
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceDownloadUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceFileNameIfAny",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAccessUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "EpsAccessToken",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GraphQLQueryGetEPSTokenStudentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GraphQLQueryGetEPSTokenStudentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GraphQLQueryGetEPSTokenStudentQuery",
    "id": null,
    "text": "query GraphQLQueryGetEPSTokenStudentQuery(\n  $ResourceId: [String]!\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n    Resources {\n      ResourceDownloadUrl\n      ResourceFileNameIfAny\n      ResourceAccessUrl\n      EpsAccessToken\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a5f99d8826a3d0c8d7668a554c2deb5';
module.exports = node;
