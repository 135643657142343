/**
 * @flow
 * @relayHash c7c31e0119b94b9b7d82f3608b585a66
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginreCaptchaTokenQueryVariables = {|
  token: string
|};
export type LoginreCaptchaTokenQueryResponse = {|
  +reCaptchaToken: ?boolean
|};
export type LoginreCaptchaTokenQuery = {|
  variables: LoginreCaptchaTokenQueryVariables,
  response: LoginreCaptchaTokenQueryResponse,
|};
*/


/*
query LoginreCaptchaTokenQuery(
  $token: String!
) {
  reCaptchaToken(token: $token)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "reCaptchaToken",
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginreCaptchaTokenQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginreCaptchaTokenQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "LoginreCaptchaTokenQuery",
    "id": null,
    "text": "query LoginreCaptchaTokenQuery(\n  $token: String!\n) {\n  reCaptchaToken(token: $token)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fc6585a4c1cb625ef9b338d410e4670';
module.exports = node;
