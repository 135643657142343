/**
 * @flow
 * @relayHash 0f58732dfcde8591727b0fb06bba9cab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExternalPlatformEnumType = "DWB" | "EBook" | "MasterWebsite" | "%future added value";
export type GetHawkKeyQueryVariables = {|
  platform: ExternalPlatformEnumType
|};
export type GetHawkKeyQueryResponse = {|
  +hawkKey: ?string
|};
export type GetHawkKeyQuery = {|
  variables: GetHawkKeyQueryVariables,
  response: GetHawkKeyQueryResponse,
|};
*/


/*
query GetHawkKeyQuery(
  $platform: ExternalPlatformEnumType!
) {
  hawkKey(platform: $platform)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "platform",
    "type": "ExternalPlatformEnumType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hawkKey",
    "args": [
      {
        "kind": "Variable",
        "name": "platform",
        "variableName": "platform",
        "type": "ExternalPlatformEnumType"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetHawkKeyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GetHawkKeyQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "GetHawkKeyQuery",
    "id": null,
    "text": "query GetHawkKeyQuery(\n  $platform: ExternalPlatformEnumType!\n) {\n  hawkKey(platform: $platform)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f632c65169fd866507b15da954b13d87';
module.exports = node;
