/**
 * @flow
 * @relayHash 4945c68c0561373ed54f2e6dde455964
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetB2CTopicResourcesQueryVariables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
  TagName: string,
  PageNo?: ?number,
  PageSize?: ?number,
|};
export type GetB2CTopicResourcesQueryResponse = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +Books: ?$ReadOnlyArray<?{|
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +RelatedTagType: ?$ReadOnlyArray<?{|
            +Tags: ?$ReadOnlyArray<?{|
              +TopicPreStudyResources: ?{|
                +Resources: ?$ReadOnlyArray<?{|
                  +IngestionId: ?string,
                  +ResourceId: ?string,
                  +RelatedId: ?string,
                  +ResourceDownloadUrl: ?string,
                  +ResourceFileNameIfAny: ?string,
                  +ResourceAccessUrl: ?string,
                  +ResourceType: ?string,
                  +Linkage: ?string,
                  +FileSource: ?string,
                  +Downloadable: ?string,
                  +Thumbnail: ?{|
                    +FilePath: ?string
                  |},
                  +ResourceLangs: ?$ReadOnlyArray<?{|
                    +Lang: ?string,
                    +ResourceName: ?string,
                  |}>,
                |}>,
                +TotalNumberOfRecord: ?number,
              |}
            |}>
          |}>
        |}>
      |}>
    |}>
  |}
|};
export type GetB2CTopicResourcesQuery = {|
  variables: GetB2CTopicResourcesQueryVariables,
  response: GetB2CTopicResourcesQueryResponse,
|};
*/


/*
query GetB2CTopicResourcesQuery(
  $UserId: String!
  $BookSeriesId: String!
  $BookId: String!
  $TagName: String!
  $PageNo: Int
  $PageSize: Int
) {
  user(UserId: $UserId) {
    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
      Books(BookId: $BookId) {
        AdoptedTagHierarchy {
          RelatedTagType {
            Tags(BookId: $BookId, TagName: $TagName) {
              TopicPreStudyResources(BookId: $BookId, IncludeFollowUpRes: true, PageNo: $PageNo, PageSize: $PageSize, Order: ASC) {
                Resources {
                  IngestionId
                  ResourceId
                  RelatedId
                  ResourceDownloadUrl
                  ResourceFileNameIfAny
                  ResourceAccessUrl
                  ResourceType
                  Linkage
                  FileSource
                  Downloadable
                  Thumbnail {
                    FilePath
                    id
                  }
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                  id
                }
                TotalNumberOfRecord
              }
              id
            }
            id
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "TagName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "PageNo",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "PageSize",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "String"
  }
],
v3 = {
  "kind": "Variable",
  "name": "BookId",
  "variableName": "BookId",
  "type": "String"
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "TagName",
    "variableName": "TagName",
    "type": "String"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String!"
  },
  {
    "kind": "Literal",
    "name": "IncludeFollowUpRes",
    "value": true,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "Order",
    "value": "ASC",
    "type": "DisplayOrderEnumType"
  },
  {
    "kind": "Variable",
    "name": "PageNo",
    "variableName": "PageNo",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "PageSize",
    "variableName": "PageSize",
    "type": "Int"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "RelatedId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceDownloadUrl",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceFileNameIfAny",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAccessUrl",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Linkage",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FileSource",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Downloadable",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TotalNumberOfRecord",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetB2CTopicResourcesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "RelatedTagType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "Tags",
                            "storageKey": null,
                            "args": (v5/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "TopicPreStudyResources",
                                "storageKey": null,
                                "args": (v6/*: any*/),
                                "concreteType": "resourceSearchResult",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "Resources",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "resource",
                                    "plural": true,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "Thumbnail",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "systemFile",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/)
                                        ]
                                      },
                                      (v18/*: any*/)
                                    ]
                                  },
                                  (v19/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GetB2CTopicResourcesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "RelatedTagType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "Tags",
                            "storageKey": null,
                            "args": (v5/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "TopicPreStudyResources",
                                "storageKey": null,
                                "args": (v6/*: any*/),
                                "concreteType": "resourceSearchResult",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "Resources",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "resource",
                                    "plural": true,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "Thumbnail",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "systemFile",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          (v20/*: any*/)
                                        ]
                                      },
                                      (v18/*: any*/),
                                      (v20/*: any*/)
                                    ]
                                  },
                                  (v19/*: any*/)
                                ]
                              },
                              (v20/*: any*/)
                            ]
                          },
                          (v20/*: any*/)
                        ]
                      },
                      (v20/*: any*/)
                    ]
                  },
                  (v20/*: any*/)
                ]
              },
              (v20/*: any*/)
            ]
          },
          (v20/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GetB2CTopicResourcesQuery",
    "id": null,
    "text": "query GetB2CTopicResourcesQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n  $TagName: String!\n  $PageNo: Int\n  $PageSize: Int\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      Books(BookId: $BookId) {\n        AdoptedTagHierarchy {\n          RelatedTagType {\n            Tags(BookId: $BookId, TagName: $TagName) {\n              TopicPreStudyResources(BookId: $BookId, IncludeFollowUpRes: true, PageNo: $PageNo, PageSize: $PageSize, Order: ASC) {\n                Resources {\n                  IngestionId\n                  ResourceId\n                  RelatedId\n                  ResourceDownloadUrl\n                  ResourceFileNameIfAny\n                  ResourceAccessUrl\n                  ResourceType\n                  Linkage\n                  FileSource\n                  Downloadable\n                  Thumbnail {\n                    FilePath\n                    id\n                  }\n                  ResourceLangs {\n                    Lang\n                    ResourceName\n                  }\n                  id\n                }\n                TotalNumberOfRecord\n              }\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1350c94a56cd122cc5cee448c2ec151c';
module.exports = node;
