import { config } from "../../Config/config";
const initGATag = async () => {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','G-6QG0XCJHS1')

  
  const data = JSON.parse(localStorage.getItem('userData'))
  if (data == null) {
    console.log('logout')
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-6QG0XCJHS1');
    gtag('set', 'user_properties', {
        user_Device: window.cordova ? "App" : "Web",
    });

    // gaTrack("set", "js", new Date());
    // gaTrack("set", "config", 'G-6QG0XCJHS1');
    // gaTrack("set", "user_properties", {
    //   user_Device: window.cordova ? "App" : "Web"
    // });
  } else {
    console.log('login')
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-6QG0XCJHS1', {  'user_id': data?.UserId });
    gtag('set', 'user_properties', {
        user_Device: window.cordova ? "App" : "Web", user_School: data?.School?.SchoolCode
    });
    // gaTrack("set", "js", new Date());
    // gaTrack("set", "config", 'G-6QG0XCJHS1', {  'user_id': data.UserId });
    // gaTrack("set", "user_properties", {
    //   user_Device: window.cordova ? "App" : "Web", user_School: data.School.SchoolCode
    // });
  }
};

export default initGATag;
