export const floating_tab_status = (user_preferences) => {
  return user_preferences.filter((item, index) => {
    return (
      item.PreferenceName == "FLOATING_TAB" && item.PreferenceValue == "CLOSE"
    );
  });
};

export const dashboard_guide_status = (user_preferences) => {
  var key = ["FIRST_TIME_GUIDE", "FIRST_TIME_GUIDE_DASHBOARD"],
    value = "SKIP"; //deafault Group
  return user_preferences.filter((item, index) => {
    return (
      key.indexOf(item.PreferenceName) != -1 && value == item.PreferenceValue
    );
  });
};
// for unit page
export const dashboard_unit_guide_status = (user_preferences) => {
  var key = [
      "FIRST_TIME_GUIDE",
      "FIRST_TIME_GUIDE_STUDYGORUP_ASSESSMENT_LIST",
      "FIRST_TIME_GUIDE_STUDYGORUP_MENU",
    ],
    value = "SKIP"; //deafault Group
  return user_preferences.filter((item, index) => {
    return (
      key.indexOf(item.PreferenceName) != -1 && value == item.PreferenceValue
    );
  });
};

export const dashboard_view_status = (user_preferences) => {
  var key = "DASHBOARD_VIEW",
    value = "List"; //deafault Group
  return user_preferences.filter((item, index) => {
    return key == item.PreferenceName && value == item.PreferenceValue;
  });
};

//Studygroup menu
export const studygroup_menu_guide_status = (user_preferences) => {
  var key = "FIRST_TIME_GUIDE_STUDYGORUP_MENU",
    value = "SKIP"; //deafault Group
  return user_preferences.filter((item, index) => {
    return key == item.PreferenceName && value == item.PreferenceValue;
  });
};
//Resource center
export const rc_guide_status = (user_preferences) => {
  var key = [
      "FIRST_TIME_GUIDE",
      "FIRST_TIME_GUIDE_STUDYGORUP_RESOURCE_CENTER",
      "FIRST_TIME_GUIDE_STUDYGORUP_MENU",
    ],
    value = "SKIP"; //deafault Group
  return user_preferences.filter((item, index) => {
    return (
      key.indexOf(item.PreferenceName) != -1 && value == item.PreferenceValue
    );
  });
};
export const rc_view_status = (user_preferences) => {
  var key = "RESOURCES_CENTER_VIEW",
    value = "List"; //deafault Group
  return user_preferences.filter((item, index) => {
    return key == item.PreferenceName && value == item.PreferenceValue;
  });
};

export const bookseries_toggle = (user_preferences) => {
  var key = "MYSCHOOL_BOOKSERIES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const bookseries_favorites = (user_preferences) => {
  var key = "MYSCHOOL_USER_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const resource_book_favorites = (user_preferences) => {
  var key = "RESOURCE_BOOK_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const myschool_tab = (user_preferences) => {
  var key = "MYSCHOOL_TAB";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const handbook_bookseries = (user_preferences) => {
  var key = "HANDBOOK_BOOK_SERIES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const handbook_book_name = (user_preferences) => {
  var key = "HANDBOOK_BOOK_NAME";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const handbook_level = (user_preferences) => {
  var key = "HANDBOOK_LEVEL";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const handbook_book_tab = (user_preferences) => {
  var key = "HANDBOOK_BOOK_TAB";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const handbook_book_name_tab = (user_preferences) => {
  var key = "HANDBOOK_BOOK_NAME_TAB";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const handbook_level_tab = (user_preferences) => {
  var key = "HANDBOOK_LEVEL_TAB";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const mylevel_tab = (user_preferences) => {
  var key = "MYLEVEL_TAB";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const assessment_book_favorites = (user_preferences) => {
  var key = "ASSESSMENT_BOOK_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
export const book_level_favorites = (user_preferences) => {
  var key = "BOOK_LEVEL_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const dashboard_book_level_favorites = (user_preferences) => {
  var key = "DASHBOARD_BOOK_LEVEL_NEW_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const dashboard_book_favorites = (user_preferences) => {
  var key = "DASHBOARD_BOOK_FAVORITES";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};

export const onboarding_addtional_res_filter = (user_preferences) => {
  return user_preferences.filter(
    (obj) => obj.PreferenceName == "ONBOARDING_ADDITIONAL_RESOURCE"
  );
};

export const bookseries_showall_toggle = (user_preferences) => {
  var key = "BOOKSERIES_SHOW_ALL";
  return user_preferences.filter((obj) => obj.PreferenceName == key);
};
