/**
 * @flow
 * @relayHash 5d3ee876c663c3615ba6e136d93edd6c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConfigNameEnumType = "DisableRedo" | "HideAccessment" | "HideResource" | "ReleaseRedo" | "ShowAccessment" | "ShowResource" | "%future added value";
export type SelfLearnConfigurationInputType = {|
  ResourceId?: ?string,
  SchoolYearId: string,
  StudyGroupId: string,
  StructureId?: ?string,
  Action: ConfigNameEnumType,
|};
export type UpdateSelfLearnConfigMutationVariables = {|
  NotifyStudent: boolean,
  SelfLearnConfigurations: $ReadOnlyArray<?SelfLearnConfigurationInputType>,
|};
export type UpdateSelfLearnConfigMutationResponse = {|
  +updateSelfLearnConfig: ?boolean
|};
export type UpdateSelfLearnConfigMutation = {|
  variables: UpdateSelfLearnConfigMutationVariables,
  response: UpdateSelfLearnConfigMutationResponse,
|};
*/


/*
mutation UpdateSelfLearnConfigMutation(
  $NotifyStudent: Boolean!
  $SelfLearnConfigurations: [SelfLearnConfigurationInputType]!
) {
  updateSelfLearnConfig(NotifyStudent: $NotifyStudent, SelfLearnConfigurations: $SelfLearnConfigurations)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "NotifyStudent",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SelfLearnConfigurations",
    "type": "[SelfLearnConfigurationInputType]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "updateSelfLearnConfig",
    "args": [
      {
        "kind": "Variable",
        "name": "NotifyStudent",
        "variableName": "NotifyStudent",
        "type": "Boolean!"
      },
      {
        "kind": "Variable",
        "name": "SelfLearnConfigurations",
        "variableName": "SelfLearnConfigurations",
        "type": "[SelfLearnConfigurationInputType]!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSelfLearnConfigMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSelfLearnConfigMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSelfLearnConfigMutation",
    "id": null,
    "text": "mutation UpdateSelfLearnConfigMutation(\n  $NotifyStudent: Boolean!\n  $SelfLearnConfigurations: [SelfLearnConfigurationInputType]!\n) {\n  updateSelfLearnConfig(NotifyStudent: $NotifyStudent, SelfLearnConfigurations: $SelfLearnConfigurations)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e8c24489d9e5d12ebb73ff35720cf28';
module.exports = node;
