/**
 * @flow
 * @relayHash 15bea8a756a0e87121b65402a796c571
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type AssessmentGetLearnosityObjectMutationVariables = {|
  ResourceId: string,
  Lang?: ?LangEnumType,
  StudyGroupId?: ?string,
  SchoolYearId: string,
  Credential?: ?string,
|};
export type AssessmentGetLearnosityObjectMutationResponse = {|
  +attemptSelfLearn: ?string
|};
export type AssessmentGetLearnosityObjectMutation = {|
  variables: AssessmentGetLearnosityObjectMutationVariables,
  response: AssessmentGetLearnosityObjectMutationResponse,
|};
*/


/*
mutation AssessmentGetLearnosityObjectMutation(
  $ResourceId: String!
  $Lang: LangEnumType
  $StudyGroupId: String
  $SchoolYearId: String!
  $Credential: String
) {
  attemptSelfLearn(ResourceId: $ResourceId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, Credential: $Credential)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Credential",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "attemptSelfLearn",
    "args": [
      {
        "kind": "Variable",
        "name": "Credential",
        "variableName": "Credential",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang",
        "type": "LangEnumType"
      },
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupId",
        "variableName": "StudyGroupId",
        "type": "String"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AssessmentGetLearnosityObjectMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AssessmentGetLearnosityObjectMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AssessmentGetLearnosityObjectMutation",
    "id": null,
    "text": "mutation AssessmentGetLearnosityObjectMutation(\n  $ResourceId: String!\n  $Lang: LangEnumType\n  $StudyGroupId: String\n  $SchoolYearId: String!\n  $Credential: String\n) {\n  attemptSelfLearn(ResourceId: $ResourceId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, Credential: $Credential)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78e4f19f3d39587c2b1df48e4fba5a43';
module.exports = node;
