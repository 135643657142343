/**
 * @flow
 * @relayHash 183fed4c8582a4a0bb62bd585454915b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewAllNotificationPECTeacherSubjectListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
  FilterOnboarded: boolean,
|};
export type ViewAllNotificationPECTeacherSubjectListQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +BookSeries: ?{|
          +IngestionId: ?string,
          +BookSeriesId: ?string,
          +DisplaySequence: ?number,
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +BookSeriesId: ?string,
            +BookSeriesName: ?string,
          |}>,
          +AdoptedBooks: ?$ReadOnlyArray<?{|
            +id: ?string,
            +BookId: ?string,
            +BookName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +BookName: ?string,
            |}>,
            +BookImage: ?{|
              +FilePath: ?string
            |},
          |}>,
          +Books: ?$ReadOnlyArray<?{|
            +id: ?string,
            +BookId: ?string,
            +BookName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +BookName: ?string,
            |}>,
            +BookImage: ?{|
              +FilePath: ?string
            |},
          |}>,
          +Subject: ?{|
            +DisplaySequence: ?number,
            +SubjectId: ?string,
            +SubjectName: ?$ReadOnlyArray<?{|
              +SubjectName: ?string,
              +Lang: ?string,
            |}>,
          |},
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
      |}>
    |},
  |}
|};
export type ViewAllNotificationPECTeacherSubjectListQuery = {|
  variables: ViewAllNotificationPECTeacherSubjectListQueryVariables,
  response: ViewAllNotificationPECTeacherSubjectListQueryResponse,
|};
*/


/*
query ViewAllNotificationPECTeacherSubjectListQuery(
  $UserId: String!
  $SchoolYearId: String!
  $FilterOnboarded: Boolean!
) {
  user(UserId: $UserId) {
    UserId
    School {
      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {
        StudyGroupId
        BookSeries {
          IngestionId
          BookSeriesId
          DisplaySequence
          BookSeriesName {
            BookSeriesId
            BookSeriesName
          }
          AdoptedBooks(SchoolYearId: $SchoolYearId) {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
              id
            }
          }
          Books {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
              id
            }
          }
          Subject {
            DisplaySequence
            SubjectId
            SubjectName {
              SubjectName
              Lang
            }
            id
          }
          id
        }
        StudyGroupName {
          StudyGroupName
          Lang
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v4 = [
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = [
  (v3/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v13/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v16 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v14/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "BookImage",
    "storageKey": null,
    "args": null,
    "concreteType": "systemFile",
    "plural": false,
    "selections": [
      (v15/*: any*/)
    ]
  }
],
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SubjectId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SubjectName",
  "storageKey": null,
  "args": null,
  "concreteType": "subjectLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "SubjectName",
      "args": null,
      "storageKey": null
    },
    (v13/*: any*/)
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    },
    (v13/*: any*/)
  ]
},
v20 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v14/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "BookImage",
    "storageKey": null,
    "args": null,
    "concreteType": "systemFile",
    "plural": false,
    "selections": [
      (v15/*: any*/),
      (v11/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "AdoptedBooks",
                        "storageKey": null,
                        "args": (v10/*: any*/),
                        "concreteType": "book",
                        "plural": true,
                        "selections": (v16/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Books",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "book",
                        "plural": true,
                        "selections": (v16/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v19/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "AdoptedBooks",
                        "storageKey": null,
                        "args": (v10/*: any*/),
                        "concreteType": "book",
                        "plural": true,
                        "selections": (v20/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Books",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "book",
                        "plural": true,
                        "selections": (v20/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      (v11/*: any*/)
                    ]
                  },
                  (v19/*: any*/),
                  (v11/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "id": null,
    "text": "query ViewAllNotificationPECTeacherSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    UserId\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        BookSeries {\n          IngestionId\n          BookSeriesId\n          DisplaySequence\n          BookSeriesName {\n            BookSeriesId\n            BookSeriesName\n          }\n          AdoptedBooks(SchoolYearId: $SchoolYearId) {\n            id\n            BookId\n            BookName {\n              Lang\n              BookName\n            }\n            BookImage {\n              FilePath\n              id\n            }\n          }\n          Books {\n            id\n            BookId\n            BookName {\n              Lang\n              BookName\n            }\n            BookImage {\n              FilePath\n              id\n            }\n          }\n          Subject {\n            DisplaySequence\n            SubjectId\n            SubjectName {\n              SubjectName\n              Lang\n            }\n            id\n          }\n          id\n        }\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40a2197875f311160dd0be53ac634277';
module.exports = node;
