/**
 * @flow
 * @relayHash 49722d8925ba8726a75f7de7ca98b4b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetResourceReportStatisticQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>
|};
export type GetResourceReportStatisticQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnosityReportStatistic: ?string
    |}>
  |}
|};
export type GetResourceReportStatisticQuery = {|
  variables: GetResourceReportStatisticQueryVariables,
  response: GetResourceReportStatisticQueryResponse,
|};
*/


/*
query GetResourceReportStatisticQuery(
  $ResourceId: [String]!
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      LearnosityReportStatistic
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnosityReportStatistic",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetResourceReportStatisticQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GetResourceReportStatisticQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GetResourceReportStatisticQuery",
    "id": null,
    "text": "query GetResourceReportStatisticQuery(\n  $ResourceId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      LearnosityReportStatistic\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d3f353dccccdb2ef6bcccb6c10e75f4';
module.exports = node;
