/**
 * @flow
 * @relayHash b348d9a5890d9a0a67f71e222307a3fe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserTypeEnumType = "P" | "S" | "T" | "%future added value";
export type SelectStudentGetStudyGroupUserQueryVariables = {|
  SchoolCode: string,
  SchoolYearId: string,
  BookSeriesId?: ?$ReadOnlyArray<?string>,
  FilterOnboarded: boolean,
  UserType: UserTypeEnumType,
  Keyword?: ?string,
|};
export type SelectStudentGetStudyGroupUserQueryResponse = {|
  +school: ?{|
    +Schools: ?$ReadOnlyArray<?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
        +StudyGroupUser: ?$ReadOnlyArray<?{|
          +UserId: ?string,
          +ClassNumber: ?string,
          +User: ?{|
            +UserName: ?string
          |},
          +StudyGroupId: ?string,
        |}>,
      |}>
    |}>
  |}
|};
export type SelectStudentGetStudyGroupUserQuery = {|
  variables: SelectStudentGetStudyGroupUserQueryVariables,
  response: SelectStudentGetStudyGroupUserQueryResponse,
|};
*/


/*
query SelectStudentGetStudyGroupUserQuery(
  $SchoolCode: String!
  $SchoolYearId: String!
  $BookSeriesId: [String]
  $FilterOnboarded: Boolean!
  $UserType: UserTypeEnumType!
  $Keyword: String
) {
  school(SchoolCode: $SchoolCode) {
    Schools {
      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {
        StudyGroupId
        StudyGroupName {
          Lang
          StudyGroupName
        }
        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType, Keyword: $Keyword) {
          UserId
          ClassNumber
          User {
            UserName
            id
          }
          StudyGroupId
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SchoolCode",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "UserType",
    "type": "UserTypeEnumType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Keyword",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "SchoolCode",
    "variableName": "SchoolCode",
    "type": "String"
  }
],
v2 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v3 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "Keyword",
    "variableName": "Keyword",
    "type": "String"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "UserType",
    "variableName": "UserType",
    "type": "UserTypeEnumType!"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectStudentGetStudyGroupUserQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Schools",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupUser",
                    "storageKey": null,
                    "args": (v6/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectStudentGetStudyGroupUserQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Schools",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupUser",
                    "storageKey": null,
                    "args": (v6/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      },
                      (v4/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SelectStudentGetStudyGroupUserQuery",
    "id": null,
    "text": "query SelectStudentGetStudyGroupUserQuery(\n  $SchoolCode: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String]\n  $FilterOnboarded: Boolean!\n  $UserType: UserTypeEnumType!\n  $Keyword: String\n) {\n  school(SchoolCode: $SchoolCode) {\n    Schools {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType, Keyword: $Keyword) {\n          UserId\n          ClassNumber\n          User {\n            UserName\n            id\n          }\n          StudyGroupId\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0815330ab874bfb7c4d63c78eb91fcb8';
module.exports = node;
