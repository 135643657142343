import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import ScrollUpButton from "react-scroll-up-button";
import { Translation } from "react-i18next";
import moment from "moment";
import "./i18n";
import "moment-timezone";

import CheckAuth from "./Components/Feature/Function/CheckAuth";
//Css
import "./App.scss";
import "./Stylesheets/main.scss";
import "./assets/css/cardAnimation.css";
import "./assets/css/box-shadow.css";
import bgimage from "./assets/img/bg-v-2.svg";

import Login from "./Layouts/LoginPage/Login";
import MainIndex from "./Components/Index/MainIndex";
import DashboardLayout from "./Layouts/DashboardLayout";
import Report from "./Layouts/ReportLayout";
import ClassViewLayout from "./Layouts/ClassViewLayout";
import ClassViewLayoutPEC from "./Layouts/ClassViewLayoutPEC";
import InAppLandingLayout from "./Layouts/InAppLandingLayout";
import ApiView from "./Components/Api";
import OnBoarding from "./Components/OnBoarding";
import ErrorPage from "./Components/ErrorPage";
import SSOLogin from "./Components/Authentication/SSOLogin";
import AppSSO from "./Components/Authentication/AppSSOLogin";
import GetUserData from "./Components/Feature/Function/GetUserData";
import LearnosityLayout from "./Components/LearnosityLayout";
import MyResourcesView from "./Components/MyResourcesView";
import Loading from "./Components/Loading/Loading";
import { rc_view_status } from "./Actions";
import StudyNotes from "./Layouts/DashboardLayout/StudyNotes/StudyNotes.js";
import {isAndroid, isIOS} from "react-device-detect";
import {config} from "./Config/config";

function App(_props) {
  const dispatch = useDispatch();


  const userData = useSelector((state) => state.UserReducer.user);
  const platFrom = useSelector((state) => state.SystemReducer.from);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [authLoging, setauthLoging] = useState(true);

  useEffect(() => {
    console.log("HERE IS LOCATION: " + window.location.pathname);
    console.log("[App] init System...");
    //lang
    dispatch({
      type: "SET_LANG",
      lang: lang,
    });

    window.handleAppSSO = handleAppSSO;
    window.afterLogout = afterLogout;
    if (window.cordova) {
      document.addEventListener("resume", onResume, false);
    }
    document.ontouchstart = function (e) {
      console.log(e.target.classList.value, "test", e.target.constructor.name);
      if (
        e.target.classList.value != "drop-menu-item" &&
        e.target.constructor.name != "HTMLInputElement" &&
        e.target.constructor.name != "HTMLButtonElement" &&
        e.target.constructor.name != "HTMLLabelElement"
      )
        document.activeElement.blur();
    };
    document
      .getElementsByTagName("body")[0]
      .setAttribute(
        "style",
        `background-repeat:no-repeat;background-size:cover;background-position:center;background-image:url(${bgimage})`
      );
  }, []);

  useEffect(() => {
    if (window.cordova) {
      console.log("[App] Deep Link checking");
      if ((_props.dpURL || localStorage.getItem("dp_URL")) && !window.ULink) {
        handleAppSSO();
      } else {
        console.log("[Auth app check]");
        AuthChecking();
      }
    } else {
      console.log("[App] Skip Deep Link checking");
      if (
        window.location.pathname.indexOf("/ssologin") != -1
        // ||
        // window.location.pathname.indexOf("/assessment=api") != -1
      ) {
        console.log("[App] From sso/api...  skip auth check");
        if (window.location.pathname.indexOf("/assessment=api") == -1)
          dispatch({
            type: "SET_USER",
            user: null,
          });
        console.log("[App] No Auth");
        setauthLoging(false);
        console.log("[App] App started!");
      } else {
        AuthChecking();
      }
    }
  }, [_props.dpURL, localStorage.getItem("dp_URL")]);

  const onResume = () => {
    console.log("[App] onResume...");

    setTimeout(() => {
      if (window.ULink) {
        localStorage.removeItem("dp_URL"); //just safe
        let params = Object.keys(window.ULink.params);
        let u_path = window.ULink.path;
        params.map((o, i) => {
          if (i == 0) u_path = u_path + "?" + o + "=" + window.ULink.params[o];
          u_path = u_path + "&" + o + "=" + window.ULink.params[o];
        });
        console.log("Final U Link:", u_path);
        _props.history.replace(u_path);
        window.ULink = null;
      }
    }, 0);
  };

  const handleAppSSO = () => {
    if (window.ULink) return console.log("[App] handleAppSSO Ulink is true");
    const dpurl = _props.dpURL || localStorage.getItem("dp_URL");
    console.log(
      "[App] Deep Link :",
      dpurl,
      dpurl.replace("pechk://", "/")
    );
    setauthLoging(false);
    localStorage.removeItem("dp_URL");
    const replaceUrl = isIOS ? dpurl.replace("pechk://", "/") : dpurl.replace("pechk:/", "/");
    console.log("[App] Deep Link replaceUrl :", replaceUrl)
    if (window.cordova && (isIOS || isAndroid)) {
      console.log("HERE IS IOS")
      if (replaceUrl.startsWith("//app/sso?action=GOOGLE_CLASS") || replaceUrl.startsWith("///app/sso?action=GOOGLE_CLASS")) {
        let url = replaceUrl.replace(replaceUrl.startsWith("//app/sso?action=GOOGLE_CLASS")? "//app/sso?action=GOOGLE_CLASS&" : "///app/sso?action=GOOGLE_CLASS&", "");
        let query = new URLSearchParams(url);
        let gresId = query.get("resId");
        let bookseriesId = query.get("BOOK_SERIES");
        let bookId = query.get("BOOK");
        let strucId = query.get("STRUCTURE_ID");
        if (!strucId) {
          strucId = 0;
        }
        if (!gresId) {
          console.log("[Entry] resId  is missing!");
          if (userData) {
            return _props.history.push("/");
          } else {
            return _props.history.push("/");
          }
        } else {
          console.log("DATA READY")
          if (userData) {
            let _action = {
              type: "GOOGLE_CLASS",
              data: {
                gresId,
                bookseriesId,
                bookId,
                strucId
              },
            };
            localStorage.setItem("_action", JSON.stringify(_action));
            return _props.history.push("/");
          } else {
            let _action = {
              type: "GOOGLE_CLASS",
              data: {
                gresId,
                bookseriesId,
                bookId,
                strucId
              },
            };
            localStorage.setItem("_action", JSON.stringify(_action));
            var IESlang = "en-US";
            var IESlang2 = "en-US";

            if (localStorage.getItem("i18nextLng")) {
              IESlang2 = localStorage.getItem("i18nextLng");
              if (localStorage.getItem("i18nextLng") != "en-US") {
                IESlang = "zh_TW";
              }
            }
            //Test Commit
            let currentHost = "https:%2F%2F" + window.location.hostname;
            let IESurl =
                config.IESURL +
                "?lang=" +
                IESlang +
                "&back_to=" +
                config.IESBACKURL +
                "%3Flang%3D" +
                IESlang2 +
                "%26back_to%3D" +
                currentHost;
            if (window.cordova) {
              _props.history.push("/login");
            } else {
              window.open(IESurl, "_self");
            }
          }
        }
      }
    }
    console.log("HERE IS END")
    return _props.history.replace(replaceUrl);
  };

  const AuthChecking = () => {
    console.log("[App] auth checking...");

    if (
      localStorage.getItem("SessionId") &&
      (localStorage.getItem("userData") ||
        localStorage.getItem("in-app-browser-data-userid"))
    ) {
      CheckAuth().then((_, error) => {
        console.log("[CheckAuth]", _, error);
        if (_?.schoolYears) {
          var schoolYearList = [],
            haveEffective = false;
          _.schoolYears.map((obj) => {
            schoolYearList.push(obj);
            if (obj.IsEffective == "true") {
              console.log("[Update effectiveSchoolYear]", obj);
              haveEffective = true;
              localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
              dispatch({
                type: "SET_SCHOOL_YEAR",
                effectiveSchoolYear: obj,
              });
            }
          });
          schoolYearList.sort(function (a, b) {
            return (
              new moment(b.EffectiveStartDate) -
              new moment(a.EffectiveStartDate)
            );
          });
          if (!haveEffective) {
            console.log(
              "[Update effectiveSchoolYear with no IsEffective]",
              schoolYearList[0]
            );
            dispatch({
              type: "SET_SCHOOL_YEAR",
              effectiveSchoolYear: schoolYearList[0],
            });
            localStorage.setItem(
              "effectiveSchoolYear",
              JSON.stringify(schoolYearList[0])
            );
          }

          if (platFrom === "App") {
            gaTrack("set", "dimension3", "App");
          }

          //console.log(platFrom, "platFrom");

          GetUserData((data) => {
            localStorage.setItem("userData", data);
            var userObj = JSON.parse(data);
            // setauthentication(true);
            dispatch({
              type: "SET_USER_PREFERENCES",
              user_preferences: userObj.Preferences,
            });
            let _rc_view_status = rc_view_status(userObj.Preferences);
            if (_rc_view_status.length > 0) {
              dispatch({
                type: "SET_USER_RCENTER_VIEW_PREFERENCES",
                user_rcenter_view_preferences:
                  _rc_view_status[0].PreferenceValue,
              });
            }
            if (!localStorage.getItem("selectedSchoolYear")) {
              let defaultSchoolYear = userObj.AdoptedSchoolYear.sort(function (
                a,
                b
              ) {
                return (
                  new moment(b.EffectiveStartDate) -
                  new moment(a.EffectiveStartDate)
                );
              })[0];
              //if (defaultSchoolYear)
              dispatch({
                type: "SET_COSTOM_SCHOOL_YEAR",
                selectedSchoolYear: defaultSchoolYear || schoolYearList[0],
              });
              // localStorage.setItem(
              //   "selectedSchoolYear",
              //   JSON.stringify(defaultSchoolYear)
              // );
            }
            dispatch({
              type: "SET_USER",
              user: userObj,
            });
            gaTrack("set", "dimension1", userObj.UserType);
            gaTrack(
              "set",
              "dimension4",
              userObj.School ? userObj.School.SchoolCode : "NoData"
            );
            console.log("[App] Have Auth");
            setauthLoging(false);
            console.log("[App] App started!");
          }, platFrom);
        } else {
          dispatch({
            type: "SET_USER",
            user: null,
          });
          gaTrack("set", "dimension1", "");
          console.log("[App] No Auth");
          setauthLoging(false);
          console.log("[App] App started!");
        }
        if (window.ULink) {
          localStorage.removeItem("dp_URL"); //just safe
          console.log("[App] Ulink true go tu path", window.ULink);
          let params = Object.keys(window.ULink.params);
          let u_path = window.ULink.path;
          params.map((o, i) => {
            if (i == 0)
              u_path = u_path + "?" + o + "=" + window.ULink.params[o];
            u_path = u_path + "&" + o + "=" + window.ULink.params[o];
          });
          console.log("Final U Link:", u_path);
          _props.history.replace(u_path);
          window.ULink = null;
        }
      });
    } else {
      console.log("[AuthChecking] empty localstorage");
      gaTrack("set", "dimension1", "");
      setauthLoging(false);
      if (window.ULink) {
        localStorage.removeItem("dp_URL"); //just safe
        let params = Object.keys(window.ULink.params);
        let u_path = window.ULink.path;
        params.map((o, i) => {
          if (i == 0) u_path = u_path + "?" + o + "=" + window.ULink.params[o];
          u_path = u_path + "&" + o + "=" + window.ULink.params[o];
        });
        console.log("Final U Link:", u_path);
        _props.history.replace(u_path);
        window.ULink = null;
      }
    }
  };

  const afterLogout = () => {
    gaTrack("set", "dimension1", "");
    gaTrack("set", "dimension4", "");
    dispatch({
      type: "SET_USER",
      user: null,
    });
  };
  console.log(_props);
  return (
    <>
      {authLoging ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <Translation>
            {(t, { i18n }) => {
              return (
                <div
                  className={
                    userData
                      ? ""
                      : _props.location.pathname != "/"
                      ? ""
                      : "be-wrapper be-nosidebar-left"
                  }
                  style={{
                    height: "100%",
                    minHeight: "630px", // Responsive fix Venni
                    display:"flex"
                  }}
                >
                  <ToastContainer
                    closeOnClick
                    hideProgressBar
                    draggable
                    pauseOnHover
                  />
                  <Switch>
                    <Route
                      exact
                      path="/app/:token"
                      render={(props) => (
                        <AppSSO
                          t={t}
                          // dispatch={dispatch}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/ssologin/:token"
                      render={(props) => (
                        <SSOLogin
                          t={t}
                          {...props}
                          // dispatch={dispatch}
                          type={"url"}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/ssologin"
                      render={(props) => (
                        <SSOLogin
                          t={t}
                          {...props}
                          // dispatch={dispatch}
                          type={"cookies"}
                        />
                      )}
                    />
                    <Route exact path="/" render={() => <MainIndex t={t} />} />
                    <Route
                      exact
                      path="/login"
                      render={(props) => (
                        <Login
                          {...props}
                          // dispatch={dispatch}
                          t={t}
                        />
                      )}
                    />
                    <Route
                        path="/m"
                        render={(props) => (
                            <DashboardLayout
                                {...props}
                                // {..._props}
                                // dispatch={dispatch}
                                t={t}
                                afterLogout={afterLogout}
                            />
                        )}
                    />
                    <Route
                        path="/studynotes"
                        render={(props) => (
                            <StudyNotes
                                {...props}
                                // {..._props}
                                // dispatch={dispatch}
                                t={t}
                                afterLogout={afterLogout}
                            />
                        )}
                    />
                    <Route
                      path="/a"
                      render={(props) => (
                        <ClassViewLayout
                          {...props}
                          // dispatch={dispatch}
                          B2C={false}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    />
                    <Route
                      path="/r"
                      render={(props) => (
                        <Report
                          {...props}
                          // dispatch={dispatch}
                          B2C={true}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    />
                    <Route
                      path="/s"
                      render={(props) => (
                        <ClassViewLayoutPEC
                          {...props}
                          // dispatch={dispatch}
                          B2C={false}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    />
                    <Route
                      path="/c"
                      render={(props) => (
                        <ClassViewLayout
                          {...props}
                          // dispatch={dispatch}
                          B2C={true}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    />
                    {/* <Route
                      path="/onboarding"
                      render={(props) => (
                        <OnBoarding
                          {...props}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    /> */}
                    <Route
                      path="/ebook4"
                      render={(props) => (
                        <Ebook4 {...props} t={t} afterLogout={afterLogout} />
                      )}
                    />

                    <Route
                      path="/assessment=api/entry/:resId"
                      render={(props) => (
                        <InAppLandingLayout
                          {...props}
                          t={t}
                          afterLogout={afterLogout}
                        />
                      )}
                    />

                    <Route
                      path="/assessment=api/"
                      render={(props) => (
                        <ApiView {...props} t={t} afterLogout={afterLogout} />
                      )}
                    />

                    <Route
                      path="/assignment/:resId"
                      render={(props) => (
                        <LearnosityLayout
                          {...props}
                          t={t}
                          entry={"assignment"}
                        />
                      )}
                    />
                    <Route
                      path="/activity/:resId"
                      render={(props) => (
                        <LearnosityLayout {...props} t={t} entry={"activity"} />
                      )}
                    />
                    <Route
                      path="/errorcode=:errorCode"
                      render={(props) => (
                        <ErrorPage
                          t={t}
                          {...props}
                          // authentication={authentication}
                          afterLogout={afterLogout}
                        />
                      )}
                    />

                    <Redirect to="/" />
                  </Switch>

                  {/* <ScrollUpButton
                    ContainerClassName="scrollToTop"
                    TransitionClassName="scrollToTopTransition"
                    ShowAtPosition={120}
                  >
                    <div>
                      <i className="icon mdi mdi-chevron-up" />
                    </div>
                  </ScrollUpButton> */}
                </div>
              );
            }}
          </Translation>
        </Suspense>
      )}
    </>
  );
}
export default withRouter(App);
