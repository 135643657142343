/**
 * @flow
 * @relayHash 01476eb62ec7b0af7d4eef5ba518f907
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectStudentGetStudyGroupClassQueryVariables = {|
  SchoolCode: string,
  SchoolYearId: string,
  BookSeriesId?: ?$ReadOnlyArray<?string>,
  FilterOnboarded: boolean,
  Keywords?: ?$ReadOnlyArray<?string>,
|};
export type SelectStudentGetStudyGroupClassQueryResponse = {|
  +school: ?{|
    +Schools: ?$ReadOnlyArray<?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
      |}>
    |}>
  |}
|};
export type SelectStudentGetStudyGroupClassQuery = {|
  variables: SelectStudentGetStudyGroupClassQueryVariables,
  response: SelectStudentGetStudyGroupClassQueryResponse,
|};
*/


/*
query SelectStudentGetStudyGroupClassQuery(
  $SchoolCode: String!
  $SchoolYearId: String!
  $BookSeriesId: [String]
  $FilterOnboarded: Boolean!
  $Keywords: [String]
) {
  school(SchoolCode: $SchoolCode) {
    Schools {
      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded, Keyword: $Keywords) {
        StudyGroupId
        StudyGroupName {
          Lang
          StudyGroupName
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SchoolCode",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Keywords",
    "type": "[String]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "SchoolCode",
    "variableName": "SchoolCode",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  {
    "kind": "Variable",
    "name": "Keyword",
    "variableName": "Keywords",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectStudentGetStudyGroupClassQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Schools",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectStudentGetStudyGroupClassQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Schools",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SelectStudentGetStudyGroupClassQuery",
    "id": null,
    "text": "query SelectStudentGetStudyGroupClassQuery(\n  $SchoolCode: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String]\n  $FilterOnboarded: Boolean!\n  $Keywords: [String]\n) {\n  school(SchoolCode: $SchoolCode) {\n    Schools {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded, Keyword: $Keywords) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5630997e603c1fad0b030f95a70ac955';
module.exports = node;
