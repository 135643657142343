/**
 * @flow
 * @relayHash e35e3ea3f80157f2832468a061e04ca0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MigrateStandaloneAttemptHistoryMutationVariables = {|
  StandaloneAttemptSessionId: string,
  ResourceIngestionId: string,
|};
export type MigrateStandaloneAttemptHistoryMutationResponse = {|
  +migrateStandaloneAttemptHistory: ?boolean
|};
export type MigrateStandaloneAttemptHistoryMutation = {|
  variables: MigrateStandaloneAttemptHistoryMutationVariables,
  response: MigrateStandaloneAttemptHistoryMutationResponse,
|};
*/


/*
mutation MigrateStandaloneAttemptHistoryMutation(
  $StandaloneAttemptSessionId: String!
  $ResourceIngestionId: String!
) {
  migrateStandaloneAttemptHistory(StandaloneAttemptSessionId: $StandaloneAttemptSessionId, ResourceIngestionId: $ResourceIngestionId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StandaloneAttemptSessionId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceIngestionId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "migrateStandaloneAttemptHistory",
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceIngestionId",
        "variableName": "ResourceIngestionId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "StandaloneAttemptSessionId",
        "variableName": "StandaloneAttemptSessionId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "id": null,
    "text": "mutation MigrateStandaloneAttemptHistoryMutation(\n  $StandaloneAttemptSessionId: String!\n  $ResourceIngestionId: String!\n) {\n  migrateStandaloneAttemptHistory(StandaloneAttemptSessionId: $StandaloneAttemptSessionId, ResourceIngestionId: $ResourceIngestionId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c6ce02907fb4af7bf94295557c407c8';
module.exports = node;
