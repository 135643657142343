/**
 * @flow
 * @relayHash 0c16eef1127d19a4673f4ae277ce7e7d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RelatedAssignmentTabelGetInfoQueryVariables = {|
  ResourceId?: ?$ReadOnlyArray<string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type RelatedAssignmentTabelGetInfoQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +LastUpdateDate: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +BookSeriesName: ?string,
          |}>,
        |},
        +AssignmentName: ?string,
        +CreateDate: ?string,
        +DueDate: ?string,
        +AssignmentTargets: ?$ReadOnlyArray<?{|
          +ResourceAssignId: ?string,
          +UserId: ?string,
          +StudyGroupId: ?string,
          +User: ?{|
            +UserDisplayName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +UserName: ?string,
            |}>,
            +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
              +ClassNumber: ?string,
              +StudyGroup: ?{|
                +BookSeries: ?{|
                  +BookSeriesId: ?string
                |},
                +StudyGroupName: ?$ReadOnlyArray<?{|
                  +Lang: ?string,
                  +StudyGroupName: ?string,
                |}>,
              |},
            |}>,
          |},
          +StudyGroup: ?{|
            +StudyGroupUser: ?$ReadOnlyArray<?{|
              +UserId: ?string
            |}>,
            +StudyGroupName: ?$ReadOnlyArray<?{|
              +StudyGroupId: ?string,
              +Lang: ?string,
              +StudyGroupName: ?string,
            |}>,
          |},
        |}>,
        +AssignmentTargetStatus: ?$ReadOnlyArray<?{|
          +ResourceAssignId: ?string,
          +UserId: ?string,
          +Status: ?string,
        |}>,
      |}>
    |}>
  |}
|};
export type RelatedAssignmentTabelGetInfoQuery = {|
  variables: RelatedAssignmentTabelGetInfoQueryVariables,
  response: RelatedAssignmentTabelGetInfoQueryResponse,
|};
*/


/*
query RelatedAssignmentTabelGetInfoQuery(
  $ResourceId: [String!]
  $StudyGroupId: String!
  $SchoolYearId: String!
) {
  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {
    Resources {
      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
        LastUpdateDate
        BookSeries {
          BookSeriesId
          BookSeriesName {
            Lang
            BookSeriesName
          }
          id
        }
        AssignmentName
        CreateDate
        DueDate
        AssignmentTargets(FilterCurrentUser: false) {
          ResourceAssignId
          UserId
          StudyGroupId
          User {
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
              ClassNumber
              StudyGroup {
                BookSeries {
                  BookSeriesId
                  id
                }
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
                id
              }
              id
            }
            id
          }
          StudyGroup {
            StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
              UserId
              id
            }
            StudyGroupName {
              StudyGroupId
              Lang
              StudyGroupName
            }
            id
          }
        }
        AssignmentTargetStatus {
          ResourceAssignId
          UserId
          Status
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "PageSize",
    "value": 9999,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LastUpdateDate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AssignmentName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "CreateDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DueDate",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "FilterCurrentUser",
    "value": false,
    "type": "Boolean"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAssignId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserName",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  }
],
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v18/*: any*/)
  ]
},
v20 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "UserType",
    "value": "S",
    "type": "UserTypeEnumType!"
  }
],
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v6/*: any*/),
    (v18/*: any*/)
  ]
},
v22 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "AssignmentTargetStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ResourceAssignmentTargetStatus",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    (v13/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Status",
      "args": null,
      "storageKey": null
    }
  ]
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ]
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AssignmentTargets",
                    "storageKey": "AssignmentTargets(FilterCurrentUser:false)",
                    "args": (v11/*: any*/),
                    "concreteType": "ResourceAssignmentTarget",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "BelongsToStudyGroup",
                            "storageKey": null,
                            "args": (v16/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "plural": true,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "StudyGroup",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "StudyGroup",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "BookSeries",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "bookSeries",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/)
                                    ]
                                  },
                                  (v19/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "StudyGroup",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "StudyGroupUser",
                            "storageKey": null,
                            "args": (v20/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/)
                            ]
                          },
                          (v21/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v23/*: any*/)
                    ]
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AssignmentTargets",
                    "storageKey": "AssignmentTargets(FilterCurrentUser:false)",
                    "args": (v11/*: any*/),
                    "concreteType": "ResourceAssignmentTarget",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "BelongsToStudyGroup",
                            "storageKey": null,
                            "args": (v16/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "plural": true,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "StudyGroup",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "StudyGroup",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "BookSeries",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "bookSeries",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v23/*: any*/)
                                    ]
                                  },
                                  (v19/*: any*/),
                                  (v23/*: any*/)
                                ]
                              },
                              (v23/*: any*/)
                            ]
                          },
                          (v23/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "StudyGroup",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "StudyGroupUser",
                            "storageKey": null,
                            "args": (v20/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v23/*: any*/)
                            ]
                          },
                          (v21/*: any*/),
                          (v23/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              },
              (v23/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "id": null,
    "text": "query RelatedAssignmentTabelGetInfoQuery(\n  $ResourceId: [String!]\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {\n    Resources {\n      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        LastUpdateDate\n        BookSeries {\n          BookSeriesId\n          BookSeriesName {\n            Lang\n            BookSeriesName\n          }\n          id\n        }\n        AssignmentName\n        CreateDate\n        DueDate\n        AssignmentTargets(FilterCurrentUser: false) {\n          ResourceAssignId\n          UserId\n          StudyGroupId\n          User {\n            UserDisplayName {\n              Lang\n              UserName\n            }\n            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n              ClassNumber\n              StudyGroup {\n                BookSeries {\n                  BookSeriesId\n                  id\n                }\n                StudyGroupName {\n                  Lang\n                  StudyGroupName\n                }\n                id\n              }\n              id\n            }\n            id\n          }\n          StudyGroup {\n            StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {\n              UserId\n              id\n            }\n            StudyGroupName {\n              StudyGroupId\n              Lang\n              StudyGroupName\n            }\n            id\n          }\n        }\n        AssignmentTargetStatus {\n          ResourceAssignId\n          UserId\n          Status\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cbf76fb53b2d1c9d254741fca606750b';
module.exports = node;
