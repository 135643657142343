/**
 * @flow
 * @relayHash 5873edade87bd16e361cd70c480c177c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewableByStudentsTableIsViewQueryVariables = {|
  ResourceId?: ?$ReadOnlyArray<string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type ViewableByStudentsTableIsViewQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +Resources: ?$ReadOnlyArray<?{|
          +ResourceId: ?string,
          +ResourceAssignTargetStatus: ?$ReadOnlyArray<?{|
            +UserId: ?string,
            +Status: ?string,
          |}>,
        |}>
      |}>
    |}>
  |}
|};
export type ViewableByStudentsTableIsViewQuery = {|
  variables: ViewableByStudentsTableIsViewQueryVariables,
  response: ViewableByStudentsTableIsViewQueryResponse,
|};
*/


/*
query ViewableByStudentsTableIsViewQuery(
  $ResourceId: [String!]
  $StudyGroupId: String!
  $SchoolYearId: String!
) {
  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {
    Resources {
      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
        Resources {
          ResourceId
          ResourceAssignTargetStatus {
            UserId
            Status
          }
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "PageSize",
    "value": 9999,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceAssignTargetStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ResourceAssignmentResourceTargetStatus",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Status",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewableByStudentsTableIsViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Resources",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResourceAssignmentResource",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewableByStudentsTableIsViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AssignementInvloved",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "resourceAssignment",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Resources",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResourceAssignmentResource",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  (v5/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewableByStudentsTableIsViewQuery",
    "id": null,
    "text": "query ViewableByStudentsTableIsViewQuery(\n  $ResourceId: [String!]\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {\n    Resources {\n      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        Resources {\n          ResourceId\n          ResourceAssignTargetStatus {\n            UserId\n            Status\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '721e45aa3ce85aea4bae084a02e82c8d';
module.exports = node;
