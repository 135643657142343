/**
 * @flow
 * @relayHash c47c620579b578d17d8e380c434e879e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnitListGetSelfLearnConfigQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>,
  StudyGroupId: $ReadOnlyArray<?string>,
  SchoolYearId: string,
|};
export type UnitListGetSelfLearnConfigQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +SelfLearnConfiguration: ?$ReadOnlyArray<?{|
        +ConfigName: ?string,
        +ConfigValue: ?string,
        +StudyGroupId: ?string,
        +ResourceId: ?string,
      |}>
    |}>
  |}
|};
export type UnitListGetSelfLearnConfigQuery = {|
  variables: UnitListGetSelfLearnConfigQueryVariables,
  response: UnitListGetSelfLearnConfigQueryResponse,
|};
*/


/*
query UnitListGetSelfLearnConfigQuery(
  $ResourceId: [String]!
  $StudyGroupId: [String]!
  $SchoolYearId: String!
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      SelfLearnConfiguration(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
        ConfigName
        ConfigValue
        StudyGroupId
        ResourceId
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "[String]!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ConfigName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ConfigValue",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UnitListGetSelfLearnConfigQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnConfiguration",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UnitListGetSelfLearnConfigQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnConfiguration",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UnitListGetSelfLearnConfigQuery",
    "id": null,
    "text": "query UnitListGetSelfLearnConfigQuery(\n  $ResourceId: [String]!\n  $StudyGroupId: [String]!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      SelfLearnConfiguration(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        ConfigName\n        ConfigValue\n        StudyGroupId\n        ResourceId\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac826316cf3d0225864ebaa448cba7f9';
module.exports = node;
