import React, { useEffect, useState } from "react";
import likeIcon from "../../../assets/img/Vector.svg";
import likedIcon from "../../../assets/img/Vector.png";
import hoverIcon from "../../../assets/img/vector-hover.png";
import icon from "../../../assets/img/Group 99.png";
// import topIcon from "../../../assets/img/Rectangle 600.png";
import MessageBox from "../../../Components/Feature/MessageBox";
import { handbook_book } from "../../../Actions";
import UpdateUserPreferencesMutation from "../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import { useDispatch, useSelector } from "react-redux";
import "./HandBookDropdownNew.scss";

const HandBookDropdownNew = (props) => {
  const {
    subjectList,
    favSubjectList,
    doSelectFunc,
    myBookFav,
    setfavSubjectList,
    bookFavTab,
    favSeriesIds,
    setfavSeriesIds,
    role,
    settabOpt,
  } = props;
  console.log("subjectList", subjectList);
  const Modal = ({ children }) => <div className="Modal">{children}</div>;
  const lang =
    localStorage.getItem("i18nextLng") ||
    localStorage.getItem("i18nextLng-inapp");
  const [filterFavButtonStateDropdown, setFilterFavButtonStateDropdown] =
    useState(bookFavTab);
  const [filterlikeItem, setFilterlikeItem] = useState({});
  const [over, setOver] = useState(-1);
  const [myFavSeries, setmyFavSeries] = useState(myBookFav);
  const { t } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatedFav = [...new Set(myFavSeries)];
    const updatedFavIds = { ...favSeriesIds };
    updatedFav.forEach((id) => {
      updatedFavIds[id] = true;
    });
    setfavSeriesIds((prev) => ({ ...prev, ...updatedFavIds }));
  }, [myFavSeries]);

  const saveFavourites = (bookSeriesId, val, bookSeriesNamearg) => {
    setfavSeriesIds((prev) => ({
      ...prev,
      [bookSeriesId]: val === "yes" ? true : false,
    }));

    var preferencesObj = handbook_book(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        // on adding the book series to favorite , starts here
        gaTrack(
          "send",
          "event",
          "General",
          "Favourite book series add",
          `Book Series:${bookSeriesNamearg}, Location:Handbook, Role:${userData.UserType}`
        );
        // on adding the book series to favorite , ends here
        let updateBookList = [];
        if (preferencesArr.length > 0) {
          preferencesArr[0].BSId.forEach(function (id, index) {
            updateBookList.push(id);
          });
          updateBookList.push(bookSeriesId);
          var obj = {
            BSId: updateBookList,
          };
        } else {
          var preferencesArr = [];
          let favoritesBook = [];
          favoritesBook.push(bookSeriesId);
          var obj = {
            BSId: favoritesBook,
          };
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let updateBookList1 = preferencesArr[0].BSId;
        const indx = updateBookList1.findIndex((v) => v == bookSeriesId);
        // on removing the book series to favorite , starts here
        gaTrack(
          "send",
          "event",
          "General",
          "Favourite book series remove",
          `Book Series:${bookSeriesNamearg}, Location:Handbook, Role:${userData.UserType}`
        );
        // on removing the book series to favorite , ends here
        updateBookList1.splice(indx, indx >= 0 ? 1 : 0);
        var obj = {
          BSId: updateBookList1,
        };
      }
    } else {
      var preferencesArr = [];
      let favoritesBook = [];
      favoritesBook.push(bookSeriesId);
      var obj = {
        BSId: favoritesBook,
      };
    }
    var updatePrefrenceArr = [];
    updatePrefrenceArr.push(obj);
    var data = {
      PreferenceName: "HANDBOOK_BOOK",
      PreferenceValue: JSON.stringify(updatePrefrenceArr),
    };

    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
    };
    UpdateUserPreferencesMutation(data, callback);
    setmyFavSeries(obj.BSId);

    var latestStructureId = obj.BSId;
    var favlevelup = subjectList.filter((obj) => {
      return latestStructureId.includes(obj.BookSeriesId);
    });
    setfavSubjectList(favlevelup);
  };

  const setFavValueFuncDropdown = (curVal) => {
    //allow toggle once start here
    if (filterFavButtonStateDropdown != curVal) return;
    //allow toggle once ends here
    // on toggling the book series favorite menu lists , starts here
    gaTrack(
      "send",
      "event",
      "General",
      "Switch book series favourite list",
      `Location:Handbook, Switch to:${
        filterFavButtonStateDropdown ? "All" : "Favourite"
      }, Role:${userData.UserType}`
    );
    // on toggling the book series favorite menu lists , ends here
    var btnVal = "";
    if (filterFavButtonStateDropdown == 1) {
      setFilterFavButtonStateDropdown(0);
      settabOpt(0);
      btnVal = 0;
    } else {
      setFilterFavButtonStateDropdown(1);
      settabOpt(1);
      btnVal = 1;
    }
    var obj = {
      tabSelect: btnVal,
    };
    var updatePrefrenceArr = [];
    updatePrefrenceArr.push(obj);

    var data = {
      PreferenceName: "HANDBOOK_BOOK_TAB",
      PreferenceValue: JSON.stringify(updatePrefrenceArr),
    };
    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
    };
    UpdateUserPreferencesMutation(data, callback);
  };

  const setFavIconDropdownFunc = (index) => {
    setFilterlikeItem((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const setClickedClassFunc = () => {
    alert("class clicked");
  };

  return (
    <div className="Component-handbook">
      {
        /* <img className="top-icon" src={topIcon}/> */ console.log(
          "role",
          role,
          "filterFavButtonStateDropdown",
          filterFavButtonStateDropdown
        )
      }
      {role != "student" && (
        <div className="main-container">
          <div
            className={`static-tab-dropdown ${
              filterFavButtonStateDropdown === 0 ? "active-tab-dropdown" : ""
            }`}
            onClick={() => setFavValueFuncDropdown(1)}
          >
            {t("All")}
          </div>
          <div
            className={`static-tab-dropdown ${
              filterFavButtonStateDropdown === 1 ? "active-tab-dropdown" : ""
            }`}
            onClick={() => setFavValueFuncDropdown(0)}
          >
            {t("Favourite")}
          </div>
        </div>
      )}
      {filterFavButtonStateDropdown == 0 ? (
        <div>
          {subjectList.length > 0 && (
            <div
              className="please-select-row"
              onClick={(e) => doSelectFunc(e, "", "All Books")}
            >
              {t("All Books")}
            </div>
          )}
          {subjectList &&
            subjectList.map((obj, index) => {
              return (
                <div className="main-class-container">
                  <div
                    className="class-row class-row1"
                    onClick={(e) =>
                      doSelectFunc(
                        e,
                        obj.BookSeriesId,
                        obj.BookSeriesName[
                          obj.BookSeriesName[0].Lang == lang ? 0 : 1
                        ].BookSeriesName
                      )
                    }
                  >
                    {
                      obj.BookSeriesName[
                        obj.BookSeriesName[0].Lang == lang ? 0 : 1
                      ].BookSeriesName
                    }
                  </div>
                  {role != "student" &&
                    (favSeriesIds[obj.BookSeriesId] ? (
                      <div className="like-row like-row1">
                        <img
                          src={likedIcon}
                          onClick={() => {
                            saveFavourites(
                              obj.BookSeriesId,
                              "no",
                              obj.BookSeriesName[
                                obj.BookSeriesName[0].Lang == lang ? 0 : 1
                              ].BookSeriesName
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="like-row like-row1">
                        <img
                          src={over == index ? hoverIcon : likeIcon}
                          onClick={() => {
                            saveFavourites(
                              obj.BookSeriesId,
                              "yes",
                              obj.BookSeriesName[
                                obj.BookSeriesName[0].Lang == lang ? 0 : 1
                              ].BookSeriesName
                            );
                          }}
                          onMouseOver={() => setOver(index)}
                          onMouseOut={() => setOver(-1)}
                        />
                      </div>
                    ))}
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          {favSubjectList.length > 0 && (
            <div className="please-select-row">{t("All books")}</div>
          )}
          {favSubjectList.length > 0 ? (
            favSubjectList.map((obj, index) => {
              return (
                <div className="main-class-container">
                  <div
                    className="class-row class-row1"
                    onClick={(e) =>
                      doSelectFunc(
                        e,
                        obj.BookSeriesId,
                        obj.BookSeriesName[
                          obj.BookSeriesName[0].Lang == lang ? 0 : 1
                        ].BookSeriesName
                      )
                    }
                  >
                    {" "}
                    {
                      obj.BookSeriesName[
                        obj.BookSeriesName[0].Lang == lang ? 0 : 1
                      ].BookSeriesName
                    }
                  </div>
                  {role != "student" &&
                    (favSeriesIds[obj.BookSeriesId] ? (
                      <div className="like-row like-row1">
                        <img
                          src={likedIcon}
                          onClick={() => {
                            //setFavIconDropdownFunc(1);
                            saveFavourites(
                              obj.bookseriesId,
                              "no",
                              obj.BookSeriesName[
                                obj.BookSeriesName[0].Lang == lang ? 0 : 1
                              ].BookSeriesName
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="like-row like-row1">
                        <img
                          src={over == index ? hoverIcon : likeIcon}
                          onClick={() => {
                            //setFavIconDropdownFunc(1);
                            saveFavourites(
                              obj.bookseriesId,
                              "yes",
                              obj.BookSeriesName[
                                obj.BookSeriesName[0].Lang == lang ? 0 : 1
                              ].BookSeriesName
                            );
                          }}
                          onMouseOver={() => setOver(index)}
                          onMouseOut={() => setOver(-1)}
                        />
                      </div>
                    ))}
                </div>
              );
            })
          ) : (
            <MessageBox
              id={"StudyGroupAssignment"}
              src={icon}
              message={`${t("No favourite book series added yet.")}`}
              // message2={`${t("resource first.")}`}
            />
          )}
        </div>
      )}
    </div>
  );

  const App = () => {
    const [showModal, setShowModal] = useState(false);
    const handleKeyup = (e) => e.keyCode === 27 && setShowModal(false);
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
      if (showModal) window.addEventListener("keyup", handleKeyup);
      return () => window.removeEventListener("keyup", handleKeyup);
    });

    return (
      <div onClick={toggleModal} className="App">
        View information
        {showModal && (
          <Modal>
            <Component />
          </Modal>
        )}
      </div>
    );
  };
};

// ReactDOM.render(<App />, root);

export default HandBookDropdownNew;
