import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import likedIcon from "../../../assets/img/pec/all-images/icon-heart-grey-small.png";
import likeIcon from "../../../assets/img/pec/all-images/icon-heart-outline-grey-small.png";
import arrowIcon from "../../../assets/img/pec/all-images/big-arrow.png";
// import dummy_img2 from "../../../assets/img/pec/all-images/cover-elect-1b.png";
import default_dummy_img from "../../../assets/img/pec/all-images/cover-default.png";
import { dashboard_book_level_favorites } from "../../../Actions";
import "./FavouritePage.scss";
import UpdateUserPreferencesMutation from "../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import Loading from "../../../Components/Loading/PEC/Loading";
import NotificationDropDown from "./NotificationDropDown";
import { processImageUrl } from "../../../utils/ImageProcessUtils";
// import { bookseries_favorites } from "../../../Actions";

const FavouritePage = (props) => {
  const {
    favBookLevel,
    setfavBookLevel,
    loadBookNotification,
    activeMenu,
    role,
    t,
    studyGroupList,
    notificationPopupOverlay,
    setNotificationPopupOverlay,
  } = props;
  const dispatch = useDispatch();
  const [showClass, setShowClass] = useState(false);
  const [showClassData, setShowClassData] = useState([]);
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  // const [favouriteClass, setFavouriteClass] = useState([]);
  const [activeClass, setActiveClass] = useState("all");
  const [resfavIds, setresFavIds] = useState({});
  const [myResFav, setmyResFav] = useState([]);
  // const [classNoticationLoading, setClassNoticationLoading] = useState(false);
  // const [selectClass, setSelectClass] = useState("Please select");
  const [selectClass, setSelectClass] = useState([]);
  const class_dropdownRef = useRef(null);
  // const { t, studyGroupList } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const favBookSeriesData = props.allData;
  const totalFavouriteCnt = props.allData.length;
  // const t = (para) => {
  //   return para;
  // };
  useEffect(() => {
    studyGroupList.length > 0
      ? setSelectClass({
        StudyGroupName:
          studyGroupList[0]?.StudyGroupName[[0].Lang == lang ? 0 : 1]
            .StudyGroupName,
        StudyGroupId: studyGroupList[0]?.StudyGroupId,
      })
      : "";
  }, [studyGroupList]);
  useEffect(() => {
    const updatedFav = [...new Set(myResFav)];
    const updatedFavIds = { ...resfavIds };
    updatedFav.forEach((id) => {
      updatedFavIds[id] = true;
    });
    setresFavIds((prev) => ({ ...prev, ...updatedFavIds }));
  }, [myResFav]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        class_dropdownRef.current !== null &&
        !class_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component1").contains(e.target)
      ) {
        setShowClass(false);
        setShowClassData([]);
      }
    };
    if (showClass) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showClass]);

  const setFavValue = (currentVal) => {
    if (currentVal == 1) {
      setActiveClass("all");
    } else {
      setActiveClass("favourite");
    }
  };

  const toggleCheckResource = (
    e,
    bookSeriesIdArg,
    structureIdArg,
    val,
    bkName
  ) => {
    e.stopPropagation();
    setFavouriteLoading(true)
    props.toggleClickable(1); //disable other action till the action completed

    // setresFavIds((prev) => ({
    //   ...prev,
    //   [structureIdArg]: val === "yes" ? true : false,
    // }));
    // const val = true ? 'yes': 'no'
    var respreferencesArr = [];
    //setmyResFav
    var latestStructureId = [];
    var preferencesObj = dashboard_book_level_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on adding to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );

        if (filterList.length > 0) {
          let updateStructureList = [];
          //'27210', '27209', '27211', '27212'
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(updateStructureList)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };

          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          const indx = localrespreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );

          localrespreferencesArr.splice(indx, indx >= 0 ? 1 : 0);

          localrespreferencesArr.push(updateObj);

          respreferencesArr = [...localrespreferencesArr];
          latestStructureId = [...new Set(updateStructureList)];
        } else {
          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(structureVal)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          localrespreferencesArr.push(obj);
          respreferencesArr = [...localrespreferencesArr];
          //respreferencesArr.push(localrespreferencesArr);
          latestStructureId = [...new Set(structureVal)];
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on removing to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        }); //\"27212\",\"27213\",\"27214\",\"27209\
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        //Removing From BookSeries
        let localrespreferencesArr = JSON.parse(
          preferencesObj[0].PreferenceValue
        );
        const bindx = localrespreferencesArr.findIndex(
          (v) =>
            v.BSId == bookSeriesIdArg &&
            v.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        localrespreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: [...new Set(updateStructureList)],
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        localrespreferencesArr.push(updateObj);
        respreferencesArr = [...localrespreferencesArr];
        /// respreferencesArr.push(localrespreferencesArr);
        latestStructureId = [...new Set(updateStructureList)];
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }

    var data = {
      PreferenceName: "DASHBOARD_BOOK_LEVEL_NEW_FAVORITES",
      // PreferenceValue: JSON.stringify([]),
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    //setFavorites(respreferencesArr);
    setmyResFav([...new Set(latestStructureId)]);

    const callback = (result) => {
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      //var obj = { BSId: bookSeriesIdArg, StructureId: [...new Set(latestStructureId)] };
      setTimeout(() => {
        setFavouriteLoading(false)        
        props.toggleClickable(0); //enable once the action completed
      }, 2000);
    };
    //filter array based on year and book id
    // var updpreferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
    let updfilterList = respreferencesArr.filter(
      (o) =>
        o.BSId == bookSeriesIdArg &&
        o.SchoolYear == selectedSchoolYear.SchoolYearId
    );
    //setfavBookLevel([updfilterList[0]]);
    setfavBookLevel(respreferencesArr);
    // setfavBookLevel(respreferencesArr);
    UpdateUserPreferencesMutation(data, callback);
  };

  // const favBookSeriesData = props.allData.filter((el) => {
  //   return props.addToFavourite.includes(el.BookId);
  // });

  if (props.role == "T") {
    return (
      <>
        <div
          className={`favourited-books ${totalFavouriteCnt <= 0 ? "favourited-books1" : ""
            }`}
        >
          {/* {totalFavouriteCnt > 0 ? (
        <h3 className="select-favourite-books-heading">My Favourites</h3>
      ) : (
        ""
      )} */}
          <h3 className="select-favourite-books-heading">
            {t("My Favourites")}
          </h3>
          {totalFavouriteCnt > 0 ? (
            loadBookNotification ? (
              <Loading type={`normal`} />
            ) : (
              <div className="selected-favourite">
                {favBookSeriesData.map((favBookData) => {
                  var favStudyGroupList = [];
                  var selectedLevelForBook = favBookLevel.filter(
                    (o) =>
                      o.BSId == favBookData.BookId &&
                      o.SchoolYear == selectedSchoolYear.SchoolYearId
                  );
                  if (selectedLevelForBook.length > 0) {
                    favStudyGroupList = studyGroupList.filter((o) =>
                      selectedLevelForBook[0].StructureId.includes(
                        o.StudyGroupId
                      )
                    );
                    //Added for Favorite Selection Initially
                    // setSelectClass({
                    //   StudyGroupName:
                    //   favStudyGroupList[0]?.StudyGroupName[[0].Lang == lang ? 0 : 1]
                    //       .StudyGroupName,
                    //   StudyGroupId: favStudyGroupList[0]?.StudyGroupId,
                    // })
                  }

                  var showEbook = false;
                  var showResBool = false;
                  if (favBookData.UsefulTools) {
                    var ebookLinkObj = favBookData.UsefulTools.filter(
                      (o) =>
                        o.ToolsType == "ebook" &&
                        (o.ApplicableRole == role || o.ApplicableRole == "ALL")
                    );

                    if (ebookLinkObj.length > 0) {
                      showEbook = true;
                    }
                    var resourceLinkObj = favBookData.UsefulTools.filter(
                      (o) =>
                        o.ToolsType == "web" &&
                        (o.ApplicableRole == role || o.ApplicableRole == "ALL")
                    );
                    //console
                    if (resourceLinkObj.length > 0) {
                      showResBool = true;
                    }
                  }

                  return (
                    <div className="selected-book-favourite">
                      <div className="selected-book-favourite-first-section">
                        <img
                          className="selected-fav-image"
                          // src={`../${favBookData.images}`}
                          src={
                            favBookData.BookImage
                              ? processImageUrl(favBookData.BookImage.FilePath)
                              : default_dummy_img
                          }
                        />
                        {showEbook && (
                          <button
                            className="buttons selected-fav-btn"
                            onClick={() =>
                              props.onBtnClickOpenLink(ebookLinkObj[0])
                            }
                          >
                            {t("Launch E-Book")}
                            <i className="icon mdi mdi-open-in-new  favourite-mdi-icons"></i>
                          </button>
                        )}
                        <div className="fav-button-section">
                          <button
                            className="buttons selected-fav-sdl-btn"
                            onClick={() =>{
                              dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {

                                  selectedResourceName:"",
                                  selectecResourceTab:"",
                                  selectedToggle:false,

                                },
                              });
                              props.onClickResource(
                                activeMenu,
                                "history",
                                favBookData.BookId,
                                favBookData.BookName[
                                  favBookData.BookName[0].Lang == lang ? 0 : 1
                                ].BookName
                              )
                            }}
                          >
                            {t("SDL")}
                          </button>
                          {role == "T" && showResBool && (
                            <button
                              className="buttons selected-fav-resource-btn"
                              onClick={() =>
                                props.onBtnClickOpenLink(resourceLinkObj[0])
                              }
                            >
                              {t("Resource")}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="selected-book-favourite-second-section">
                        <div className="favourite-book-heading">
                          <label
                            className="fav-book-name"
                            title={favBookData.BookName[0].BookName}
                          >
                            {/* {favBookData.bookName} */}
                            {
                              favBookData.BookName[
                                favBookData.BookName[0].Lang == lang ? 0 : 1
                              ].BookName
                            }
                          </label>
                          <button
                            className="favourite-icon-heart"
                            onClick={(e) =>
                              props.removeFavourite(
                                e,
                                favBookData.BookId,
                                "no",
                                favBookData.BookName[0].BookName
                              )
                            }
                          ></button>
                        </div>
                        {studyGroupList.length > 0 ? (
                          <>
                            <NotificationDropDown
                              likeIcon={likeIcon}
                              likedIcon={likedIcon}
                              setSelectClass={setSelectClass}
                              selectClass={selectClass}
                              setShowClass={setShowClass}
                              showClass={showClass}
                              setShowClassData={setShowClassData}
                              showClassData={showClassData}
                              toggleCheckResource={toggleCheckResource}
                              favBookData={favBookData}
                              favouriteLoading={favouriteLoading}
                              t={t}
                              role={props.role}
                              class_dropdownRef={class_dropdownRef}
                              studyGroupList={studyGroupList}
                              activeClass={activeClass}
                              favBookLevel={favBookLevel}
                              activeMenu={props.activeMenu}
                              BookId={favBookData.BookId}
                              favBookSeriesData={favBookSeriesData}
                              setFavValue={setFavValue}
                              favStudyGroupList={favStudyGroupList}
                              notificationPopupOverlay={
                                notificationPopupOverlay
                              }
                              setNotificationPopupOverlay={
                                setNotificationPopupOverlay
                              }
                            />

                            {/* <div className="favourite-book-notification-details favourite-book-notification-details-scroll-bar">
                              <div className="single-favourite-notification">
                                <NotificationDetails
                                  toggleNotificationPopUp={
                                    toggleNotificationPopUp
                                  }
                                  favBookData={favBookData}
                                  activeMenu={props.activeMenu}
                                  BookId={favBookData.BookId}
                                  favBookSeriesData={favBookSeriesData}
                                  role={props.role}
                                />
                              </div>
                            </div> */}
                          </>
                        ) : (
                          <Loading type={`normal`} />
                        )}
                      </div>
                    </div>
                  );
                })}
                {totalFavouriteCnt % 2 == 1 ? (
                  <div className="selected-book-area">
                    {/* Select books to favourite below. */}
                    {t("Add your favourite book")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )
          ) : (
            <div className="favourite-section">
              <div className="first-section">
                <label className="first-section-heading">
                  {/* Start your book series by favouriting your books. */}
                  {/* {t("Start your book series by favouriting your books.")} */}
                  {t("Get started by adding a book to your favourites.")}
                </label>
                <div className="first-section-details1">
                  {/* Click on the “heart” of your favourite books to be able to
                  share activities, assign self directed learning, track student
                  progress, and download teacher resources. */}
                  {/* {t(
                    "Click on the heart of your favourite books to be able to share activities, assign self directed learning, track student progress, and download teacher resources."
                  )} */}
                  {t(
                    "Click on the heart below a book to create a shortcut for accessing e-Books, sharing resources with your students and downloading teacher's resources."
                  )}
                </div>
                <div className="first-section-details2">
                  {/* {t("Start by selecting the books below.")} */}
                  <p className="down-arrow">
                    <img
                      className="down-arrow-images bounce arrow-setting"
                      src={arrowIcon}
                      alt="down arrow"
                    />
                  </p>
                </div>
              </div>
              <div className="second-section">
              {/*  <div className="favourite-book-area favourite-book-area-background-image">
                   <img className="new-book-image" src={bookIcon} alt="book icons" /> 
                   <i className="new-book-image"></i> 
                </div>*/}
                <div className="favourite-book-name">
                {t("Add your favourite book")}
                </div>                
                  {/*<div className="selected-book-area">
                    Select books to favourite below.
                  </div>              
                  <button className="favourite-icon-heart favourite-icon-heart-transparent"></button>*/}
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default FavouritePage;
