/**
 * @flow
 * @relayHash 1cd737eb618193d9d0888984801b292f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TopicListingOptionsQueryVariables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
|};
export type TopicListingOptionsQueryResponse = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesName: ?string,
        +Lang: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +BookName: ?string,
          +Lang: ?string,
        |}>,
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +HierarchyReference: ?string,
          +DisplaySequence: ?string,
          +HierarchyReferenceLabelEn: ?string,
          +HierarchyReferenceLabelChi: ?string,
        |}>,
      |}>,
    |}>
  |}
|};
export type TopicListingOptionsQuery = {|
  variables: TopicListingOptionsQueryVariables,
  response: TopicListingOptionsQueryResponse,
|};
*/


/*
query TopicListingOptionsQuery(
  $UserId: String!
  $BookSeriesId: String!
  $BookId: String!
) {
  user(UserId: $UserId) {
    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
      BookSeriesId
      BookSeriesName {
        BookSeriesName
        Lang
      }
      Books(BookId: $BookId) {
        BookId
        BookName {
          BookName
          Lang
        }
        AdoptedTagHierarchy {
          HierarchyReference
          DisplaySequence
          HierarchyReferenceLabelEn
          HierarchyReferenceLabelChi
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/)
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/)
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReference",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReferenceLabelEn",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReferenceLabelChi",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopicListingOptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v6/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TopicListingOptionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v6/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              (v13/*: any*/)
            ]
          },
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TopicListingOptionsQuery",
    "id": null,
    "text": "query TopicListingOptionsQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      BookSeriesName {\n        BookSeriesName\n        Lang\n      }\n      Books(BookId: $BookId) {\n        BookId\n        BookName {\n          BookName\n          Lang\n        }\n        AdoptedTagHierarchy {\n          HierarchyReference\n          DisplaySequence\n          HierarchyReferenceLabelEn\n          HierarchyReferenceLabelChi\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6c1c56097d7cb03b321e4d9881c680d';
module.exports = node;
