/**
 * @flow
 * @relayHash 172883d61da75d377c44cad24738bee6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActivityLayoutGetAssessmentNameStudentQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>,
  UserId: string,
  BookSeriesId: string,
  BookId?: ?string,
  UnitId?: ?string,
  StudyGroupId?: ?string,
  SchoolYearId?: ?string,
|};
export type ActivityLayoutGetAssessmentNameStudentQueryResponse = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +PearsonLevel: ?{|
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
          |}>
        |},
        +Units: ?$ReadOnlyArray<?{|
          +UnitId: ?string,
          +UnitName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +UnitName: ?string,
            +UnitTitle: ?string,
          |}>,
        |}>,
      |}>,
    |}>
  |},
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +RelatedId: ?string,
      +ResourceId: ?string,
      +IngestionId: ?string,
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +ResourceName: ?string,
      |}>,
    |}>
  |},
|};
export type ActivityLayoutGetAssessmentNameStudentQuery = {|
  variables: ActivityLayoutGetAssessmentNameStudentQueryVariables,
  response: ActivityLayoutGetAssessmentNameStudentQueryResponse,
|};
*/


/*
query ActivityLayoutGetAssessmentNameStudentQuery(
  $ResourceId: [String]!
  $UserId: String!
  $BookSeriesId: String!
  $BookId: String
  $UnitId: String
  $StudyGroupId: String
  $SchoolYearId: String
) {
  user(UserId: $UserId) {
    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
      BookSeriesId
      BookSeriesName {
        Lang
        BookSeriesName
      }
      Books(BookId: $BookId) {
        BookId
        BookName {
          Lang
          BookName
        }
        PearsonLevel {
          LevelName {
            Lang
            LevelName
          }
          id
        }
        Units(UnitId: $UnitId) {
          UnitId
          UnitName {
            Lang
            UnitName
            UnitTitle
          }
          id
        }
        id
      }
      id
    }
    id
  }
  resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
    Resources {
      RelatedId
      ResourceId
      IngestionId
      ResourceLangs {
        Lang
        ResourceName
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "UnitId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LevelName",
  "storageKey": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LevelName",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = [
  {
    "kind": "Variable",
    "name": "UnitId",
    "variableName": "UnitId",
    "type": "String"
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UnitId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UnitName",
  "storageKey": null,
  "args": null,
  "concreteType": "unitLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UnitTitle",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String"
  }
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "RelatedId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    }
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ActivityLayoutGetAssessmentNameStudentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v6/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "PearsonLevel",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": (v10/*: any*/),
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v13/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ActivityLayoutGetAssessmentNameStudentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v6/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "PearsonLevel",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v18/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Units",
                    "storageKey": null,
                    "args": (v10/*: any*/),
                    "concreteType": "unit",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v18/*: any*/)
                    ]
                  },
                  (v18/*: any*/)
                ]
              },
              (v18/*: any*/)
            ]
          },
          (v18/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v13/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ActivityLayoutGetAssessmentNameStudentQuery",
    "id": null,
    "text": "query ActivityLayoutGetAssessmentNameStudentQuery(\n  $ResourceId: [String]!\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String\n  $UnitId: String\n  $StudyGroupId: String\n  $SchoolYearId: String\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      Books(BookId: $BookId) {\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        PearsonLevel {\n          LevelName {\n            Lang\n            LevelName\n          }\n          id\n        }\n        Units(UnitId: $UnitId) {\n          UnitId\n          UnitName {\n            Lang\n            UnitName\n            UnitTitle\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n  resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n    Resources {\n      RelatedId\n      ResourceId\n      IngestionId\n      ResourceLangs {\n        Lang\n        ResourceName\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '18925b7d5e10ed6e5a6626e49912458d';
module.exports = node;
