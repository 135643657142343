/**
 * @flow
 * @relayHash 5d370dd681c94b9503c42a01b901d96d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardStudyGroupStudentQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type DashboardStudyGroupStudentQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
      +SchoolYearId: ?string,
      +ClassNumber: ?string,
      +StudyGroupId: ?string,
      +StudyGroup: ?{|
        +StudyGroupId: ?string,
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
            +PearsonLevelId: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
        +LeapBookSeriesID: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IngestionId: ?string,
          +IsB2CBookSeries: ?boolean,
          +DisplaySequence: ?number,
          +BookSeriesImage: ?{|
            +FilePath: ?string
          |},
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +BookSeriesName: ?string,
            +Lang: ?string,
          |}>,
          +Subject: ?{|
            +SubjectName: ?$ReadOnlyArray<?{|
              +SubjectName: ?string,
              +Lang: ?string,
            |}>
          |},
        |},
        +LeapLevelId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
        +StudyGroupCode: ?string,
        +StudyGroupTheme: ?{|
          +ColorTone: ?string,
          +BookSeriesImage: ?{|
            +FilePath: ?string
          |},
        |},
      |},
    |}>,
  |}
|};
export type DashboardStudyGroupStudentQuery = {|
  variables: DashboardStudyGroupStudentQueryVariables,
  response: DashboardStudyGroupStudentQueryResponse,
|};
*/


/*
query DashboardStudyGroupStudentQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    UserId
    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
      SchoolYearId
      ClassNumber
      StudyGroupId
      StudyGroup {
        StudyGroupId
        Level {
          PearsonLevelId
          LevelName {
            Lang
            LevelName
            PearsonLevelId
          }
          DisplaySequence
          id
        }
        LeapBookSeriesID
        BookSeries {
          BookSeriesId
          IngestionId
          IsB2CBookSeries
          DisplaySequence
          BookSeriesImage {
            FilePath
            id
          }
          BookSeriesName {
            BookSeriesName
            Lang
          }
          Subject {
            SubjectName {
              SubjectName
              Lang
            }
            id
          }
          id
        }
        LeapLevelId
        StudyGroupName {
          StudyGroupName
          Lang
        }
        StudyGroupCode
        StudyGroupTheme {
          ColorTone
          BookSeriesImage {
            FilePath
            id
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PearsonLevelId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LevelName",
  "storageKey": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LevelName",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/)
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapBookSeriesID",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsB2CBookSeries",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesImage",
  "storageKey": null,
  "args": null,
  "concreteType": "systemFile",
  "plural": false,
  "selections": [
    (v15/*: any*/)
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SubjectName",
  "storageKey": null,
  "args": null,
  "concreteType": "subjectLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "SubjectName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapLevelId",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupCode",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ColorTone",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesImage",
  "storageKey": null,
  "args": null,
  "concreteType": "systemFile",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v23/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardStudyGroupStudentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "BelongsToStudyGroup",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupTheme",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeriesLevelDisplayOption",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v16/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardStudyGroupStudentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "BelongsToStudyGroup",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v23/*: any*/)
                    ]
                  },
                  (v11/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v24/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Subject",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "subject",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v23/*: any*/)
                        ]
                      },
                      (v23/*: any*/)
                    ]
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupTheme",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeriesLevelDisplayOption",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v24/*: any*/),
                      (v23/*: any*/)
                    ]
                  },
                  (v23/*: any*/)
                ]
              },
              (v23/*: any*/)
            ]
          },
          (v23/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardStudyGroupStudentQuery",
    "id": null,
    "text": "query DashboardStudyGroupStudentQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n      SchoolYearId\n      ClassNumber\n      StudyGroupId\n      StudyGroup {\n        StudyGroupId\n        Level {\n          PearsonLevelId\n          LevelName {\n            Lang\n            LevelName\n            PearsonLevelId\n          }\n          DisplaySequence\n          id\n        }\n        LeapBookSeriesID\n        BookSeries {\n          BookSeriesId\n          IngestionId\n          IsB2CBookSeries\n          DisplaySequence\n          BookSeriesImage {\n            FilePath\n            id\n          }\n          BookSeriesName {\n            BookSeriesName\n            Lang\n          }\n          Subject {\n            SubjectName {\n              SubjectName\n              Lang\n            }\n            id\n          }\n          id\n        }\n        LeapLevelId\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        StudyGroupCode\n        StudyGroupTheme {\n          ColorTone\n          BookSeriesImage {\n            FilePath\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '14ac7ce2a150bc33259a12310819de67';
module.exports = node;
