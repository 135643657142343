/**
 * @flow
 * @relayHash f216cfb49f241e89f944b1a2fc34fc46
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditAssignmentMutationVariables = {|
  ResourceAssignId: string,
  AssignmentName?: ?string,
  AssignmentDescription?: ?string,
  DueDate?: ?string,
  AllowRedo?: ?boolean,
|};
export type EditAssignmentMutationResponse = {|
  +editAssignment: ?boolean
|};
export type EditAssignmentMutation = {|
  variables: EditAssignmentMutationVariables,
  response: EditAssignmentMutationResponse,
|};
*/


/*
mutation EditAssignmentMutation(
  $ResourceAssignId: String!
  $AssignmentName: String
  $AssignmentDescription: String
  $DueDate: String
  $AllowRedo: Boolean
) {
  editAssignment(ResourceAssignId: $ResourceAssignId, AssignmentName: $AssignmentName, AssignmentDescription: $AssignmentDescription, DueDate: $DueDate, AllowRedo: $AllowRedo)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "AssignmentName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "AssignmentDescription",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "DueDate",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "AllowRedo",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "editAssignment",
    "args": [
      {
        "kind": "Variable",
        "name": "AllowRedo",
        "variableName": "AllowRedo",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "AssignmentDescription",
        "variableName": "AssignmentDescription",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "AssignmentName",
        "variableName": "AssignmentName",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "DueDate",
        "variableName": "DueDate",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "ResourceAssignId",
        "variableName": "ResourceAssignId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EditAssignmentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditAssignmentMutation",
    "id": null,
    "text": "mutation EditAssignmentMutation(\n  $ResourceAssignId: String!\n  $AssignmentName: String\n  $AssignmentDescription: String\n  $DueDate: String\n  $AllowRedo: Boolean\n) {\n  editAssignment(ResourceAssignId: $ResourceAssignId, AssignmentName: $AssignmentName, AssignmentDescription: $AssignmentDescription, DueDate: $DueDate, AllowRedo: $AllowRedo)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe84a3fd2206e20ad48da9481b0b15d7';
module.exports = node;
