/**
 * @flow
 * @relayHash 23d6af24b9ff668192d03d06b7ba24c1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type CompleteAssignmentMutationVariables = {|
  ResourceAssignResId: string,
  Lang?: ?LangEnumType,
  SessionId: string,
|};
export type CompleteAssignmentMutationResponse = {|
  +completeAssignment: ?string
|};
export type CompleteAssignmentMutation = {|
  variables: CompleteAssignmentMutationVariables,
  response: CompleteAssignmentMutationResponse,
|};
*/


/*
mutation CompleteAssignmentMutation(
  $ResourceAssignResId: String!
  $Lang: LangEnumType
  $SessionId: String!
) {
  completeAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, SessionId: $SessionId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignResId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SessionId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "completeAssignment",
    "args": [
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang",
        "type": "LangEnumType"
      },
      {
        "kind": "Variable",
        "name": "ResourceAssignResId",
        "variableName": "ResourceAssignResId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "SessionId",
        "variableName": "SessionId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteAssignmentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CompleteAssignmentMutation",
    "id": null,
    "text": "mutation CompleteAssignmentMutation(\n  $ResourceAssignResId: String!\n  $Lang: LangEnumType\n  $SessionId: String!\n) {\n  completeAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, SessionId: $SessionId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c13366c670572486f23ad08c87c4673';
module.exports = node;
