/**
 * @flow
 * @relayHash 819abadd90fad508b45be96da1fc314b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserTypeEnumType = "P" | "S" | "T" | "%future added value";
export type ViewableByStudentsTableGetListQueryVariables = {|
  StudyGroupId: string,
  SchoolYearId: string,
  UserType: UserTypeEnumType,
  ResourceId: string,
|};
export type ViewableByStudentsTableGetListQueryResponse = {|
  +studyGroup: ?{|
    +StudyGroupUser: ?$ReadOnlyArray<?{|
      +ClassNumber: ?string,
      +User: ?{|
        +UserId: ?string,
        +UserDisplayName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +UserName: ?string,
        |}>,
      |},
      +AssignedForResource: ?boolean,
    |}>
  |}
|};
export type ViewableByStudentsTableGetListQuery = {|
  variables: ViewableByStudentsTableGetListQueryVariables,
  response: ViewableByStudentsTableGetListQueryResponse,
|};
*/


/*
query ViewableByStudentsTableGetListQuery(
  $StudyGroupId: String!
  $SchoolYearId: String!
  $UserType: UserTypeEnumType!
  $ResourceId: String!
) {
  studyGroup(StudyGroupId: $StudyGroupId) {
    StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType) {
      ClassNumber
      User {
        UserId
        UserDisplayName {
          Lang
          UserName
        }
        id
      }
      AssignedForResource(ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "UserType",
    "type": "UserTypeEnumType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String!"
  }
],
v2 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "UserType",
    "variableName": "UserType",
    "type": "UserTypeEnumType!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserName",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AssignedForResource",
  "args": [
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId",
      "type": "String!"
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewableByStudentsTableGetListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupUser",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewableByStudentsTableGetListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupUser",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewableByStudentsTableGetListQuery",
    "id": null,
    "text": "query ViewableByStudentsTableGetListQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n  $UserType: UserTypeEnumType!\n  $ResourceId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType) {\n      ClassNumber\n      User {\n        UserId\n        UserDisplayName {\n          Lang\n          UserName\n        }\n        id\n      }\n      AssignedForResource(ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61156f47f3d6247979aa6b4b3299a9a0';
module.exports = node;
