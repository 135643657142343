/**
 * @flow
 * @relayHash 6f65f8659f48d84bc62c59b24b68b87c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AllStudentResultTeacherStudyGroupQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type AllStudentResultTeacherStudyGroupQueryResponse = {|
  +user: ?{|
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IngestionId: ?string,
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
        +StudyGroupInSameLevel: ?$ReadOnlyArray<?{|
          +StudyGroupId: ?string,
          +StudyGroupName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +StudyGroupName: ?string,
          |}>,
        |}>,
      |}>
    |}
  |}
|};
export type AllStudentResultTeacherStudyGroupQuery = {|
  variables: AllStudentResultTeacherStudyGroupQueryVariables,
  response: AllStudentResultTeacherStudyGroupQueryResponse,
|};
*/


/*
query AllStudentResultTeacherStudyGroupQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    School {
      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: true) {
        StudyGroupId
        BookSeries {
          BookSeriesId
          IngestionId
          id
        }
        StudyGroupName {
          Lang
          StudyGroupName
        }
        StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
          StudyGroupId
          StudyGroupName {
            Lang
            StudyGroupName
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String!"
},
v3 = [
  {
    "kind": "Literal",
    "name": "FilterOnboarded",
    "value": true,
    "type": "Boolean!"
  },
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = [
  (v2/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AllStudentResultTeacherStudyGroupQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  },
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupInSameLevel",
                    "storageKey": null,
                    "args": (v8/*: any*/),
                    "concreteType": "StudyGroup",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllStudentResultTeacherStudyGroupQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupInSameLevel",
                    "storageKey": null,
                    "args": (v8/*: any*/),
                    "concreteType": "StudyGroup",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AllStudentResultTeacherStudyGroupQuery",
    "id": null,
    "text": "query AllStudentResultTeacherStudyGroupQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: true) {\n        StudyGroupId\n        BookSeries {\n          BookSeriesId\n          IngestionId\n          id\n        }\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {\n          StudyGroupId\n          StudyGroupName {\n            Lang\n            StudyGroupName\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13c476c51991ff24526b01920f90fe8b';
module.exports = node;
