/**
 * @flow
 * @relayHash 84404c7ec4f6f36a39fbfa003a359e38
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewAssignmentByStudentsQueryVariables = {|
  ResourceAssignId: string,
  FilterCurrentuser?: ?boolean,
  SchoolYearId: string,
|};
export type ViewAssignmentByStudentsQueryResponse = {|
  +resourceAssignment: ?{|
    +ResourceAssignId: ?string,
    +AssignmentName: ?string,
    +DueDate: ?string,
    +AssignmentDescription: ?string,
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceAssignResId: ?string,
      +DisplaySequence: ?number,
      +Resource: ?{|
        +ResourceLangs: ?$ReadOnlyArray<?{|
          +ResourceName: ?string,
          +Lang: ?string,
        |}>,
        +ResourceId: ?string,
      |},
      +ResourceId: ?string,
      +ResourceAssignTargetStatus: ?$ReadOnlyArray<?{|
        +Status: ?string
      |}>,
    |}>,
    +AssignmentTargetStatus: ?$ReadOnlyArray<?{|
      +UserId: ?string,
      +Status: ?string,
      +StatusTime: ?string,
    |}>,
    +AssignmentTargets: ?$ReadOnlyArray<?{|
      +ResourceAssignId: ?string,
      +UserId: ?string,
      +StudyGroupId: ?string,
      +StudyGroup: ?{|
        +StudyGroupUser: ?$ReadOnlyArray<?{|
          +User: ?{|
            +UserId: ?string,
            +UserName: ?string,
            +UserDisplayName: ?$ReadOnlyArray<?{|
              +UserId: ?string,
              +Lang: ?string,
              +UserName: ?string,
            |}>,
          |},
          +ClassNumber: ?string,
          +UserId: ?string,
        |}>,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupId: ?string,
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
      |},
      +User: ?{|
        +UserId: ?string,
        +UserDisplayName: ?$ReadOnlyArray<?{|
          +UserName: ?string,
          +Lang: ?string,
        |}>,
        +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
          +ClassNumber: ?string,
          +StudyGroup: ?{|
            +BookSeries: ?{|
              +BookSeriesId: ?string
            |},
            +StudyGroupName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +StudyGroupName: ?string,
            |}>,
          |},
        |}>,
      |},
    |}>,
  |}
|};
export type ViewAssignmentByStudentsQuery = {|
  variables: ViewAssignmentByStudentsQueryVariables,
  response: ViewAssignmentByStudentsQueryResponse,
|};
*/


/*
query ViewAssignmentByStudentsQuery(
  $ResourceAssignId: String!
  $FilterCurrentuser: Boolean
  $SchoolYearId: String!
) {
  resourceAssignment(ResourceAssignId: $ResourceAssignId) {
    ResourceAssignId
    AssignmentName
    DueDate
    AssignmentDescription
    Resources {
      ResourceAssignResId
      DisplaySequence
      Resource {
        ResourceLangs {
          ResourceName
          Lang
        }
        ResourceId
        id
      }
      ResourceId
      ResourceAssignTargetStatus {
        Status
      }
      id
    }
    AssignmentTargetStatus {
      UserId
      Status
      StatusTime
    }
    AssignmentTargets(FilterCurrentUser: $FilterCurrentuser) {
      ResourceAssignId
      UserId
      StudyGroupId
      StudyGroup {
        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
          User {
            UserId
            UserName
            UserDisplayName {
              UserId
              Lang
              UserName
            }
            id
          }
          ClassNumber
          UserId
          id
        }
        StudyGroupName {
          StudyGroupId
          StudyGroupName
          Lang
        }
        id
      }
      User {
        UserId
        UserDisplayName {
          UserName
          Lang
        }
        BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
          ClassNumber
          StudyGroup {
            BookSeries {
              BookSeriesId
              id
            }
            StudyGroupName {
              Lang
              StudyGroupName
            }
            id
          }
          id
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterCurrentuser",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceAssignId",
    "variableName": "ResourceAssignId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAssignId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AssignmentName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DueDate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AssignmentDescription",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAssignResId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Status",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceAssignTargetStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ResourceAssignmentResourceTargetStatus",
  "plural": true,
  "selections": [
    (v11/*: any*/)
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "AssignmentTargetStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ResourceAssignmentTargetStatus",
  "plural": true,
  "selections": [
    (v13/*: any*/),
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StatusTime",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = [
  {
    "kind": "Variable",
    "name": "FilterCurrentUser",
    "variableName": "FilterCurrentuser",
    "type": "Boolean"
  }
],
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  },
  {
    "kind": "Literal",
    "name": "UserType",
    "value": "S",
    "type": "UserTypeEnumType!"
  }
],
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    (v13/*: any*/),
    (v8/*: any*/),
    (v18/*: any*/)
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupName",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v16/*: any*/),
    (v21/*: any*/),
    (v8/*: any*/)
  ]
},
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    (v18/*: any*/),
    (v8/*: any*/)
  ]
},
v24 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  }
],
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v21/*: any*/)
  ]
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewAssignmentByStudentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceAssignment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceAssignment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Resource",
                "storageKey": null,
                "args": null,
                "concreteType": "resource",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/),
              (v12/*: any*/)
            ]
          },
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentTargets",
            "storageKey": null,
            "args": (v15/*: any*/),
            "concreteType": "ResourceAssignmentTarget",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/),
              (v16/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupUser",
                    "storageKey": null,
                    "args": (v17/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ]
                      },
                      (v20/*: any*/),
                      (v13/*: any*/)
                    ]
                  },
                  (v22/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v23/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BelongsToStudyGroup",
                    "storageKey": null,
                    "args": (v24/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "StudyGroup",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "BookSeries",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "bookSeries",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/)
                            ]
                          },
                          (v26/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewAssignmentByStudentsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceAssignment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceAssignment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Resource",
                "storageKey": null,
                "args": null,
                "concreteType": "resource",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v27/*: any*/)
                ]
              },
              (v10/*: any*/),
              (v12/*: any*/),
              (v27/*: any*/)
            ]
          },
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentTargets",
            "storageKey": null,
            "args": (v15/*: any*/),
            "concreteType": "ResourceAssignmentTarget",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/),
              (v16/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "StudyGroupUser",
                    "storageKey": null,
                    "args": (v17/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "User",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "user",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v27/*: any*/)
                        ]
                      },
                      (v20/*: any*/),
                      (v13/*: any*/),
                      (v27/*: any*/)
                    ]
                  },
                  (v22/*: any*/),
                  (v27/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v23/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BelongsToStudyGroup",
                    "storageKey": null,
                    "args": (v24/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "StudyGroup",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "BookSeries",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "bookSeries",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/),
                              (v27/*: any*/)
                            ]
                          },
                          (v26/*: any*/),
                          (v27/*: any*/)
                        ]
                      },
                      (v27/*: any*/)
                    ]
                  },
                  (v27/*: any*/)
                ]
              }
            ]
          },
          (v27/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewAssignmentByStudentsQuery",
    "id": null,
    "text": "query ViewAssignmentByStudentsQuery(\n  $ResourceAssignId: String!\n  $FilterCurrentuser: Boolean\n  $SchoolYearId: String!\n) {\n  resourceAssignment(ResourceAssignId: $ResourceAssignId) {\n    ResourceAssignId\n    AssignmentName\n    DueDate\n    AssignmentDescription\n    Resources {\n      ResourceAssignResId\n      DisplaySequence\n      Resource {\n        ResourceLangs {\n          ResourceName\n          Lang\n        }\n        ResourceId\n        id\n      }\n      ResourceId\n      ResourceAssignTargetStatus {\n        Status\n      }\n      id\n    }\n    AssignmentTargetStatus {\n      UserId\n      Status\n      StatusTime\n    }\n    AssignmentTargets(FilterCurrentUser: $FilterCurrentuser) {\n      ResourceAssignId\n      UserId\n      StudyGroupId\n      StudyGroup {\n        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {\n          User {\n            UserId\n            UserName\n            UserDisplayName {\n              UserId\n              Lang\n              UserName\n            }\n            id\n          }\n          ClassNumber\n          UserId\n          id\n        }\n        StudyGroupName {\n          StudyGroupId\n          StudyGroupName\n          Lang\n        }\n        id\n      }\n      User {\n        UserId\n        UserDisplayName {\n          UserName\n          Lang\n        }\n        BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n          ClassNumber\n          StudyGroup {\n            BookSeries {\n              BookSeriesId\n              id\n            }\n            StudyGroupName {\n              Lang\n              StudyGroupName\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '508b69114ec637964e4aa31c9377f099';
module.exports = node;
