/**
 * @flow
 * @relayHash 5a86a8db366e673098c1bdddabc88cad
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReadResourceAssignmentResourceMutationVariables = {|
  ResourceAssignResId: string
|};
export type ReadResourceAssignmentResourceMutationResponse = {|
  +readResourceAssignmentResource: ?string
|};
export type ReadResourceAssignmentResourceMutation = {|
  variables: ReadResourceAssignmentResourceMutationVariables,
  response: ReadResourceAssignmentResourceMutationResponse,
|};
*/


/*
mutation ReadResourceAssignmentResourceMutation(
  $ResourceAssignResId: String!
) {
  readResourceAssignmentResource(ResourceAssignResId: $ResourceAssignResId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignResId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "readResourceAssignmentResource",
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceAssignResId",
        "variableName": "ResourceAssignResId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReadResourceAssignmentResourceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReadResourceAssignmentResourceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReadResourceAssignmentResourceMutation",
    "id": null,
    "text": "mutation ReadResourceAssignmentResourceMutation(\n  $ResourceAssignResId: String!\n) {\n  readResourceAssignmentResource(ResourceAssignResId: $ResourceAssignResId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '197da7723387725bfe81d3ebdc505613';
module.exports = node;
