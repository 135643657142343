/**
 * @flow
 * @relayHash cc4354daae4350a45c57b23ed043a627
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationPopUpGetSchoolBookSeriesListPecQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type NotificationPopUpGetSchoolBookSeriesListPecQueryResponse = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +LeapBookSeriesId: ?string,
      +IngestionId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +IsB2CBookSeries: ?boolean,
      +BookSeriesImage: ?{|
        +FilePath: ?string
      |},
      +BookSeriesBGImage: ?{|
        +FilePath: ?string
      |},
      +AdoptedBooks: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string
        |},
      |}>,
    |}>
  |}
|};
export type NotificationPopUpGetSchoolBookSeriesListPecQuery = {|
  variables: NotificationPopUpGetSchoolBookSeriesListPecQueryVariables,
  response: NotificationPopUpGetSchoolBookSeriesListPecQueryResponse,
|};
*/


/*
query NotificationPopUpGetSchoolBookSeriesListPecQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    AdoptedBookSeries {
      BookSeriesId
      LeapBookSeriesId
      IngestionId
      BookSeriesName {
        Lang
        BookSeriesName
      }
      IsB2CBookSeries
      BookSeriesImage {
        FilePath
        id
      }
      BookSeriesBGImage {
        FilePath
        id
      }
      AdoptedBooks(SchoolYearId: $SchoolYearId) {
        id
        BookId
        BookName {
          Lang
          BookName
        }
        BookImage {
          FilePath
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapBookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsB2CBookSeries",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = [
  (v8/*: any*/),
  (v11/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationPopUpGetSchoolBookSeriesListPecQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": null,
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "BookSeriesImage",
                "storageKey": null,
                "args": null,
                "concreteType": "systemFile",
                "plural": false,
                "selections": (v9/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "BookSeriesBGImage",
                "storageKey": null,
                "args": null,
                "concreteType": "systemFile",
                "plural": false,
                "selections": (v9/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedBooks",
                "storageKey": null,
                "args": (v10/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationPopUpGetSchoolBookSeriesListPecQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": null,
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "BookSeriesImage",
                "storageKey": null,
                "args": null,
                "concreteType": "systemFile",
                "plural": false,
                "selections": (v14/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "BookSeriesBGImage",
                "storageKey": null,
                "args": null,
                "concreteType": "systemFile",
                "plural": false,
                "selections": (v14/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedBooks",
                "storageKey": null,
                "args": (v10/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "plural": false,
                    "selections": (v14/*: any*/)
                  }
                ]
              },
              (v11/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NotificationPopUpGetSchoolBookSeriesListPecQuery",
    "id": null,
    "text": "query NotificationPopUpGetSchoolBookSeriesListPecQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries {\n      BookSeriesId\n      LeapBookSeriesId\n      IngestionId\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      IsB2CBookSeries\n      BookSeriesImage {\n        FilePath\n        id\n      }\n      BookSeriesBGImage {\n        FilePath\n        id\n      }\n      AdoptedBooks(SchoolYearId: $SchoolYearId) {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3fa1833c5b7e5804be5ba3bcf3ec7458';
module.exports = node;
