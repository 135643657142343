/**
 * @flow
 * @relayHash 5a095dbf176b8cab9680497fca2c709e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetB2CResourcesCenterTopicsMenuQueryVariables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
|};
export type GetB2CResourcesCenterTopicsMenuQueryResponse = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +RelatedTagType: ?$ReadOnlyArray<?{|
            +Tags: ?$ReadOnlyArray<?{|
              +TagName: ?string,
              +TagLongName: ?string,
              +TagType: ?string,
            |}>
          |}>,
          +AdoptedLearnosityResource: ?$ReadOnlyArray<?{|
            +Level2TagType: ?string,
            +Level2TagName: ?string,
          |}>,
        |}>,
      |}>,
    |}>
  |}
|};
export type GetB2CResourcesCenterTopicsMenuQuery = {|
  variables: GetB2CResourcesCenterTopicsMenuQueryVariables,
  response: GetB2CResourcesCenterTopicsMenuQueryResponse,
|};
*/


/*
query GetB2CResourcesCenterTopicsMenuQuery(
  $UserId: String!
  $BookSeriesId: String!
  $BookId: String!
) {
  user(UserId: $UserId) {
    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
      BookSeriesName {
        Lang
        BookSeriesName
      }
      Books(BookId: $BookId) {
        BookName {
          Lang
          BookName
        }
        AdoptedTagHierarchy {
          RelatedTagType {
            Tags(BookId: $BookId) {
              TagName
              TagLongName
              TagType
              id
            }
            id
          }
          AdoptedLearnosityResource(BookId: $BookId) {
            Level2TagType
            Level2TagName
            id
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String"
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagLongName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagType",
  "args": null,
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String!"
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level2TagType",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level2TagName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v5/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "RelatedTagType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "Tags",
                            "storageKey": null,
                            "args": (v5/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "AdoptedLearnosityResource",
                        "storageKey": null,
                        "args": (v10/*: any*/),
                        "concreteType": "LearnosityActivityTagInfo",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedBookSeries",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": (v5/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "AdoptedTagHierarchy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "RelatedTagType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "Tags",
                            "storageKey": null,
                            "args": (v5/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v13/*: any*/)
                            ]
                          },
                          (v13/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "AdoptedLearnosityResource",
                        "storageKey": null,
                        "args": (v10/*: any*/),
                        "concreteType": "LearnosityActivityTagInfo",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ]
                      },
                      (v13/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              (v13/*: any*/)
            ]
          },
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "id": null,
    "text": "query GetB2CResourcesCenterTopicsMenuQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      Books(BookId: $BookId) {\n        BookName {\n          Lang\n          BookName\n        }\n        AdoptedTagHierarchy {\n          RelatedTagType {\n            Tags(BookId: $BookId) {\n              TagName\n              TagLongName\n              TagType\n              id\n            }\n            id\n          }\n          AdoptedLearnosityResource(BookId: $BookId) {\n            Level2TagType\n            Level2TagName\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0059d45f3bbd797a5862af8a01348464';
module.exports = node;
