import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../Components/Loading/PEC/Loading";
import ViewNotificationPopUp from "./ViewNotificationPopUp";
import ResourceIconMapping from "../../../Components/Feature/Function/ResourceIconMappingPEC";
import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { useSelector, useDispatch } from "react-redux";
import VidoeLightBox from "../../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../../Components/Feature/ImageLightBox";
import sorting_arrow_black from "../../../assets/img/pec/all-images/sorting-arrow.png";
import sorting_arrow_white from "../../../assets/img/pec/all-images/sorting-arrow-white.png";
var browserRef = null;
import {
  isMobile,
  isBrowser,
  isTablet,
  isMobileOnly,
  osName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";
import ReadResourceMutation from "../../../Components/Feature/Function/ReadResourceMutation";
import UnitListGetSelfLearnConfig from "../../../Services/api/GraphQL/Query/UnitListGetSelfLearnConfig";

import UpdateSelfLearnConfigMutation from "../../../Services/api/GraphQL/Mutation/UpdateSelfLearnConfigMutation";
import * as XLSX from "xlsx";
import { data } from "jquery";
const DownloadReportExcel = (props) => {
  const {
    t,
    selectedBookSeriesId,
    doDownload,
    setdoDownload,
    selectedBookId,
    ClassId,
    selectedClass,
    showSDLPage,
    setShowSDLPage,
    toggleProgress,
    getScoreAndAverageClass,
    selectedBookName,
    showReportLoading,
    setShowReportLoading,
  } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const [activeBook, setActiveBook] = useState(1);

  const [unitLoadingHere, setUnitLoadingHere] = useState(true);

  const [currentPage, setcurrentPage] = useState(1);
  const [originData, setoriginData] = useState([]);
  // const [classChapterData, setClassChapterData] = useState([]);
  const [resourceCenterStructure, setresourceCenterStructure] = useState(null);
  const [resourceCenterStructureList, setresourceCenterStructureList] =
    useState([]);

  const [classUsers, setclassUsers] = useState([]);
  const [studentLoading, setstudentLoading] = useState(true);
  const [allStudentData, setallStudentData] = useState([]);
  const [reportData, setreportData] = useState([]);
  // const [dummyreportData, setDummyreportData] = useState([]);

  const [classSummaryInfo, setclassSummaryInfo] = useState({});

  // const UnitPageGetBookDataQuery = graphql`
  //   query UnitPageGetBookDataQuery($BookId: [String]!) {
  //     BooksData(BookId: $BookId){
  //       id
  //       BookSeriesId
  //       LeapBookSeriesId
  //       BookSeriesName
  //       DisplaySequence
  //       Subject
  //       Books(BookId: String)
  //       ResourceAttrMappings
  //       BookSeriesImage
  //       BookSeriesBGImage
  //       BookSeriesColorTone
  //       EBookLinkage
  //       MasterWebLinkage
  //       DwbLinkage
  //     }
  //   }
  // `;

  const DownloadReportExcelStructureResourcesListQuery = graphql`
    query DownloadReportExcelStructureResourcesListQuery(
      $StudyGroupId: String!
      $StructureId: String!
      $SchoolYearId: String!
      $UserId: String!
      $PageSize: Int!
      $PageNo: Int!
      $StudyGroupIds: [String]!
    ) {
      resourceCenterStructure(StructureId: $StructureId) {
        StructureLang {
          Lang
          StructureTitle
        }
        SummaryData(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)
        RelatedResources(
          StudyGroupId: $StudyGroupId
          PageSize: $PageSize
          PageNo: $PageNo
          SchoolYearId: $SchoolYearId
        ) {
          Resources {
            IngestionId
            ResourceVisibilityStatus(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            )
            AssignementInvloved(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              AssignmentName
            }
            MappedLevels {
              LevelName {
                LevelName
              }
            }
            Thumbnail {
              FilePath
            }
            SelfLearnConfiguration(
              StudyGroupId: $StudyGroupIds
              SchoolYearId: $SchoolYearId
            ) {
              ConfigName
              ConfigValue
            }

            SelfLearnAttemptHistory(UserId: $UserId) {
              IsCompleted
              Score
              MaxScore
              AttemptDate
              LearnositySessionRawResult
              LearnositySessionResult
              LastAttempt
            }
            ResourceReadStatus(
              StructureId: $StructureId
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              id
              StructureId
              ResourceId
              SchoolYearId
              UserId
              Status
              StatusTime
            }

            SelfLearnAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              UserDetail {
                UserId
                UserDisplayName {
                  Lang
                  UserName
                }
                BelongsToStudyGroup(
                  StudyGroupId: $StudyGroupId
                  SchoolYearId: $SchoolYearId
                ) {
                  UserId
                  ClassNumber
                }
              }
              Score
              MaxScore
              LearnositySessionRawResult
            }
            SelfLearnLastAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              UserDetail {
                UserId
                UserDisplayName {
                  Lang
                  UserName
                }
                BelongsToStudyGroup(
                  StudyGroupId: $StudyGroupId
                  SchoolYearId: $SchoolYearId
                ) {
                  UserId
                  ClassNumber
                }
              }
              Score
              MaxScore
              LearnositySessionResult
              LearnositySessionRawResult
            }
            VisibleByStudent
            ShareableToStudent
            ResourceId
            RelatedId
            ResourceType
            FileSource
            ResourceLangs {
              Lang
              ResourceName
            }
            Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
          }
          TotalNumberOfRecord
        }
      }
    }
  `;

  const DownloadReportExcelStudentGetStudentReportQuery = graphql`
    query DownloadReportExcelStudentGetStudentReportQuery(
      $UserId: String!
      $StudyGroupId: String!
      $SchoolYearId: String!
      $BookSeriesId: String
      $PageSize: Int!
      $PageNo: Int!
      $BookId: String
    ) {
      studyGroup(StudyGroupId: $StudyGroupId) {
        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
          ClassNumber
          User {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
          }
        }
      }
      user(UserId: $UserId) {
        AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
          Books(BookId: $BookId) {
            BookId
            BookName {
              Lang
              BookName
            }
            ResourceCenterStructure(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              StructureId
              SummaryData(
                StudyGroupId: $StudyGroupId
                SchoolYearId: $SchoolYearId
              )
              StructureLang {
                Lang
                StructureTitle
              }
              ParentStructureId
              DisplaySequence
              ChildStructure {
                StructureId
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructureId
                DisplaySequence
              }
              RelatedResourcesReadStatus(
                StudyGroupId: $StudyGroupId
                SchoolYearId: $SchoolYearId
              ) {
                id
                StructureId
                ResourceId
                SchoolYearId
                UserId
                Status
                StatusTime
              }
              RelatedResources(
                StudyGroupId: $StudyGroupId
                PageSize: $PageSize
                PageNo: $PageNo
                SchoolYearId: $SchoolYearId
              ) {
                Resources {
                  IngestionId
                  ResourceId
                  ResourceType
                  MappedLevels {
                    LevelName {
                      LevelName
                    }
                  }

                  SelfLearnLastAttemptHistoryByStudyGroup(
                    StudyGroupId: $StudyGroupId
                    SchoolYearId: $SchoolYearId
                    IncludeAssignmentRecord: false
                  ) {
                    LearnositySessionRawResult
                    LearnositySessionResult
                    AttemptSessionId
                    AttemptDate
                    UserDetail {
                      UserId
                      UserDisplayName {
                        Lang
                        UserName
                      }
                      BelongsToStudyGroup(
                        StudyGroupId: $StudyGroupId
                        SchoolYearId: $SchoolYearId
                      ) {
                        UserId
                        ClassNumber
                      }
                    }
                  }
                }
                TotalNumberOfRecord
              }
            }
          }
        }
      }
    }
  `;

  // view settings code starts here

  // view settings code ends here
  const getStudentList = () => {
    var variables = {
      UserId: userData.UserId,
      BookSeriesId: selectedBookSeriesId,
      BookId: selectedBookId,
      StudyGroupId: ClassId || "",
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      PageNo: currentPage,
      PageSize: 20,
    };
    return fetchQuery(
      environment,
      DownloadReportExcelStudentGetStudentReportQuery,
      variables
    );
  };

  // useEffect(() => {
  //   if (ClassId != "" && selectedBookId != "") {
  //     getStudentList().then((data) => {
  //     });
  //   } }, []);

  useEffect(() => {
    if (ClassId != "" && selectedBookId != "") {
      manipulateData("Book", selectedBookId);
    }

    //setActiveBookName(BookLists[0].unit);
  }, [ClassId, selectedBookId]);

  // sorting function
  const compareNames = (a, b) => {
    if (a.ResourceLangs[0].ResourceName < b.ResourceLangs[0].ResourceName) {
      return -1;
    }
    if (a.ResourceLangs[0].ResourceName > b.ResourceLangs[0].ResourceName) {
      return 1;
    }
    return 0;
  };

  const exportExcelSheetData = (allReportsData, allClassInfo, itsmyRes) => {
    console.log("AllExcel", {
      allReportsData,
      allClassInfo,
      itsmyRes,
      reportData,
      resourceCenterStructureList,
    });

    const allData = [{}, {}];
    const chaptersData = [];
    const chapterLength = resourceCenterStructureList.length - 1;
    const allDataResult = {};
    allDataResult.class = selectedClass ? selectedClass : "1A";
    allDataResult.student = "Class average";
    // allDataResult.avg = "N/A";
    allDataResult.avg = "";

    var chapterResObj = [];
    itsmyRes.chaptersInfo.map((data) => {
      var objR = {
        ChapterName: data.Name,
      };
      var resources = [];
      // if (data.StructureContent.length > 0) {
      //   var structureDataTemp = data.StructureContent;
      //   structureDataTemp.sort(compareNames);
      //   structureDataTemp.map((struc) => {
      //     resources.push(struc.ResourceLangs[0].ResourceName);
      //   });
      //   objR["Resources"] = resources;
      // } else {
      //   objR["Resources"] = resources;
      // }

      if (data.StructureContent.length > 0) {
        data.StructureContent.map((struc) => {
          resources.push(struc.ResourceLangs[0].ResourceName);
        });
        objR["Resources"] = resources;
      } else {
        objR["Resources"] = resources;
      }
      chapterResObj.push(objR);
    });

    for (let i = 0; i < chapterLength; i++) {
      chaptersData.push([]);
      // allDataResult["Score" + i] = "N/A";
      // allDataResult["Completion Progress" + i] = "N/A";
      // allDataResult["Viewing Progress" + i] = "N/A";

      const chaptersDetails = reportData.filter((data) => {
        return data.ChapterName == resourceCenterStructureList[i + 1].Name;
      });
      allDataResult["Score" + i] =
        chaptersDetails[0].classSummary.AvgScore === ""
          ? "N/A"
          : chaptersDetails[0].classSummary.AvgScore;
      allDataResult["Completion Progress" + i] =
        chaptersDetails[0].classSummary.TotPercentage === ""
          ? "N/A"
          : chaptersDetails[0].classSummary.TotPercentage + "%";
      allDataResult["Viewing Progress" + i] =
        chaptersDetails[0].classSummary.ViewTotPercentage === ""
          ? "N/A"
          : chaptersDetails[0].classSummary.ViewTotPercentage + "%";
      // const chapterKeys = [];
      // const chapterKeysValues = [];
      // itsmyRes.chaptersInfo[i].Resources.map((resourceData) => {
      //   chapterKeys.push(resourceData.ResourceName);
      //   chapterKeysValues[resourceData.ResourceName] = resourceData.ClassAvg
      //     ? resourceData.ClassAvg
      //     : "N/A";
      // });
      // dynamic_excel_column_chapter2.push({ ...chapterKeys });
      // dynamic_excel_chapter_key2.push({ ...chapterKeysValues });
    }

    allData.push(allDataResult);
    const remainingData = allReportsData.map((data, i) => {
      const dataStructs = {};

      dataStructs.class = selectedClass ? selectedClass : "1A";
      dataStructs.student = data.StudentName;
      // dataStructs.avg = 50;
      dataStructs.avg = data.StudentAvg === "" ? "N/A" : data.StudentAvg;
      data.ChapterInfo.map((el, i) => {
        dataStructs["Score" + i] =
          el.GradedResources.score === "" ? "N/A" : el.GradedResources.score;
        dataStructs["Completion Progress" + i] =
          el.GradedResources.completionprogress === ""
            ? "N/A"
            : el.GradedResources.completionprogress + "%";
        dataStructs["Viewing Progress" + i] =
          el.ViewOnlyResources.viewingprogress === ""
            ? "N/A"
            : el.ViewOnlyResources.viewingprogress + "%";

        // dataStructs["Score"] = el.GradedResources.score;
        // dataStructs["Completion Progress"] = el.GradedResources.completionprogress;
        // dataStructs["Viewing Progress"] = el.ViewOnlyResources.viewingprogress;
        /*************
        var rowObj = {
          Class: selectedClass ? selectedClass : "1A",
          Student: data.StudentName,
          // "Student's average score": 50,
          "Student's average score": data.StudentAvg ? data.StudentAvg : "N/A",
          
        }
        const columnNames = structObj[resourceCenterStructureList[i + 1].Name];
        if(columnNames){
          columnNames.map(singleColumn =>{
            singleColumn: 
          })
        }
          //if(filter the cosbj on chapter[i]){
              // loop the structure content if length > 0
              // create the below format
          //         }

        // "ONLINE VOCAB PRACTICE": el.GradedResources.score
        //     ? el.GradedResources.score
        //     : "N/A",
        //   eDICTATION: el.GradedResources.completionprogress
        //     ? el.GradedResources.completionprogress
        //     : "N/A",
        //   CHECKPOINT: el.ViewOnlyResources.viewingprogress
        //     ? el.ViewOnlyResources.viewingprogress
        //     : "N/A",
        // chaptersData[i].push(); 
        *************/
        var chapterNameTemp = el.name;
        var chapterForStudent = itsmyRes.finalData.AllStudentRes.filter(
          (o) => o.StudentId == data.StudentId
        );

        if (chapterForStudent.length > 0) {
          var chapterWithResource = chapterForStudent[0].ChapterName.filter(
            (o) => o.Name == chapterNameTemp
          );
          var structObj = {
            Class: selectedClass ? selectedClass : "1A",
            Student: data.StudentName,
            "Student's average score":
              chapterWithResource[0].StudentAvg === ""
                ? "N/A"
                : chapterWithResource[0].StudentAvg,
          };

          var chapterResources = [];

          if (chapterWithResource.length > 0) {
            var chaptersColumns = chapterResObj.filter((data) => {
              return data.ChapterName == chapterNameTemp;
            });
            if (chapterWithResource[0].Score.length > 0) {
              chapterWithResource[0].Score.map((struc) => {
                chapterResources.push(struc.ResourceName);
                structObj[struc.ResourceName] =
                  struc.Score === "" ? "N/A" : struc.Score;
              });

              // var a1 = ['a', 'b'];
              // var a2 = ['a', 'b', 'c', 'd'];

              // let intersection = a2.filter(x => !a1.includes(x));
              if (chaptersColumns.length > 0) {
                let intersection = chaptersColumns[0].Resources.filter(
                  (x) => !chapterResources.includes(x)
                );
                if (intersection.length > 0) {
                  intersection.map((defaultColumn) => {
                    structObj[defaultColumn] = "N/A";
                  });
                }
              }
            } else {
              var chapterColumns = chapterResObj.filter((data) => {
                return data.ChapterName == chapterNameTemp;
              });

              if (chapterColumns[0].Resources.length > 0) {
                chapterColumns[0].Resources.map((chapterPercent) => {
                  structObj[chapterPercent] = "N/A";
                });
              }
            }
          }
        } else {
          var structObj = {
            Class: selectedClass ? selectedClass : "1A",
            Student: data.StudentName,
            "Student's average score": "N/A",
          };
        }

        //structObj["Resources"] = chapterResources;
        chaptersData[i].push(structObj);
        // chaptersData[i].push({
        //   Class: selectedClass ? selectedClass : "1A",
        //   Student: data.StudentName,
        //   // "Student's average score": 50,
        //   "Student's average score": data.StudentAvg ? data.StudentAvg : "N/A",
        //   "ONLINE VOCAB PRACTICE": el.GradedResources.score
        //     ? el.GradedResources.score
        //     : "N/A",
        //   eDICTATION: el.GradedResources.completionprogress
        //     ? el.GradedResources.completionprogress
        //     : "N/A",
        //   CHECKPOINT: el.ViewOnlyResources.viewingprogress
        //     ? el.ViewOnlyResources.viewingprogress
        //     : "N/A",
        // });
      });
      allData.push(dataStructs);
    });
    // return;

    /* generate worksheet and workbook */
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(allData);
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Book A1 Overview");
    var overViewSheetName;
    if (selectedBookName.length > 18) {
      overViewSheetName = selectedBookName.substr(0, 18) + "...";
    } else {
      overViewSheetName = selectedBookName;
    }
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      overViewSheetName + " Overview"
    );
    for (let i = 0; i < chapterLength; i++) {
      // chaptersData[i].unshift(allClassInfo[i]);
      const dynamic_excel_column_chapter = [];
      const dynamic_excel_chapter_key = {};

      /** Added By Suresh **/
      var chapterWithResourceClassAvg = itsmyRes.finalData.ClassAvgRes.filter(
        (o) => o.Name == resourceCenterStructureList[i + 1].Name
      );
      var chapterResFilter = chapterResObj.filter(
        (o) => o.ChapterName == resourceCenterStructureList[i + 1].Name
      );
      if (chapterResFilter.length > 0) {
        if (chapterWithResourceClassAvg.length > 0) {
          chapterResFilter[0].Resources.map((resData) => {
            var resourceClassAvg =
              chapterWithResourceClassAvg[0].Resources.filter(
                (o) => o.ResourceName == resData
              );

            dynamic_excel_column_chapter.push(resData);
            dynamic_excel_chapter_key[resData] =
              resourceClassAvg[0].ClassAvg === ""
                ? "N/A"
                : resourceClassAvg[0].ClassAvg;
          });
        }
      }

      var classAvgForChapter = allClassInfo.filter(
        (o) => o.cname == resourceCenterStructureList[i + 1].Name
      );

      // itsmyRes.finalData.ClassAvgRes[i].Resources.map((resourceData) => {
      //   dynamic_excel_column_chapter.push(resourceData.ResourceName);
      //   dynamic_excel_chapter_key[resourceData.ResourceName] =
      //     resourceData.ClassAvg ? resourceData.ClassAvg : "N/A";
      // });
      if (classAvgForChapter.length > 0) {
        chaptersData[i].unshift({
          Class: selectedClass ? selectedClass : "1A",
          Student: "Class average",
          "Student's average score":
            classAvgForChapter[0].classAvg === ""
              ? "N/A"
              : classAvgForChapter[0].classAvg,
          ...dynamic_excel_chapter_key,
        });
      } else {
        chaptersData[i].unshift({
          Class: selectedClass ? selectedClass : "1A",
          Student: "Class average",
          "Student's average score": "N/A",
          ...dynamic_excel_chapter_key,
        });
      }
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(chaptersData[i]),
        resourceCenterStructureList[i + 1].Name.replace(/[^a-zA-Z0-9 ]/g, "")
      );
      // itsmyRes.finalData.ClassAvgRes[i].Resources.map((resourceData) => {
      //   dynamic_excel_column_chapter.push(resourceData.ResourceName);
      //   dynamic_excel_chapter_key[resourceData.ResourceName] =
      //     resourceData.ClassAvg ? resourceData.ClassAvg : "N/A";
      // });
      // chaptersData[i].unshift({
      //   Class: selectedClass ? selectedClass : "1A",
      //   Student: "Class average",
      //   "Student's average score": allClassInfo[i].classAvg
      //     ? allClassInfo[i].classAvg
      //     : "N/A",
      //   ...dynamic_excel_chapter_key,
      // });
      setExcelSheetHeader(XLSX.utils.json_to_sheet(chaptersData[i]), [
        [
          "CLASS",
          "STUDENTS",
          "Student's average score",
          ...dynamic_excel_column_chapter,
        ],
      ]);
    }

    const overview_chapters = [
      "Class",
      "STUDENTS",
      "Student's average score of graded resources",
    ];
    const overview_resources = [, , ,];
    const overview_resources_mark = [, , ,];

    for (let i = 0; i < chapterLength; i++) {
      overview_chapters.push(
        resourceCenterStructureList[i + 1].Name.replace(/[^a-zA-Z0-9 ]/g, "")
      );
      overview_chapters.push("");
      overview_chapters.push("");
      overview_resources.push("AUTO-MARKED EXERCISES");
      overview_resources.push("");
      overview_resources.push("VIEW-ONLY RESOURCES");
      overview_resources_mark.push("Score");
      overview_resources_mark.push("Completion Progress");
      overview_resources_mark.push("Viewing Progress");
    }
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [...overview_chapters],
        [...overview_resources],
        [...overview_resources_mark],
      ],
      {
        origin: "A1",
      }
    );

    /* calculate column width */
    // const max_width = rows.reduce((w, r) => Math.max(w, r.student.length), 10);
    worksheet["!cols"] = [
      ,
      ,
      ,
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
    ];
    const mergingChapters = [
      { s: { c: 0, r: 0 }, e: { c: 0, r: 2 } }, //overview-class
      { s: { c: 1, r: 0 }, e: { c: 1, r: 2 } }, //overview-students
      { s: { c: 2, r: 0 }, e: { c: 2, r: 2 } }, //overview-Student's average score of graded resources
      { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } }, //overview-chapter1=>columns
      { s: { c: 3, r: 1 }, e: { c: 4, r: 1 } }, //overview-chapter1=>rows
    ];
    for (let i = 1; i < chapterLength; i++) {
      mergingChapters.push(
        { s: { c: 3 + 3 * i, r: 0 }, e: { c: 5 + 3 * i, r: 0 } }, //overview-chapter1=>columns
        { s: { c: 3 + 3 * i, r: 1 }, e: { c: 4 + 3 * i, r: 1 } }
      );
    }

    worksheet["!merges"] = mergingChapters;

    /* create an XLSX file and try to save to Presidents.xlsx */
    setShowReportLoading(false);
    XLSX.writeFile(workbook, "ReportsData.xlsx");
  };

  // function to set the exported excel's sheet header
  const setExcelSheetHeader = (worksheet, dataArr) => {
    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, dataArr, {
      origin: "A1",
    });
  };

  var finalReportData = [];

  useEffect(() => {
    if (doDownload != "") {
      if (resourceCenterStructureList.length > 0) {
        var checkLen = reportData.length + 1;
        if (reportData.length == resourceCenterStructureList.length) {
          downloadFormat();
          //  var finalObj = formatChapterData().then((data)=>{
          //   return data
          //  });
          // if(chDataVal.length >0){
          // }
          // downloadFormat();
        }
      }
    }
  }, [reportData, resourceCenterStructureList]);

  useEffect(() => {
    if (doDownload != "") {
      dowloadReport();
    }
  }, [doDownload]);
  const getChapterList = (childObj) => {
    var variables = {
      StudyGroupId: ClassId || "",
      StructureId: childObj.StructureId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      PageNo: 1,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      PageSize: 20,
      StudyGroupIds: ClassId == null ? [] : [ClassId],
    };
    return fetchQuery(
      environment,
      DownloadReportExcelStructureResourcesListQuery,
      variables
    );
  };

  const excelSubunitResourceInfo = async (childObj, childIndex) => {
    //var studentLists = reportData[0].studentObj;
    var finaldata = await getChapterList(childObj).then((data) => {
      if (!data?.resourceCenterStructure) {
        return props.history.push("/m/myschool");
      }
      if (!data.resourceCenterStructure.RelatedResources) {
        return props.history.push("/m/myschool");
      }

      // var userType = JSON.parse(localStorage.getItem("userData")).UserType;
      var predata = [];
      var gradeResourceForChapter = [];
      //var chapterDetails = []
      // var chapterObj = {
      //   "chapterName": childObj.StructureLang[0].StructureTitle,
      //   "structureId":childObj.StructureId,
      //   "parentStructureId":childObj.ParentStructureId,
      //   "resources":[],
      // }
      // if (userType == "T") {
      var unitResources = [];
      if (data.resourceCenterStructure.RelatedResources.Resources != null) {
        predata = [...data.resourceCenterStructure.RelatedResources.Resources];

        predata.map((resObj, resIndex) => {
          if (
            resObj.ResourceType == "PRIZM" ||
            resObj.ResourceType == "LEARNOSITY"
          ) {
            gradeResourceForChapter.push(resObj);
          }
        });
        // unitResources.push(gradeResourceForChapter)
      }
      return gradeResourceForChapter;
    });
    return finaldata;
  };

  const formatChapterData = async () => {
    var subchapters = [];

    await Promise.all(
      resourceCenterStructureList.map(async (unitObj, uindexVal) => {
        if (unitObj.Type != "Book") {
          var chapterObj = {
            Name: unitObj.Name,
            StructureId: unitObj.StructureId,
            
          };
          var resourceInfo = [];
          var resourceInfoTemp = [];
          var StructureContentTemp = [];
          var allResourceWithChapters = [];
          await Promise.all(
            unitObj.ChildStructure.map(async (childObj, childInd) => {
              var objC = {"Display": childInd, "Res":[]}
              StructureContentTemp = await excelSubunitResourceInfo(
                childObj,childInd
              ).then((data) => {
                data.map((ires, iresindex) => {
                  resourceInfo.push(ires);
                  objC.Res.push({"Display": childInd, "Res": ires})
                  
                });
                
              });
              resourceInfoTemp.push(objC)
            })
          );
          resourceInfoTemp.sort(function(a, b) {
            return a.Display - b.Display;
            });
          var newResourceInfo = []
            resourceInfoTemp.map((finalChData, finalChDataIndex) => {
              finalChData.Res.map((valRes)=>{
                newResourceInfo.push(valRes.Res)

              })
              
          })

          chapterObj.StructureContent = newResourceInfo;
          chapterObj.StructureContentTemp = newResourceInfo;
          if (resourceInfo.length > 0) {
            allResourceWithChapters.push(resourceInfo);
          }

          subchapters.push(chapterObj);
        }
      })
    );

    var finalResData = await doCalculationOnStudents(subchapters).then(
      (data) => {
        return data;
      }
    );
    //console.log("subchapters", subchapters);
    return { finalData: finalResData, chaptersInfo: subchapters };
  };

  const doCalculationOnStudents = async (dataLists) => {
    var studentLists = [...classUsers];
    var teacherReportTableData = [];
    var userInfo = [];
    var userDetails = [];
    var gradeResLen = 0;
    var viewResLen = 0;
    var totalAttemptView = 0;
    var totalAttempt = 0;
    var classSummary = {
      AttemptgradedRes: 0,
      GradeResLen: 0,
      Percentage: 0,
      TotalAttempt: 0,
      ResPercentage: 0,
      TotPercentage: 0,
      ViewResPercentage: 0,
      ViewTotPercentage: 0,
      AvgScore: 0,
    };
    var allStudentsRes = [];

    var allResources = [];
    studentLists.map((obj, index) => {
      var finalStudentResult = {
        StudentId: obj.User.UserId,
        ChapterName: [],
      };
      dataLists.map((allObj, indexk) => {
        var studentScores = [];
        if (allObj.StructureContent) {
          allObj.StructureContent.map((gradeObj, gindex) => {
            if (
              !allResources.includes(gradeObj.ResourceLangs[0].ResourceName)
            ) {
              allResources.push(gradeObj.ResourceLangs[0].ResourceName);
            }

            gradeObj.SelfLearnLastAttemptHistoryByStudyGroup.map(
              (attm, aindex) => {
                if (attm.UserDetail.UserId == obj.User.UserId) {
                  var json_data = JSON.parse(attm.LearnositySessionRawResult);
                  if (gradeObj.ResourceType == "PRIZM") {
                    var avgScore =
                      (json_data.score.raw / json_data.score.max) * 100;
                    studentScores.push({
                      ResourceId: gradeObj.ResourceId,
                      ResourceName: gradeObj.ResourceLangs[0].ResourceName,
                      Score: Math.round(avgScore),
                      AvgScore: Math.round(avgScore),
                    });
                  } else {
                    //totalAttempt= totalAttempt+1;
                    var avgScore =
                      (json_data.score / json_data.max_score) * 100;
                    studentScores.push({
                      ResourceId: gradeObj.ResourceId,
                      ResourceName: gradeObj.ResourceLangs[0].ResourceName,
                      Score: Math.round(avgScore),
                      AvgScore: Math.round(avgScore),
                    });
                  }
                }
              }
            );
          });
          //finalStudentResult.Scores = studentScores;
        } else {
        }

        var tempScore = "";
        //var tempCompletion = "";
        var attempt = 0;
        var studentAllChapterAvg = "";
        studentScores.map((gradeAvgObj, gind) => {
          if (gradeAvgObj.Score === "") {
          } else {
            attempt = attempt + 1;
            if (tempScore === "") {
              tempScore = 0;
            }
            // if(tempCompletion == ""){
            //   tempCompletion = 0;
            // }
            tempScore = tempScore + gradeAvgObj.Score;
            // tempCompletion = tempCompletion + gradeAvgObj.GradedResources.completionprogress;
          }
        });

        if (tempScore === "") {
        } else {
          studentAllChapterAvg = Math.round(tempScore / attempt);
          //finalStudentInfo.StudentAvg = studentAllChapterAvg;
        }

        finalStudentResult.ChapterName.push({
          Name: allObj.Name,
          StudentAvg: studentAllChapterAvg,
          Score: studentScores,
        });
      });
      allStudentsRes.push(finalStudentResult);
    });

    //Class Average Calculation on Individual Resource Sheet
    var classAverageResource = [];
    dataLists.map((cobj, cind) => {
      var chapterClassInfo = {
        Name: cobj.Name,
        Resources: [],
      };
      allResources.map((individualRes) => {
        var cTemp = "";
        var attempt = 0;
        var resourceClassAvg = "";
        allStudentsRes.map((studentOb) => {
          //Chapter Name
          var chapterFilter = studentOb.ChapterName.filter(
            (o) => o.Name == cobj.Name
          );
          if (chapterFilter.length > 0) {
            //ResourceName
            var resFilter = chapterFilter[0].Score.filter(
              (o) => o.ResourceName == individualRes
            );
            if (resFilter.length > 0) {
              if (resFilter[0].Score === "") {
                // if
              } else {
                attempt = attempt + 1;
                if (cTemp === "") {
                  cTemp = 0;
                }
                cTemp = cTemp + resFilter[0].Score;
              }
            }
          }
        }); //Student Loop End

        if (cTemp === "") {
          // if
        } else {
          resourceClassAvg = Math.round(cTemp / attempt);
        }

        var resClassInfo = {
          ResourceName: individualRes,
          ClassAvg: resourceClassAvg,
        };
        chapterClassInfo.Resources.push(resClassInfo);
      }); //Resource Loop End

      classAverageResource.push(chapterClassInfo);
    }); //Chapter Loop End

    return {
      AllStudentRes: allStudentsRes,
      ClassAvgRes: classAverageResource,
    };
    // setClassChapterData(classAverageResource);
  };
  const downloadFormat = async () => {
    setShowReportLoading(true);
    // var allObjSummary = {argType: type,UnitId:argfilter,studentObj: studentObj, classSummary: classSummary };
    var studentList = reportData[0].studentObj;

    var filteredAllScore = reportData.filter(
      (o) => o.argType == "Book"
    );
    console.log("suresh",filteredAllScore)
    var allStudentInfo = [];
console.log("suresh", reportData);
    await studentList.map((sobj, sindex) => {
      var finalStudentInfo = {
        StudentId: sobj.id,
        StudentName: sobj.student_name,
        StudentId: sobj.student_id,
        ChapterInfo: [],
        StudentAvg: "",
      };

      resourceCenterStructureList.map((unitObj, unitIndex) => {
        if (unitObj.Type != "Book") {
          var unitFilter = reportData.filter(
            (o) => o.UnitId == unitObj.StructureId
          );
          var studentInfo = unitFilter[0].studentObj.filter(
            (individualStudentObj) => individualStudentObj.id == sobj.id
          );
          var chapterObj = {
            name: unitObj.Name,
            structureid: unitObj.StructureId,
            GradedResources: {
              score: studentInfo[0].score === "" ? "" : studentInfo[0].score,
              completionprogress: studentInfo[0].average_progress,
            },
            ViewOnlyResources: {
              viewingprogress: studentInfo[0].view_progress,
            },
          };

          finalStudentInfo.ChapterInfo.push(chapterObj);
        }
      });
      var tempScore = "";
      //var tempCompletion = "";
      var attempt = 0;
      var studentAllChapterAvg = "";
      // finalStudentInfo.ChapterInfo.map((gradeAvgObj, gind) => {
      //   if (gradeAvgObj.GradedResources.score === "") {
      //   } else {
      //     attempt = attempt + 1;
      //     if (tempScore === "") {
      //       tempScore = 0;
      //     }
       
      //     tempScore = tempScore + gradeAvgObj.GradedResources.score;
      //   }
      // });

      // if (tempScore === "") {
      // } else {
      //   studentAllChapterAvg = Math.round(tempScore / attempt);
      //   finalStudentInfo.StudentAvgOld = studentAllChapterAvg;
      // }

      //var studentAllChapterCompletion = Math.round((tempCompletion/attempt)*100)


      //Newly Added Score update 
      let allScoreForStudent = filteredAllScore[0].allStudentScoreForAllResources;
      console.log("suresh", allScoreForStudent)
       
      let studentScoreObj = allScoreForStudent.find(f=>f.userId==sobj.student_id);
      console.log("suresh", studentScoreObj)
      if(studentScoreObj){
        let totalScore = studentScoreObj.score.reduce((a, b) => a + b, 0)
        studentAllChapterAvg = Math.round(totalScore / studentScoreObj.score.length);
        finalStudentInfo.StudentAvg = studentAllChapterAvg;
      }else {
        //finalStudentInfo.StudentAvg = "";
      }
      
      //End Newly Added Score update

      allStudentInfo.push(finalStudentInfo);
    });

    var itsmyRes = await formatChapterData();
    // setDummyreportData(allStudentInfo);
    // function to generate reports based on real data
    //Class Average for OVerview Sheet
    var classInfo = [];
    resourceCenterStructureList.map((unitObj, unitIndex) => {
      if (unitObj.Type != "Book") {
        var chapterObjClass = {
          id: unitIndex,
          cname: unitObj.Name,
          structureid: unitObj.StructureId,
          classAvg: "",
          classCompletion: "",
        };
        var unitFilter = reportData.filter(
          (o) => o.UnitId == unitObj.StructureId
        );
        if (unitFilter.length > 0) {
          (chapterObjClass.classAvg = unitFilter[0].classSummary.AvgScore),
            (chapterObjClass.classCompletion =
              unitFilter[0].classSummary.TotPercentage);
        }
        classInfo.push(chapterObjClass);
        //classInfo[unitIndex] = chapterObjClass
      }
      //test
    });

    exportExcelSheetData(allStudentInfo, classInfo, itsmyRes);
  };
  //var finalReportData = [];
  const dowloadReport = () => {
    var finalData = [];
    resourceCenterStructureList.map((unitObj, unitIndex) => {
      if (unitObj.Name == "Book Summary") {
        var returnedData = manipulateDataExcel(
          "Book",
          unitObj.Name,
          unitObj.StructureId
        ).then((data) => {
          finalData.push(data);
          return data;
        });
        //finalReportData.push(tempfinalReportData);
      } else {
        var returnedData = manipulateDataExcel(
          "Structure",
          unitObj.Name,
          unitObj.StructureId
        ).then((data) => {
          finalData.push(data);
          if (finalData.length == resourceCenterStructureList.length) {
            setreportData(finalData);
          }
          return data;
        });
      }
    });
  };

  async function manipulateDataExcel(type, chapterNameArg, argfilter) {
    var finaldata = await getStudentList().then((data) => {
      const { user: { AdoptedBookSeries } = {} } = data || {};

      const {
        studyGroup: { StudyGroupUser = [{}] },
      } = data || {};
      let findOnlyOneStudyId;

      //const booksList = AdoptedBookSeries[0];

      let booksListT = data.user.AdoptedBookSeries[0].Books.filter(
        (o) => o.BookId == selectedBookId
      );

      let booksList = booksListT[0];

      var preSetData = [],
        topLevelIds = [],
        callback = JSON.parse(
          JSON.stringify(booksList.ResourceCenterStructure)
        );

      preSetData.push({
        book: {
          bookseriesId: booksList.BookSeriesId,
          DisplaySequence: booksList.DisplaySequence,
          BookSeriesName: booksList.BookSeriesName,
          options: [],
        },
      });
      var allReadRes = [];
      callback.map((robj, rinde) => {
        topLevelIds.push(robj.StructureId);
        //get all parent first
        robj.RelatedResourcesReadStatus.map((readObj, rind) => {
          allReadRes.push(readObj);
        });

        if (robj.ParentStructureId == null || robj.ParentStructureId == "") {
          preSetData[0].book.options.push(robj);
        }
      });

      preSetData[0].book.options.map((oobj, ooindex) => {
        var tempSave = oobj.ChildStructure,
          newChil = [];
        tempSave.map((tc, tindex) => {
          if (topLevelIds.indexOf(tc.StructureId) != -1) {
            newChil.push(tc);
          }
        });
        oobj.ChildStructure = newChil;
        oobj.ChildStructure.map((cobj, cindex) => {
          if (
            callback
              .map((i) => {
                return i.ParentStructureId;
              })
              .indexOf(cobj.StructureId) != -1 &&
            topLevelIds.indexOf(oobj.StructureId) != -1
          ) {
            callback.map((i) => {
              if (
                i.ParentStructureId == cobj.StructureId &&
                topLevelIds.indexOf(i.StructureId) != -1
              ) {
                if (!cobj.ChildStructure) {
                  cobj.ChildStructure = [];
                  cobj.ChildStructure.push(i);
                } else {
                  cobj.ChildStructure.push(i);
                }
              }
            });
          }
        });
      });
      preSetData.map((i) => {
        i.book.options.sort((a, b) => {
          return parseInt(a.DisplaySequence) - parseInt(b.DisplaySequence);
        });
      });

      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          if (oobj.ParentStructureId == null || oobj.ParentStructureId == "") {
          }
        });
      });

      // if (preSetData[0].book.options.length > 0) {
      //   setActiveBookName(
      //     preSetData[0].book.options[0].StructureLang[
      //       preSetData[0].book.options[0].StructureLang[0].Lang == lang
      //         ? 0
      //         : 1
      //     ].StructureTitle
      //   );
      //   setActiveBook(preSetData[0].book.options[0].StructureId);

      // }

      var unitDataWithBook = [];
      if (type == "Book") {
        setActiveBook(selectedBookId);
      }

      var displaySeq = 1;
      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          var gradedResources = [];
          var viewResources = [];
          var readResources = [];
          if (oobj.ChildStructure.length > 0) {
            oobj.ChildStructure.map((childobj, oindex) => {
              let csres = callback.filter(
                (o) => o.StructureId == childobj.StructureId
              );

              var readResFilter = allReadRes.filter(
                (o) => o.StructureId == childobj.StructureId
              );
              readResFilter.map((readObj, iv) => {
                readResources.push(readObj);
              });

              if (csres[0].RelatedResources.TotalNumberOfRecord > 0) {
                csres[0].RelatedResources.Resources.map((resObj, oindex) => {
                  if (
                    resObj.ResourceType == "PRIZM" ||
                    resObj.ResourceType == "LEARNOSITY"
                  ) {
                    gradedResources.push(resObj);
                  } else {
                    viewResources.push(resObj);
                  }
                });
              }
            });
          }
          var summaryInfo = JSON.parse(oobj.SummaryData);
          var progressClassVal = "";
          var progressClassName = "large-progressbar-highest";
          if (summaryInfo.avgScore === null) {
          } else {
            progressClassVal = Math.round(summaryInfo.avgScore);
          }

          var resobj = {
            Name: oobj.StructureLang[oobj.StructureLang[0].Lang == lang ? 0 : 1]
              .StructureTitle,
            AvgScore: progressClassVal,
            Type: "Unit",
            StructureId: oobj.StructureId,
            RelatedResources: readResources,
            GradedResources: gradedResources,
            ChildStructure: oobj.ChildStructure,
            ViewResources: viewResources,

            DisplayLevel: displaySeq + 1,
          };
          unitDataWithBook.push(resobj);
        });
      });
      var gradeResourcesSummary = [];
      var viewResourcesSummary = [];
      var relatedResourcesSummary = [];
      var bookSummaryTotalavg = 0;
      var avgTotCount = 0;
      unitDataWithBook.map((oobj, oindex) => {
        if (oobj.AvgScore != "") {
          avgTotCount = avgTotCount + 1;
          bookSummaryTotalavg = bookSummaryTotalavg + oobj.AvgScore;
        }

        oobj.GradedResources.map((gobj, ind) => {
          gradeResourcesSummary.push(gobj);
        });
        oobj.ViewResources.map((vobj, ind) => {
          viewResourcesSummary.push(vobj);
        });
        oobj.RelatedResources.map((robj, ind) => {
          relatedResourcesSummary.push(robj);
        });
      });
      var bookSumAvg = "";
      if (avgTotCount > 0) {
        bookSumAvg = Math.round(bookSummaryTotalavg / avgTotCount);
      }
      var obj = {
        Name: "Book Summary",
        AvgScore: bookSumAvg,
        Type: "Book",
        StructureId: selectedBookId,
        GradedResources: gradeResourcesSummary,
        ViewResources: viewResourcesSummary,
        RelatedResources: relatedResourcesSummary,
        DisplayLevel: 1,
        ChildStructure: [],
      };
      unitDataWithBook.push(obj);
      unitDataWithBook.sort((a, b) => {
        return parseInt(a.DisplayLevel) - parseInt(b.DisplayLevel);
      });

      var reportData = [];
      var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
      if (type == "Book") {
        let booksListT = unitDataWithBook.filter((o) => o.Type == "Book");
        return handleTeacherReportDataExcel(
          booksListT,
          StudyGroupUserTemp,
          type,
          argfilter,
          chapterNameArg
        );
        //reportData.push(getIndividualReport);
      } else {
        let booksListT = unitDataWithBook.filter(
          (o) => o.StructureId == argfilter
        );
        return handleTeacherReportDataExcel(
          booksListT,
          StudyGroupUserTemp,
          type,
          argfilter,
          chapterNameArg
        );
        //reportData.push(getIndividualReport);
      }

      //return reportData;
    });
    return finaldata;
  }

  const manipulateData = (type, argfilter) => {
    getStudentList().then((data) => {
      setoriginData([]);
      setresourceCenterStructureList([]);
      const { user: { AdoptedBookSeries } = {} } = data || {};

      const {
        studyGroup: { StudyGroupUser = [{}] },
      } = data || {};
      let findOnlyOneStudyId;

      //const booksList = AdoptedBookSeries[0];

      let booksListT = data.user.AdoptedBookSeries[0].Books.filter(
        (o) => o.BookId == selectedBookId
      );

      let booksList = booksListT[0];

      var preSetData = [],
        topLevelIds = [],
        callback = JSON.parse(
          JSON.stringify(booksList.ResourceCenterStructure)
        );

      preSetData.push({
        book: {
          bookseriesId: booksList.BookSeriesId,
          DisplaySequence: booksList.DisplaySequence,
          BookSeriesName: booksList.BookSeriesName,
          options: [],
        },
      });
      var allReadRes = [];
      callback.map((robj, rinde) => {
        topLevelIds.push(robj.StructureId);
        //get all parent first
        robj.RelatedResourcesReadStatus.map((readObj, rind) => {
          allReadRes.push(readObj);
        });

        if (robj.ParentStructureId == null || robj.ParentStructureId == "") {
          preSetData[0].book.options.push(robj);
        }
      });

      preSetData[0].book.options.map((oobj, ooindex) => {
        var tempSave = oobj.ChildStructure,
          newChil = [];
        tempSave.map((tc, tindex) => {
          if (topLevelIds.indexOf(tc.StructureId) != -1) {
            newChil.push(tc);
          }
        });
        oobj.ChildStructure = newChil;
        oobj.ChildStructure.map((cobj, cindex) => {
          if (
            callback
              .map((i) => {
                return i.ParentStructureId;
              })
              .indexOf(cobj.StructureId) != -1 &&
            topLevelIds.indexOf(oobj.StructureId) != -1
          ) {
            callback.map((i) => {
              if (
                i.ParentStructureId == cobj.StructureId &&
                topLevelIds.indexOf(i.StructureId) != -1
              ) {
                if (!cobj.ChildStructure) {
                  cobj.ChildStructure = [];
                  cobj.ChildStructure.push(i);
                } else {
                  cobj.ChildStructure.push(i);
                }
              }
            });
          }
        });
      });
      preSetData.map((i) => {
        i.book.options.sort((a, b) => {
          return parseInt(a.DisplaySequence) - parseInt(b.DisplaySequence);
        });
      });

      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          if (oobj.ParentStructureId == null || oobj.ParentStructureId == "") {
          }
        });
      });

      // if (preSetData[0].book.options.length > 0) {
      //   setActiveBookName(
      //     preSetData[0].book.options[0].StructureLang[
      //       preSetData[0].book.options[0].StructureLang[0].Lang == lang
      //         ? 0
      //         : 1
      //     ].StructureTitle
      //   );
      //   setActiveBook(preSetData[0].book.options[0].StructureId);

      // }

      var unitDataWithBook = [];
      if (type == "Book") {
        setActiveBook(selectedBookId);
      }

      var displaySeq = 1;
      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          var gradedResources = [];
          var viewResources = [];
          var readResources = [];
          if (oobj.ChildStructure.length > 0) {
            oobj.ChildStructure.map((childobj, oindex) => {
              let csres = callback.filter(
                (o) => o.StructureId == childobj.StructureId
              );

              var readResFilter = allReadRes.filter(
                (o) => o.StructureId == childobj.StructureId
              );
              readResFilter.map((readObj, iv) => {
                readResources.push(readObj);
              });

              if (csres[0].RelatedResources.TotalNumberOfRecord > 0) {
                csres[0].RelatedResources.Resources.map((resObj, oindex) => {
                  if (
                    resObj.ResourceType == "PRIZM" ||
                    resObj.ResourceType == "LEARNOSITY"
                  ) {
                    gradedResources.push(resObj);
                  } else {
                    viewResources.push(resObj);
                  }
                });
              }
            });
          }
          var summaryInfo = JSON.parse(oobj.SummaryData);
          var progressClassVal = "";
          var progressClassName = "large-progressbar-highest";
          if (summaryInfo.avgScore === null) {
          } else {
            progressClassVal = Math.round(summaryInfo.avgScore);
          }

          var resobj = {
            Name: oobj.StructureLang[oobj.StructureLang[0].Lang == lang ? 0 : 1]
              .StructureTitle,
            AvgScore: progressClassVal,
            Type: "Unit",
            StructureId: oobj.StructureId,
            RelatedResources: readResources,
            GradedResources: gradedResources,
            ViewResources: viewResources,
            ChildStructure: oobj.ChildStructure,
            DisplayLevel: displaySeq + 1,
          };
          unitDataWithBook.push(resobj);
        });
      });
      var gradeResourcesSummary = [];
      var viewResourcesSummary = [];
      var relatedResourcesSummary = [];
      var bookSummaryTotalavg = 0;
      var avgTotCount = 0;
      unitDataWithBook.map((oobj, oindex) => {
        if (oobj.AvgScore != "") {
          avgTotCount = avgTotCount + 1;
          bookSummaryTotalavg = bookSummaryTotalavg + oobj.AvgScore;
        }

        oobj.GradedResources.map((gobj, ind) => {
          gradeResourcesSummary.push(gobj);
        });
        oobj.ViewResources.map((vobj, ind) => {
          viewResourcesSummary.push(vobj);
        });
        oobj.RelatedResources.map((robj, ind) => {
          relatedResourcesSummary.push(robj);
        });
      });
      var bookSumAvg = "";
      if (avgTotCount > 0) {
        bookSumAvg = Math.round(bookSummaryTotalavg / avgTotCount);
      }
      var obj = {
        Name: "Book Summary",
        AvgScore: bookSumAvg,
        Type: "Book",
        StructureId: selectedBookId,
        GradedResources: gradeResourcesSummary,
        ViewResources: viewResourcesSummary,
        RelatedResources: relatedResourcesSummary,
        DisplayLevel: 1,
        ChildStructure: [],
      };
      unitDataWithBook.push(obj);
      unitDataWithBook.sort((a, b) => {
        return parseInt(a.DisplayLevel) - parseInt(b.DisplayLevel);
      });
      var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
      if (type == "Book") {
        let booksListT = unitDataWithBook.filter((o) => o.Type == "Book");
        handleTeacherReportData(booksListT, StudyGroupUserTemp);
      } else {
        let booksListT = unitDataWithBook.filter(
          (o) => o.StructureId == argfilter
        );
        handleTeacherReportData(booksListT, StudyGroupUserTemp);
      }
      setclassUsers(StudyGroupUserTemp);
      setresourceCenterStructureList(unitDataWithBook);
      setoriginData(callback);
      setUnitLoadingHere(false);
      setstudentLoading(false);

      // setstudyGroupData(mixedData);

      // initGuide(skipGuide, skipMenuSteps);
    });
  };

  const handleTeacherReportDataExcel = (
    dataLists,
    studentLists,
    type,
    argfilter,
    chapterNameArg
  ) => {
    let teacherReportTableData = [],
      attemptsData = [],
      questionCount = 0;

    if (studentLists === null || typeof studentLists == "undefined") {
      studentLists = [];
    }
    if (dataLists === null || typeof dataLists == "undefined") {
      dataLists = [];
    }
    var userInfo = [];
    var userDetails = [];
    var gradeResLen = 0;
    var viewResLen = 0;
    var totalAttemptView = 0;
    var totalAttempt = 0;
    var classSummary = {
      ChapterName: chapterNameArg,
      AttemptgradedRes: 0,
      GradeResLen: 0,
      Percentage: 0,
      TotalAttempt: 0,
      ResPercentage: 0,
      TotPercentage: 0,
      ViewResPercentage: 0,
      ViewTotPercentage: 0,
      AvgScore: 0,
    };

    var userInfoView = [];
    var userDetailsView = [];
    var allStudentScoreForAllResources = [];

    dataLists.map((allObj, index) => {
      if (viewResLen == 0)
        viewResLen = viewResLen + allObj.ViewResources.length;

      allObj.ViewResources.map((viewObj, vindex) => {
        if (allObj.RelatedResources) {
          //allObj
          var readResourceStatus = allObj.RelatedResources.filter(
            (o) => o.ResourceId == viewObj.ResourceId
          );

          readResourceStatus.map((readObj, aindex) => {
            if (userInfoView.includes(readObj.UserId)) {
              totalAttemptView = totalAttemptView + 1;
              var userIndex = userDetailsView.findIndex(
                (x) => x.UserId === readObj.UserId
              );
              userDetailsView[userIndex].AttemptedViewRes =
                userDetailsView[userIndex].AttemptedViewRes + 1;
              userDetailsView[userIndex].ViewResourcesLength = viewResLen;
              userDetailsView[userIndex].ViewAvgCount =
                userDetailsView[userIndex].ViewAvgCount + 1;
            } else {
              totalAttemptView = totalAttemptView + 1;
              userInfoView.push(readObj.UserId);
              userDetailsView.push({
                UserId: readObj.UserId,
                ViewResourcesLength: viewResLen,
                ViewAvgCount: 1,
                AttemptedViewRes: 1,
              });
            }
          });
        }
      });
    });
    dataLists.map((allObj, index) => {
      if (gradeResLen == 0)
        gradeResLen = gradeResLen + allObj.GradedResources.length;
      allObj.GradedResources.map((gradeObj, gindex) => {
        gradeObj.SelfLearnLastAttemptHistoryByStudyGroup.map((attm, aindex) => {
          var json_data = JSON.parse(attm.LearnositySessionRawResult);

          

          if (userInfo.includes(attm.UserDetail.UserId)) {
            totalAttempt = totalAttempt + 1;
            var userIndex = userDetails.findIndex(
              (x) => x.UserId === attm.UserDetail.UserId
            );
            userDetails[userIndex].AttemptedGradedRes =
              userDetails[userIndex].AttemptedGradedRes + 1;
            userDetails[userIndex].GradedResourcesLength = gradeResLen;
            userDetails[userIndex].AvgCount =
              userDetails[userIndex].AvgCount + 1;

 
              let scoreUderobj = allStudentScoreForAllResources.find(f=>f.userId==attm.UserDetail.UserId);
              let newScoreArr = scoreUderobj.score;
              if (gradeObj.ResourceType == "PRIZM") {
              userDetails[userIndex].Score =
                (json_data.score.raw / json_data.score.max) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
              newScoreArr.push( (json_data.score.raw / json_data.score.max) * 100)
              scoreUderobj.score = newScoreArr;
            } else {
              userDetails[userIndex].Score =
                (json_data.score / json_data.max_score) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
              newScoreArr.push( (json_data.score / json_data.max_score) * 100)
              scoreUderobj.score = newScoreArr;
            }
          } else {
            userInfo.push(attm.UserDetail.UserId);
            
            totalAttempt = totalAttempt + 1;
            if (gradeObj.ResourceType == "PRIZM") {
              var avgScore = (json_data.score.raw / json_data.score.max) * 100;
              var studentScoreobj = {"userId": attm.UserDetail.UserId, "score": [avgScore]}
              allStudentScoreForAllResources.push(studentScoreobj)
              userDetails.push({
                UserId: attm.UserDetail.UserId,
                UserDisplayName: attm.UserDetail.UserDisplayName,
                GradedResourcesLength: gradeResLen,
                AvgCount: 1,
                AttemptedGradedRes: 1,
                Score: avgScore,
                AvgScore: avgScore,
              });
            } else {
              //totalAttempt= totalAttempt+1;
              var avgScore = (json_data.score / json_data.max_score) * 100;
              var studentScoreobj = {"userId": attm.UserDetail.UserId, "score": [avgScore]}
              allStudentScoreForAllResources.push(studentScoreobj)
              userDetails.push({
                UserId: attm.UserDetail.UserId,
                UserDisplayName: attm.UserDetail.UserDisplayName,
                GradedResourcesLength: allObj.GradedResources.length,
                AttemptedGradedRes: 1,
                AvgCount: 1,
                Score: avgScore,
                AvgScore: avgScore,
              });
            }
          }

          
        });
      });
    });

    // var finalUserInfo = userDetails.map((item, i) => Object.assign({}, item, userDetailsView[i]));

    var finalUserInfo = [];

    const map = new Map();
    userDetails.forEach((item) => map.set(item.UserId, item));
    userDetailsView.forEach((item) =>
      map.set(item.UserId, { ...map.get(item.UserId), ...item })
    );
    finalUserInfo = Array.from(map.values());

    classSummary.GradeResLen = gradeResLen;
    classSummary.ViewResLen = viewResLen;
    classSummary.AttemptgradedRes = userInfo.length;
    classSummary.TotalAttempt = totalAttempt;
    if (studentLists.length > 0)
      // classSummary.ResPercentage = Math.round(
      //   totalAttempt / studentLists.length
      // );
      classSummary.ResPercentage = totalAttempt
        ? Math.round(totalAttempt / studentLists.length)
        : "";
    if (gradeResLen > 0)
      classSummary.TotPercentage = Math.round(
        (classSummary.ResPercentage / gradeResLen) * 100
      );
    if (studentLists.length > 0)
      // classSummary.ViewResPercentage = Math.round(
      //   totalAttemptView / studentLists.length
      // );
      classSummary.ViewResPercentage = totalAttemptView
        ? Math.round(totalAttemptView / studentLists.length)
        : "";
    if (viewResLen > 0)
      classSummary.ViewTotPercentage = Math.round(
        (classSummary.ViewResPercentage / viewResLen) * 100
      );
    // classSummary.AvgScore = Math.round(dataLists[0].AvgScore);
    classSummary.AvgScore = classSummary.TotalAttempt
      ? Math.round(dataLists[0].AvgScore)
      : "";

    if (studentLists.length > 0) {
      studentLists.map((obj, index) => {
        var matchId = finalUserInfo
          .map((i) => {
            return i.UserId;
          })
          .indexOf(obj.User.UserId);
        if (matchId != -1) {
          if (!finalUserInfo[matchId].GradedResourcesLength) {
            finalUserInfo[matchId].GradedResourcesLength = gradeResLen;
          }
          if (!finalUserInfo[matchId].UserDisplayName) {
            finalUserInfo[matchId].UserDisplayName = obj.User.UserDisplayName;
          }
          if (!finalUserInfo[matchId].UserId) {
            finalUserInfo[matchId].UserId = obj.User.UserId;
          }
          if (!finalUserInfo[matchId].AttemptedGradedRes) {
            finalUserInfo[matchId].AttemptedGradedRes = 0;
          }
          if (!finalUserInfo[matchId].ViewResourcesLength) {
            finalUserInfo[matchId].ViewResourcesLength = viewResLen;
          }
          if (!finalUserInfo[matchId].AttemptedViewRes) {
            finalUserInfo[matchId].AttemptedViewRes = 0;
          }
          if (!finalUserInfo[matchId].ViewAvgCount) {
            finalUserInfo[matchId].ViewAvgCount = "";
          }
          if (!finalUserInfo[matchId].AvgScore) {
            if (finalUserInfo[matchId].AvgScore != 0) {
              finalUserInfo[matchId].AvgScore = "";
            }
          }
          if (!finalUserInfo[matchId].Score) {
            if (finalUserInfo[matchId].Score != 0) {
              finalUserInfo[matchId].Score = "";
            }
          }

          teacherReportTableData.push(finalUserInfo[matchId]);
        } else {
          teacherReportTableData.push({
            UserId: obj.User.UserId,
            UserDisplayName: obj.User.UserDisplayName,
            GradedResourcesLength: gradeResLen,
            AttemptedGradedRes: 0,
            ViewResourcesLength: viewResLen,
            AttemptedViewRes: 0,
            ViewAvgCount: "",
            AvgScore: "",
            Score: "",
          });
        }
      });
    }

    var studentObj = [];
    teacherReportTableData.map((uobj, uindex) => {
      var avgScore = "";
      if (uobj.Score != "") {
        if (uobj.AvgCount > 0)
          avgScore = Math.round(uobj.Score / uobj.AvgCount);
      } else {
        if (uobj.Score == 0) {
          if (uobj.AvgCount > 0)
            avgScore = Math.round(uobj.Score / uobj.AvgCount);
        }
      }

      var avgProgress = "";
      var viewAvgProgress = "";
      if (uobj.GradedResourcesLength > 0)
        avgProgress = Math.round(
          (uobj.AttemptedGradedRes / uobj.GradedResourcesLength) * 100
        );

      if (uobj.ViewResourcesLength > 0)
        viewAvgProgress = Math.round(
          (uobj.AttemptedViewRes / uobj.ViewResourcesLength) * 100
        );

      // studentObj.push({
      //   id: uindex,
      //   student_number: uindex + 1,
      //   student_name: uobj.UserDisplayName[0].UserName,
      //   student_id: uobj.UserId,
      //   score: avgScore,
      //   average_progress: avgProgress,
      //   completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
      //   view_progress: viewAvgProgress,
      //   view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      // });

      studentObj.push({
        id: uindex,
        student_number: uindex + 1,
        student_id: uobj.UserId,
        student_name: uobj.UserDisplayName[0].UserName,
        score: avgScore,
        average_progress: uobj.AttemptedGradedRes ? avgProgress : "",
        completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
        view_progress: uobj.AttemptedViewRes ? viewAvgProgress : "",
        view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      });
    });

    var allObjSummary = {
      argType: type,
      UnitId: argfilter,
      studentObj: studentObj,
      classSummary: classSummary,
      ChapterName: chapterNameArg,
      allStudentScoreForAllResources: allStudentScoreForAllResources
    };

    // if (reportData.length > 0) {
    //   var reportDataTemp = [...reportData, allObjSummary];
    //   //reportDataTemp.push(allObjSummary)
    //   setreportData(reportDataTemp);
    // } else {
    //   var reportDataTemp = [];
    //   reportDataTemp.push(allObjSummary);
    //   //setreportData(reportDataTemp);
    // }

    return allObjSummary;
  };
  const handleTeacherReportData = (dataLists, studentLists) => {
    let teacherReportTableData = [],
      attemptsData = [],
      questionCount = 0;

    if (studentLists === null || typeof studentLists == "undefined") {
      studentLists = [];
    }
    if (dataLists === null || typeof dataLists == "undefined") {
      dataLists = [];
    }
    var userInfo = [];
    var userDetails = [];
    var gradeResLen = 0;
    var viewResLen = 0;
    var totalAttemptView = 0;
    var totalAttempt = 0;
    var classSummary = {
      AttemptgradedRes: 0,
      GradeResLen: 0,
      Percentage: 0,
      TotalAttempt: 0,
      ResPercentage: 0,
      TotPercentage: 0,
      ViewResPercentage: 0,
      ViewTotPercentage: 0,
      AvgScore: 0,
    };

    var userInfoView = [];
    var userDetailsView = [];

    dataLists.map((allObj, index) => {
      if (viewResLen == 0)
        viewResLen = viewResLen + allObj.ViewResources.length;

      allObj.ViewResources.map((viewObj, vindex) => {
        if (allObj.RelatedResources) {
          //allObj
          var readResourceStatus = allObj.RelatedResources.filter(
            (o) => o.ResourceId == viewObj.ResourceId
          );
          readResourceStatus.map((readObj, aindex) => {
            if (userInfoView.includes(readObj.UserId)) {
              totalAttemptView = totalAttemptView + 1;
              var userIndex = userDetailsView.findIndex(
                (x) => x.UserId === readObj.UserId
              );
              userDetailsView[userIndex].AttemptedViewRes =
                userDetailsView[userIndex].AttemptedViewRes + 1;
              userDetailsView[userIndex].ViewResourcesLength = viewResLen;
              userDetailsView[userIndex].ViewAvgCount =
                userDetailsView[userIndex].ViewAvgCount + 1;
            } else {
              totalAttemptView = totalAttemptView + 1;
              userInfoView.push(readObj.UserId);
              userDetailsView.push({
                UserId: readObj.UserId,
                ViewResourcesLength: viewResLen,
                ViewAvgCount: 1,
                AttemptedViewRes: 1,
              });
            }
          });
        }
      });
    });
    dataLists.map((allObj, index) => {
      if (gradeResLen == 0)
        gradeResLen = gradeResLen + allObj.GradedResources.length;
      allObj.GradedResources.map((gradeObj, gindex) => {
        gradeObj.SelfLearnLastAttemptHistoryByStudyGroup.map((attm, aindex) => {
          var json_data = JSON.parse(attm.LearnositySessionRawResult);
console.log("JSON", json_data);
          if (userInfo.includes(attm.UserDetail.UserId)) {
            totalAttempt = totalAttempt + 1;
            var userIndex = userDetails.findIndex(
              (x) => x.UserId === attm.UserDetail.UserId
            );
            userDetails[userIndex].AttemptedGradedRes =
              userDetails[userIndex].AttemptedGradedRes + 1;
            userDetails[userIndex].GradedResourcesLength = gradeResLen;
            userDetails[userIndex].AvgCount =
              userDetails[userIndex].AvgCount + 1;

            if (gradeObj.ResourceType == "PRIZM") {
              userDetails[userIndex].Score =
                (json_data.score.raw / json_data.score.max) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
            } else {
              userDetails[userIndex].Score =
                (json_data.score / json_data.max_score) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
            }
          } else {
            userInfo.push(attm.UserDetail.UserId);
            totalAttempt = totalAttempt + 1;
            if (gradeObj.ResourceType == "PRIZM") {
              var avgScore = (json_data.score.raw / json_data.score.max) * 100;

              userDetails.push({
                UserId: attm.UserDetail.UserId,
                UserDisplayName: attm.UserDetail.UserDisplayName,
                GradedResourcesLength: gradeResLen,
                AvgCount: 1,
                AttemptedGradedRes: 1,
                Score: avgScore,
                AvgScore: avgScore,
              });
            } else {
              if(json_data){
                var avgScore = (json_data.score / json_data.max_score) * 100;
                userDetails.push({
                  UserId: attm.UserDetail.UserId,
                  UserDisplayName: attm.UserDetail.UserDisplayName,
                  GradedResourcesLength: allObj.GradedResources.length,
                  AttemptedGradedRes: 1,
                  AvgCount: 1,
                  Score: avgScore,
                  AvgScore: avgScore,
                });
              }
              //totalAttempt= totalAttempt+1;
              
            }
          }
        });
      });
    });

    // var finalUserInfo = userDetails.map((item, i) => Object.assign({}, item, userDetailsView[i]));

    var finalUserInfo = [];

    const map = new Map();
    userDetails.forEach((item) => map.set(item.UserId, item));
    userDetailsView.forEach((item) =>
      map.set(item.UserId, { ...map.get(item.UserId), ...item })
    );
    finalUserInfo = Array.from(map.values());

    classSummary.GradeResLen = gradeResLen;
    classSummary.ViewResLen = viewResLen;
    classSummary.AttemptgradedRes = userInfo.length;
    classSummary.TotalAttempt = totalAttempt;

    if (studentLists.length > 0)
      classSummary.ResPercentage = Math.round(
        totalAttempt / studentLists.length
      );
    if (gradeResLen > 0)
      classSummary.TotPercentage = Math.round(
        (classSummary.ResPercentage / gradeResLen) * 100
      );
    if (studentLists.length > 0)
      classSummary.ViewResPercentage = Math.round(
        totalAttemptView / studentLists.length
      );
    if (viewResLen > 0)
      classSummary.ViewTotPercentage = Math.round(
        (classSummary.ViewResPercentage / viewResLen) * 100
      );
    classSummary.AvgScore = Math.round(dataLists[0].AvgScore);

    if (studentLists.length > 0) {
      studentLists.map((obj, index) => {
        var matchId = finalUserInfo
          .map((i) => {
            return i.UserId;
          })
          .indexOf(obj.User.UserId);
        if (matchId != -1) {
          if (!finalUserInfo[matchId].UserDisplayName) {
            finalUserInfo[matchId].UserDisplayName = obj.User.UserDisplayName;
          }
          if (!finalUserInfo[matchId].UserId) {
            finalUserInfo[matchId].UserDisplayName = obj.User.UserId;
          }
          if (!finalUserInfo[matchId].GradedResourcesLength) {
            finalUserInfo[matchId].GradedResourcesLength = gradeResLen;
          }
          if (!finalUserInfo[matchId].AttemptedGradedRes) {
            finalUserInfo[matchId].AttemptedGradedRes = 0;
          }
          if (!finalUserInfo[matchId].ViewResourcesLength) {
            finalUserInfo[matchId].ViewResourcesLength = viewResLen;
          }
          if (!finalUserInfo[matchId].AttemptedViewRes) {
            finalUserInfo[matchId].AttemptedViewRes = 0;
          }
          if (!finalUserInfo[matchId].ViewAvgCount) {
            finalUserInfo[matchId].ViewAvgCount = "";
          }
          if (!finalUserInfo[matchId].AvgScore) {
            if (finalUserInfo[matchId].AvgScore != 0) {
              finalUserInfo[matchId].AvgScore = "";
            }
          }
          if (!finalUserInfo[matchId].Score) {
            if (finalUserInfo[matchId].Score != 0) {
              finalUserInfo[matchId].Score = "";
            }
          }

          teacherReportTableData.push(finalUserInfo[matchId]);
        } else {
          teacherReportTableData.push({
            UserId: obj.User.UserId,
            UserDisplayName: obj.User.UserDisplayName,
            GradedResourcesLength: gradeResLen,
            AttemptedGradedRes: 0,
            ViewResourcesLength: viewResLen,
            AttemptedViewRes: 0,
            ViewAvgCount: "",
            AvgScore: "",
            Score: "",
          });
        }
      });
    }
    var studentObj = [];
    teacherReportTableData.map((uobj, uindex) => {
      var avgScore = "";
      if (uobj.Score != "") {
        if (uobj.AvgCount > 0)
          avgScore = Math.round(uobj.Score / uobj.AvgCount);
      }
      var avgProgress = "";
      var viewAvgProgress = "";
      if (uobj.GradedResourcesLength > 0)
        avgProgress = Math.round(
          (uobj.AttemptedGradedRes / uobj.GradedResourcesLength) * 100
        );

      if (uobj.ViewResourcesLength > 0)
        viewAvgProgress = Math.round(
          (uobj.AttemptedViewRes / uobj.ViewResourcesLength) * 100
        );

      // studentObj.push({
      //   id: uindex,
      //   student_number: uindex + 1,
      //   student_name: uobj.UserDisplayName[0].UserName,
      //   student_id: uobj.UserId,
      //   score: avgScore,
      //   average_progress: avgProgress,
      //   completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
      //   view_progress: viewAvgProgress,
      //   view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      // });
      studentObj.push({
        id: uindex,
        student_number: uindex + 1,
        student_id: uobj.UserId,
        student_name: uobj.UserDisplayName[0].UserName,
        score: avgScore,
        average_progress: uobj.AttemptedGradedRes ? avgProgress : "",
        completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
        view_progress: uobj.AttemptedViewRes ? viewAvgProgress : "",
        view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      });
    });
    setallStudentData(studentObj);
    setclassSummaryInfo(classSummary);
    setstudentLoading(false);
  };

  return unitLoadingHere && <div></div>;
};

export default DownloadReportExcel;
