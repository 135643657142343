import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoginHawkMutation from "./LoginHawkMutation";
import {
  isBrowser,
  isMobileOnly,
  isTablet,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge, isIOS, isAndroid,
} from "react-device-detect";
import GetAllSchoolYears from "../../Feature/Function/GetAllSchoolYears";
import GetUserData from "../../Feature/Function/GetUserData";
import { config } from "../../../Config/config";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import moment from "moment";
import useEncryption from "../../../hooks/useEncryption";

const AppSSOLoginGetEPSTokenQuery = graphql`
  query AppSSOLoginGetEPSTokenQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
      }
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const index = (props) => {
  // const [{}, dispatch] = useStateValue();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const {encrypt, decryptToLocal} = useEncryption();
  let query = useQuery();
  const location = useLocation();
  useEffect(() => {
    const action = query.get("action");
    let platform = platformChecking();
    let userKey = query.get("userKey");
    //lang handle [start]
    if (query.get("lang")) {
      dispatch({
        type: "SET_LANG",
        lang: query.get("lang"),
      });
    }

    const checkAppExists = async () => {
      if (!isIOS && !isAndroid) {
        return false;
      }
      const scheme = 'pechk://';
      const newLocation = new URL(scheme + location.pathname + '?' + query);
      window.location = newLocation;
      let result = await new Promise((resolve) => setTimeout(() => {
        if (document.visibilityState !== 'visible') {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 1000));
      return result;
    }
    //[end]

    switch (action) {
      case "OPEN_RESOURCE":
        console.log("open resources~");

        let resId = query.get("resid");
        let sessionId = query.get("sessionid");
        // resid and sesstion id checking
        if (resId && sessionId) {
          const temp = {
            SessionId: localStorage.getItem("SessionId"),
            accessToken: localStorage.getItem("accessToken"),
            ssoToken: localStorage.getItem("ssoToken"),
            userData: localStorage.getItem("userData"),
          };

          localStorage.removeItem("SessionId");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("ssoToken");
          localStorage.removeItem("userData");
          localStorage.setItem("SessionId", sessionId);
          getEpsToken(resId).then((data) => {
            //recover temp to local
            localStorage.setItem("SessionId", temp.SessionId);
            localStorage.setItem("accessToken", temp.accessToken);
            localStorage.setItem("ssoToken", temp.ssoToken);
            localStorage.setItem("userData", temp.userData);

            window.location.href =
              data.resourceSearch.Resources[0].ResourceAccessUrl;
          });
          // have them  call EPSquery as token redirect
        } else {
          // no them redirect /
          props.history.push("/");
        }

        break;
      case "GOOGLE_CLASS":
          console.log("[Entry] QR_CODE");
          console.log("pechk://"+location.pathname+query);
          let appExists;
          checkAppExists().then((result)=>{
            appExists = result;
            if (window.location.origin.startsWith("app://")) {
              console.log("here is iphone app");
            }
            if (appExists) {
              return;
            } else {
              let gresId = query.get("resId");
              let bookseriesId = query.get("BOOK_SERIES");
              let bookId = query.get("BOOK");
              let strucId = query.get("STRUCTURE_ID");
              if(!strucId){
                strucId = 0;
              }
              if (!gresId) {
                console.log("[Entry] resId  is missing!");
                if (userData) {
                  return props.history.push("/");
                } else {
                  return props.history.push("/");
                }
              }else {
                if (userData) {
                  let _action = {
                    type: "GOOGLE_CLASS",
                    data: {
                      gresId,
                      bookseriesId,
                      bookId,
                      strucId
                    },
                  };
                  localStorage.setItem("_action", JSON.stringify(_action));
                  return props.history.push("/");
                } else {
                  let _action = {
                    type: "GOOGLE_CLASS",
                    data: {
                      gresId,
                      bookseriesId,
                      bookId,
                      strucId
                    },
                  };
                  localStorage.setItem("_action", JSON.stringify(_action));
                  var IESlang = "en-US";
                  var IESlang2 = "en-US";

                  if (localStorage.getItem("i18nextLng")) {
                    IESlang2 = localStorage.getItem("i18nextLng");
                    if (localStorage.getItem("i18nextLng") != "en-US") {
                      IESlang = "zh_TW";
                    }
                  }

                  let currentHost = "https:%2F%2F" + window.location.hostname;
                  let IESurl =
                      config.IESURL +
                      "?lang=" +
                      IESlang +
                      "&back_to=" +
                      config.IESBACKURL +
                      "%3Flang%3D" +
                      IESlang2 +
                      "%26back_to%3D" +
                      currentHost;
                  if (window.cordova) {
                    props.history.push("/login");
                  } else {
                    window.open(IESurl, "_self");
                  }
                }
              }
              }
          })

 // alert("ccc")
          break;
      case "QR_CODE":
        console.log("[Entry] QR_CODE");

        let resourcesId = query.get("resId");
        let pin = query.get("pin");
        let attSession = query.get("attSession");

        if (!resourcesId) {
          console.log("[Entry] resId  is missing!");
          if (userData) {
            return props.history.push("/");
          } else {
            return props.history.push("/");
          }
        } else {
          if (!pin && !userKey) {
            // if no userKey, pin is require
            console.log("[Entry] no userKey and pin is missing!");
            if (userData) {
              return props.history.push("/");
            } else {
              return props.history.push("/");
            }
          }
        }

        if (!userKey) {
          if (userData) {
            let _action = {
              type: "QR_CODE_OPEN_RESOURCE",
              data: {
                pin,
                resourcesId,
                attSession,
              },
            };
            localStorage.setItem("_action", JSON.stringify(_action));
            return props.history.push("/");
          } else {
            //if no sso userkey save the params and redirct to login page
            let _action = {
              type: "QR_CODE_OPEN_RESOURCE",
              data: {
                pin,
                resourcesId,
                attSession,
              },
            };
            localStorage.setItem("_action", JSON.stringify(_action));
            return props.history.push("/");
          }
        } else {
          //if have sso userkey  login and open the resource
          //login...
          let _action = {
            type: "QR_CODE_OPEN_RESOURCE",
            data: {
              pin,
              resourcesId,
              attSession,
            },
          };
          localStorage.setItem("_action", JSON.stringify(_action));
          const callback = (result) => {
            console.log("AppSSO result", result);
            if (result.loginHawk != null) {
              getUserInfo(result.loginHawk);
            } else {
              console.log("[AppSSO] Fail to App SSO, Redirct...");
              handleFaildAppSSO();
            }
          };
          LoginHawkMutation(userKey, platform, callback);
        }

        break;
      default:
        if (userKey) {
          console.log(userKey);
        } else {
          console.log("[AppSSO] Fail to App SSO, userKey missing");
          return handleFaildAppSSO();
        }
        console.log("[Start] AppSSO");

        console.log("[Login with platform]", platform);

        const callback = (result) => {
          console.log("AppSSO result", result);
          if (result.loginHawk != null) {
            getUserInfo(result.loginHawk);
          } else {
            console.log("[AppSSO] Fail to App SSO, Redirct...");
            handleFaildAppSSO();
          }
        };
        LoginHawkMutation(userKey, platform, callback);
        break;
    }
  }, []);

  const platformChecking = () => {
    let platform = "WEB"; //WEB MOBILE TABLET
    if (isMobileOnly || isTablet) {
      if (isMobileOnly && window.cordova) {
        platform = "MOBILE";
      }

      if (isTablet && window.cordova) {
        platform = "TABLET";
      }
    } else {
      if (
        !window.cordova &&
        (isBrowser ||
          isChrome ||
          isFirefox ||
          isSafari ||
          isOpera ||
          isIE ||
          isEdge ||
          navigator.userAgent.match(
            /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
          ))
      ) {
        platform = "WEB";
      }
    }
    return platform;
  };

  const handleFaildAppSSO = () => {
    console.log("[AppSSO] no Auth , Redirct login");
    localStorage.removeItem("dp_");
    dispatch({
      type: "SET_USER",
      user: null,
    });
    return props.history.push("/");
  };

  const getEpsToken = (resId) => {
    var value = {
      ResourceId: resId,
    };
    return fetchQuery(environment, AppSSOLoginGetEPSTokenQuery, value);
  };

  const getUserInfo = (loginData) => {
    // props.loadingStyle && props.loadingStyle(true);
    console.log("[Start get user info]", loginData);
    localStorage.setItem("userData", JSON.stringify(loginData.User));
    localStorage.setItem("SessionId", loginData.SessionId);
    localStorage.setItem("accessToken", loginData.SAMAssessToken);
    localStorage.setItem(
      "ssoToken",
      loginData.SAMSsoToken ? loginData.SAMSsoToken : null
    );
    localStorage.setItem("__from", "l");

    var isAbleAccountType = false,
      sindex;
    // loginData.User.UserType.map((obj, index) => {
    //   if (config.userTypeList.indexOf(obj.RoleId) != -1 && !isAbleAccountType) {
    //     isAbleAccountType = true;
    //     sindex = index;
    //   }
    // });

    if (loginData.User.UserType == "T") {
      localStorage.setItem("role", "teacher");
      isAbleAccountType = true;
    } else if (loginData.User.UserType == "S") {
      localStorage.setItem("role", "student");
      isAbleAccountType = true;
    } else {
      localStorage.setItem("role", null);
    }
    if (isAbleAccountType) {
      // if (loginData.User.UserType[sindex].RoleId == "2") {
      //   localStorage.setItem("role", "teacher");
      // } else if (loginData.User.UserType[sindex].RoleId == "1") {
      //   localStorage.setItem("role", "student");
      // } else {
      //   localStorage.setItem("role", null);
      // }
    } else {
      window.alert("Login UserType Error!");

      localStorage.removeItem("dp_URL");
      dispatch({
        type: "SET_USER",
        user: null,
      });
      setTimeout(() => props.history.push("/"), 100);
      return;
    }

    // console.log("[App] Have Auth");

    gaTrack("set", "dimension1", loginData.User.UserType);
    gaTrack(
      "send",
      "event",
      "Login",
      "Success",
      "Role:" + localStorage.getItem("role")
    );

    var haveEffective = false;
    GetAllSchoolYears((list) => {
      var schoolYearList = list;
      console.log(list);
      schoolYearList.map((obj, index) => {
        if (obj.IsEffective == "true") {
          haveEffective = true;
          localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
          dispatch({
            type: "SET_SCHOOL_YEAR",
            effectiveSchoolYear: obj,
          });
        }
      });

      if (!haveEffective) {
        localStorage.setItem(
          "effectiveSchoolYear",
          JSON.stringify(schoolYearList[0])
        );
        dispatch({
          type: "SET_SCHOOL_YEAR",
          effectiveSchoolYear: schoolYearList[0],
        });
      }

      GetUserData((userData) => {
        localStorage.setItem("userData", userData);
        // localStorage.setItem("dp_", true);
        var userObj = JSON.parse(userData);
        let defaultSchoolYear = userObj.AdoptedSchoolYear.sort(function (a, b) {
          return (
            new moment(b.EffectiveStartDate) - new moment(a.EffectiveStartDate)
          );
        })[0];
        dispatch({
          type: "SET_COSTOM_SCHOOL_YEAR",
          selectedSchoolYear: defaultSchoolYear || schoolYearList[0],
        });
        // localStorage.setItem(
        //   "selectedSchoolYear",
        //   JSON.stringify(defaultSchoolYear)
        // );

        dispatch({
          type: "SET_USER",
          user: userObj,
        });
        dispatch({
          type: "SET_USER_PREFERENCES",
          user_preferences: userObj.Preferences,
        });
        gaTrack(
          "set",
          "dimension4",
          userObj.School ? userObj.School.SchoolCode : "NoData"
        );
        //check match two account for ios review
        if (
          (userObj.UserName == "tp16992702" ||
            userObj.UserName == "sp16992703") &&
          window.cordova
        ) {
          props.history.push("/m/studynotes");
        } else {
          console.log(userObj);
          if (userObj.UserType == "T") {

            var listArray = [];
            userObj.School.SchoolAdoptedBookSeries.map((obj, index) => {
              if (obj.IngestionId == null || obj.IngestionId == "") return;
              listArray.push(obj);
            });
           
            if(userObj.AdoptedSchoolYear.length>0 && listArray.length > 0){
              props.history.push("/m");
              // if (
              //   userObj.PreferencesWithYear.map((obj) => {
              //     return obj.PreferenceName;
              //   }).indexOf("ONBOARDING_SKIP") != -1
              // ) {
              //   if (
              //     userObj.PreferencesWithYear[
              //       userObj.PreferencesWithYear.map((obj) => {
              //         return obj.PreferenceName;
              //       }).indexOf("ONBOARDING_SKIP")
              //     ].PreferenceValue == "true"
              //   ) {
              //     props.history.push("/m");
              //   } else {
              //     props.history.push("/onboarding");
              //   }
              // } else {
              //       props.history.push("/onboarding");
              //  }
  
            }else{
              props.history.push("/m");
            }
          } else {
            props.history.push("/m");
          }
        }
      });
    });
  };

  return <div>App Loading...</div>;
};

export default index;
