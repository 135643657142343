import GetSmartClassToken from "../../Components/Feature/Function/GetSmartClassToken";
import GetHawkKey from "../../Components/Feature/Function/GetHawkKey";
import useInAppBrowser from "../../hooks/useInAppBrowser";

const checkURLpramas = (url) => {
  console.log(url);
  console.log(url.split("?"));
  var arr = url.split("?");
  if (arr.length > 1 && arr[1] !== "") {
    return true;
  } else {
    return false;
  }
};

const handleSmartClass = (dataObj, device) => {
  var userData = JSON.parse(localStorage.getItem("userData"));
  GetSmartClassToken().then((data) => {
    console.log(data);
    if (data.smartClassSSOToken) {
      var token = data.smartClassSSOToken;
      //get token
      if (dataObj.platform == "SCT") {
        if (device == "ANDROID") {
          window.plugins.webintent.startActivity(
            {
              action: "com.nd.ppt101.pearson.THIRD_AUTO_LOGIN",
              extras: {
                open_id: userData.UserName,
                org: "V_ORG_K12_AWS",
                plat: "EBOOK",
                token: token,
              },
            },
            function () {
              console.log("[App] Open the app path with webintent done!");
            },
            function () {
              alert("Failed to open URL via Android Intent.");
              console.log("Failed to open URL via Android Intent. URL: ");
            }
          );
        }
        if (device == "IOS") {
          var openUrlPath =
            Link +
            "?type=1&open_id=" +
            userData.UserName +
            "&org=V_ORG_K12_AWS&plat=EBOOK" +
            "&token=" +
            token;
          console.log("[App] Open the app path :", openUrlPath);
          window.open(openUrlPath, "_system");
        }
      }
      if (dataObj.platform == "SCS") {
        var openUrlPath =
          dataObj.url +
          "startup?type=1&open_id=" +
          userData.UserName +
          "&org=V_ORG_K12_AWS&plat=EBOOK" +
          "&token=" +
          token;
        console.log("[App] Open the app path :", openUrlPath);
        window.open(openUrlPath, "_system");
      }
    } else {
      window.alert("Error, Can not open the app.");
      console.log("[App] smart class token null");
    }
  });
};

const HandleSSOtoOtherPlatform = (device, dataObj, success) => {
  const { systemBrowserOpenLink } = useInAppBrowser();
  //device  ANDROID / IOS / WEB
  switch (device) {
    case "ANDROID":
      switch (dataObj.platform) {
        case "ebook":
          console.log("[Tool SSO] ebook");
          return GetHawkKey({ platform: "EBook" }).then((data, error) => {
            console.log("[Tool SSO] getKey", data);
            let openUrlPath =
              dataObj.url +
              "?userKey=" +
              encodeURIComponent(data.hawkKey) +
              "&sender=LongmanPlus";
            if (checkURLpramas(dataObj.url)) {
              openUrlPath =
                dataObj.url +
                "&userKey=" +
                encodeURIComponent(data.hawkKey) +
                "&sender=LongmanPlus";
            }
            console.log("[Tool SSO] Final path:", openUrlPath);
            systemBrowserOpenLink(openUrlPath);
          });
        case "SCT":
        case "SCS":
          handleSmartClass(dataObj, device);
          break;
        case "web":
        default:
          console.log("[Tool SSO] Web MasterWebsite");
          return GetHawkKey({ platform: "MasterWebsite" }).then(
            (data, error) => {
              console.log("[Tool SSO] getKey", data);
              var openUrlPath =
                dataObj.url + "?userKey=" + encodeURIComponent(data.hawkKey);
              if (checkURLpramas(dataObj.url)) {
                openUrlPath =
                  dataObj.url + "&userKey=" + encodeURIComponent(data.hawkKey);
              }
              console.log("[Tool SSO] Final path:", openUrlPath);
              systemBrowserOpenLink(openUrlPath);
            }
          );
      }

      break;
    case "IOS":
      switch (dataObj.platform) {
        case "ebook":
          console.log("[Tool SSO] ebook");
          return GetHawkKey({ platform: "EBook" }).then((data, error) => {
            console.log("[Tool SSO] getKey", data);
            var openUrlPath =
              dataObj.url +
              "?userKey=" +
              encodeURIComponent(data.hawkKey) +
              "&sender=LongmanPlus";
            if (checkURLpramas(dataObj.url)) {
              openUrlPath =
                dataObj.url +
                "&userKey=" +
                encodeURIComponent(data.hawkKey) +
                "&sender=LongmanPlus";
            }
            console.log("[Tool SSO] Final path:", openUrlPath);
            systemBrowserOpenLink(openUrlPath);
          });
        case "SCT":
        case "SCS":
          handleSmartClass(dataObj, device);
          break;
        case "web":
        default:
          console.log("[Tool SSO] Web MasterWebsite");
          return GetHawkKey({ platform: "MasterWebsite" }).then(
            (data, error) => {
              console.log("[Tool SSO] getKey", data);
              var openUrlPath =
                dataObj.url + "?userKey=" + encodeURIComponent(data.hawkKey);
              if (checkURLpramas(dataObj.url)) {
                openUrlPath =
                  dataObj.url + "&userKey=" + encodeURIComponent(data.hawkKey);
              }
              console.log("[Tool SSO] Final path:", openUrlPath);
              systemBrowserOpenLink(openUrlPath);
            }
          );
      }
      break;
    case "WEB":
    default:
      switch (dataObj.platform) {
        case "ebook":
          console.log("[Tool SSO] ebook");
          return GetHawkKey({ platform: "EBook" }).then((data, error) => {
            console.log("[Tool SSO] getKey", data);
            var openUrlPath =
              dataObj.url +
              "?userKey=" +
              encodeURIComponent(data.hawkKey) +
              "&sender=LongmanPlus";
            if (checkURLpramas(dataObj.url)) {
              openUrlPath =
                dataObj.url +
                "&userKey=" +
                encodeURIComponent(data.hawkKey) +
                "&sender=LongmanPlus";
            }
            console.log("[Tool SSO] Final path:", openUrlPath);
            console.log("1");
            window.open(
              openUrlPath,
              dataObj.isDefaultBrowser ? "_system" : "_blank"
            );
          });
        case "DWB":
          return GetHawkKey({ platform: "DWB" }).then((data, error) => {
            var webLinkToken = dataObj.url.replace(
              "{token}",
              encodeURIComponent(data.hawkKey)
            );
            var openUrlPath = webLinkToken.replace(
              "{schoolcode}",
              JSON.parse(localStorage.getItem("userData")).School.SchoolCode
            );

            // window.open(openUrlPath, "_blank");
            console.log("[Useful tools] DWB WebURL: " + openUrlPath);
            console.log("2")
            window.open(
              openUrlPath,
              dataObj.isDefaultBrowser ? "_system" : "_blank"
            );
          });

        case "web":
        default:
          console.log("[Tool SSO] Web MasterWebsite");
          return GetHawkKey({ platform: "MasterWebsite" }).then(
            (data, error) => {
              console.log("[Tool SSO] getKey", data);
              var openUrlPath =
                dataObj.url + "?userKey=" + encodeURIComponent(data.hawkKey);
              console.log(dataObj);
              if (checkURLpramas(dataObj.url)) {
                openUrlPath =
                  dataObj.url + "&userKey=" + encodeURIComponent(data.hawkKey);
              }
              console.log("[Tool SSO] Final path:", openUrlPath);
              systemBrowserOpenLink(openUrlPath);
            }
          );
        // default:
        //   var openUrlPath =
        //     dataObj.url +
        //     "?token=" +
        //     localStorage.getItem("ssoToken") +
        //     (localStorage.getItem("__from") == "p"
        //       ? "&longmanpluslogintype=p"
        //       : "&longmanpluslogintype=l");
        //   console.log(checkURLpramas(dataObj.url));
        //   if (checkURLpramas(dataObj.url)) {
        //     openUrlPath =
        //       dataObj.url +
        //       "&token=" +
        //       localStorage.getItem("ssoToken") +
        //       (localStorage.getItem("__from") == "p"
        //         ? "&longmanpluslogintype=p"
        //         : "&longmanpluslogintype=l");
        //   }

        //   console.log("[App] WebURL: " + openUrlPath);
        //   window.open(
        //     openUrlPath,
        //     dataObj.isDefaultBrowser ? "_system" : "_blank"
        //   );
        //   break;
      }
      break;
  }
};

export default HandleSSOtoOtherPlatform;
