/**
 * @flow
 * @relayHash 1556a38bdbe01eb39c12c4b3205f21bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignedStudentsInfoGetNameQueryVariables = {|
  StudyGroupId: string
|};
export type AssignedStudentsInfoGetNameQueryResponse = {|
  +studyGroup: ?{|
    +StudyGroupName: ?$ReadOnlyArray<?{|
      +StudyGroupName: ?string,
      +Lang: ?string,
    |}>
  |}
|};
export type AssignedStudentsInfoGetNameQuery = {|
  variables: AssignedStudentsInfoGetNameQueryVariables,
  response: AssignedStudentsInfoGetNameQueryResponse,
|};
*/


/*
query AssignedStudentsInfoGetNameQuery(
  $StudyGroupId: String!
) {
  studyGroup(StudyGroupId: $StudyGroupId) {
    StudyGroupName {
      StudyGroupName
      Lang
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String!"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AssignedStudentsInfoGetNameQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AssignedStudentsInfoGetNameQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AssignedStudentsInfoGetNameQuery",
    "id": null,
    "text": "query AssignedStudentsInfoGetNameQuery(\n  $StudyGroupId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupName {\n      StudyGroupName\n      Lang\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a765595afa9fbdc7720771930ff74b5';
module.exports = node;
