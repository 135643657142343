import XLSX from "xlsx";
const ExportDataToXlsx = (action, dataObject) => {
  var filename;
  var ws = [],
    wb = XLSX.utils.book_new();
  switch (action) {
    case "AssessmentReports":
      filename =
        dataObject[0][1] +
        "_" +
        new Date().toJSON().slice(0, 10).replace(/-/g, "") +
        ".xlsx";
      var ws = XLSX.utils.aoa_to_sheet(dataObject);
      const questionCount = dataObject[5][1];
      let _temp = [];
      for (let i = 0; i <= questionCount; i++) {
        _temp.push({ wch: 12 });
      }
      var wscols = [
        { wch: 12 },
        { wch: 16 },
        { wch: 10 },
        { wch: 18 },
        { wch: 7 },
      ];
      console.log(_temp);
      const final = wscols.concat(_temp);
      ws["!cols"] = final;

      XLSX.utils.book_append_sheet(
        wb,
        ws,
        dataObject[0][1].length > 31 ? "assessment" : dataObject[0][1]
      );
      XLSX.writeFile(wb, filename);
      console.log("[Success Submit] " + filename);
      break;
  }
};

export default ExportDataToXlsx;
