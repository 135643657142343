/**
 * @flow
 * @relayHash c1d5d39395dcfc201f09b507a6478a41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MySchoolBookSeriesDataStudentCheckingQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type MySchoolBookSeriesDataStudentCheckingQueryResponse = {|
  +user: ?{|
    +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
      +StudyGroup: ?{|
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IsB2CBookSeries: ?boolean,
          +IngestionId: ?string,
        |}
      |}
    |}>
  |}
|};
export type MySchoolBookSeriesDataStudentCheckingQuery = {|
  variables: MySchoolBookSeriesDataStudentCheckingQueryVariables,
  response: MySchoolBookSeriesDataStudentCheckingQueryResponse,
|};
*/


/*
query MySchoolBookSeriesDataStudentCheckingQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
      StudyGroup {
        BookSeries {
          BookSeriesId
          IsB2CBookSeries
          IngestionId
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsB2CBookSeries",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MySchoolBookSeriesDataStudentCheckingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "BelongsToStudyGroup",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MySchoolBookSeriesDataStudentCheckingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "BelongsToStudyGroup",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroup",
                "storageKey": null,
                "args": null,
                "concreteType": "StudyGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MySchoolBookSeriesDataStudentCheckingQuery",
    "id": null,
    "text": "query MySchoolBookSeriesDataStudentCheckingQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n      StudyGroup {\n        BookSeries {\n          BookSeriesId\n          IsB2CBookSeries\n          IngestionId\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00025c2e9341f24645df0bbdbd63bb11';
module.exports = node;
