/**
 * @flow
 * @relayHash 4aa102b59ab48d3e61b39312c23cdfe6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteNotificationMutationVariables = {|
  SharingId: string
|};
export type DeleteNotificationMutationResponse = {|
  +deleteUserNotification: ?boolean
|};
export type DeleteNotificationMutation = {|
  variables: DeleteNotificationMutationVariables,
  response: DeleteNotificationMutationResponse,
|};
*/


/*
mutation DeleteNotificationMutation(
  $SharingId: String!
) {
  deleteUserNotification(SharingId: $SharingId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SharingId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "deleteUserNotification",
    "args": [
      {
        "kind": "Variable",
        "name": "SharingId",
        "variableName": "SharingId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteNotificationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteNotificationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteNotificationMutation",
    "id": null,
    "text": "mutation DeleteNotificationMutation(\n  $SharingId: String!\n) {\n  deleteUserNotification(SharingId: $SharingId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee48e4897f7c0723d588caaeed479c6b';
module.exports = node;
