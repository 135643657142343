/**
 * @flow
 * @relayHash 0e0b2a99b5927293467ec7de54e74332
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OnBoardingGetSchoolBookSeriesListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type OnBoardingGetSchoolBookSeriesListQueryResponse = {|
  +user: ?{|
    +School: ?{|
      +SchoolAdoptedBookSeries: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +LeapBookSeriesId: ?string,
        +IngestionId: ?string,
        +BookSeriesName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookSeriesName: ?string,
        |}>,
        +Books: ?$ReadOnlyArray<?{|
          +BookName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +BookName: ?string,
          |}>,
          +PearsonLevelId: ?string,
          +PearsonLevel: ?{|
            +PearsonLevelId: ?string,
            +LevelName: ?$ReadOnlyArray<?{|
              +PearsonLevelId: ?string,
              +Lang: ?string,
              +LevelName: ?string,
            |}>,
          |},
        |}>,
        +DisplaySequence: ?number,
      |}>
    |}
  |}
|};
export type OnBoardingGetSchoolBookSeriesListQuery = {|
  variables: OnBoardingGetSchoolBookSeriesListQueryVariables,
  response: OnBoardingGetSchoolBookSeriesListQueryResponse,
|};
*/


/*
query OnBoardingGetSchoolBookSeriesListQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    School {
      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
        BookSeriesId
        LeapBookSeriesId
        IngestionId
        BookSeriesName {
          Lang
          BookSeriesName
        }
        Books {
          BookName {
            Lang
            BookName
          }
          PearsonLevelId
          PearsonLevel {
            PearsonLevelId
            LevelName {
              PearsonLevelId
              Lang
              LevelName
            }
            id
          }
          id
        }
        DisplaySequence
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LeapBookSeriesId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PearsonLevelId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LevelName",
  "storageKey": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LevelName",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OnBoardingGetSchoolBookSeriesListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SchoolAdoptedBookSeries",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "bookSeries",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Books",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "book",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "PearsonLevel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "pearsonLevel",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OnBoardingGetSchoolBookSeriesListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SchoolAdoptedBookSeries",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "bookSeries",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Books",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "book",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "PearsonLevel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "pearsonLevel",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v12/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v12/*: any*/)
            ]
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OnBoardingGetSchoolBookSeriesListQuery",
    "id": null,
    "text": "query OnBoardingGetSchoolBookSeriesListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    School {\n      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {\n        BookSeriesId\n        LeapBookSeriesId\n        IngestionId\n        BookSeriesName {\n          Lang\n          BookSeriesName\n        }\n        Books {\n          BookName {\n            Lang\n            BookName\n          }\n          PearsonLevelId\n          PearsonLevel {\n            PearsonLevelId\n            LevelName {\n              PearsonLevelId\n              Lang\n              LevelName\n            }\n            id\n          }\n          id\n        }\n        DisplaySequence\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c3e22213060f52d51590247d1d5e7a66';
module.exports = node;
