/**
 * @flow
 * @relayHash 3022cad24bd6f9a889118f62abffb1f2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewStudentAttemptHistoryPopUpGetStudentReportQueryVariables = {|
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type ViewStudentAttemptHistoryPopUpGetStudentReportQueryResponse = {|
  +studyGroup: ?{|
    +StudyGroupUser: ?$ReadOnlyArray<?{|
      +ClassNumber: ?string,
      +User: ?{|
        +UserId: ?string,
        +UserDisplayName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +UserName: ?string,
        |}>,
      |},
    |}>
  |}
|};
export type ViewStudentAttemptHistoryPopUpGetStudentReportQuery = {|
  variables: ViewStudentAttemptHistoryPopUpGetStudentReportQueryVariables,
  response: ViewStudentAttemptHistoryPopUpGetStudentReportQueryResponse,
|};
*/


/*
query ViewStudentAttemptHistoryPopUpGetStudentReportQuery(
  $StudyGroupId: String!
  $SchoolYearId: String!
) {
  studyGroup(StudyGroupId: $StudyGroupId) {
    StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
      ClassNumber
      User {
        UserId
        UserDisplayName {
          Lang
          UserName
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  },
  {
    "kind": "Literal",
    "name": "UserType",
    "value": "S",
    "type": "UserTypeEnumType!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ClassNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewStudentAttemptHistoryPopUpGetStudentReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupUser",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewStudentAttemptHistoryPopUpGetStudentReportQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupUser",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "User",
                "storageKey": null,
                "args": null,
                "concreteType": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewStudentAttemptHistoryPopUpGetStudentReportQuery",
    "id": null,
    "text": "query ViewStudentAttemptHistoryPopUpGetStudentReportQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {\n      ClassNumber\n      User {\n        UserId\n        UserDisplayName {\n          Lang\n          UserName\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '15b1b9fa6bb6410d538640f3c5948ea5';
module.exports = node;
