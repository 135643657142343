import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";

import AssessmentInfo from "./B2CBody/AssessmentInfo";
import ResultAndAnswer from "./B2CBody/ResultAndAnswer";
import AnalysisAndSuggestion from "./B2CBody/AnalysisAndSuggestion";
// import AssesmentGroupIconActive from "../../../assets/img/Assemment-Group-Icon-InActive.svg";
// import AssesmentGroupIconNotActive from "../../../assets/img/Assemment-Group-Icon-Active.svg";
// import ResultAnswerIconActive from "../../../assets/img/Result-Answer-Icon-InActive.svg";
// import ResultAnswerIconNotActive from "../../../assets/img/Result-Answer-Icon-Active.svg";
// import AnalysisAndSuggestionIconActive from "../../../assets/img/group-27.svg";
// import AnalysisAndSuggestionIconNotActive from "../../../assets/img/group-27-copy.svg";

import "./index.scss";

const B2Ccontent = (props) => {
  const { bookData, resourceData, t, isReadyToRender, B2C } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [tabState, settabState] = useState(null);

  useEffect(() => {
    //menu bottom  border color
    checkTabStatus();
    console.log("...........................");
  }, []);

  const checkTabStatus = async () => {
    const path = props.location.pathname;
    switch (path) {
      case props.match.url + "/start":
        settabState("assessment");
        break;
      case props.match.url + "/result":
        settabState("result");
        break;
      case props.match.url + "/as":
        settabState("as");
        break;
      default:
        settabState("assessment");
        break;
    }
  };

  return (
    <div className="card ani-card learnosity" id="b2c">
      <div id="b2c" className={`sub-menu ${tabState}`}>
        <div className="menu-title">
          {
            resourceData.ResourceLangs[
              resourceData.ResourceLangs[0].Lang == lang ? 0 : 1
            ].ResourceName
          }
        </div>
        <div className={`menu-list`}>
          <ul>
            <li className="tab">
              <NavLink
                id={"assessment_tab"}
                to={props.match.url + "/start"}
                className="tab-item assessment"
                onClick={() => {
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Analysis & Follow up, Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("report");
                }}
              >
                <div className="tab-body">
                  <span
                    className={`icon-tab-icon-assessment ${
                      tabState == "assessment" ? "active" : ""
                    }`}
                  ></span>
                  {/* <icon
                    // src={
                    //   tabState == "assessment"
                    //     ? AssesmentGroupIconActive
                    //     : AssesmentGroupIconNotActive
                    // }
                  /> */}
                  {t("Assessment")}
                </div>
              </NavLink>
            </li>
            <li className="tab">
              <NavLink
                id={"result_tab"}
                to={props.match.url + "/result"}
                className="tab-item result"
                onClick={() => {
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Result & Answer, Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("report");
                }}
              >
                <div className="tab-body">
                  <span
                    className={`icon-tab-icon-result ${
                      tabState == "result" ? "active" : ""
                    }`}
                  ></span>
                  {/* <img
                    src={
                      tabState == "result"
                        ? ResultAnswerIconActive
                        : ResultAnswerIconNotActive
                    }
                  /> */}
                  {t("ResultAndAnswer")}
                </div>
              </NavLink>
            </li>
            <li className="tab">
              <NavLink
                id={"as_tab"}
                to={props.match.url + "/as"}
                className="tab-item as"
                onClick={() => {
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:AnalysisAndSuggestion, " +
                      "Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("as");
                }}
              >
                <div className="tab-body">
                  <span
                    className={`icon-tab-icon-analysis ${
                      tabState == "as" ? "active" : ""
                    }`}
                  ></span>
                  {/* <img
                    src={
                      tabState == "as"
                        ? AnalysisAndSuggestionIconActive
                        : AnalysisAndSuggestionIconNotActive
                    }
                  /> */}
                  {t("AnalysisAndSuggestion")}
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={`body ${tabState}`}>
        {tabState && (
          <Switch>
            <Route
              exact
              path="/c/myresview/:id/:book/assessments/:subid/:resId/as"
              component={(p) => (
                <AnalysisAndSuggestion
                  {...p}
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  resourceData={resourceData}
                  B2C={B2C}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
            <Route
              exact
              path="/c/myresview/:id/:book/assessments/:subid/:resId/result"
              component={(p) => (
                <ResultAndAnswer
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  resourceData={resourceData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />

            <Route
              exact
              path="/c/myresview/:id/:book/assessments/:subid/:resId/start"
              component={(p) => (
                <AssessmentInfo
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  resourceData={resourceData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
          </Switch>
        )}
      </div>
    </div>
  );
};

export default B2Ccontent;
