import AssessmentGetLearnosityObjectMutation
    from "../Components/PopUpView/Page/AssessmentLayout/AssessmentTab/AssessmentGetLearnosityObjectMutation";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const useIosOpenPrizm = () => {
    const OPEN_PRIZM_INAPPBROWSER_SETTING = "closebuttoncaption=BACK,hidenavigationbuttons=yes,location=no,hideurlbar=yes,toolbarposition=top,toolbar=no";
    const LANG = "EN";
    const user = useSelector((state) => state.UserReducer.user);
    const history = useHistory();
    const userType = user?.UserType;
    let activityName;
    let dataSet;
    let props;
    let data;


    const iosOpenPrizm = (inputProps, notificationTitle, resourceId, studyGroupId, schoolYearId, credential, inputData) => {
        props = inputProps;
        dataSet = {
            Lang: LANG,
            ResourceId: resourceId,
            StudyGroupId: studyGroupId,
            SchoolYearId: schoolYearId,
            Credential: credential
        };
        data = inputData;
        activityName = notificationTitle;
        AssessmentGetLearnosityObjectMutation(dataSet, mutationCallback);
    };

    const iosOpenPrizmSubmission = (iframeUrl, actName) => {
        activityName = actName;
        initInAppBrowser(iframeUrl);
    }

    const mutationCallback = (response) => {
        console.log("mutationCallback response:", response);
        let prizmUrl;
        if (response.attemptSelfLearn) {
            console.log("attemptSelfLearn:", response.attemptSelfLearn)
            prizmUrl = response.attemptSelfLearn;
        } else {
            console.error("attemptSelfLearn is null");
        }
        initInAppBrowser(prizmUrl);
    }

    const initInAppBrowser = async (prizmUrl) => {
        let browserRef = window.cordova.InAppBrowser.open(prizmUrl, "_blank", OPEN_PRIZM_INAPPBROWSER_SETTING)
        let headerHtml;

        browserRef.addEventListener("loadstop", async function (e) {
            if (userType === 'S') {
                headerHtml = '<h3 class="main__iframe-heading">'+activityName+'</h3><div class="main__iframe-righ-section"><button class="btns buttons main__iframe-close-btn"><span class="mdi-open-in-new-sdl-close"></span>Close</button></div>';
            } else {
                headerHtml = '<h3 class="main__iframe-heading">'+activityName+'</h3><div class="main__iframe-righ-section"><button class="btns buttons main__iframe-result-btn"><span class="mdi-open-in-new-sdl-student-active"></span>Class results</button><button class="btns buttons main__iframe-close-btn"><span class="mdi-open-in-new-sdl-close"></span>Close</button></div>';
            }
            const loading = '<div class="sprint"><div class="splash-container"><div class="loader mx-auto" /></div></div>';
            // const popUpDiv = '<div class="activity_notification__popup"><label class="activity_notification__popup-top-heading">Activity Submitted</label><span class="activity_notification__popup-border"></span><h3 class="activity_notification__popup-heading">The activity is submitted.</h3><span class="activity_notification__popup-border"></span><div class="activity_notification__popup-bottom"><button class="buttons btns activity_notification__popup-back-btn" onclick="()=>{webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}))}">Back to SDL</button></div></div>'
            //
            // let popUpDiv = document.createElement('div');
            // popUpDiv.classList.add('activity_notification__popup-overlay');
            // popUpDiv.innerHTML = '` + popUpDiv + `';
            // body.appendChild(popUpDiv);
            browserRef.executeScript({
                code: `
                    var loading = document.createElement('div');
                    loading.classList.add('loading');
                    loading.innerHTML = '`+loading+`';
                    var body = document.querySelector('body');
                    let headerNav = document.createElement('div');
                    headerNav.classList.add('main__iframe-navigation');
                    headerNav.innerHTML = '` + headerHtml + `';
                    body.insertBefore(headerNav, body.firstChild);
                    body.insertBefore(loading, body.firstChild);
                    document.querySelector('.main__iframe-close-btn').addEventListener('click', function() {webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}))});
                    
                    let closeButton = document.getElementsByClassName("activity_notification__popup-back-btn")[0];
                    if (closeButton) {
                        closeButton.addEventListener('click', ()=>{webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}))});
                    }
                    let resultButton = document.getElementsByClassName("main__iframe-result-btn");
                    if (resultButton[0]) {
                        resultButton[0].addEventListener('click', ()=>{webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResult"}))});
                    }
                    async function clickedSubmit(){
                        loading.style.display = "block";
                        setTimeout(()=>{
                            alert('Successfully submitted.');
                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));
                        }, 5000);
                    }
                    var interval;
                    window.addEventListener('message', async function (params) {
                        if (params?.data.type === 'SUBMIT_BTN_ACK') {
                            clickedSubmit();
                        }
                        if (!interval && params?.data.type === 'ICPLAYER_READY') {
                            interval = setInterval(() => {
                                window.postMessage({
                                    type: 'SAVE_BTN', payload:
                                        {saveBtn: true}
                                }, '*');
                            }, 30000);
                        }
                    })
                    
                `
            });
            browserRef.insertCSS({
                code: `
                    .main__iframe-navigation{
                        max-width: 1360px;
                        margin: 0 auto;
                        width: 100%;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 10px;
                    }
                    .main__iframe-heading{
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 25px;
                        color: #0088cf;
                        margin-left: 10px;
                    }
                    .main__iframe-righ-section{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 19px;
                        margin-right: 10px;
                    }
                    .mdi-open-in-new-sdl-close {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH7SURBVHgBtZdLTgJBEIarCjW+ElmqK70BLn07JzCeQG6gxldcOW59RDmBegL1BEziYytHcCXE1ZiYaFSmrcIQQWD6McO/oYeu6e+fZqqrQGBl/WJ2YKg3D0iLcq0idVPZmb2AFDR2/LDGK+Z5mAOEJwS6eN6cPpA5rIGH+4q1yWaV3jOfXrjhheCo8ZP7faXAb53BoLw14yEHnHNAvsP9zgY6g3+lVOSRAliKWSM3UO0rZk+LWUgRXBPSKoGCCYiXlQEjsLABXok3IAC9jAyYgkUqghLDowKYKdaAFZgfWLKJylsL1/zjH4CZ2hqwAbNKH29DKzLA+jejR7c+Iu2bLlDPAlvw+9ugF/pTYRPcxQAgBpwz66bxjeAWuIMBU7WA28K7YKAtuCM8RQMdwbHwFAzEgrXwBAa0YCO4aOz4jqseLoGh1BdNVvamn3RxpAuQPLYBi7A3ujKpBagDWxwg/6Utx9glsJEB7CJYawC7DI41QAnApaTVkFzBkseV7Xk/iQF0BTceIK7lmEYP7/NJwCKXHZAB8un1yB85V3CjbHagWq2uUFpgkc0OUCazLC+c7g+BEdjWQK115sBCWmAbA7ztAfX3fJ+1692lvXUBGxoovOwuXP91r/zW80VOEYxwR3/JNweQgmRdILXKzeYEKhXyU12Wt+fOZO4Hn9uHI1h9bAwAAAAASUVORK5CYII=);
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 19px;
                        width: 30px;
                        box-sizing: border-box;
                        display: block;
                    }
                    .btns {
                        background: #ffffff;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                    }
                    
                    .buttons {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                    }
                    
                    .mdi-open-in-new-sdl-student-active {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAATCAYAAACHrr18AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFVSURBVHgBtZWBcYMwDEWVTuANygiMwAgZwRuEDcgGZQO6QbsBI5ANzAY0E6jiIh+KkMFpmnfn+PJl6csODgAPgognGj3emGh8wKshkwZtwhyDZ6EihdjVt9D2cLy24+9D1DRvhumJpoFGxdLI8xH28TxfeS5pBKpZ7xoTZxqyyx+ezc4VTuVErdE7vzOmYAVrg3eeR9jnohqQ5kXSWHUauUI+LjemjceEOWzouVySETrq0nhSJxH/3HiiO7EuGPFyy9glkmrR2JQwLnhNY8QCJq6VND8aiRMud7TeaMyhjYcccPnziAwiVhmFq5zcHONaHJEX+ryjL8O4U/kel5/M55pWnNTicrxOFbOwmmxZL/YMey5yVt1PmE9QDbTxVFYN4P2TGBL6ozSiTljpeNuRxCf0vxBr6ZtQz+KgxJIXD/g8PdfSr9T+MH/KYz8QvBjhH0jV+wWxKMtDYnJCdgAAAABJRU5ErkJggg==);
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 19px;
                        width: 30px;
                        display: block;
                    }
                    
                    @media only screen and (max-width: 640px) {
                        .main__iframe-close-btn {
                            align-self: end;
                        }
                        .main__iframe-result-btn {
                            align-self: start;
                        }
                        .main__iframe-navigation {
                            flex-direction: column;
                            padding-top: 0px;
                            height: auto;
                        }
                        .main__iframe-heading {
                            order: 2;
                            align-self: self-start;
                            margin-top: 45px;
                        }
                        .main__iframe-righ-section {
                            order: 1;
                            align-self: end;
                            display: flex;
                            flex-direction: column-reverse;
                            max-width: 641px;
                            width: 98%;
                            gap: 20px;
                        }
                    }
                    .main__iframe-result-btn {
                        box-sizing: border-box;
                        border-radius: 10px;
                        height: 43px;
                        min-width: 189px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;
                        background: #198cc5 !important;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        color: #ffffff;
                        padding: 0px 16px;
                    }
                    
                    .main__iframe-close-btn {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-align: center;
                        color: #198cc5 !important;
                        background: #ffffff;
                        height: 43px;
                        width: 74px;
                        padding: 0px 8px;
                        border-radius: 10px;
                        padding-right: 0px !important;
                    }
                    .activity_notification__popup-overlay {
                        display: none;
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0,0,0,0.85);
                        z-index: 2;
                    }
                    .activity_notification__popup {
                        background: #ffffff;
                        max-width: 450px;
                        width: 95%;
                        height: 220px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 10px 20px;
                        padding-bottom: 45px;
                        border-radius: 12px;
                    }
                    .activity_notification__popup-top-heading {
                        color: #000;
                        font-size: 18px;
                        font-weight: 800;
                        margin-top: 20px;
                    }
                    .activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .activity_notification__popup-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 36px;
                        color: #000000;
                        min-height: 60px;
                        max-height: 200px;
                        padding-bottom: 16px;
                    }
                    .activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .activity_notification__popup-bottom {
                        display: flex;
                        align-items: end;
                        justify-content: end;
                        gap: 16px;
                    }
                    .activity_notification__popup-back-btn {
                        background: #198cc5 !important;
                        border-radius: 10px;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #ffffff;
                        padding: 7px 20px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                    }
                    .prizmIframe .loading {
                        position: absolute;
                    }
                    .loading {
                        position: fixed;
                        display:none;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #3d3c3ca1;
                        z-index: 99999;
                    }
                    .sprint {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -60px;
                        margin-left: -60px;
                        width: 120px;
                        height: 120px;
                        border-radius: 10px;
                        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
                        background-color: #fff;
                    }
                    .sprint .splash-container {
                        margin: 35px auto;
                    }
                    .sprint .splash-container {
                        margin: 35px auto;
                    }
                    .splash-container {
                        text-align: center;
                    }
                    .loader {
                        border: 6px solid #f3f3f3;
                        border-radius: 50%;
                        border-top: 6px solid #007fa3;
                        width: 50px;
                        height: 50px;
                        -webkit-animation: spin 2s linear infinite;
                        animation: spin 2s linear infinite;
                        margin:auto;
                    }
                `
            })
            // let sButton = document.getElementById("icplayer_submit_button");
            // if (sButton) {
            //     sButton.addEventListener('click', ()=>{document.getElementsByClassName("activity_notification__popup-overlay")[0].style.display = "block"});
            // }
        });
        browserRef.addEventListener("exit", function (e) {
            browserRef.close();
            browserRef = null;
        });

        browserRef.addEventListener("message", async function (params) {
            console.log("HERE IS MESSAGE:", params)
            if (params.data.action === "closeIAB") {
                browserRef.close();
                history.push("/s/studygroup/" + props.match.params.id + "/rcenter");
            }
            if (params.data.action === "openResult") {
                browserRef.close();
                console.log("HERE IS PROPS:", props);
                const path = "/a/studygroup/" +
                    (data? data.BookSeriesId:props.match.params.id) +
                    "/assessment/" +
                    "prizm&" +
                    (props.selectedBookId ? props.selectedBookId : props.prizmBookId) +
                        "/" +
                        dataSet.ResourceId +
                        "/result";
                console.log("HERE IS PATH:", path);
                history.push({
                    pathname: path,
                    state: {
                        group_id: dataSet.StudyGroupId,
                        leftMenu: "",
                        bookVal: props.selectedBookId ? props.selectedBookId : props.prizmBookId ,
                    },
                });
                // history.push("/m")
                console.log("HISTORY DONE")
                //alert(tabClassId);
                // dispatch({
                //     type: "SET_SELECTED_TYPE",
                //     selectOptions: {
                //         selectedStudyGroupId: dataSet.StudyGroupId,
                //         selectedStudyGroupName: selectOptions.selectedStudyGroupName,
                //         selectedResourceName:selectOptions.selectedResourceName,
                //         selectecResourceTab:selectOptions.selectecResourceTab,
                //         selectedToggle: selectOptions.selectedToggle,
                //         setselectedResAssignId: "",
                //         selectedReportType: "",
                //         selectedAssessType: "",
                //         firstLevel: "", //activeFetchId,
                //         secondLevel: "", //bredCrumRes[1],
                //         thirdLevel: "", //bredCrumRes[2],
                //     },
                // });
            }
            if (params.data.action === "SUBMIT_BTN_ACK") {
                browserRef.close();
            }

        });
    }

    return {iosOpenPrizm ,iosOpenPrizmSubmission};
}

export default useIosOpenPrizm;