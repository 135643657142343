import React, { Component } from "react";
import Modal from "react-modal";
import ViewRedo from "./ViewRedo";
import SettingsLevel from "./SettingsLevel";
import AdvanceViewRedo from "./AdvanceViewRedo";
import AssignedStudentsInfo from "./AssignedStudentsInfo";
import AssignmentStudents from "./AssignmentStudents";
import AdditionalResource from "./AdditionalResource";
import Assessmenticon from "../../../assets/img/chart.svg";
import FirstOnboarding from "./FirstOnboarding";
import PrivacyPolicy from "./PrivacyPolicy";
import ShowHide from "./ShowHide";
import "./access-right.scss";

class MessageLightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  toggleModal = () => {
    this.props.changeSkip(null);
  };

  renderContent = () => {
    const {
      isOpen,
      title,
      message,
      message2,
      message3,
      onClose,
      type,
      onConfirm,
      customButton,
      t,
      // view
      modalData,
      onChange,
      studyGroupList,
      loadingList,
      studyGroupInfo,
      //settings
      levelList,
      levelOptions,
      //advance
      unitTitle,
      assessmentsData,
      studyGroupListAdvance,
      assignmentName,
      // handleAdvance
      listAdvance,
      loadingBox,
      // assignedStudents
      resData,
      studyGroupId,
      resDataForGa,
      studyGroupData,
      resourceAssignId,
      resourceId,
      resourceName,
      resourceData,
      selectedResourceData,
      okBtn,
      changefun,
      checkfun,
      onNotify,
      bookSeriesData,
      accessFlag,
    } = this.props;
    switch (type) {
      case "ImageTitleMessageBox":
        return (
          <>
            <div className="modal-header modal-header-colored icon-header">
              <div className="react-modal-header-overflow-image">
                <img src={Assessmenticon} />
              </div>

              <p className="modal-title with-overflow-image score_title">
                {title}
              </p>
            </div>
            <div className="modal-body border-top border-bottom with-image-pop-up">
              <div className="container QA score_desc">
                <p>{message}</p>
              </div>
            </div>
            <div className="modal-footer scorebox">
              <div className="modal-btn-right score_btn">
                <button className="btn btn-primary" onClick={() => onClose()}>
                  {t("OK")}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="react-modal-close circle"
              title="Close"
              onClick={() => onClose()}
            >
              <div>
                <i className="icon mdi mdi-close" />
              </div>
            </button>
          </>
        );

      case "messageBox":
        return (
          <>
            <div className="modal-header messagebox">
              <div className="modal-title">{title}</div>
            </div>
            {message && (
              <div className="modal-body">
                <div>{typeof message == "string" ? message : message()}</div>
                <div>{message2}</div>
                <div>{message3}</div>
              </div>
            )}

            <div className="modal-footer messagebox">
              <div className="modal-btn-right ">
                {customButton ? (
                  customButton()
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => onClose()}
                    >
                      {t("Cancel-2")}
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        onConfirm();
                      }}
                    >
                      {t("Skip")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <button
              type="button"
              className="react-modal-close normal"
              title="Close"
              onClick={() => onClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1"
                viewBox="0 0 24 24"
              >
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
              </svg>
            </button>
          </>
        );
      case "accessRight":
        return (
          <>
            <div className="modal-header messagebox">
              <div className="modal-title">{title}</div>
            </div>
            {message && (
              <div className="modal-body access__right-body">
                <h3 className="access__right-message">
                  {typeof message == "string" ? message : message()}
                </h3>
                <div>{message2}</div>
                <div>{message3}</div>
              </div>
            )}

            <div className="modal-footer messagebox">
              <div className="modal-btn-right ">
                {customButton ? (
                  customButton()
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => onClose()}
                    >
                      {t("Cancel-2")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <button
              type="button"
              className="react-modal-close normal"
              title="Close"
              onClick={() => onClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1"
                viewBox="0 0 24 24"
              >
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
              </svg>
            </button>
          </>
        );
      case "viewRedo":
        return (
          <ViewRedo
            modalData={modalData}
            onChange={onChange}
            studyGroupList={studyGroupList}
            loadingList={loadingList}
            onConfirm={onConfirm}
            onClose={onClose}
            t={t}
          />
        );
      case "showHide":
        return (
          <ShowHide
            modalData={modalData}
            onChange={onChange}
            studyGroupList={studyGroupList}
            loadingList={loadingList}
            onConfirm={onConfirm}
            onClose={onClose}
            onNotify={onNotify}
            t={t}
          />
        );
      case "settingsLevel":
        return (
          <SettingsLevel
            modalData={modalData}
            onChange={onChange}
            levelList={levelList}
            studyGroupInfo={studyGroupInfo}
            loadingList={loadingList}
            onConfirm={onConfirm}
            onClose={onClose}
            levelOptions={levelOptions}
            t={t}
          />
        );
      case "AssignmentStudents":
        return (
          <AssignmentStudents
            isOpen={isOpen}
            onClose={onClose}
            assignmentName={assignmentName}
            resourceAssignId={resourceAssignId}
            resourceId={resourceId}
            resourceName={resourceName}
            t={t}
          />
        );
      case "AdditionalResource":
        return (
          <AdditionalResource
            isOpen={isOpen}
            resourceData={resourceData}
            onClose={onClose}
            okBtn={okBtn}
            selectedResourceData={selectedResourceData}
            changefun={changefun}
            checkfun={checkfun}
            t={t}
          />
        );
      case "advanceViewRedo":
        return (
          <AdvanceViewRedo
            studyGroupInfo={studyGroupInfo}
            studyGroupListAdvance={studyGroupListAdvance}
            listAdvance={listAdvance}
            assessmentsData={assessmentsData}
            unitTitle={unitTitle}
            loadingBox={loadingBox}
            onConfirm={onConfirm}
            onClose={onClose}
            onChange={onChange}
            t={t}
          />
        );
      case "firstOnboarding":
        return (
          <FirstOnboarding
            isOpen={isOpen}
            onClose={onClose}
            bookSeriesData={bookSeriesData}
            history={this.props.history}
            t={t}
          />
        );
      case "assignedStudents":
        return (
          <AssignedStudentsInfo
            onClose={onClose}
            resData={resData}
            t={t}
            studyGroupId={studyGroupId}
            resDataForGa={resDataForGa}
            studyGroupData={studyGroupData}
          />
        );
      case "privacypolicy":
        return (
          <PrivacyPolicy onClose={onClose} t={t} history={this.props.history} />
        );
    }
  };

  render() {
    const { isOpen, type, title, message, onClose } = this.props;
    const customStyles = {
      content: {
        transform: "translate(-50%, -50%)",
        maxWidth:
          type === "firstOnboarding"
            ? "500px"
            : type === "AssignmentStudents" ||
              type === "assignedStudents" ||
              type === "AdditionalResource"
            ? "800px"
            : "600px",
        width: "100%",
        // height: "250px",
      },
    };
    return (
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        // onRequestClose={() => onClose()}
        style={customStyles}
        className={type == "ImageTitleMessageBox" ? "img-title" : ""}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

export default MessageLightBox;
