/**
 * @flow
 * @relayHash 40349ddaf9b38e52cd9d5d94d048e18d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReadResourceMutationVariables = {|
  StructureId: string,
  ResourceId: string,
  SchoolYearId: string,
|};
export type ReadResourceMutationResponse = {|
  +readResource: ?string
|};
export type ReadResourceMutation = {|
  variables: ReadResourceMutationVariables,
  response: ReadResourceMutationResponse,
|};
*/


/*
mutation ReadResourceMutation(
  $StructureId: String!
  $ResourceId: String!
  $SchoolYearId: String!
) {
  readResource(StructureId: $StructureId, ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StructureId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "readResource",
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "StructureId",
        "variableName": "StructureId",
        "type": "String"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReadResourceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReadResourceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReadResourceMutation",
    "id": null,
    "text": "mutation ReadResourceMutation(\n  $StructureId: String!\n  $ResourceId: String!\n  $SchoolYearId: String!\n) {\n  readResource(StructureId: $StructureId, ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '733e2493b7f23ca59a43de8cfa4d2b27';
module.exports = node;
