/**
 * @flow
 * @relayHash 8935db74190d1e8c4189b079a3646995
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OnBoardingGetBookSeriesStudyGroupListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
  BookSeriesId: $ReadOnlyArray<string>,
  FilterOnboarded: boolean,
|};
export type OnBoardingGetBookSeriesStudyGroupListQueryResponse = {|
  +user: ?{|
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
        +BookSeries: ?{|
          +BookSeriesId: ?string
        |},
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +LevelName: ?string,
            +Lang: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
      |}>
    |}
  |}
|};
export type OnBoardingGetBookSeriesStudyGroupListQuery = {|
  variables: OnBoardingGetBookSeriesStudyGroupListQueryVariables,
  response: OnBoardingGetBookSeriesStudyGroupListQueryResponse,
|};
*/


/*
query OnBoardingGetBookSeriesStudyGroupListQuery(
  $UserId: String!
  $SchoolYearId: String!
  $BookSeriesId: [String!]!
  $FilterOnboarded: Boolean!
) {
  user(UserId: $UserId) {
    School {
      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {
        StudyGroupId
        StudyGroupName {
          Lang
          StudyGroupName
        }
        BookSeries {
          BookSeriesId
          id
        }
        Level {
          PearsonLevelId
          LevelName {
            LevelName
            Lang
          }
          DisplaySequence
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookSeriesId",
    "type": "[String!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "FilterOnboarded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId",
    "type": "[String]"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded",
    "type": "Boolean!"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Lang",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PearsonLevelId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LevelName",
  "storageKey": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LevelName",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/)
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "School",
            "storageKey": null,
            "args": null,
            "concreteType": "school",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "StudyGroups",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "BookSeries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "Level",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "id": null,
    "text": "query OnBoardingGetBookSeriesStudyGroupListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String!]!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        BookSeries {\n          BookSeriesId\n          id\n        }\n        Level {\n          PearsonLevelId\n          LevelName {\n            LevelName\n            Lang\n          }\n          DisplaySequence\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a695b8426aa5c193484cb9fe5571426b';
module.exports = node;
