/**
 * @flow
 * @relayHash 3400dd5ef624c8e96c177888530a2ca7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type SingleResultStudentReportHistoryQueryVariables = {|
  UserId: string,
  ResourceId: string,
  Lang: LangEnumType,
  Credential?: ?string,
|};
export type SingleResultStudentReportHistoryQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
      +IsCompleted: ?string,
      +AttemptDate: ?string,
      +LearnositySessionResult: ?string,
    |}>,
  |}
|};
export type SingleResultStudentReportHistoryQuery = {|
  variables: SingleResultStudentReportHistoryQueryVariables,
  response: SingleResultStudentReportHistoryQueryResponse,
|};
*/


/*
query SingleResultStudentReportHistoryQuery(
  $UserId: String!
  $ResourceId: String!
  $Lang: LangEnumType!
  $Credential: String
) {
  user(UserId: $UserId) {
    UserId
    SelfLearnAttemptHistory(ResourceId: $ResourceId, IncludeAssignmentRecord: true) {
      IsCompleted
      AttemptDate
      LearnositySessionResult(Lang: $Lang, Credential: $Credential)
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Credential",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SelfLearnAttemptHistory",
  "storageKey": null,
  "args": [
    {
      "kind": "Literal",
      "name": "IncludeAssignmentRecord",
      "value": true,
      "type": "Boolean"
    },
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId",
      "type": "String!"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "IsCompleted",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AttemptDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LearnositySessionResult",
      "args": [
        {
          "kind": "Variable",
          "name": "Credential",
          "variableName": "Credential",
          "type": "String"
        },
        {
          "kind": "Variable",
          "name": "Lang",
          "variableName": "Lang",
          "type": "LangEnumType"
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SingleResultStudentReportHistoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SingleResultStudentReportHistoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SingleResultStudentReportHistoryQuery",
    "id": null,
    "text": "query SingleResultStudentReportHistoryQuery(\n  $UserId: String!\n  $ResourceId: String!\n  $Lang: LangEnumType!\n  $Credential: String\n) {\n  user(UserId: $UserId) {\n    UserId\n    SelfLearnAttemptHistory(ResourceId: $ResourceId, IncludeAssignmentRecord: true) {\n      IsCompleted\n      AttemptDate\n      LearnositySessionResult(Lang: $Lang, Credential: $Credential)\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee2c4ab57fdee90dae9e948cd420dea6';
module.exports = node;
