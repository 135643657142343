/**
 * @flow
 * @relayHash 2b815ee15076152c5f741d15252fa088
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type AttemptAssignmentMutationVariables = {|
  ResourceAssignResId: string,
  Credential?: ?string,
  Lang?: ?LangEnumType,
|};
export type AttemptAssignmentMutationResponse = {|
  +attemptAssignment: ?string
|};
export type AttemptAssignmentMutation = {|
  variables: AttemptAssignmentMutationVariables,
  response: AttemptAssignmentMutationResponse,
|};
*/


/*
mutation AttemptAssignmentMutation(
  $ResourceAssignResId: String!
  $Credential: String
  $Lang: LangEnumType
) {
  attemptAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, Credential: $Credential)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignResId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Credential",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "attemptAssignment",
    "args": [
      {
        "kind": "Variable",
        "name": "Credential",
        "variableName": "Credential",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang",
        "type": "LangEnumType"
      },
      {
        "kind": "Variable",
        "name": "ResourceAssignResId",
        "variableName": "ResourceAssignResId",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AttemptAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AttemptAssignmentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AttemptAssignmentMutation",
    "id": null,
    "text": "mutation AttemptAssignmentMutation(\n  $ResourceAssignResId: String!\n  $Credential: String\n  $Lang: LangEnumType\n) {\n  attemptAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, Credential: $Credential)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f4d870ddceb0a4389e9bd360e17bf13';
module.exports = node;
