import React, { Component, useState, useEffect } from "react";
import "./index.scss";

export const index = (props) => {
  const { t, haveFirstLastButton } = props;
  const [currentPage, setcurrentPage] = useState(props.currentPage);
  const [totalPage, settotalPage] = useState(props.totalPage);
  useEffect(() => {
    // console.log(props.totalPage, props.currentPage);
    setcurrentPage(props.currentPage);
    settotalPage(props.totalPage);
  }, [props.currentPage, props.totalPage]);

  const onChangePage = (action, page) => {
    // action -> dropDown / button
    console.log(action, page);
    if (action == "dropDown") {
      // this.setState({
      //   currentPage: page,
      // });
      // this.props.onPageChange(page);
      setcurrentPage(page);
      props.onPageChange(page);
    }
    if (action == "button") {
      // this.setState({
      //   currentPage: page,
      // });
      // this.props.onPageChange(page);
      setcurrentPage(page);
      props.onPageChange(page);
    }
    //call back function
  };

  const renderPagesOption = (totalPage, currentPage) => {
    const options = [];
    for (var i = 0; i < totalPage; i++) {
      options.push(
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      );
    }
    return (
      <select
        name="page_select"
        aria-controls=""
        className=""
        value={parseInt(currentPage)}
        onChange={(e) => onChangePage("dropDown", parseInt(e.target.value))}
      >
        {options}
      </select>
    );
  };

  return (
    <div className="pangination__box">
      {haveFirstLastButton && (
        <button
          className="pangination__box__touch first"
          disabled={currentPage == 1 ? true : false}
          onClick={() => onChangePage("button", 1)}
        >
          <div className="pangination__arrow__btn">
            <i className="icon mdi mdi-skip-previous" />
          </div>
        </button>
      )}
      <button
        className="pangination__box__touch"
        disabled={currentPage == 1 ? true : false}
        onClick={() => onChangePage("button", parseInt(currentPage) - 1)}
      >
        <div className="pangination__arrow__btn">
          <i className="icon mdi mdi-chevron-left" />
        </div>
      </button>
      <span className="pangination__span">
        {t("page")}
        <div className="page__dropdown">
          {
            //Render Pagination
            renderPagesOption(totalPage, currentPage)
          }
        </div>
        {" / "} {totalPage}
      </span>
      <button
        className="pangination__box__touch"
        disabled={currentPage == totalPage ? true : false}
        onClick={() => onChangePage("button", parseInt(currentPage) + 1)}
      >
        <div className="pangination__arrow__btn">
          <i className="icon mdi mdi-chevron-right" />
        </div>
      </button>
      {haveFirstLastButton && (
        <button
          className="pangination__box__touch last"
          disabled={currentPage == totalPage ? true : false}
          onClick={() => onChangePage("button", parseInt(totalPage))}
        >
          <div className="pangination__arrow__btn">
            <i className="icon mdi mdi-skip-next" />
          </div>
        </button>
      )}
    </div>
  );
};

// class index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentPage: props.currentPage,
//       totalPage: props.totalPage,
//     };
//   }

//   componentWillReceiveProps(props) {
//     this.setState({
//       currentPage: props.currentPage,
//       totalPage: props.totalPage,
//     });
//   }

//   onChangePage = (action, page) => {
//     // action -> dropDown / button
//     console.log(action, page);
//     if (action == "dropDown") {
//       this.setState({
//         currentPage: page,
//       });
//       this.props.onPageChange(page);
//     }
//     if (action == "button") {
//       this.setState({
//         currentPage: page,
//       });
//       this.props.onPageChange(page);
//     }
//     //call back function
//   };

//   renderPagesOption = (totalPage, currentPage) => {
//     const options = [];
//     for (var i = 0; i < totalPage; i++) {
//       options.push(
//         <option key={i + 1} value={i + 1}>
//           {i + 1}
//         </option>
//       );
//     }
//     return (
//       <select
//         name="page_select"
//         aria-controls=""
//         className=""
//         value={parseInt(currentPage)}
//         onChange={(e) =>
//           this.onChangePage("dropDown", parseInt(e.target.value))
//         }
//       >
//         {options}
//       </select>
//     );
//   };

//   render() {
//     const { currentPage, totalPage } = this.state;
//     const { t } = this.props;
//     //icon mdi mdi-book
//     return (
//       <div className="pangination__box">
//         <button
//           className="pangination__box__touch"
//           disabled={currentPage == 1 ? true : false}
//           onClick={() => this.onChangePage("button", parseInt(currentPage) - 1)}
//         >
//           <div className="pangination__arrow__btn">
//             <i className="icon mdi mdi-chevron-left" />
//           </div>
//         </button>
//         <span className="pangination__span">
//           {t("page")}
//           <div className="page__dropdown">
//             {
//               //Render Pagination
//               this.renderPagesOption(totalPage, currentPage)
//             }
//           </div>
//           {" / "} {totalPage}
//         </span>
//         <button
//           className="pangination__box__touch"
//           disabled={currentPage == totalPage ? true : false}
//           onClick={() => this.onChangePage("button", parseInt(currentPage) + 1)}
//         >
//           <div className="pangination__arrow__btn">
//             <i className="icon mdi mdi-chevron-right" />
//           </div>
//         </button>
//       </div>
//     );
//   }
// }

export default index;
