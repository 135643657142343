/**
 * @flow
 * @relayHash 49eea809f384383224f8cf26d52051cc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuestionViewGetStudyGroupListQueryVariables = {|
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type QuestionViewGetStudyGroupListQueryResponse = {|
  +studyGroup: ?{|
    +StudyGroupName: ?$ReadOnlyArray<?{|
      +Lang: ?string,
      +StudyGroupName: ?string,
    |}>,
    +StudyGroupInSameLevel: ?$ReadOnlyArray<?{|
      +StudyGroupId: ?string,
      +StudyGroupName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +StudyGroupName: ?string,
      |}>,
    |}>,
  |}
|};
export type QuestionViewGetStudyGroupListQuery = {|
  variables: QuestionViewGetStudyGroupListQueryVariables,
  response: QuestionViewGetStudyGroupListQueryResponse,
|};
*/


/*
query QuestionViewGetStudyGroupListQuery(
  $StudyGroupId: String!
  $SchoolYearId: String!
) {
  studyGroup(StudyGroupId: $StudyGroupId) {
    StudyGroupName {
      Lang
      StudyGroupName
    }
    StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
      StudyGroupId
      StudyGroupName {
        Lang
        StudyGroupName
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String!"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "StudyGroupName",
  "storageKey": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "StudyGroupName",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "StudyGroupId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuestionViewGetStudyGroupListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupInSameLevel",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "StudyGroup",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "QuestionViewGetStudyGroupListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "studyGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "StudyGroupInSameLevel",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "StudyGroup",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "QuestionViewGetStudyGroupListQuery",
    "id": null,
    "text": "query QuestionViewGetStudyGroupListQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupName {\n      Lang\n      StudyGroupName\n    }\n    StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {\n      StudyGroupId\n      StudyGroupName {\n        Lang\n        StudyGroupName\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '468cf4190ace9268dd4e43378c215458';
module.exports = node;
