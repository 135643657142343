import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import Loading from "../../../../Components/Loading/Loading";

import MyResources from "../../../../Services/api/GraphQL/Query/MyResources";
import { render } from "enzyme";
function index(props) {
  const { t, reportTagId, reportTagsArray, showSubjectReport } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);

  const renderSubjects = () => {
    console.log("test" + reportTagsArray);
    let reportTagsArrayL = reportTagsArray;
    let reportTagIdL = reportTagId;

    return (
      <ul>
        {reportTagsArrayL.map(function (obj, index) {
          const splitTitle = (
            lang == "en-US"
              ? obj.HierarchyReferenceLabelEn
              : obj.HierarchyReferenceLabelChi
          ).split(";");
          return (
            <li
              onClick={() => {
                showSubjectReport(obj.HierarchyReference);
              }}
              key={index}
              className={`assessment ${
                obj.HierarchyReference == reportTagIdL ? "active" : ""
              }`}
            >
              <div>
                <div className="title">{splitTitle[0]}</div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  return <React.Fragment>{renderSubjects()}</React.Fragment>;
}

export default index;
