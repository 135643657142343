/**
 * @flow
 * @relayHash 002ac6210df448270ae78e6adfb011e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResultTabSelfLearnAttemptHistoryPrizmQueryVariables = {|
  UserId: string,
  ResourceId: string,
|};
export type ResultTabSelfLearnAttemptHistoryPrizmQueryResponse = {|
  +user: ?{|
    +UserId: ?string,
    +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +IsCompleted: ?string,
      +AttemptSessionId: ?string,
      +AttemptDate: ?string,
      +UserId: ?string,
      +LearnositySessionRawResult: ?string,
      +Score: ?string,
      +MaxScore: ?string,
    |}>,
  |}
|};
export type ResultTabSelfLearnAttemptHistoryPrizmQuery = {|
  variables: ResultTabSelfLearnAttemptHistoryPrizmQueryVariables,
  response: ResultTabSelfLearnAttemptHistoryPrizmQueryResponse,
|};
*/


/*
query ResultTabSelfLearnAttemptHistoryPrizmQuery(
  $UserId: String!
  $ResourceId: String!
) {
  user(UserId: $UserId) {
    UserId
    SelfLearnAttemptHistory(ResourceId: $ResourceId) {
      ResourceId
      IsCompleted
      AttemptSessionId
      AttemptDate
      UserId
      LearnositySessionRawResult
      Score
      MaxScore
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SelfLearnAttemptHistory",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId",
      "type": "String!"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "IsCompleted",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AttemptSessionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AttemptDate",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "LearnositySessionRawResult",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Score",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "MaxScore",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResultTabSelfLearnAttemptHistoryPrizmQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ResultTabSelfLearnAttemptHistoryPrizmQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ResultTabSelfLearnAttemptHistoryPrizmQuery",
    "id": null,
    "text": "query ResultTabSelfLearnAttemptHistoryPrizmQuery(\n  $UserId: String!\n  $ResourceId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    SelfLearnAttemptHistory(ResourceId: $ResourceId) {\n      ResourceId\n      IsCompleted\n      AttemptSessionId\n      AttemptDate\n      UserId\n      LearnositySessionRawResult\n      Score\n      MaxScore\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a675dce63f7e830ee689aa84c6493967';
module.exports = node;
