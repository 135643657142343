import React, { useState, useEffect, useRef } from "react";
import "./ViewNotificationPopUp.scss";
const ViewNotificationPopUp = (props) => {
  const {
    // fetchResources,
    setShowPopUp,
    showPopUp,
    activeSubUnitSectionName,
    // activeBook,
    // openedUnitSection,
    // setActiveBook,
    // setOpenedUnitSection,
    submitShowHide,
    isViewable,
    t,
  } = props;

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowPopUp(false);
      }
    };
    if (showPopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showPopUp]);
  const notification_popupref = useRef(null);
  const [notifyStudent, setNotifyStudent] = useState(false);

  const validateNotifyStudents = (e) => {
    if (e.target.checked) {
      setNotifyStudent(true);
    } else {
      setNotifyStudent(false);
    }
  };

  return (
    <div className="view_notification__popup-overlay">
      <div className="view_notification__popup" ref={notification_popupref}>
        <label
          className="view_notification__popup-close-popup"
          onClick={() => setShowPopUp(false)}
        >
          &nbsp;
        </label>
        {/* <h3 className="view_notification__popup-heading">
          Resource to show to students “Topic Intro” to &nbsp;
          {`Resource to ${!isViewable ? "show" : "hide"} to students `}
          <span className="view_notification__popup-heading-resource">{`“${activeSubUnitSectionName}” `}</span>
          {`to `}
          <span className="view_notification__popup-heading-class">
            {t("Class")} {props.selectedClass}
          </span>
        </h3> */}
        <h3 className="view_notification__popup-heading">
          {/* Resource to show to students “Topic Intro” to &nbsp; */}
          {`You are going to ${!isViewable ? "share" : "stop sharing"} `}
          <span className="view_notification__popup-heading-resource">{`“${activeSubUnitSectionName}” `}</span>
          {` with `}
          <span className="view_notification__popup-heading-class">
            {t("Class")} {`${props.selectedClass}.`}
          </span>
        </h3>
        {!isViewable ? (
          <div className="view_notification__popup-show">
            <input
              className="view_notification__popup-show-checkbox"
              type="checkbox"
              name="notification"
              onChange={(e) => validateNotifyStudents(e)}
            />
            <label className="view_notification__popup-show-label">
              Send notification to students
            </label>
          </div>
        ) : (
          ""
        )}
        <span className="view_notification__popup-border"></span>
        <div className="view_notification__popup-bottom">
          <button
            className="buttons btns view_notification__popup-cancel-btn"
            onClick={() => setShowPopUp(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="buttons btns view_notification__popup-save-btn"
            onClick={() => {
              // submitShowHide(isViewable);
              submitShowHide(notifyStudent);
              // setActiveBook(activeBook);
              // setOpenedUnitSection(openedUnitSection);
              // // props.submitShowHide("", "");
              // fetchResources(props.openedUnitSection);
              // setShowPopUp(false);
            }}
          >
            {/* {t("Save")} */}
            {t("OK  ")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewNotificationPopUp;
