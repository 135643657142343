import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import "./index.scss";
import MessageLightBox from "../MessageLightBox/index";
import CreateEditAssignment from "./CreateEditAssignment";
import ViewAllNotification from "./../../NavBar/Dashboard/ViewAllNotification";
import ViewAllNotificationPEC from "../../NavBar/Dashboard/PEC/ViewAllNotificationPEC";
// import Footer from "../../Footer/Footer";
import Footer from "../../Footer/PEC/Footer";
import ViewAssignmentDetails from "./../../NavBar/Dashboard/ViewAssignmentDetails";
import ViewAssignmentByStudents from "../../NavBar/Dashboard/ViewAssignmentByStudents";
import { Route } from "react-router-dom";
import NotificationPopUp from "../../../Layouts/DashboardLayout/FavouritePage/NotificationPopUp";
const modalStyles = {
  content: {
    top: "100%",
    left: "100%",
    height: "100%",
    width: "100%",
    border: "none",
  },
};

export default (props) => {
  const {
    t,
    isOpen,
    onClose,
    onConfirm,
    clearAssignment,
    assignmentList,
    studyGroupId,
    bookSeriesName,
    assignStudyGroup,
    type,
    title,
    setAssignmentDetailsData,
    resourceAssignId,
    messageContent,
    assignmentData,
    refreshHandbook,
    isModalOpen,
  } = props;
  const [isMessageLightBoxOpen, setIsMessageLightBoxOpen] = useState({
    isOpen: false,
    data: null,
  });
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const [showHandbookPopup, setShowHandbookPopup] = useState(false);
  const [notifyDetails, setnotifyDetails] = useState({});
  //const [fromHandbook, setfromHandbook] = useState(false);
  const [handbookNotificationTitle, setHandbookNotificationTitle] =
    useState("");

  useEffect(() => {
    //
    if (isModalOpen == true) {
      document.querySelector("[data-modal]").dataset.modal = "open";

      // setIsMessageLightBoxOpen(false);
    } else {
      document.querySelector("[data-modal]").dataset.modal = "close";
      // setIsMessageLightBoxOpen(false);
    }
  }, [isModalOpen]);

  const messageBox = () => (
    //for now only for create assignment will use
    <MessageLightBox
      title={isMessageLightBoxOpen.data?.title}
      message={isMessageLightBoxOpen.data?.message}
      isOpen={isMessageLightBoxOpen.isOpen}
      onClose={() => setIsMessageLightBoxOpen(false)}
      type={"messageBox"}
      customButton={() => {
        return (
          <>
            <button
              className={
                isMessageLightBoxOpen.data?.cancelBtnClassName
                  ? isMessageLightBoxOpen.data?.cancelBtnClassName
                  : "btn btn-primary"
              }
              onClick={() =>
                setIsMessageLightBoxOpen({
                  isOpen: false,
                  data: null,
                })
              }
            >
              {t("Cancel")}
            </button>
            <button
              className={
                isMessageLightBoxOpen.data?.confirmBtnClassName
                  ? isMessageLightBoxOpen.data?.confirmBtnClassName
                  : "btn btn-secondary"
              }
              onClick={() => {
                isMessageLightBoxOpen.data?.onClose(),
                  setIsMessageLightBoxOpen({
                    isOpen: false,
                    data: null,
                  });
              }}
            >
              {t("Confirm")}
            </button>
          </>
        );
      }}
      t={t}
    />
  );

  const renderConent = () => {
    const { type } = props;
    switch (type) {
      case "createAssign":
        return (
          <CreateEditAssignment
            assignmentList={assignmentList}
            bookSeriesName={bookSeriesName}
            studyGroupId={studyGroupId}
            clearAssignment={clearAssignment}
            assignStudyGroup={assignStudyGroup}
            t={t}
            onCloseButton={() => {
              setIsMessageLightBoxOpen({
                isOpen: true,
                data: {
                  title: t("Confirm to leave the assignment?"),
                  message: t(
                    "Your change will not be saved, do you confirm to leave?"
                  ),
                  onClose: onClose,
                },
              });
            }}
          />
        );
      case "editAssign":
        return (
          <CreateEditAssignment
            isEdit={true}
            // assignmentList={assignmentList}
            assignmentData={assignmentData}
            // bookSeriesName={bookSeriesName}
            // studyGroupId={studyGroupId}
            // clearAssignment={clearAssignment}
            // assignStudyGroup={assignStudyGroup}
            t={t}
            onConfirm={onConfirm}
            onCloseButton={() => {
              setIsMessageLightBoxOpen({
                isOpen: true,
                data: {
                  title: t("Confirm to leave the assignment?"),
                  message: t(
                    "Your change will not be saved, do you confirm to leave?"
                  ),
                  onClose: onClose,
                },
              });
            }}
          />
        );
      case "viewAllNotification":
        return (
          // <ViewAllNotification
          //   t={t}
          //   setAssignmentDetailsData={setAssignmentDetailsData}
          //   onCloseButton={() => onClose()}
          // />
          <ViewAllNotificationPEC
            t={t}
            HandBookData={props.HandBookData}
            setHandbookNotificationTitle={setHandbookNotificationTitle}
            handbookNotificationTitle={handbookNotificationTitle}
            showHandbookPopup={showHandbookPopup}
            
            setShowHandbookPopup={setShowHandbookPopup}
            refreshHandbook={refreshHandbook}
            HandbookPopupFlag={false}
            showNotificationDataUsingPopUp={showNotificationDataUsingPopUp}
          />
        );
      case "ViewAssignmentByStudents":
        return (
          <ViewAssignmentByStudents
            t={t}
            setAssignmentDetailsData={setAssignmentDetailsData}
            onCloseButton={() => onClose()}
          />
        );
      case "viewAssignmentDetails":
        return (
          <ViewAssignmentDetails
            t={t}
            resourceAssignId={resourceAssignId}
            onDeleteButton={(data) => {
              setIsMessageLightBoxOpen({
                isOpen: true,
                data: data,
              });
            }}
            onCloseButton={() => onClose()}
          />
        );
    }
  };

  const handleModalClose = (type) => {
    switch (type) {
      case "createAssign":
        setIsMessageLightBoxOpen(true);
        break;
      case "viewAllNotification":
        onClose();
        break;
      default:
        onClose();
        break;
    }
  };

  const showNotificationDataUsingPopUp = (popUpFlag, obj, title) => {
    setnotifyDetails(obj);
    setHandbookNotificationTitle(title);
    setShowHandbookPopup(true);
    // return showHandbookPopup ? (
    //   <NotificationPopUp
    //     toggleNotificationPopUp={() => setShowHandbookPopup(!popUpFlag)}
    //     selectClass={"1A"}
    //     notificationTitle={title}
    //     popUpType={"handbook"}
    //     showNotificatioPopUp={popUpFlag}
    //     setShowNotificatioPopUp={setShowHandbookPopup}
    //     t={t}
    //     notificationPopupOverlay={""}
    //     setNotificationPopupOverlay={setShowHandbookPopup}
    //   />
    // ) : (
    //   ""
    // );
  };

  return (
    <React.Fragment>
      {showHandbookPopup && (
        <NotificationPopUp
            {...props}
          toggleNotificationPopUp={() =>
            setShowHandbookPopup(!showHandbookPopup)
          }
          selectClass={"1A"}
          notificationTitle={handbookNotificationTitle}
          popUpType={"handbook"}
          showNotificatioPopUp={showHandbookPopup}
          setShowNotificatioPopUp={setShowHandbookPopup}
          notificationResourceInfo={notifyDetails}
          t={t}
          notificationPopupOverlay={""}
          setNotificationPopupOverlay={setShowHandbookPopup}
          refreshhandbook={refreshhandbook}
          HandbookPopupFlag={true}
        />
      )}
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        {/* <div id="fullscreen_modal" className="fullscreen_modal_container"> */}
        {/* <div className="school_year_row">
            <span>{selectedSchoolYear.SchoolYearName}</span>
          </div> */}
        {/* <div className="be-navbar-header">
            <div className="back">
              <i className="icon mdi mdi-chevron-left" />
              <div
                className="back-btnText"
                onClick={() => handleModalClose(type)}
              >
                {t("Back")}
              </div>
            </div>
            <div className="fullscreen_modal_center_header">
              <div className="center">{headerTitle}</div>
            </div>
          </div> */}

        <div className="body">
          <div className="content">
            {/* <div
              className={`col-12 `}
              data-assignment-type={
                type == "viewAllNotification" && "view_all_notification"
              }
            > */}
            {renderConent()}
            {/* </div> */}
          </div>
          <div className="fullscreen_modal_footer">
            <Footer />
          </div>
        </div>
        {/* </div> */}
      </Modal>
      {messageBox()}
    </React.Fragment>
  );
};
