/**
 * @flow
 * @relayHash 9588f7cf5c2e7a18991520392f6ae7bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StudentAttemptyHistoryAssignmentQueryVariables = {|
  UserId: string,
  ResourceAssignId: string,
|};
export type StudentAttemptyHistoryAssignmentQueryResponse = {|
  +resourceAssignment: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +ResourceAssignResId: ?string,
      +LearnosityAttemptHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +IsCompleted: ?string,
        +AttemptSessionId: ?string,
        +AttemptDate: ?string,
        +UserId: ?string,
      |}>,
      +LearnosityAttemptLastHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
      +LearnosityAttemptFirstHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
    |}>
  |}
|};
export type StudentAttemptyHistoryAssignmentQuery = {|
  variables: StudentAttemptyHistoryAssignmentQueryVariables,
  response: StudentAttemptyHistoryAssignmentQueryResponse,
|};
*/


/*
query StudentAttemptyHistoryAssignmentQuery(
  $UserId: String!
  $ResourceAssignId: String!
) {
  resourceAssignment(ResourceAssignId: $ResourceAssignId) {
    Resources {
      ResourceId
      ResourceAssignResId
      LearnosityAttemptHistory(UserId: $UserId) {
        ResourceId
        IsCompleted
        AttemptSessionId
        AttemptDate
        UserId
      }
      LearnosityAttemptLastHistory {
        ResourceId
        LearnositySessionRawResult
        UserDetail {
          UserName
          UserDisplayName {
            UserName
            Lang
          }
          id
        }
      }
      LearnosityAttemptFirstHistory {
        ResourceId
        LearnositySessionRawResult
        UserDetail {
          UserName
          UserDisplayName {
            UserName
            Lang
          }
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceAssignId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceAssignId",
    "variableName": "ResourceAssignId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAssignResId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "LearnosityAttemptHistory",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "UserId",
      "variableName": "UserId",
      "type": "String"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "IsCompleted",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AttemptSessionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "AttemptDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserId",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnositySessionRawResult",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = [
  (v2/*: any*/),
  (v5/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "UserDetail",
    "storageKey": null,
    "args": null,
    "concreteType": "user",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v7/*: any*/)
    ]
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v5/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "UserDetail",
    "storageKey": null,
    "args": null,
    "concreteType": "user",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceAssignment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceAssignment",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnosityAttemptLastHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v8/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnosityAttemptFirstHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v8/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceAssignment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceAssignment",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnosityAttemptLastHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v10/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnosityAttemptFirstHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v10/*: any*/)
              },
              (v9/*: any*/)
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "id": null,
    "text": "query StudentAttemptyHistoryAssignmentQuery(\n  $UserId: String!\n  $ResourceAssignId: String!\n) {\n  resourceAssignment(ResourceAssignId: $ResourceAssignId) {\n    Resources {\n      ResourceId\n      ResourceAssignResId\n      LearnosityAttemptHistory(UserId: $UserId) {\n        ResourceId\n        IsCompleted\n        AttemptSessionId\n        AttemptDate\n        UserId\n      }\n      LearnosityAttemptLastHistory {\n        ResourceId\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      LearnosityAttemptFirstHistory {\n        ResourceId\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9873b92e11f994ce5ad0c513f94534a3';
module.exports = node;
