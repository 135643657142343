//Main
import React, { Component, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../Config/config";
// import { fetchQuery, graphql } from "relay-runtime";
// import environment from "../../../Environment";

import Guest from "./Guest";
import User from "./User";
import Loading from "../../Loading/Loading";
import {isAndroid, isIOS, isMobileOnly} from "react-device-detect";

//Component
// import i18n from "../../../i18n";
//Css
import "./NavBar.scss";
//img
import backHome from "../../../assets/img/back-home.svg";
import { NavLink } from "react-router-dom";
//import DashHandbook from '../Dashboard/DBPage/HandBook/DashHandbook';

//Component

const NavBar = (props) => {
  const dispatch = useDispatch();
  const { t, showRigthButton, detectTitle } = props;
  const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const userData = useSelector((state) => state.UserReducer.user);
  const userSchoolCode = userData?.School?.SchoolCode;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  var str = window.location.pathname;
  var n = str.indexOf("onboarding");
  var pin = str.indexOf("access");
  const [loading, setloading] = useState(false);
  const [logoNotClickable, setlogoNotClickable] = useState(
    props.logoNotClickable
  );
  const [isAssessmentView, setisAssessmentView] = useState(
    props.isAssessmentView
  );
  window.addEventListener("message", function(e) {
    console.log("HERE IS MESSAGE", JSON.stringify(e.data))
    const data = JSON.parse(e?.data);
    if (isIOS || isAndroid) {
      return;
    }
    if (data.hasOwnProperty("iesToken")){
      if (data?.iesToken) {
        window.localStorage.setItem("IESToken", data?.iesToken);
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    }
  });


  const [backTitle, setbackTitle] = useState(props.backTitle);
  useEffect(() => {
    setisAssessmentView(props.isAssessmentView);
  }, [props.isAssessmentView]);

  useEffect(() => {
    setbackTitle(props.backTitle);
  }, [props.backTitle]);
  useEffect(() => {
    setlogoNotClickable(props.logoNotClickable);
  }, [props.logoNotClickable]);

  const logout = () => {
    setloading(true);
    gaTrack("set", "dimension1", "");
    gaTrack("set", "dimension4", "");
    dispatch({
      type: "SET_USER",
      user: null,
    });
    // props.history.push("/");
    // this.setState({
    //   loading: false,
    // });
    setloading(false);
  };
  const logoutbk = () => {
    // this.setState({
    //   loading: true,
    // });
    setloading(true);
    var isSTGiam = false;
    if (window.cordova) {
      if (config.mode == "dev") {
        isSTGiam = true;
      }
    } else {
      if (
        window.location.hostname.indexOf(".stg") != -1 ||
        window.location.hostname.indexOf(".dev") != -1 ||
        window.location.hostname.indexOf(".demo") != -1 ||
        window.location.hostname.indexOf(".qa") != -1 ||
        window.location.hostname.indexOf("-poc") != -1
      ) {
        isSTGiam = true;
      }
    }
    const url =
      localStorage.getItem("__from") == "l"
        ? config.forgerock_logout_url
        : config.forgerock_sso_logout_url;

    window
      .fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [isSTGiam ? "PearsonExtSTGSSOSession" : "PearsonExtSSOSession"]:
            localStorage.getItem("ssoToken"),
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          switch (response.status) {
            case 401:
              // localStorage.removeItem("accessToken");
              // localStorage.removeItem("SessionId");
              // localStorage.removeItem("ssoToken");
              // localStorage.removeItem("userData");
              // localStorage.removeItem("effectiveSchoolYear");
              // localStorage.removeItem("__from");
              // localStorage.removeItem("role");
              props.afterLogout();
              // props.history.push("/");
              // this.setState({
              //   loading: false,
              // });
              setloading(false);
              throw new Error("IAM Unauthorized");
            default:
              throw new Error("IAM issue");
          }
        }
      })
      .then((data) => {
        // if (window.cordova) {
        //   window.ga.addCustomDimension(
        //     1,
        //     "",
        //     () => {
        //       console.log("[GA addCustomDimension 1 remove] Success");
        //     },
        //     () => {
        //       console.log("[GA addCustomDimension 1 remove] Error");
        //     }
        //   );
        // } else {
        //   ReactGA.set({ dimension1: "" });
        // }
        gaTrack("set", "dimension1", "");
        gaTrack("set", "dimension4", "");
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("SessionId");
        // localStorage.removeItem("ssoToken");
        // localStorage.removeItem("userData");
        // localStorage.removeItem("effectiveSchoolYear");
        // localStorage.removeItem("__from");
        // localStorage.removeItem("role");
        console.log("[logout]", data);
        // props.afterLogout();
        dispatch({
          type: "SET_USER",
          user: null,
        });
        // props.history.push("/");
        // this.setState({
        //   loading: false,
        // });
        setloading(false);
      })
      .catch((error) => {
        console.error("Logout Error: ", error);
        // this.setState({
        //   loading: true,
        // });
        setloading(true);
      });
    //  fetchQuery(environment, NavBarQuery).then(data => {

    //   });
  };

  const checkUserForChangeMenuOrderAndLabel = () => {
    // if (match account)
    // 1: study note
    // 2: ebook shelf
    // -------------
    //if (not match)
    // 1: ToolBox
    // 2: study note

    var show = false;
    var userName = JSON.parse(localStorage.getItem("userData")).UserName;
    var allowAcount = ["tp16992702", "sp16992703"];
    allowAcount.map((name) => {
      if (userName.indexOf(name) > -1) {
        show = true;
      }
    });
    //return show;

    return show;
  };

  const showResultPage = () => {
    console.log(props.location);
    console.log(props.location.pathname.split("/"));

    var newURL =
      props.location.pathname.split("/")[0] +
      "/" +
      props.location.pathname.split("/")[1] +
      "/" +
      props.location.pathname.split("/")[2] +
      "/" +
      props.location.pathname.split("/")[3] +
      "/" +
      props.location.pathname.split("/")[4] +
      "/" +
      props.location.pathname.split("/")[5] +
      "/" +
      props.location.pathname.split("/")[6] +
      "/result";
    props.history.push({
      pathname: newURL,
      state: {
        group_id: selectOptions.selectedStudyGroupId,
        leftMenu: props.location.state.leftMenu,
      },
    });
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
        selectedStudyGroupId: selectOptions.selectedStudyGroupId,
        selectedStudyGroupName: selectOptions.selectedStudyGroupName,
        setselectedResAssignId: "",
        selectedReportType: "",
        selectedAssessType: "",
      },
    });
  };

  const showStartPage = () => {
    var newURL =
      props.location.pathname.split("/")[0] +
      "/" +
      props.location.pathname.split("/")[1] +
      "/" +
      props.location.pathname.split("/")[2] +
      "/" +
      props.location.pathname.split("/")[3] +
      "/" +
      props.location.pathname.split("/")[4] +
      "/" +
      props.location.pathname.split("/")[5] +
      "/" +
      props.location.pathname.split("/")[6] +
      "/start";
    props.history.push({
      pathname: newURL,
      state: {
        group_id: selectOptions.selectedStudyGroupId,
        leftMenu: props.location.state.leftMenu,
      },
    });
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
        selectedStudyGroupId: selectOptions.selectedStudyGroupId,
        selectedStudyGroupName: selectOptions.selectedStudyGroupName,
        setselectedResAssignId: "",
        selectedReportType: "",
        selectedAssessType: "",
      },
    });
  };

  const checkUserToShowStudyNote = () => {
    if (window.cordova) {
      var show = false;
      var userName = JSON.parse(localStorage.getItem("userData")).UserName;
      var allowAcount = [
        "tp12177447",
        "5051t0119",
        "5051t0219",
        "5051t0319",
        "5051s1b0119",
        "5051s1b0219",
        "5051s1b0319",
        "5081t0118",
        "5081t0218",
        "5081s1a0118",
        "5081s1a0218",
        "5096t0118",
        "tp16992702",
        "sp16992703",
      ];
      allowAcount.map((name) => {
        if (userName.indexOf(name) > -1) {
          show = true;
        }
      });
      //return show;

      return show;
    } else {
      return false;
    }
  };

  const renderBackBottom = () => {
    if (window.document.querySelector(".lrn_btn.lrn_pause_playback")) {
      window.document.querySelector(".lrn_btn.lrn_pause_playback").click();
    }
    let title = t("Back");
    let icon = "mdi-chevron-left";
    let backDivClassNAme = "back";
    if (layout == "activity") {
      if (layoutInfo.B2C == true) {
        backDivClassNAme = backDivClassNAme + " b2c";
        icon = "mdi-arrow-left";
        title =
          layoutInfo.backTitle[layoutInfo.backTitle[0].Lang == lang ? 0 : 1]
            .BookName;
      }
    }
    if (layout == "class" || layout == "supplementary-report") {
      if (layoutInfo.B2C == true) {
        backDivClassNAme = backDivClassNAme + " b2c";
        icon = "mdi-arrow-left";
      }
    }
    console.log(props.location.pathname.split("/").length);

    console.log("[renderBackBottom] :", title);
    return (
      <div
        className={`${backDivClassNAme}`}
        id={"studygroup_back"}
        onClick={() => {
          if (props.location.pathname.indexOf("/prizm/") != -1) {
            var rcenterURL =
              props.location.pathname.split("/")[0] +
              "/" +
              props.location.pathname.split("/")[1] +
              "/" +
              props.location.pathname.split("/")[2] +
              "/" +
              props.location.pathname.split("/")[3] +
              "/rcenter";

            if (props.location.pathname.indexOf("/prizm/") != -1) {
              if (props.location.pathname.split("/").length == 9) {
                var newURL =
                  props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4] +
                  "/" +
                  props.location.pathname.split("/")[5] +
                  "/" +
                  props.location.pathname.split("/")[6] +
                  "/result";

                props.history.push({
                  pathname: newURL,
                  state: {
                    group_id: selectOptions.selectedStudyGroupId,
                    leftMenu: props.location.state.leftMenu,
                  },
                });

                dispatch({
                  type: "SET_SELECTED_TYPE",
                  selectOptions: {
                    selectedStructureId: props.location.pathname.split("/")[6],
                    selectedStudyGroupId: selectOptions.selectedStudyGroupId,
                    selectedStudyGroupName:
                      selectOptions.selectedStudyGroupName,
                    setselectedResAssignId: "",
                    selectedReportType: "",
                    selectedAssessType: "",
                  },
                });
              } else {
                var rcenterURL =
                  props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/rcenter";

                props.history.push({
                  pathname: rcenterURL,
                });
                dispatch({
                  type: "SET_SELECTED_TYPE",
                  selectOptions: {
                    selectedStructureId: props.location.pathname.split("/")[6],
                    selectedStudyGroupId: selectOptions.selectedStudyGroupId,
                    selectedStudyGroupName:
                      selectOptions.selectedStudyGroupName,
                    setselectedResAssignId: "",
                    selectedReportType: "",
                    selectedAssessType: "",
                  },
                });
              }
            }
          } else {
            if (props.location.pathname.split("/").length == 8)
              props.history.push(
                props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4] +
                  "/" +
                  props.location.pathname.split("/")[5]
              );
            if (props.location.pathname.split("/").length == 9)
              props.history.push(
                props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4] +
                  "/" +
                  props.location.pathname.split("/")[5] +
                  "/" +
                  props.location.pathname.split("/")[6] +
                  (!layoutInfo.B2C
                    ? "/" + props.location.pathname.split("/")[7]
                    : "")
              );
            if (props.location.pathname.split("/").length == 10)
              props.history.push(
                props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4] +
                  "/" +
                  props.location.pathname.split("/")[5] +
                  "/" +
                  props.location.pathname.split("/")[6] +
                  "/" +
                  props.location.pathname.split("/")[7]
              );
            if (props.location.pathname.split("/").length == 7) {
              props.history.push(
                props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4] +
                  "/" +
                  props.location.pathname.split("/")[5]
              );
            }

            if (
              props.location.pathname.split("/").length == 6 &&
              props.location.pathname.split("/")[2] == "studygroup" &&
              props.location.pathname.split("/")[4] == "assessment"
            ) {
              props.history.push(
                props.location.pathname.split("/")[0] +
                  "/" +
                  props.location.pathname.split("/")[1] +
                  "/" +
                  props.location.pathname.split("/")[2] +
                  "/" +
                  props.location.pathname.split("/")[3] +
                  "/" +
                  props.location.pathname.split("/")[4]
              );
            } else {
              if (
                props.location.pathname.split("/").length == 6 ||
                props.location.pathname.split("/").length == 5
              ) {
                layoutInfo.B2C
                  ? props.history.push("/m/resource")
                  : props.history.push("/m/myschool");
              }
            }
          }
        }}
      >
        <div className="icon-btn">
          <i className={`icon mdi ${icon}`} />
        </div>
        <div className="back-btnText">{title}</div>
      </div>
    );
  };

  const renderRightSideBtn = () => {
    if (layout == "activity" && layoutInfo.B2C == true) {
      return (
        <div className="class-nb-buttons b2c">
          <div className="back-home">
            <img
              src={backHome}
              onClick={() => {
                props.history.push("/m/resource");
              }}
            />
          </div>
        </div>
      );
    }
    return "";
  };
  return (
    <nav
      id="dashboard"
      className={`navbar navbar-expand fixed-top class-nav-bar ${
        isAssessmentView ? "assessment" : ""
      } ${layout == "class" ? "studygroup" : ""}`}
    >
      <div className="main_nav_container">
        {loading && <Loading />}
        <div className={`be-navbar-header`}>
          {layout != "activity" &&
            layout != "class" &&
            layout != "supplementary-report" &&
            (logoNotClickable ? (
              <span
                className={
                  config.pickhHostVal == "PEC"
                    ? `navbar-brand-pec`
                    : `navbar-brand`
                }
              />
            ) : (
              <NavLink
                to={"/"}
                onClick={() => {
                  // if (window.cordova) {
                  //   window.ga.trackEvent(
                  //     "General",
                  //     "Click top logo",
                  //     "Role:" + UserData.UserType
                  //   );
                  // } else {
                  //   ReactGA.event({
                  //     category: "General",
                  //     action: "Click top logo",
                  //     label: "Role:" + UserData.UserType,
                  //   });
                  // }
                  gaTrack(
                    "send",
                    "event",
                    "General",
                    "Click top logo",
                    "Role:" + UserData.UserType
                  );
                }}
              >
                <span
                  className={
                    config.pickhHostVal == "PEC"
                      ? `navbar-brand-pec`
                      : `navbar-brand`
                  }
                />
              </NavLink>
            ))}
          {/* {layout == "activity" &&
            (backTitle == null ? (
              <div
                id={"assessment_back"}
                className="back"
                onClick={() => props.history.push("/m/assessment")}
              >
                <i className="icon mdi mdi-chevron-left" />
                <div className="back-btnText">{t("Assessment")}</div>
              </div>
            ) : props.location.pathname.indexOf("/result/") == -1 ? (
              <div
                id={"assessment_back"}
                className="back"
                onClick={() => props.history.push(props.backPath)}
              >
                <i className="icon mdi mdi-chevron-left" />
                <div className="back-btnText">
                  {backTitle != null &&
                    backTitle[lang == backTitle[0].Lang ? 0 : 1].backTitle}
                </div>
              </div>
            ) : (
              <div
                id={"assessment_back"}
                className="back"
                onClick={() =>
                  props.history.push(
                    props.location.pathname.split("/")[0] +
                      "/" +
                      props.location.pathname.split("/")[1] +
                      "/" +
                      props.location.pathname.split("/")[2] +
                      "/" +
                      props.location.pathname.split("/")[3] +
                      "/" +
                      props.location.pathname.split("/")[4] +
                      "/" +
                      props.location.pathname.split("/")[5] +
                      "/" +
                      props.location.pathname.split("/")[6] +
                      "/" +
                      props.location.pathname.split("/")[7]
                  )
                }
              >
                <i className="icon mdi mdi-chevron-left" />
                <div className="back-btnText">
                  {backTitle != null &&
                    backTitle[lang == backTitle[0].Lang ? 0 : 1].backTitle}
                </div>
              </div>
            ))} */}
          {
            layout != "dashboard" &&
              (props.location.pathname.split("/").length == 8 ||
              props.location.pathname.split("/").length == 9 ||
              props.location.pathname.split("/").length == 10 ||
              props.location.pathname.split("/").length == 7 ||
              props.location.pathname.split("/").length == 6 ||
              props.location.pathname.indexOf("/prizm/") != -1 ||
              props.location.pathname.split("/").length == 5 ? (
                renderBackBottom()
              ) : (
                <div
                  className="back"
                  onClick={() => {
                    layoutInfo.B2C
                      ? props.history.push("/m/resource")
                      : props.history.push("/m/myschool");
                  }}
                >
                  <i className="icon mdi mdi-chevron-left" />
                  <div className="back-btnText">
                    {layoutInfo.B2C ? t("My Supplementary") : t("My School")}
                  </div>
                </div>
              ))
            // (backTitle == null ? ( //enter teachingplan / ebook / resource / assessment / report page
            //   props.location.pathname.split("/").length == 8 ||
            //   props.location.pathname.split("/").length == 9 ||
            //   props.location.pathname.split("/").length == 10 ||
            //   props.location.pathname.split("/").length == 6 ? (
            //     renderBackBottom()
            //   ) : (
            //     <div
            //       className="back"
            //       onClick={() => {
            //         detectTitle === "myResources"
            //           ? props.history.push("/m/resource")
            //           : props.history.push("/m/myschool");
            //       }}
            //     >
            //       <i className="icon mdi mdi-chevron-left" />
            //       <div className="back-btnText">
            //         {detectTitle === "myResources"
            //           ? t("Supplementary")
            //           : t("My School")}
            //       </div>
            //     </div>
            //   )
            // ) : (
            //   <div
            //     className="back"
            //     onClick={() => props.history.push("/m/myschool")}
            //   >
            //     <i className="icon mdi mdi-chevron-left" />
            //     <div className="back-btnText">
            //       {backTitle != null &&
            //         backTitle[lang == backTitle[0].Lang ? 0 : 1].backTitle}
            //     </div>
            //   </div>
            // ))}
          }
        </div>
        {layout == "dashboard" &&
          config.pickhHostVal == "PEC" &&
          !isMobileOnly && (
            <div className="pear-eng-con-text">
              {t("Pearson English Connect")}
            </div>
          )}
        <div
          className={`class-nb-title ${
            props.location.pathname.indexOf("/m/") != -1 ||
            props.location.pathname.indexOf("/a/") != -1
              ? "none"
              : ""
          }`}
        >
          {layout == "activity" &&
            !layoutInfo.B2C &&
            props.location.pathname.indexOf("/result") == -1 && (
              <div style={{ color: "black" }}>
                {
                  layoutInfo.title[lang == layoutInfo.title[0].Lang ? 0 : 1]
                    .ResourceName
                }
              </div>
            )}
          {layout == "activity" &&
            !layoutInfo.B2C &&
            props.location.pathname.indexOf("/result") != -1 && (
              <div style={{ color: "black" }}>
                {t("Result") + " - "}{" "}
                {
                  layoutInfo.title[lang == layoutInfo.title[0].Lang ? 0 : 1]
                    .ResourceName
                }
              </div>
            )}
          {layout == "class" && (
            <div
              style={{ color: "black" }}
              id="study-group-view-title"
              className="study-group-view-title"
            >
              {
                layoutInfo.title[lang == layoutInfo.title[0].Lang ? 0 : 1]
                  .BookSeriesName
              }
              {/* {pageTitle &&
            pageTitle[lang == pageTitle[0].Lang ? 0 : 1].pageTitle} */}
            </div>
          )}
        </div>
        {props.location.pathname.indexOf("/prizm/") != -1 &&
          props.location.pathname.indexOf("/start") != -1 &&
          userData.UserType == "T" && (
            <div className={`class-nb-title-prizm`} style={{ left: "42%" }}>
              <button
                onClick={() => {
                  showResultPage();
                }}
                className="btn btn-primary assigned_students"
              >
                {t("Students' Result")}
              </button>
            </div>
          )}
        {props.location.pathname.indexOf("/prizm/") != -1 &&
          props.location.pathname.indexOf("/result") != -1 && (
            <div className={`class-nb-title-prizm`} style={{ left: "44%" }}>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showStartPage();
                }}
                className="btn btn-primary assigned_students"
              >
                {t("Exercises")}
              </button>
            </div>
          )}
        {userData ? (
          layout != "activity" ? (
            <User
              {...props}
              handleCloseLeftMenu={() => handleCloseLeftMenu()}
              n={n}
              logout={logout}
              t={t}
              // openSetting={this.state.openSetting}
              // openUser={this.state.openUser}
              // openHelp={this.state.openHelp}
              // openNotification={this.state.openNotification}
              // handleOpenSetting={this.handleOpenSetting}
              // handleOpenUser={this.handleOpenUser}
              // handleOpenHelp={this.handleOpenHelp}
              // handleOpenNotification={this.handleOpenNotification}
              // closeAll={closeAll}
            />
          ) : (
            renderRightSideBtn()
          )
        ) : (
          <Guest userData={userData} logout={logout} t={t} />
        )}
         <button onClick={this.logout} className="btn btn-primary">logout</button>
        { !window.cordova &&
          <iframe
              src={config.prizmRefreshURL}
              style={{
                visibility: "hidden",
                width: "0%",
              }}
          ></iframe>}
        <iframe
            src={config.prizmHiddenIframeURL}
            style={{
              visibility: "hidden",
              width: "0%",
              height: "0",
            }}
        ></iframe>
      </div>
    </nav>
  );
};

// export default NavBar;

export default withRouter(NavBar);
