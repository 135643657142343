/**
 * @flow
 * @relayHash 4e0a77071c0dfab4b5a93615d28f2713
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserPreferencesMutationVariables = {|
  SchoolYearId: string,
  PreferenceName: string,
  PreferenceValue: string,
|};
export type UpdateUserPreferencesMutationResponse = {|
  +modifyUserPreference: ?{|
    +UserId: ?string,
    +Preferences: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +PreferencesWithYear: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
  |}
|};
export type UpdateUserPreferencesMutation = {|
  variables: UpdateUserPreferencesMutationVariables,
  response: UpdateUserPreferencesMutationResponse,
|};
*/


/*
mutation UpdateUserPreferencesMutation(
  $SchoolYearId: String!
  $PreferenceName: String!
  $PreferenceValue: String!
) {
  modifyUserPreference(SchoolYearId: $SchoolYearId, PreferenceName: $PreferenceName, PreferenceValue: $PreferenceValue) {
    UserId
    Preferences {
      PreferenceName
      PreferenceValue
      SchoolYearId
      id
    }
    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {
      PreferenceName
      PreferenceValue
      SchoolYearId
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "PreferenceName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "PreferenceValue",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId",
  "type": "String"
},
v2 = [
  {
    "kind": "Variable",
    "name": "PreferenceName",
    "variableName": "PreferenceName",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "PreferenceValue",
    "variableName": "PreferenceValue",
    "type": "String!"
  },
  (v1/*: any*/)
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PreferenceName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "PreferenceValue",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SchoolYearId",
  "args": null,
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  (v1/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v9/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserPreferencesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "modifyUserPreference",
        "storageKey": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Preferences",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v7/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "PreferencesWithYear",
            "name": "Preferences",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v7/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserPreferencesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "modifyUserPreference",
        "storageKey": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Preferences",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v10/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "PreferencesWithYear",
            "name": "Preferences",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "plural": true,
            "selections": (v10/*: any*/)
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserPreferencesMutation",
    "id": null,
    "text": "mutation UpdateUserPreferencesMutation(\n  $SchoolYearId: String!\n  $PreferenceName: String!\n  $PreferenceValue: String!\n) {\n  modifyUserPreference(SchoolYearId: $SchoolYearId, PreferenceName: $PreferenceName, PreferenceValue: $PreferenceValue) {\n    UserId\n    Preferences {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48ab0b7e84f033cbca358de592ab6cf9';
module.exports = node;
