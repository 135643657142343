/**
 * @flow
 * @relayHash 8520b05a9d5482250f41053d213c4620
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetLearnositySuggestionMappingQueryVariables = {|
  ResourceId: $ReadOnlyArray<?string>
|};
export type GetLearnositySuggestionMappingQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagObjectiveName: ?string,
        +Criteria: ?string,
        +ConditionRule: ?string,
        +TagType: ?string,
        +MappedResources: ?$ReadOnlyArray<?{|
          +DisplayOrder: ?string,
          +Resource: ?{|
            +IngestionId: ?string,
            +ResourceId: ?string,
            +RelatedId: ?string,
            +ResourceDownloadUrl: ?string,
            +ResourceFileNameIfAny: ?string,
            +ResourceAccessUrl: ?string,
            +ResourceType: ?string,
            +Linkage: ?string,
            +FileSource: ?string,
            +Downloadable: ?string,
            +ResourceLangs: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +ResourceName: ?string,
            |}>,
          |},
        |}>,
      |}>
    |}>
  |}
|};
export type GetLearnositySuggestionMappingQuery = {|
  variables: GetLearnositySuggestionMappingQueryVariables,
  response: GetLearnositySuggestionMappingQueryResponse,
|};
*/


/*
query GetLearnositySuggestionMappingQuery(
  $ResourceId: [String]!
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      LearnositySuggestionMapping {
        TagName
        TagObjectiveName
        Criteria
        ConditionRule
        TagType
        MappedResources {
          DisplayOrder
          Resource {
            IngestionId
            ResourceId
            RelatedId
            ResourceDownloadUrl
            ResourceFileNameIfAny
            ResourceAccessUrl
            ResourceType
            Linkage
            FileSource
            Downloadable
            ResourceLangs {
              Lang
              ResourceName
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagObjectiveName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Criteria",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ConditionRule",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplayOrder",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IngestionId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "RelatedId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceDownloadUrl",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceFileNameIfAny",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceAccessUrl",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceType",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Linkage",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FileSource",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Downloadable",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ResourceLangs",
  "storageKey": null,
  "args": null,
  "concreteType": "resourceLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ResourceName",
      "args": null,
      "storageKey": null
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetLearnositySuggestionMappingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnositySuggestionMapping",
                "storageKey": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "MappedResources",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Resource",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "resource",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GetLearnositySuggestionMappingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "LearnositySuggestionMapping",
                "storageKey": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "MappedResources",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "Resource",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "resource",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v19/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GetLearnositySuggestionMappingQuery",
    "id": null,
    "text": "query GetLearnositySuggestionMappingQuery(\n  $ResourceId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      LearnositySuggestionMapping {\n        TagName\n        TagObjectiveName\n        Criteria\n        ConditionRule\n        TagType\n        MappedResources {\n          DisplayOrder\n          Resource {\n            IngestionId\n            ResourceId\n            RelatedId\n            ResourceDownloadUrl\n            ResourceFileNameIfAny\n            ResourceAccessUrl\n            ResourceType\n            Linkage\n            FileSource\n            Downloadable\n            ResourceLangs {\n              Lang\n              ResourceName\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27f202aaa2d351903594358818896e46';
module.exports = node;
