/**
 * @flow
 * @relayHash fd36b76d52a75bde3903ac525164f264
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FocusSubfocusGetLearnosityTagHierarchyQueryVariables = {|
  HierarchyReference: string
|};
export type FocusSubfocusGetLearnosityTagHierarchyQueryResponse = {|
  +learnosityTagHierarchyStructure: ?$ReadOnlyArray<?{|
    +HierarchyReference: ?string,
    +RelatedTagType: ?$ReadOnlyArray<?{|
      +HierarchyTagTypesId: ?string,
      +HierarchyReference: ?string,
      +TagType: ?string,
      +Description: ?string,
      +Tags: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagLongName: ?string,
      |}>,
      +ParentTagType: ?{|
        +HierarchyTagTypesId: ?string,
        +HierarchyReference: ?string,
        +TagType: ?string,
        +Description: ?string,
      |},
    |}>,
  |}>
|};
export type FocusSubfocusGetLearnosityTagHierarchyQuery = {|
  variables: FocusSubfocusGetLearnosityTagHierarchyQueryVariables,
  response: FocusSubfocusGetLearnosityTagHierarchyQueryResponse,
|};
*/


/*
query FocusSubfocusGetLearnosityTagHierarchyQuery(
  $HierarchyReference: String!
) {
  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
    HierarchyReference
    RelatedTagType {
      HierarchyTagTypesId
      HierarchyReference
      TagType
      Description
      Tags {
        TagName
        TagLongName
        id
      }
      ParentTagType {
        HierarchyTagTypesId
        HierarchyReference
        TagType
        Description
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "HierarchyReference",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "HierarchyReference",
    "variableName": "HierarchyReference",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReference",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyTagTypesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagLongName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FocusSubfocusGetLearnosityTagHierarchyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnosityTagHierarchyStructure",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "RelatedTagType",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Tags",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ParentTagType",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FocusSubfocusGetLearnosityTagHierarchyQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnosityTagHierarchyStructure",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "RelatedTagType",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Tags",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ParentTagType",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FocusSubfocusGetLearnosityTagHierarchyQuery",
    "id": null,
    "text": "query FocusSubfocusGetLearnosityTagHierarchyQuery(\n  $HierarchyReference: String!\n) {\n  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {\n    HierarchyReference\n    RelatedTagType {\n      HierarchyTagTypesId\n      HierarchyReference\n      TagType\n      Description\n      Tags {\n        TagName\n        TagLongName\n        id\n      }\n      ParentTagType {\n        HierarchyTagTypesId\n        HierarchyReference\n        TagType\n        Description\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4428f25c63d9eabb0d1aa59716976756';
module.exports = node;
