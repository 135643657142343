/**
 * @flow
 * @relayHash 620c8f282c766453251f40ab24627180
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TopicContentsQueryVariables = {|
  HierarchyReference: string,
  BookId: string,
  UserId: string,
|};
export type TopicContentsQueryResponse = {|
  +learnosityTagHierarchyStructure: ?$ReadOnlyArray<?{|
    +DisplaySequence: ?string,
    +HierarchyReferenceLabelEn: ?string,
    +HierarchyReferenceLabelChi: ?string,
    +HierarchyReference: ?string,
    +RelatedTagType: ?$ReadOnlyArray<?{|
      +Tags: ?$ReadOnlyArray<?{|
        +TagType: ?string,
        +TagName: ?string,
        +TagLongName: ?string,
        +TagDisplayNameEn: ?string,
        +TagDisplayNameChi: ?string,
        +DisplaySequence: ?string,
      |}>
    |}>,
    +AdoptedLearnosityResource: ?$ReadOnlyArray<?{|
      +LearnosityReferenceId: ?string,
      +Level1TagType: ?string,
      +Level1TagName: ?string,
      +Level2TagType: ?string,
      +Level2TagName: ?string,
      +RelatedResource: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
          +CompletedDate: ?string,
          +Score: ?string,
          +MaxScore: ?string,
          +IsCompleted: ?string,
        |}>,
      |}>,
    |}>,
  |}>
|};
export type TopicContentsQuery = {|
  variables: TopicContentsQueryVariables,
  response: TopicContentsQueryResponse,
|};
*/


/*
query TopicContentsQuery(
  $HierarchyReference: String!
  $BookId: String!
  $UserId: String!
) {
  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
    DisplaySequence
    HierarchyReferenceLabelEn
    HierarchyReferenceLabelChi
    HierarchyReference
    RelatedTagType {
      Tags {
        TagType
        TagName
        TagLongName
        TagDisplayNameEn
        TagDisplayNameChi
        DisplaySequence
        id
      }
      id
    }
    AdoptedLearnosityResource(BookId: $BookId) {
      LearnosityReferenceId
      Level1TagType
      Level1TagName
      Level2TagType
      Level2TagName
      RelatedResource(BookId: $BookId) {
        ResourceId
        SelfLearnAttemptHistory(UserId: $UserId, IncludeAssignmentRecord: false) {
          CompletedDate
          Score
          MaxScore
          IsCompleted
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "HierarchyReference",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "BookId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "HierarchyReference",
    "variableName": "HierarchyReference",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReferenceLabelEn",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReferenceLabelChi",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "HierarchyReference",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagLongName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagDisplayNameEn",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "TagDisplayNameChi",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId",
    "type": "String!"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnosityReferenceId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level1TagType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level1TagName",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level2TagType",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Level2TagName",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "SelfLearnAttemptHistory",
  "storageKey": null,
  "args": [
    {
      "kind": "Literal",
      "name": "IncludeAssignmentRecord",
      "value": false,
      "type": "Boolean"
    },
    {
      "kind": "Variable",
      "name": "UserId",
      "variableName": "UserId",
      "type": "String!"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "CompletedDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Score",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "MaxScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "IsCompleted",
      "args": null,
      "storageKey": null
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopicContentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnosityTagHierarchyStructure",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "RelatedTagType",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Tags",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedLearnosityResource",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "RelatedResource",
                "storageKey": null,
                "args": (v11/*: any*/),
                "concreteType": "resource",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TopicContentsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnosityTagHierarchyStructure",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "RelatedTagType",
            "storageKey": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Tags",
                "storageKey": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/),
                  (v19/*: any*/)
                ]
              },
              (v19/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AdoptedLearnosityResource",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "RelatedResource",
                "storageKey": null,
                "args": (v11/*: any*/),
                "concreteType": "resource",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ]
              },
              (v19/*: any*/)
            ]
          },
          (v19/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TopicContentsQuery",
    "id": null,
    "text": "query TopicContentsQuery(\n  $HierarchyReference: String!\n  $BookId: String!\n  $UserId: String!\n) {\n  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {\n    DisplaySequence\n    HierarchyReferenceLabelEn\n    HierarchyReferenceLabelChi\n    HierarchyReference\n    RelatedTagType {\n      Tags {\n        TagType\n        TagName\n        TagLongName\n        TagDisplayNameEn\n        TagDisplayNameChi\n        DisplaySequence\n        id\n      }\n      id\n    }\n    AdoptedLearnosityResource(BookId: $BookId) {\n      LearnosityReferenceId\n      Level1TagType\n      Level1TagName\n      Level2TagType\n      Level2TagName\n      RelatedResource(BookId: $BookId) {\n        ResourceId\n        SelfLearnAttemptHistory(UserId: $UserId, IncludeAssignmentRecord: false) {\n          CompletedDate\n          Score\n          MaxScore\n          IsCompleted\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '566a2ace548db4703397b52acf245e8d';
module.exports = node;
