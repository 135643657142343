//Main
import React, { Component, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../../Config/config";
// import { fetchQuery, graphql } from "relay-runtime";
// import environment from "../../../Environment";

import Guest from "../Guest";
import User from "./User";
import Loading from "../../../Loading/PEC/Loading";
import {isAndroid, isIOS, isMobileOnly} from "react-device-detect";

//Component
// import i18n from "../../../i18n";
//Css
import "./NavBar.scss";
//img
// import backHome from "../../../assets/img/back-home.svg";
import { NavLink } from "react-router-dom";
import NavBarPrizm from "./NavBarPrizm";
//import DashHandbook from '../Dashboard/DBPage/HandBook/DashHandbook';

//Component

const NavBar = (props) => {
  const dispatch = useDispatch();
  const { t, updateSchoolYear } = props;
  const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const userData = useSelector((state) => state.UserReducer.user);
  const userSchoolCode = userData?.School?.SchoolCode;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  var str = window.location.pathname;
  var n = str.indexOf("onboarding");
  var pin = str.indexOf("access");
  const [loading, setloading] = useState(false);
  const [logoNotClickable, setlogoNotClickable] = useState(
    props.logoNotClickable
  );
  const [isAssessmentView, setisAssessmentView] = useState(
    props.isAssessmentView
  );

  const [backTitle, setbackTitle] = useState(props.backTitle);
  useEffect(() => {
    setisAssessmentView(props.isAssessmentView);
  }, [props.isAssessmentView]);

  useEffect(() => {
    setbackTitle(props.backTitle);
  }, [props.backTitle]);
  useEffect(() => {
    setlogoNotClickable(props.logoNotClickable);
  }, [props.logoNotClickable]);

  const logout = () => {
    setloading(true);
    dispatch({
      type: "SET_USER",
      user: null,
    });
    // props.history.push("/");
    // this.setState({
    //   loading: false,
    // });
    setloading(false);
  };

  const checkNav = () => {
    var count = 0;
    var namesArr = ["/prizm", "/assessment"];

    namesArr.forEach(function (id, index) {
      if (props.location.pathname.indexOf(id) != -1) {
        count = count + 1;
      }
    });

    if (count == 0) {
      return true;
    }
    return false;
  };

  window.addEventListener("message", function(e) {
    console.log("HERE IS MESSAGE", JSON.stringify(e.data))
    const data = JSON.parse(e?.data);
    if (isIOS || isAndroid) {
      return;
    }
    if (data.hasOwnProperty("iesToken")){
      if (data?.iesToken) {
        window.localStorage.setItem("IESToken", data?.iesToken);
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    }
  });

  return (
    <>
      {checkNav() && (
        <nav
          id="dashboard"
          className={`navbar navbar-expand fixed-tops class-nav-bar`}
        >
          <div className="main_nav_container">
            {loading && <Loading />}
            {props.showFlag ? (
              <>
                <div
                  className={`be-navbar-header`}
                  onClick={() => {
                    const getUsersData = JSON.parse(
                      localStorage.getItem("userData")
                    );
                    userData.Preferences = getUsersData.Preferences;
                    dispatch({
                      type: "SET_USER_PREFERENCES",
                      user_preferences: getUsersData.Preferences,
                    });
                    props.history.push("/");
                  }}
                >
                  {/* <NavLink to={"/"}> */}
                  <span className={`navbar-brand-pec`} />
                  {/* </NavLink> */}
                </div>
                <div className="pear-eng-con-text">
                  {t("Pearson English Connect")}
                </div>
              </>
            ) : (
              ""
            )}

            <User
              {...props}
              handleCloseLeftMenu={() => handleCloseLeftMenu()}
              n={""}
              updateSchoolYear={updateSchoolYear}
              logout={logout}
              t={t}
              showFlag={props.showFlag}
              hanbookFlag={props.hanbookFlag}
              redirectToDashboard={() => {
                const getUsersData = JSON.parse(
                  localStorage.getItem("userData")
                );
                userData.Preferences = getUsersData.Preferences;
                dispatch({
                  type: "SET_USER_PREFERENCES",
                  user_preferences: getUsersData.Preferences,
                });
                props.history.push("/");
              }}
            />
          </div>
        </nav>
      )}
      { !window.cordova &&
      <iframe
          src={config.prizmRefreshURL}
          style={{
            visibility: "hidden",
            width: "0%",
            height: "0",
          }}
      ></iframe>
      }
      <iframe
          src={config.prizmHiddenIframeURL}
          style={{
            visibility: "hidden",
            width: "0%",
            height: "0",
          }}
      ></iframe>

    </>
  );
};

// export default NavBar;

export default withRouter(NavBar);
