/**
 * @flow
 * @relayHash fee647e4e492bb6a0f75f036f1fc8d0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewAllNotificationPECSubjectListQueryVariables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type ViewAllNotificationPECSubjectListQueryResponse = {|
  +user: ?{|
    +AssignmentBookSeriesList: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +DisplaySequence: ?number,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +BookSeriesName: ?string,
      |}>,
      +AdoptedBooks: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string
        |},
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string
        |},
      |}>,
    |}>
  |}
|};
export type ViewAllNotificationPECSubjectListQuery = {|
  variables: ViewAllNotificationPECSubjectListQueryVariables,
  response: ViewAllNotificationPECSubjectListQueryResponse,
|};
*/


/*
query ViewAllNotificationPECSubjectListQuery(
  $UserId: String!
  $SchoolYearId: String!
) {
  user(UserId: $UserId) {
    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {
      BookSeriesId
      DisplaySequence
      BookSeriesName {
        BookSeriesId
        BookSeriesName
      }
      AdoptedBooks(SchoolYearId: $SchoolYearId) {
        id
        BookId
        BookName {
          Lang
          BookName
        }
        BookImage {
          FilePath
          id
        }
      }
      Books {
        id
        BookId
        BookName {
          Lang
          BookName
        }
        BookImage {
          FilePath
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId",
    "type": "String!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookSeriesId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "DisplaySequence",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookSeriesName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookSeriesName",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "BookId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "BookName",
  "storageKey": null,
  "args": null,
  "concreteType": "bookLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BookName",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "FilePath",
  "args": null,
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "BookImage",
    "storageKey": null,
    "args": null,
    "concreteType": "systemFile",
    "plural": false,
    "selections": [
      (v9/*: any*/)
    ]
  }
],
v11 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "BookImage",
    "storageKey": null,
    "args": null,
    "concreteType": "systemFile",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      (v6/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewAllNotificationPECSubjectListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentBookSeriesList",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedBooks",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": (v10/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": null,
                "concreteType": "book",
                "plural": true,
                "selections": (v10/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewAllNotificationPECSubjectListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "AssignmentBookSeriesList",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "bookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "AdoptedBooks",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "book",
                "plural": true,
                "selections": (v11/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "Books",
                "storageKey": null,
                "args": null,
                "concreteType": "book",
                "plural": true,
                "selections": (v11/*: any*/)
              },
              (v6/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ViewAllNotificationPECSubjectListQuery",
    "id": null,
    "text": "query ViewAllNotificationPECSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {\n      BookSeriesId\n      DisplaySequence\n      BookSeriesName {\n        BookSeriesId\n        BookSeriesName\n      }\n      AdoptedBooks(SchoolYearId: $SchoolYearId) {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n      }\n      Books {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d9eaf7bf970ca59f8ae68f986c8f961';
module.exports = node;
