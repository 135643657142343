/**
 * @flow
 * @relayHash 3fcf22c53bc9d9bdb11a3f2a05614010
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginPlatformEnumType = "MOBILE" | "TABLET" | "WEB" | "%future added value";
export type LoginHawkMutationVariables = {|
  UserKey: string,
  platform: LoginPlatformEnumType,
|};
export type LoginHawkMutationResponse = {|
  +loginHawk: ?{|
    +SAMAssessToken: ?string,
    +SAMRefreshToken: ?string,
    +SAMSsoToken: ?string,
    +SessionId: ?string,
    +SAMExpiresIn: ?string,
    +User: ?{|
      +UserId: ?string,
      +UserType: ?string,
    |},
  |}
|};
export type LoginHawkMutation = {|
  variables: LoginHawkMutationVariables,
  response: LoginHawkMutationResponse,
|};
*/


/*
mutation LoginHawkMutation(
  $UserKey: String!
  $platform: LoginPlatformEnumType!
) {
  loginHawk(UserKey: $UserKey, platform: $platform) {
    SAMAssessToken
    SAMRefreshToken
    SAMSsoToken
    SessionId
    SAMExpiresIn
    User {
      UserId
      UserType
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "UserKey",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "platform",
    "type": "LoginPlatformEnumType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserKey",
    "variableName": "UserKey",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform",
    "type": "LoginPlatformEnumType!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMAssessToken",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMRefreshToken",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMSsoToken",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SessionId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "SAMExpiresIn",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserType",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginHawkMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loginHawk",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "User",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginHawkMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loginHawk",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "User",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginHawkMutation",
    "id": null,
    "text": "mutation LoginHawkMutation(\n  $UserKey: String!\n  $platform: LoginPlatformEnumType!\n) {\n  loginHawk(UserKey: $UserKey, platform: $platform) {\n    SAMAssessToken\n    SAMRefreshToken\n    SAMSsoToken\n    SessionId\n    SAMExpiresIn\n    User {\n      UserId\n      UserType\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c49429da1593fc6ad42ebdaafa0a8197';
module.exports = node;
