/**
 * @flow
 * @relayHash 433f384afa8c66638e9b25d3c5c501b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type SingleResultStudentReportQueryVariables = {|
  SessionId: string,
  Lang: LangEnumType,
  Credential?: ?string,
|};
export type SingleResultStudentReportQueryResponse = {|
  +learnositySelfLearnResult: ?{|
    +AttemptSessionId: ?string,
    +AttemptDate: ?string,
    +IsCompleted: ?string,
    +CompletedDate: ?string,
    +UserId: ?string,
    +ResourceId: ?string,
    +LearnositySessionResult: ?string,
    +UserDetail: ?{|
      +UserDisplayName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +UserName: ?string,
      |}>
    |},
  |}
|};
export type SingleResultStudentReportQuery = {|
  variables: SingleResultStudentReportQueryVariables,
  response: SingleResultStudentReportQueryResponse,
|};
*/


/*
query SingleResultStudentReportQuery(
  $SessionId: String!
  $Lang: LangEnumType!
  $Credential: String
) {
  learnositySelfLearnResult(SessionId: $SessionId) {
    AttemptSessionId
    AttemptDate
    IsCompleted
    CompletedDate
    UserId
    ResourceId
    LearnositySessionResult(Lang: $Lang, Credential: $Credential)
    UserDetail {
      UserDisplayName {
        Lang
        UserName
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "SessionId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Lang",
    "type": "LangEnumType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "Credential",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "SessionId",
    "variableName": "SessionId",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AttemptSessionId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "AttemptDate",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "IsCompleted",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "CompletedDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ResourceId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnositySessionResult",
  "args": [
    {
      "kind": "Variable",
      "name": "Credential",
      "variableName": "Credential",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "Lang",
      "variableName": "Lang",
      "type": "LangEnumType"
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "UserName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SingleResultStudentReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnositySelfLearnResult",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "UserDetail",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SingleResultStudentReportQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "learnositySelfLearnResult",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "UserDetail",
            "storageKey": null,
            "args": null,
            "concreteType": "user",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SingleResultStudentReportQuery",
    "id": null,
    "text": "query SingleResultStudentReportQuery(\n  $SessionId: String!\n  $Lang: LangEnumType!\n  $Credential: String\n) {\n  learnositySelfLearnResult(SessionId: $SessionId) {\n    AttemptSessionId\n    AttemptDate\n    IsCompleted\n    CompletedDate\n    UserId\n    ResourceId\n    LearnositySessionResult(Lang: $Lang, Credential: $Credential)\n    UserDetail {\n      UserDisplayName {\n        Lang\n        UserName\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fdb45b1452a2ed3c902e0a0947a43701';
module.exports = node;
