import React, { Component, useState, useEffect } from "react";
import ProgressBar from "../../../../../Feature/ProgressBar";
// import Loading from "../../../../../Loading/Loading";
import Loading from "../../../../../Loading/PEC/Loading";
import environment from "../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { useSelector } from "react-redux";

const FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery = graphql`
  query FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery(
    $ResourcesId: [String]!
  ) {
    resourceSearch(ResourceId: $ResourcesId) {
      Resources {
        LearnosityTagHierarchyRef
        LearnositySuggestionMapping {
          TagName
          TagObjectiveName
          Criteria
          ConditionRule
          TagType
        }
      }
    }
  }
`;

const FocusSubfocusGetLearnosityTagHierarchyQuery = graphql`
  query FocusSubfocusGetLearnosityTagHierarchyQuery(
    $HierarchyReference: String!
  ) {
    learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
      HierarchyReference
      RelatedTagType {
        HierarchyTagTypesId
        HierarchyReference
        TagType
        Description
        Tags {
          TagName
          TagLongName
        }
        ParentTagType {
          HierarchyTagTypesId
          HierarchyReference
          TagType
          Description
        }
      }
    }
  }
`;

function new_index(props) {
  const { B2C, t, resourceData, bookData, isInappBrowser, standalone, changeclick } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);

  const [openState, setopenState] = useState([]);
  const [focusAndSubFocus, setfocusAndSubFocus] = useState([]);
  const [tagsData, settagsData] = useState(props.tagsData);
  const [tagData, settagData] = useState(null);
  const [loading, setloading] = useState(true);
  const [questions, setquestions] = useState(props.questions);
  const [LearnosityTagHierarchyRef, setLearnosityTagHierarchyRef] = useState(
    null
  );
  const [focusup, setfocusup] = useState(
    null
  );
  const [sortOrder, setSortOrder] = useState('asc');

  console.log(props);
  useEffect(() => {
    //handle update
    setloading(true);
    getResourceLearnosityTagHierarchyNameQuery();
    if (LearnosityTagHierarchyRef) {
      getLearnosityTagHierarchyQuery();
    }
  }, [questions]);

  useEffect(() => {
    if (LearnosityTagHierarchyRef) {
      getLearnosityTagHierarchyQuery();
    }
  }, [LearnosityTagHierarchyRef, focusup]);

  useEffect(() => {
    //questions
    setquestions(props.questions);
  }, [props.questions]);

  const getScoreAndAverageClass = (scoreVal, scoreType) => {
    if (scoreVal === "") {
      return "";
    } else {
      if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      }
    }
  };

  const handleOpenTab = (id, type) => {
    // if (!B2C && !standalone) {
    //   var book =
    //       studyGroupData.BookSeries.Books &&
    //       studyGroupData.BookSeries.Books.length > 0
    //         ? studyGroupData.BookSeries.Books[0].BookName[0].BookName
    //         : "NoData",
    //     chapter =
    //       studyGroupData.BookSeries.Books &&
    //       studyGroupData.BookSeries.Books.length > 0
    //         ? studyGroupData.BookSeries.Books[0].Units[0].UnitName[0].UnitName
    //         : "NoData";
    // }
    var newOpenState = [...openState];
    if (openState.indexOf(id) == -1) {
      // var userType = "";
      // if (isInappBrowser) {
      //   userType = userType == "teacher" ? "T" : "S";
      // } else {
      //   userType = JSON.parse(userData).UserType;
      // }
      // console.log(studyGroupData);
      try {
        if (!standalone) {
          gaTrack(
            "send",
            "event",
            "Assessment",
            "Expand learning objective",
            "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            (B2C
              ? "NoData"
              : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            bookData.Books[0].BookName[0].BookName +
            ", Chapter:" +
            (bookData.Books && bookData.Books.length > 0
              ? bookData.Books[0].Units[0].UnitName[0].UnitName
              : "NoData") +
            ", Role:" +
            userType +
            `, From:${B2C ? "Supplementary" : "My Class"}`
          );
        } else {
          gaTrack(
            "send",
            "event",
            "Assessment",
            "Expand learning objective",
            "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            "NoData" +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            "NoData" +
            ", Chapter:" +
            "NoData" +
            ", Role:" +
            userType
          );
        }
      } catch (error) {
        console.log(error);
      }

      newOpenState.push(id);
      var height = 0;
      for (var i = 0; i < document.getElementById(id).children.length; i++) {
        height = height + document.getElementById(id).children[i].offsetHeight;
      }
      document.getElementById(id).style.height = height + "px";
      setopenState(newOpenState);
    } else {
      // var userType = "";
      // if (isInappBrowser) {
      //   userType = userType == "teacher" ? "T" : "S";
      // } else {
      //   userType = JSON.parse(userData).UserType;
      // }
      try {
        if (!standalone) {
          gaTrack(
            "send",
            "event",
            "Assessment",
            "Collapse learning objective",
            "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            (B2C
              ? "NoData"
              : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            bookData.Books[0].BookName[0].BookName +
            ", Chapter:" +
            (bookData.Books && bookData.Books.length > 0
              ? bookData.Books[0].Units[0].UnitName[0].UnitName
              : "NoData") +
            ", Role:" +
            userType +
            `, From:${B2C ? "Supplementary" : "My Class"}`
          );
        } else {
          gaTrack(
            "send",
            "event",
            "Assessment",
            "Collapse learning objective",
            "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            "NoData" +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            "NoData" +
            ", Chapter:" +
            "NoData" +
            ", Role:" +
            userType
          );
        }
      } catch (error) {
        console.log(error);
      }
      document.getElementById(id).style.height = 0;
      newOpenState.splice(openState.indexOf(id), 1);
      setopenState(newOpenState);
    }
  };

  const getResourceLearnosityTagHierarchyNameQuery = () => {
    var variables;

    if (isInappBrowser) {
      variables = {
        ResourcesId: [
          standalone == true
            ? props.match.params.resId.split("&")[0]
            : props.inappData.resourceId,
        ],
      };
    } else {
      variables = {
        ResourcesId: [props.match.params.resId.split("&")[0]],
      };
    }

    fetchQuery(
      environment,
      FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery,
      variables
    ).then((data) => {
      const {
        resourceSearch: { Resources = {} },
      } = data || {};
      console.log(data);
      if (!Resources) {
        console.log(
          "Faill to get Data : Resources LearnosityTagHierarchyRef null"
        );

        setfocusAndSubFocus([]);
        settagsData([]);
        setloading(false);
        return;
        // return this.props.history.push("/m/myschool");
      }
      settagData(data.resourceSearch.Resources[0].LearnositySuggestionMapping);
      setLearnosityTagHierarchyRef(
        data.resourceSearch.Resources[0].LearnosityTagHierarchyRef
      );
      setfocusup(changeclick);
      setloading(false);

      //call query get Hierarchy data
    });
  };

  const getLearnosityTagHierarchyQuery = () => {
    //console.log("Questions", questions);
    var variables = {
      HierarchyReference: LearnosityTagHierarchyRef,
    };
    fetchQuery(
      environment,
      FocusSubfocusGetLearnosityTagHierarchyQuery,
      variables
    ).then((data) => {
      console.log(data);
      if (!data.learnosityTagHierarchyStructure) {
        return console.log(
          "Faill to get learnosityTagHierarchyStructure, return null"
        );
      }
      if (data.learnosityTagHierarchyStructure.length == 0) {
        return console.log(
          "Faill to get learnosityTagHierarchyStructure, return empty array"
        );
      }

      var preSetHierarchy = [],
        _HierarchyData = [];
      data.learnosityTagHierarchyStructure.map((obj, index) => {
        obj.RelatedTagType.map((robj, rindex) => {
          preSetHierarchy.push(robj);
        });
      });

      preSetHierarchy.map((obj, index) => {
        if (!obj.ParentTagType) {
          _HierarchyData.push({
            ...obj,
          });
        }
      });

      preSetHierarchy.map((obj, index) => {
        if (obj.ParentTagType) {
          _HierarchyData.map((hobj, hindex) => {
            if (
              obj.ParentTagType.HierarchyTagTypesId == hobj.HierarchyTagTypesId
            ) {
              _HierarchyData[hindex].Childs = [{ ...obj }];
            }
          });
        }
      });
      var newQuestionsData = [...questions];
      newQuestionsData.map((obj, index) => {
        if (
          tagsData
            .map((i) => {
              return i.reference;
            })
            .indexOf(obj.name) != -1
        )
          newQuestionsData[index].tags =
            tagsData[
              tagsData
                .map((i) => {
                  return i.reference;
                })
                .indexOf(obj.name)
            ].tags;
      });
      newQuestionsData.map((obj) => {
        if (!obj.tags) {
          obj.tags = {};
        }
      });
      var focusAndSubFocus = [];
      //Focus
      _HierarchyData.map((hobj, hindex) => {
        if (hobj.ParentTagType == null) {
          newQuestionsData.map((qobj, qindex) => {
            if (typeof qobj.tags[hobj.TagType] !== "undefined") {
              // console.log(qobj.tags[hobj.TagType]);
              qobj.tags[hobj.TagType].map((tobj, tindex) => {
                if (
                  focusAndSubFocus.map((a) => a.Focus.Title).indexOf(tobj) == -1
                ) {
                  focusAndSubFocus.push({
                    Focus: {
                      Title: tobj,
                      LongTitle:
                        hobj.Tags[
                          hobj.Tags.map((i) => {
                            return i.TagName;
                          }).indexOf(tobj)
                        ].TagLongName,
                      TagType: hobj.TagType,
                      Description: hobj.Description,
                    },
                    SubFocus: [],
                  });
                }
              });
            }
          });
        }
        console.log(focusAndSubFocus);

        if (focusAndSubFocus.length == 0) {
          setloading(false);
          // setHierarchyData(_HierarchyData);
          setfocusAndSubFocus(focusAndSubFocus);
          return;
        }
        //SubFocus

        var subFocus = [];
        _HierarchyData.map((hobj, hindex) => {
          var topLevel = hobj.TagType;
          hobj.Childs &&
            hobj.Childs.map((cobj, cindex) => {
              newQuestionsData.map((qobj, qindex) => {
                if (qobj.tags[cobj.TagType]) {
                  qobj.tags[cobj.TagType].map((tobj, tindex) => {
                    if (qobj.tags[topLevel]) {
                      qobj.tags[topLevel].map((tlobj, tlindex) => {
                        // console.log("tobj", tobj);
                        if (
                          subFocus
                            .map((i) => {
                              return i.Title;
                            })
                            .indexOf(tobj) == -1
                        )
                          subFocus.push({
                            ParentTitle: tlobj,
                            ParentTagType: hobj.TagType,
                            Title: tobj,
                            TagType: cobj.TagType,
                            Description: cobj.Description,
                          });
                      });
                    }
                  });
                }
              });
            });
        });
        subFocus.map((sobj, sindex) => {
          focusAndSubFocus[
            focusAndSubFocus.map((a) => a.Focus.Title).indexOf(sobj.ParentTitle)
          ].SubFocus.push(sobj);
        });

        focusAndSubFocus.map((fobj, findex) => {
          var score = 0,
            max_score = 0;
          fobj.SubFocus.map((sobj, sindex) => {
            var sscore = 0,
              smax_score = 0;
            newQuestionsData.map((qobj, qindex) => {
              if (qobj.tags[sobj.TagType]) {
                if (qobj.tags[sobj.TagType].indexOf(sobj.Title) != -1) {
                  if (Array.isArray(qobj.data)) {
                    qobj.data.map((dobj, dindex) => {
                      max_score = max_score + dobj.question.scoring.max_score;
                      score = score + dobj.question.scoring.score;

                      smax_score = smax_score + dobj.question.scoring.max_score;
                      sscore = sscore + dobj.question.scoring.score;
                    });
                  } else {
                    max_score = max_score + qobj.data.max_score;
                    score = score + qobj.data.score;

                    smax_score = smax_score + qobj.data.max_score;
                    sscore = sscore + qobj.data.score;
                  }
                }
              }
            });

            focusAndSubFocus[findex].SubFocus[sindex].max_score = smax_score;
            focusAndSubFocus[findex].SubFocus[sindex].score = sscore;
          });
          focusAndSubFocus[findex].SubFocus.sort(function (a, b) {
            return a.Title.localeCompare(b.Title);
          });
          focusAndSubFocus[findex].max_score = max_score;
          focusAndSubFocus[findex].score = score;
        });
      });

      focusAndSubFocus.map((fobj, findex) => {
        fobj.SubFocus.map((sobj, sindex) => {
          if (
            tagData
              .map((i) => {
                return i.TagName;
              })
              .indexOf(sobj.Title) != -1
          ) {
            sobj.LongName =
              tagData[
                tagData
                  .map((i) => {
                    return i.TagName;
                  })
                  .indexOf(sobj.Title)
              ].TagObjectiveName;
          } else {
            sobj.LongName = "";
          }
        });
      });
      console.log(focusAndSubFocus);
      setloading(false);
      // setHierarchyData(_HierarchyData);
      setfocusAndSubFocus(focusAndSubFocus);
    });
  };

  const alphabetically = (a, b) => {
    // equal items sort equally
    if (a.score === b.score) {
        return 0;
    }

    // nulls sort after anything else
    if (a.score === null || a.score === "") {
        return 1;
    }
    if (b.score === null || b.score === "") {
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    
        return parseInt(a.score) < parseInt(b.score) ? -1 : 1;
  

    // if descending, highest sorts first
    //return a.score < b.score ? 1 : -1;
  }

  const alphabeticallyDesc = (a, b) => {
    // equal items sort equally
    if (a.score === b.score) {
        return 0;
    }

    // nulls sort after anything else
    if (a.score === null || a.score === "") {
        return 1;
    }
    if (b.score === null || b.score === "") {
        return -1;
    }

    
    // if descending, highest sorts first
    return parseInt(a.score) < parseInt(b.score) ? 1 : -1;
  }

  return (
    <>
      
      {loading && <Loading type="normal" />}
      {!loading && (
      <section className="parent__container">
<section className="suggestion__container">
  <details open className="suggestion">
    <summary className="suggestion__label">
      Score
    </summary>
    {
        focusAndSubFocus.map((fobj, findex) => {
    return <React.Fragment key={fobj.Focus.Title}>
    <div className="suggestions__mainsection">
      <div className="score_label_up_content">
        <label className="score_label_sub_content-text-head" onClick={()=>{
          // const sortedData = focusAndSubFocus.sort(function (a, b) {
          //   if (sortOrder == 'asc') {
          //   if (a.Focus.LongTitle < b.Focus.LongTitle) { return -1; }
          //   if (a.Focus.LongTitle > b.Focus.LongTitle) { return 1; }
          //   return 0;
          //   } else {
          //   if (a.Focus.LongTitle < b.Focus.LongTitle) { return 1; }
          //   if (a.Focus.LongTitle > b.Focus.LongTitle) { return -1; }
          //   return 0;
          //   }
          // })
          // setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
          // setfocusAndSubFocus(sortedData)

        }}>{fobj.Focus.LongTitle}
          {/* <label className="student_accordion_arrow">&nbsp;</label> */}
        </label>
        <label className="score_label_sub_content-text-head score_label_center" onClick={() => {
        // var sortedDataOverAll;
        //   if(sortOrder == "asc"){
        //     sortedDataOverAll = focusAndSubFocus.sort(alphabeticallyDesc)
        //   }else{
        //     sortedDataOverAll = focusAndSubFocus.sort(alphabetically)
        //   }
        //   setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
        //   setfocusAndSubFocus(sortedDataOverAll)
          }}>Score
        {/* <label className="student_accordion_arrow">&nbsp;</label> */}
        </label>
      </div>
      <React.Fragment key={fobj.Focus.LongTitle}>
                <div className="score_label_main_content">
        {/* <div className="score_label_sub_content"> */}
          {/* <label className="score_label_sub_content-text">{fobj.Focus.LongTitle}</label> */}
          <label className="score_label_sub_content-text">Overall</label>
          <div
            key={findex}
            className={`score_label_center student-progressbar ${getScoreAndAverageClass(
              fobj.score === 0
              ? "0"
              : Math.round(
                (fobj.score / fobj.max_score) *
                100
              ), "score")} progressbar_center_position`}
            role="progressbar"
            aria-valuenow={fobj.score === 0
              ? "0"
              : Math.round(
                (fobj.score / fobj.max_score) *
                100
              )}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": fobj.score === 0
            ? "0"
            : Math.round(
              (fobj.score / fobj.max_score) *
              100
            ) }}
          ></div>
        {/* </div>
        <span className="score_label_sub_content_border">&nbsp;</span> */}
      </div>
            </React.Fragment>
      {
        fobj.SubFocus.map((sobj, sindex) => {
          return (
            <React.Fragment key={sobj.Title}>
                <div className="score_label_main_content">
        {/* <div className="score_label_sub_content"> */}
          {/* <label className="score_label_sub_content-text">{fobj.Focus.LongTitle}</label> */}
          <label className="score_label_sub_content-text">{sobj.LongName}</label>
          <div
            key={sindex}
            className={`score_label_center student-progressbar ${getScoreAndAverageClass(
              sobj.score === 0
              ? "0"
              : Math.round(
                (sobj.score / sobj.max_score) *
                100
              ), "score")} progressbar_center_position`}
            role="progressbar"
            aria-valuenow={sobj.score === 0
              ? "0"
              : Math.round(
                (sobj.score / sobj.max_score) *
                100
              )}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": sobj.score === 0
            ? "0"
            : Math.round(
              (sobj.score / sobj.max_score) *
              100
            ) }}
          ></div>
        {/* </div>
        <span className="score_label_sub_content_border">&nbsp;</span> */}
      </div>
            </React.Fragment>
          );
        })}
         </div>
         </React.Fragment>
        })}
</details>
</section></section>)}
</>
    
  );
}
export default new_index;
