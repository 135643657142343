import React, { useState } from "react";
import newAssignmentIcon from "../../../assets/img/new-assignment.svg";
import { Trans } from "react-i18next";
import ReadResourceAssignmentMutation from "../../Feature/Function/ReadResourceAssignmentMutation";
import GetDueDateStyle from "../../Feature/Function/GetDueDateStyle";
import moment from "moment";

const NotificationItem = (props) => {
  const {
    notificationData,
    t,
    handleAssignmentDetails,
    from,
    doopenNotificationInfo,
  } = props;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const role = localStorage.getItem("role");
  const lang = localStorage.getItem("i18nextLng");
  const renderTheViews = (obj) => {
    var userIds = [];

    obj.AssignmentTargets.map((record, index) => {
      if (!record.UserId) {
        if (!record.StudyGroup) return;
        if (!record.StudyGroup.StudyGroupUser) return;
        var _userids = record.StudyGroup.StudyGroupUser.map((i) => {
          return i.UserId;
        });
        userIds = [...userIds, ..._userids];
      } else {
        userIds.push(record.UserId);
      }
    });
    userIds = [...new Set(userIds)];
    if (userIds.indexOf(userData.UserId) != -1) {
      //it will show the views for teacher only, so no need to check is student or not
      userIds.splice(userIds.indexOf(userData.UserId), 1);
    }
    return obj.AssignmentTargetStatus.length + "/" + userIds.length;
  };

  const renderBody = (targets) => {
    if (role == "student") {
      if (targets.AssignmentOrSharing == "Sharing") {
        return (
          <>
            <div className="notification__handbook-main-heading">
            <label className="main__handbooks-label-unread">
            {targets.SharingObj.SharingReadTargetStatus.length ==0  && 
            ( <span className="main__handbooks-unread"></span>)}</label>
           
              {
                targets.SharingObj.RelatedResourcesConfig[0].Resource
                  .ResourceLangs[
                  targets.SharingObj.RelatedResourcesConfig[0].Resource
                    .ResourceLangs[0].Lang == lang
                    ? 0
                    : 1
                ].ResourceName+ " - "}
                {t("has been shared")}
            </div>
            <div className="notification__handbook-main-description" title={targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                .BookSeriesName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                  .BookSeriesName[0].Lang == lang
                  ? 0
                  : 1
              ].BookSeriesName + " "+ targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
              .BookName[
              targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                .BookName[0].Lang == lang
                ? 0
                : 1
            ].BookName + " - " + t("Class") + " "+ targets.SharingObj.RelatedResourcesConfig[0].StudyGroup
                  .StudyGroupName[[0].Lang == lang ? 0 : 1].StudyGroupName
              }>
            <label class="main__handbooks-label-unread"></label>
              {targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                .BookSeriesName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                  .BookSeriesName[0].Lang == lang
                  ? 0
                  : 1
              ].BookSeriesName + " "}
              {targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                .BookName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                  .BookName[0].Lang == lang
                  ? 0
                  : 1
              ].BookName  + " - "}
              {t("Class")}
              {
                targets.SharingObj.RelatedResourcesConfig[0].StudyGroup
                  .StudyGroupName[[0].Lang == lang ? 0 : 1].StudyGroupName
              }
            </div>
            <span className="notification__handbook-main-border"></span>
          </>
        );
      } else {
      }
    }
    if (role == "teacher") {
      if (targets.AssignmentOrSharing == "Sharing") {
        return (
          <>
            <div className="notification__handbook-main-heading">
            {/* {targets.SharingObj.SharingReadTargetStatus.length ==0 && 
            ( <span className="main__handbooks-unread"></span>)} */}
            
              {targets.SharingObj.RelatedResourcesConfig[0].Resource
                .ResourceLangs[
                targets.SharingObj.RelatedResourcesConfig[0].Resource
                  .ResourceLangs[0].Lang == lang
                  ? 0
                  : 1 
              ].ResourceName + " - "}
              {/* {t("has been shown")} */}
              {t("has been shared")}
            </div>
            <div className="notification__handbook-main-description" title={targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                .BookSeriesName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                  .BookSeriesName[0].Lang == lang
                  ? 0
                  : 1
              ].BookSeriesName + " "+ targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                .BookName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                  .BookName[0].Lang == lang
                  ? 0
                  : 1
              ].BookName + " - " + t("Class") +" "+ targets.SharingObj.RelatedResourcesConfig[0].StudyGroup
                  .StudyGroupName[[0].Lang == lang ? 0 : 1].StudyGroupName
              }>
              {/* <label class="main__handbooks-label-unread"></label> */}
              {targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                .BookSeriesName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.BookSeries
                  .BookSeriesName[0].Lang == lang
                  ? 0
                  : 1
              ].BookSeriesName + " "}
              {/* {t("Book")}  */}
              {targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                .BookName[
                targets.SharingObj.RelatedResourcesConfig[0].Resource.Book
                  .BookName[0].Lang == lang
                  ? 0
                  : 1
              ].BookName + " - "}
              {t("Class")+ " "}
              {
                targets.SharingObj.RelatedResourcesConfig[0].StudyGroup
                  .StudyGroupName[[0].Lang == lang ? 0 : 1].StudyGroupName
              }
            </div>
            <span className="notification__handbook-main-border"></span>
          </>
        );
      } else {
      }
    }
  };

  const handleAssignmentIsView = (obj, returnBool) => {
    if (
      obj.AssignmentTargetStatus.map((i) => {
        return i.UserId;
      }).indexOf(userData.UserId) != -1
    ) {
      return returnBool ? false : "";
    }

    return returnBool ? (
      true
    ) : (
      <div>
        <img src={newAssignmentIcon}></img>
      </div>
    );
  };

  const openDetails = (obj) => {
    gaTrack(
      "send",
      "event",
      "Assignment",
      "Open",
      "Position:" + from + ", Role:" + userData.UserType
    );
    if (role == "student") {
      // if (handleAssignmentIsView(obj, true)) {
      //   var variables = {
      //     ResourceAssignId: obj.ResourceAssignId,
      //   };
      //   ReadResourceAssignmentMutation(variables, (data) => {
      //     if (data.readResourceAssignment == "true") {
      //       window.setnotificationUnreadCount();
      //     }
      //   });
      // }

      var targetMyschool = document.querySelectorAll(
        `[data-myschool-handbook-id='${obj.ResourceAssignId}']`
      );
      let targetHandbook = document.querySelectorAll(
        `[data-studygroup-handbook-id='${obj.ResourceAssignId}']`
      );
      if (targetMyschool.length > 0) {
        targetMyschool[0].innerHTML = "";
      }
      if (targetHandbook.length > 0) {
        targetHandbook[0].innerHTML = "";
      }
    }

    handleAssignmentDetails({
      isOpen: true,
      resId: obj.SharingObj.SharingId,
      AssignmentName:
        obj.SharingObj.RelatedResourcesConfig[0].Resource.ResourceLangs[
          obj.SharingObj.RelatedResourcesConfig[0].Resource.ResourceLangs[0]
            .Lang == lang
            ? 0
            : 1
        ].ResourceName + " - ",
    });
  };

  return (
    <>
      <div className="notification__handbook-message">
        {notificationData.map((data, index) => {
          return (
            <div
              key={index}
              className="notification__handbook-main"
              onClick={() => {
                doopenNotificationInfo(data);
              }}
            >
              {renderBody(data)}
            </div>
          );
        })}
        {/* )}*/}
      </div>
    </>
  );
};

export default NotificationItem;
