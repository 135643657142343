import React, { useEffect, useState } from "react";
import environment from "../../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import Loading from "../../../../../../Loading/PEC/Loading";
import { useDispatch, useSelector } from "react-redux";
import sorting_arrow_black from "../../../../../../../assets/img/pec/all-images/sorting-arrow.png";
import sorting_arrow_black_asc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc.png";
import sorting_arrow_black_desc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc.png";
import sorting_arrow_white from "../../../../../../../assets/img/pec/all-images/sorting-arrow-white.png";
import sorting_arrow_white_asc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc-white.png";
import sorting_arrow_white_desc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc-white.png";

const StudentListGetLearnosityTeacherReportQuery = graphql`
  query StudentListGetLearnosityTeacherReportQuery(
    $ResourceId: [String]!
    $StudyGroupId: String!
    $SchoolYearId: String!
    $IncludeAssignmentRecord: Boolean
  ) {
    studyGroup(StudyGroupId: $StudyGroupId) {
      StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
        StudyGroup{
          StudyGroupName {
            Lang
            StudyGroupName
          }
        }
        ClassNumber
        User {
          UserId
          UserDisplayName {
            Lang
            UserName
          }
        }
      }
    }
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        TagData
        SelfLearnFirstAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
        SelfLearnLastAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
        SelfLearnAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
      }
    }
  }
`;

function index(props) {
  // const _selectedStudyGroupId = props.selectedStudyGroupId;
  const {
    isInappBrowser,
    t,
    assignmentList,
    standalone,
    resourceId,
    assignmentData,
    classGroup,
    classFlag
  } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const lang = useSelector((state) => state.SystemReducer.lang);
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc');

  const [loading, setloading] = useState(true);
  const [teacherReport, setteacherReport] = useState(null);
  const [initReady, setinitReady] = useState(false);
  const [tagsData, settagsData] = useState(null);
  const [questionCount, setquestionCount] = useState(0);
  const[classAvgLearnosity, setclassAvgLearnosity]= useState("");
  const [selectedStudyGroupId, setselectedStudyGroupId] = useState(
    props.selectedStudyGroupId
  );
  const [classAvgProgress, setclassAvgProgress] = useState("");
  const [selectedReportType, setselectedReportType] = useState(
    props.selectedReportType
  ); //SelfLearnFirstAttemptHistoryByStudyGroup /  SelfLearnLastAttemptHistoryByStudyGroup
  const [selectedResAssignId, setselectedResAssignId] = useState(
    props.selectedResAssignId
  );
  const [selectedAssessType, setselectedAssessType] = useState(
    props.selectedAssessType
  );

  const entry = useSelector(
    (state) => state.EntryOfAssessmentReducer.entryPoints
  );
  const dispatch = useDispatch();
  console.log(selectedAssessType, selectedReportType);

  useEffect(() => {
    setloading(true);
    // var userType = "";
    // if (isInappBrowser) {
    //   userType = userType == "teacher" ? "T" : "S";
    // } else {
    //   userType = JSON.parse(userData).UserType;
    // }
    if (
      selectedAssessType === "Self Assessment" ||
      selectedAssessType === "All Result"
    ) {
      if (!standalone) {
        // gaTrack(
        //   "send",
        //   "event",
        //   "Assessment",
        //   "Change class",
        //   "Class Name:" +
        //     studyGroupData.StudyGroupName[0].StudyGroupName +
        //     ", Assessment:" +
        //     resourceData[0].ResourceName +
        //     ", Resource ID:" +
        //     resourceIngestionIdGa +
        //     ", Level:" +
        //     studyGroupData.Level.LevelName[0].LevelName +
        //     ", Book Series:" +
        //     studyGroupData.BookSeries.BookSeriesName[0].BookSeriesName +
        //     ", Book:" +
        //     studyGroupData.BookSeries.Books[0].BookName[0].BookName +
        //     ", Chapter:" +
        //     studyGroupData.BookSeries.Books[0].Units[0].UnitName[0].UnitName +
        //     ", Role:" +
        //     userType
        // );
      }

      
    }
    if (selectedAssessType === "Assignment") {
      getLearnosityTeacherReportFromAssignment();
    }
  }, [
    selectedReportType,
    assignmentData,
    selectedAssessType,
  ]);

  useEffect(()=>{
    // alert('inside')
    console.log('insideuseeffect',{classFlag, selectedStudyGroupId, 'propselectedStudyGroupId': props.selectedStudyGroupId, loading})
    if (selectedStudyGroupId) {
      setloading(true)
      getLearnosityTeacherReport();
    }
  },[selectedStudyGroupId])

  useEffect(() => {
    setselectedStudyGroupId(props.selectedStudyGroupId);
    setselectedReportType(props.selectedReportType);
    setselectedResAssignId(props.selectedResAssignId);
    setselectedAssessType(props.selectedAssessType);
  }, [
    props.selectedStudyGroupId  ,
    props.selectedReportType,
    props.selectedResAssignId,
    props.selectedAssessType,
  ]);

  const getLearnosityTeacherReport = () => {
    var value;

    if (!isInappBrowser) {
      value = {
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: selectedStudyGroupId,
        ResourceId: [resourceId],
        IncludeAssignmentRecord:
          selectedAssessType === "All Result" ? true : false,
        Lang: lang == "en-US" ? "EN" : "TC",
      };
    } else {
      value = {
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: selectedStudyGroupId,
        ResourceId: [
          standalone == true
            ? props.match.params.resId.split("&")[0]
            : props.inappData.resourceId,
        ],
        IncludeAssignmentRecord:
          selectedAssessType === "All Result" ? true : false,
        Lang: lang == "en-US" ? "EN" : "TC",
      };
    }
    fetchQuery(
      environment,
      StudentListGetLearnosityTeacherReportQuery,
      value
    ).then((data) => {
      console.log(data);

      const {
        studyGroup: { StudyGroupUser = [{}] },
        resourceSearch: { Resources = [{}] },
      } = data || {};
      console.log(Resources, StudyGroupUser);
      const TagData = JSON.parse(Resources[0].TagData);
      //start handle the list of users in studygroup
      /*
        {
          UserId
          UserDisplayName
          AttemptHistory {  //First/Last
            session_id
            QuestionsData [
              q1 {
                max_score
                score
                ...
              }
            ]
          }
        }
      */
      var argRec = [];
      if (!Resources) {
        argRec = Resources;
      }
      var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
      handleTeacherReortData(Resources, StudyGroupUserTemp, TagData);
      console.log(Resources, StudyGroupUserTemp);
    });
  };

  const getLearnosityTeacherReportFromAssignment = () => {

    const assignStudnetLists = assignmentData.AssignmentTargets.reduce(
      (records, current) => {
        const { UserId, StudyGroup, User } = current;

        if (!UserId) {
          if (!StudyGroup) return;
          if (!StudyGroup.StudyGroupUser) return;
          StudyGroup.StudyGroupUser.map((obj) => {
            records.push({
              User: {
                UserId: obj.UserId,
                UserDisplayName: obj.User.UserDisplayName,
              },
              ClassNumber: obj.ClassNumber,
              StudyGroupName:
                StudyGroup.StudyGroupName[
                  StudyGroup.StudyGroupName[0].Lang === lang ? 0 : 1
                ].StudyGroupName,
            });
          });
        } else {
          records.push({
            User: {
              UserId: UserId,
              UserDisplayName: User.UserDisplayName,
            },
            ClassNumber: User.BelongsToStudyGroup[0].ClassNumber,
            StudyGroupName:
              User.BelongsToStudyGroup[0].StudyGroup.StudyGroupName[
                User.BelongsToStudyGroup[0].StudyGroup.StudyGroupName[0]
                  .Lang === lang
                  ? 0
                  : 1
              ].StudyGroupName,
          });
        }

        return records;
      },
      []
    );
    console.log(assignStudnetLists);

    var argRec = [];
    if (!assignmentData.records) {
      argRec = assignmentData.records;
    }
    handleTeacherReortData(
      assignmentData.records,
      assignStudnetLists,
      assignmentList.TagData
    );
  };

  const handleTeacherReortData = (dataLists, studentLists, TagData) => {
    let teacherReportTableData = [],
      attemptsData = [],
      questionCount = 0;
      setclassAvgLearnosity("")
      setclassAvgProgress("")
    //var classOverAll = "";

    if (studentLists === null || typeof studentLists == "undefined") {
      studentLists = [];
    }
    if (dataLists === null || typeof dataLists == "undefined") {
      dataLists = [];
    }
    // if(dataListsArg){
    //   dataLists = dataListsArg;
    // }

    // if(TagDataArg){
    //   TagData = TagDataArg;
    // }
    if (dataLists.length != 0 && dataLists[0][selectedReportType].length != 0) {
      dataLists[0][selectedReportType].map((attm, index) => {
        var json_data = JSON.parse(attm.LearnositySessionRawResult);

        if (json_data) {
          console.log(json_data);
          questionCount = json_data.items.length;
          let _temp;
          if (selectedAssessType === "Assignment") {
            _temp =
              attm.UserDetail.BelongsToStudyGroup[0].StudyGroup.StudyGroupName;
          }

          attemptsData.push({
            StudyGroupName:
              selectedAssessType === "Assignment"
                ? _temp[_temp[0].Lang === lang ? 0 : 1].StudyGroupName
                : studentLists[0].StudyGroup.StudyGroupName[0
                ].StudyGroupName,
            ClassNumber: attm.UserDetail.BelongsToStudyGroup[0].ClassNumber,
            UserId: attm.UserDetail.UserId,
            user_id: json_data.user_id,
            UserDisplayName: attm.UserDetail.UserDisplayName,
            AttemptDate: attm.AttemptDate,
            AttemptHistory: {
              session_id: json_data.session_id,
              QuestionsData: [...json_data.items],
              score: json_data.score,
              max_score: json_data.max_score,
            },
          });
        }

      });
    }
    console.log(attemptsData);
    console.log(studentLists, teacherReportTableData);

    if (studentLists.length > 0) {
      studentLists.map((obj, index) => {
        var matchId = attemptsData
          .map((i) => {
            return i.UserId;
          })
          .indexOf(obj.User.UserId);
        if (matchId != -1) {
          teacherReportTableData.push(attemptsData[matchId]);
        } else {
          teacherReportTableData.push({
            StudyGroupName:
              selectedAssessType === "Assignment" ? obj.StudyGroupName : obj.StudyGroup.StudyGroupName[0].StudyGroupName,
            ClassNumber: obj.ClassNumber,
            UserId: obj.User.UserId,
            UserDisplayName: obj.User.UserDisplayName,
            user_id: null,
            AttemptDate: null,
            AttemptHistory: {
              session_id: null,
              QuestionsData: [],
              score: null,
              max_score: 0,
            },
          });
        }
      });
    }
    if (dataLists.length != 0) {
      if (dataLists[0][selectedReportType].length != 0) {
        //For Question bar / Focus&SubFocus [Start]

        var newArray = [];
        dataLists[0][selectedReportType].map((uobj, i) => {
          var json_data = JSON.parse(uobj.LearnositySessionRawResult);
          console.log(json_data);
          if (json_data) {
            json_data.items.map((questionObj, qindex) => {
              if (i == 0) {
                newArray.push({
                  name: questionObj.reference,
                  data: [
                    {
                      user_id: json_data.user_id,
                      question: questionObj,
                    },
                  ],
                });
              } else {
                newArray[qindex].data.push({
                  user_id: json_data.user_id,
                  question: questionObj,
                });
              }
              // });
            });
          }

        });
        console.log(newArray, "For Question bar / Focus&SubFocus");

        //For Question bar / Focus&SubFocus [End]

        //manage users data on charts [Start]
        var charts = {};
        // var userKey = Object.keys(data[e].users);
        var StudentsScore = [];
        let maxScore = 0;

        teacherReportTableData.map((userObj, i) => {
          let totalScore = 0;
          if (userObj.AttemptHistory.session_id == null) return;
          if (maxScore == 0) {
            maxScore = userObj.AttemptHistory.max_score;
            totalScore = userObj.AttemptHistory.score;
          } else {
            totalScore = userObj.AttemptHistory.score;
          }
          StudentsScore.push({
            StudentName: userObj.user_id,
            totalScore: totalScore,
          });
        });
        // console.log(StudentsScore, "StudentsScore");
        // manage no.submmited
        const studScorePercents = StudentsScore.map((obj1) => {
          return Math.round(parseFloat(((obj1.totalScore / maxScore) * 100).toFixed(0)));
        });

        var chartsRage = {
          zeroToTwenty: 0,
          twentyToForty: 0,
          fortyToSixty: 0,
          sixtyToEighty: 0,
          eightyTohundred: 0,
        };

        for (let studScorePercent of studScorePercents) {
          // console.log(studScorePercent);
          if (studScorePercent <= 20) {
            chartsRage.zeroToTwenty++;
          }
          if (studScorePercent > 20 && studScorePercent <= 40) {
            chartsRage.twentyToForty++;
          }

          if (studScorePercent > 40 && studScorePercent <= 60) {
            chartsRage.fortyToSixty++;
          }
          if (studScorePercent > 60 && studScorePercent <= 80) {
            chartsRage.sixtyToEighty++;
          }
          if (studScorePercent > 80 && studScorePercent <= 100) {
            chartsRage.eightyTohundred++;
          }
        }

        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = StudentsScore.length - 1; i >= 0; i--) {
          tmp = StudentsScore[i].totalScore;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        const highestFinal = (highest / maxScore) * 100;
        const lowestFinal = (lowest / maxScore) * 100;

        var aveScore = 0,
          temp = 0;

        StudentsScore.map((obj, i) => {
          // console.log(obj.totalScore);

          temp += obj.totalScore;


        });
        //aveScore = Math.round((temp / StudentsScore.length / maxScore) * 100); suresh
        var percentageT = 0;

        StudentsScore.map((obj1) => {
          let temp = parseFloat(obj1.totalScore);
          var percentageTemp = Math.round((temp / maxScore) * 100)
          percentageT = percentageTemp + percentageT;

        });

        aveScore = Math.round(percentageT / StudentsScore.length);
        console.log(aveScore, temp, StudentsScore.length, maxScore);
        //finAvg = Math.round(finscore);
        setclassAvgLearnosity(Math.round(aveScore));
        setclassAvgProgress(
          getScoreAndAverageClass(Math.round(aveScore), "score")
        );
        charts = {
          DoughnutChartData: {
            aveScore: aveScore.toFixed(0),
            highestFinal: highestFinal.toFixed(0),
            lowestFinal: lowestFinal.toFixed(0),
          },
          BarChartData: {
            chartsRage: chartsRage,
          },
          noOfSubmit: StudentsScore.length,
        };
        console.log(
          charts,
          "manage users data on charts",
          teacherReportTableData
        );
        //manage users data on charts [End]
        const _tempInfo = {
          dataLists: dataLists,
          TagData: TagData,
        };
        props.handleReportData(newArray, charts, TagData, _tempInfo);
      } else {
        props.handleReportData(
          [],
          {
            DoughnutChartData: {
              aveScore: 0,
              highestFinal: 0,
              lowestFinal: 0,
            },
            BarChartData: {
              chartsRage: {
                zeroToTwenty: 0,
                twentyToForty: 0,
                fortyToSixty: 0,
                sixtyToEighty: 0,
                eightyTohundred: 0,
              },
            },
          },
          null,
          []
        );
      }
    } else {
      props.handleReportData(
        [],
        {
          DoughnutChartData: {
            aveScore: 0,
            highestFinal: 0,
            lowestFinal: 0,
          },
          BarChartData: {
            chartsRage: {
              zeroToTwenty: 0,
              twentyToForty: 0,
              fortyToSixty: 0,
              sixtyToEighty: 0,
              eightyTohundred: 0,
            },
          },
        },
        null,
        []
      );
    }

    setteacherReport(teacherReportTableData);
    setquestionCount(questionCount);
    settagsData(TagData); //  For tag
    setloading(false);
  };

  const getScoreAndAverageClass = (scoreVal, scoreType) => {
    if (scoreVal === "") {
      return "";
    } else {
      if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      }
    }
  };

  const renderQuestionTitle = () => {
    var titles = [];
    for (let i = 0; i < questionCount; i++) {


      titles.push(<th key={i} className="main__section-student-unit-grid-details-bottomheading-primary result-page-th-heading result-table-heading" onClick={()=>{
        // setSortColumn(`Item ${i + 1}`)
        // setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
      }}>
         <div className="student-result-container student-result-container-gap">
                        <div
                          className="student-result-container-left"
                         
                        >
                          Item {i + 1}
                        </div>
                        {/* <div className="student-result-container-right">
                          <img
                            className="student-result-container-img"
                            src={sortColumn != `Item ${i + 1}` ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_asc: sorting_arrow_black_desc}
                            alt="icon"
                          />
                        </div> */}
                      </div>
      </th>

      );
    }
    return titles;
  };

  
  const renderQuestionEmptyrow = () => {
    var titles = [];
    for (let i = 0; i < questionCount; i++) {


      titles.push( 
        <td className="progress-bar-center-position single-progress-bar-center-position "></td>
      );
    }
    return titles;
  };
  const renderQuestionEmpty = () => {
    var titles = [];
    for (let i = 0; i < questionCount; i++) {


      titles.push( 
      <td
        // className={`progress-bar-center-position single-progress-bar-center-position ${singleColumnClass}`}
        className={`progress-bar-center-position single-progress-bar-center-position ${questionCount.length<= 1 ? "single-column-border-radius" : ""}`}
      >
        
      </td>
        

      );
    }
    return titles;
  };

  const alphabetically = (a, b) => {
    // equal items sort equally
    if (a.AttemptHistory.score === b.AttemptHistory.score) {
        return 0;
    }

    // nulls sort after anything else
    if (a.AttemptHistory.score === null || a.AttemptHistory.score === "") {
        return 1;
    }
    if (b.AttemptHistory.score === null || b.AttemptHistory.score === "") {
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    
        return parseInt(a.AttemptHistory.score) < parseInt(b.AttemptHistory.score) ? -1 : 1;
  

    // if descending, highest sorts first
    //return a.AttemptHistory.score < b.AttemptHistory.score ? 1 : -1;
}

const alphabeticallyDesc = (a, b) => {
  // equal items sort equally
  if (a.AttemptHistory.score === b.AttemptHistory.score) {
      return 0;
  }

  // nulls sort after anything else
  if (a.AttemptHistory.score === null || a.AttemptHistory.score === "") {
      return 1;
  }
  if (b.AttemptHistory.score === null || b.AttemptHistory.score === "") {
      return -1;
  }

   
  // if descending, highest sorts first
  return parseInt(a.AttemptHistory.score) < parseInt(b.AttemptHistory.score) ? 1 : -1;
}

  const onClickStudentTitle = (id, session_id, attemptDate) => {
    var gqString = "Click student performance";

    var userType = "";
    // if (isInappBrowser) {
    //   userType = userType == "teacher" ? "T" : "S";
    // } else {
    //   userType = JSON.parse(userData).UserType;
    // }
    if (!standalone) {
      // gaTrack(
      //   "send",
      //   "event",
      //   "Assessment",
      //   gqString,
      //   "Assessment:" +
      //     resourceData[0].ResourceName +
      //     ", Resource ID:" +
      //     resourceIngestionIdGa +
      //     ", Level:" +
      //     studyGroupData.Level.LevelName[0].LevelName +
      //     ", Book Series:" +
      //     studyGroupData.BookSeries.BookSeriesName[0].BookSeriesName +
      //     ", Book:" +
      //     studyGroupData.BookSeries.Books[0].BookName[0].BookName +
      //     ", Chapter:" +
      //     studyGroupData.BookSeries.Books[0].Units[0].UnitName[0].UnitName +
      //     ", Role:" +
      //     userType
      // );
    }
    dispatch({
      type: "SET_STUDENT_RESULT",
      session_id: session_id,
      attemptDate: attemptDate,
      userId: id,
      classGroup:classGroup
    })
    props.history.push(props.location.pathname + "/" + id, {
      session_id: session_id,
      attemptDate: attemptDate,
      userId: id,
      classGroup:classGroup
      // questions: data[e].users[id].items,
    });
  };
  console.log(props);
  console.log(teacherReport, "teacherReport");

  return (
    <>
      {loading ? (
        <div
          className="loading-box"
          style={{
            display: `${!initReady ? "block" : "none"}`,
            position: `${!initReady ? "relative" : "absolute"}`,
          }}
        >
          <Loading type="normal" />
        </div>
      ) : (
        <div className="main__section-student-unit-grid result-page learnosity-scroll-height">
          <table className="main__section-student-unit-grid-details">
            <thead className="result-page-table-head-height">
              <tr className="main__section-student-unit-grid-details-bottom-row">
                <th className="main__section-student-unit-grid-details-bottomheading-primary"
                onClick={()=>{
                  setSortColumn('No')
                  const sortedData = teacherReport.sort((a,b) => sortOrder=='asc' ? parseInt(b.ClassNumber) - parseInt(a.ClassNumber) : parseInt(a.ClassNumber) - parseInt(b.ClassNumber));
                  setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                  setteacherReport(sortedData)
                  }}
                >
                  {t("No")}
                  <img
                  src={sortColumn != 'No' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                  alt="sorting"
                  className="table-sorting-arrow"
                  />
                </th>
                <th className="main__section-student-unit-grid-details-bottomheading-primary"
                  onClick={() => {
                    setSortColumn('Student Name')
                    const sortedData = teacherReport.sort(function (a, b) {
                      if (sortOrder == 'asc') {
                        if (a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName < b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return -1; }
                        if (a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName > b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return 1; }
                        return 0;
                      } else {
                        if (a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName < b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return 1; }
                        if (a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName > b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return -1; }
                        return 0;
                      }
                    })
                    setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
                    setteacherReport(sortedData)
                  }}>
                  {t("Student Name")}
                  <img
                    src={sortColumn != 'Student Name' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_desc : sorting_arrow_black_asc}
                    alt="sorting"
                    className="table-sorting-arrow"
                  />
                </th>
                <th className="main__section-student-unit-grid-details-bottomheading-primary">
                  {t("Submission")}
                </th>

              </tr>
            </thead>
            <tr className="main__section-student-unit-grid-details-up-row">
              <td>-</td>
              <td>Class {teacherReport[0].StudyGroupName}</td>
              <td></td>
            </tr>

            {teacherReport.map((user, index) => {
              console.log('qwerty res', user)
              return (
                <tr className="main__section-student-unit-grid-details-down-row">
                  <td className="main__section-student-unit-grid-details-down-row-number">
                    {/* {index + 1} */}
                    
                    {user.ClassNumber}
                  </td>
                  <td className="main__section-student-unit-grid-details-down-row-name">
                    {
                      user.UserDisplayName[
                        lang == user.UserDisplayName[0].Lang ? 0 : 1
                      ].UserName
                    }
                  </td>

                  <td className="main__section-student-unit-grid-details-down-row-submission">
                    {user.submitURL != "" && user.AttemptHistory.session_id != null && (
                      <button
                        className="buttons btns submission-view-button"
                        onClick={() => {
                          console.log("HERE IS USER:", user);
                          if (user.AttemptHistory.session_id == null) return;
                          onClickStudentTitle(
                            user.user_id,
                            user.AttemptHistory.session_id,
                            user.AttemptDate
                          );
                        }}
                      // onClick={() =>
                      //   doOpenSubmission(
                      //     user.submitURL,
                      //     user.UserDisplayName[
                      //       lang == user.UserDisplayName[0].Lang ? 0 : 1
                      //     ].UserName
                      //   )
                      // }
                      >
                        {t("View")}
                      </button>
                    )}
                  </td>


                </tr>
              );
            })}

          </table>


          {/* overall table */}
          <table className="main__section-student-unit-grid-grade overall-table">
            <thead className="result-page-table-head-height">
              <tr className="main__section-student-unit-grid-grade-bottom-row">
                <th className="main__section-student-unit-grid-details-bottomheading"
                  onClick={() => {
                    setSortColumn('Overall')
                    console.log('value teacherReport',teacherReport)
                    var sortedDataOverAll;
                    if(sortOrder == "asc"){
                      sortedDataOverAll = teacherReport.sort(alphabeticallyDesc)
                    }else{
                       sortedDataOverAll = teacherReport.sort(alphabetically)
                    }
                    setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                    setteacherReport(sortedDataOverAll)
                  }}>
                  {t("Overall")}
                  <img
                    src={sortColumn != 'Overall' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                    alt="sorting"
                    className="table-sorting-arrow"
                  />
                </th>
              </tr>
            </thead>
            <tr className="main__section-student-unit-grid-grade-up-row">
              <td
                className={`column-data-with-progress-color progress-bar-center-position`}
              >
                {classAvgProgress != "" ? (
                  <div
                    className={`student-progressbar ${classAvgProgress}`}
                    role="progressbar"
                    aria-valuenow={classAvgLearnosity}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": classAvgLearnosity }}
                  ></div>
                ) : (
                  <div>
                    <span class="main__section-unit-grid-details-progress">
                    <i class="main__section-unit-grid-details-progress-bar">
                      -
                    </i>
                  </span>
                  </div>
                )}
                 
              </td>
            </tr>
            {teacherReport.map((user) => {
              var percentageOverAll = "";
              if (user.AttemptHistory.session_id != null) {
                percentageOverAll = Math.round(
                  (user.AttemptHistory.score / user.AttemptHistory.max_score) *
                  100
                );
              }


              var studentProgressLevelClass = getScoreAndAverageClass(
                percentageOverAll,
                "score"
              );

              return (
                <tr className="main__section-student-unit-grid-grade-down-row">
                  <td
                    className={`column-data-with-progress progress-bar-center-position`}
                  >
                    {studentProgressLevelClass != "" ? (
                      <div
                        className={`student-progressbar ${studentProgressLevelClass}`}
                        role="progressbar"
                        aria-valuenow={percentageOverAll}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ "--value": percentageOverAll }}
                      ></div>
                    ) : (
                      <span class="main__section-unit-grid-details-progress">
                        <i class="main__section-unit-grid-details-progress-bar">
                          -
                        </i>
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>


          <div className="school_activities_table">
            <table className="main__section-student-unit-grid-details">
              <thead className="result-page-table-head-height">
                <tr className="main__section-student-unit-grid-details-bottom-row">
                {renderQuestionTitle()}
                </tr>
              </thead>
              <tr className="main__section-student-unit-grid-view-up-row activities-table-data">
                {/* {exerciseNames.map((data) => { */}
                {renderQuestionEmpty()}
              </tr>
              
              {teacherReport.map((user) => {
                return (
                  // <tr className="main__section-student-unit-grid-view-up-row activities-table-data">
                  <tr className="main__section-student-unit-grid-details-down-row">
                    {/* {exerciseNames.map((exer) => { */}
                    {/* 
                    question_tick_arrow
                    question_half_tick_arrow
                    question_cross_tick_arrow
                    question_hyphen_arrow
                    */}
                    {user.AttemptHistory.session_id != null ? (
                      user.AttemptHistory.QuestionsData.map((q, qindex) => {
                        var dotClass = "";

                        if (!q.scoring.attempted)
                          // dotClass = "main__section-student-unit-grid-grey";
                          dotClass = "question_hyphen_arrow";
                        else {
                          if (q.scoring.max_score != q.scoring.score) {
                            if (q.scoring.score != 0)
                              // dotClass = "main__section-student-unit-grid-red";
                              dotClass = "question_half_tick_arrow";
                            // else dotClass = "main__section-student-unit-grid-red";
                            else dotClass = "question_cross_tick_arrow";
                          } else {
                            // dotClass = "main__section-student-unit-grid-green";
                            dotClass = "question_tick_arrow";
                          }
                        }
                        return (
                          <td key={"q_" + qindex} 
                          className={`progress-bar-center-position single-progress-bar-center-position ${questionCount.length<= 1 ? "single-column-border-radius" : ""}`}
                          // className={`main__section-student-unit-grid-details-down-row-number progress-bar-center-position ${questionCount <= 1 ? "single-column-border-radius" : ""}`}
                          >
                            <div className={`${dotClass}`}></div>
                          </td>
                        );
                      })
                    ) : (
                      <>
                      {/* <td colSpan={questionCount}></td> */}
                      {renderQuestionEmptyrow()}
                          
                      </>
                    )}
                  </tr>
                );
              })}
            </table>
          </div>











        </div>
      )}
    </>
  );
}

export default index;
