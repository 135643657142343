import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import "./index.scss";

// import NavBar from "../../Components/NavBar/Dashboard/NavBar";
import NavBar from "../../Components/NavBar/Dashboard/PEC/NavBar";
// import NavBar from "../../Components/NavBar/Dashboard/PEC/NavBarPrizm";
import Footer from "../../Components/Footer/PEC/Footer";
import Content from "./Body";
import B2CContent from "./B2CBody";
// import AdoptedBookSeriesInfo from "../../Services/api/GraphQL/Query/AdoptedBookSeriesInfo";

const index = (props) => {
  const { t, afterLogout, B2C } = props;
  // const dispatch = useDispatch();
  // const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  // const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const [loading, setloading] = useState(true);
  const [navBarTitle, setnavBarTitle] = useState([
    { Lang: "en-US", pageTitle: "" },
    { Lang: "zh-HK", pageTitle: "" },
  ]);
  const [backPath, setbackPath] = useState(null);
  const [backTitle, setbackTitle] = useState(null);
  useEffect(() => {
    // console.log(props, "././");

    setloading(false);
    // }
  }, []);

  const setPageTitle = (navBarTitle, backTitle, backPath) => {
    console.log(navBarTitle, backTitle, backPath);
    setnavBarTitle(navBarTitle);
    setbackPath(backPath ? backPath : null);
    setbackTitle(backTitle ? backTitle : null);
  };

  if (!userData) {
    return <Redirect to="/" />;
  }
  if (loading) {
    return <div></div>;
  }
  return (
    <>
      <div className={`be-wrapper be-fixed-sidebar pop_up_view ${props.location.pathname.indexOf("/submission") != -1 ? 'pop_up_view_submission' : ''}`}>
        <NavBar
          afterLogout={afterLogout}
          t={t}
          pageTitle={navBarTitle}
          backTitle={backTitle}
          backPath={backPath}
          // studentResult={true}
          // {...p}
        />
        {UserType == "T" &&
          !B2C && ( //teacher enter study group from dashboard
            <Switch>
              <Route
                path="/a/studygroup/:id"
                component={(p) => (
                  <Content
                    {...p}
                    //afterLogout={this.props.afterLogout}
                    //jsonData={jsonData}
                    t={t}
                    setPageTitle={setPageTitle}
                    pageTitle={navBarTitle}
                    B2C={false}
                  />
                )}
              />

              {/* {window.cordova ? (
              <Redirect exact from="/a" to="/m/myschool" />
            ) : (
              <Redirect exact from="/a" to="/m/myschool" />
            )} */}
              <Redirect exact from="/a/studygroup" to="/m/myschool" />
              {/* <Redirect exact from="/a/studygroup" to="/a/studygroup" />
            <Redirect exact from="/rcenter" to="/rcenter" /> */}
              <Redirect exact from="/a" to="/errorcode=404" />
            </Switch>
          )}

        {UserType == "S" &&
          !B2C && ( //student enter study group from dashboard
            <Switch>
              <Route
                path="/a/studygroup/:id"
                component={(p) => (
                  <Content
                    {...p}
                    //afterLogout={this.props.afterLogout}
                    //jsonData={jsonData}
                    t={t}
                    setPageTitle={setPageTitle}
                    pageTitle={navBarTitle}
                    B2C={false}
                  />
                )}
              />

              {window.cordova ? (
                <Redirect exact from="/a" to="/m/myschool" />
              ) : (
                <Redirect exact from="/a" to="/m/myschool" />
              )}
              <Redirect exact from="/a/studygroup" to="/m/myschool" />
              <Redirect exact from="/a" to="/errorcode=404" />
            </Switch>
          )}

        {B2C && (
          <Switch>
            <Route
              path="/c/myresview/:id/:book"
              component={(p) => (
                <B2CContent
                  {...p}
                  t={t}
                  // setPageTitle={setPageTitle}
                  // pageTitle={navBarTitle}
                  B2C={true}
                />
              )}
            />

            {window.cordova ? (
              <Redirect exact from="/c" to="/m/myschool" />
            ) : (
              <Redirect exact from="/c" to="/m/myschool" />
            )}
            <Redirect exact from="/c/myresview" to="/m/resource" />
            <Redirect exact from="/c/myresview/:id" to="/m/resource" />
            <Redirect exact from="/c" to="/errorcode=404" />
          </Switch>
        )}
      </div>
      {props.location.pathname.indexOf("/start") != -1 ? '' : <div className="result-page-footer-section">
        <Footer />
      </div>}
      
    </>
  );
};

export default index;
