/**
 * @flow
 * @relayHash c96b78e7eca3c04f52b56f7217361b74
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuestionViewAllStudentsResultQueryVariables = {|
  StudyGroupId: string,
  SchoolYearId: string,
  ResourceId: $ReadOnlyArray<?string>,
  IncludeAssignmentRecord?: ?boolean,
|};
export type QuestionViewAllStudentsResultQueryResponse = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +SelfLearnLastAttemptHistoryByStudyGroup: ?$ReadOnlyArray<?{|
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
      +SelfLearnFirstAttemptHistoryByStudyGroup: ?$ReadOnlyArray<?{|
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
    |}>
  |}
|};
export type QuestionViewAllStudentsResultQuery = {|
  variables: QuestionViewAllStudentsResultQueryVariables,
  response: QuestionViewAllStudentsResultQueryResponse,
|};
*/


/*
query QuestionViewAllStudentsResultQuery(
  $StudyGroupId: String!
  $SchoolYearId: String!
  $ResourceId: [String]!
  $IncludeAssignmentRecord: Boolean
) {
  resourceSearch(ResourceId: $ResourceId) {
    Resources {
      SelfLearnLastAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {
        LearnositySessionRawResult
        UserDetail {
          UserName
          UserDisplayName {
            UserName
            Lang
          }
          id
        }
      }
      SelfLearnFirstAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {
        LearnositySessionRawResult
        UserDetail {
          UserName
          UserDisplayName {
            UserName
            Lang
          }
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "StudyGroupId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "SchoolYearId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ResourceId",
    "type": "[String]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "IncludeAssignmentRecord",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId",
    "type": "[String]"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "IncludeAssignmentRecord",
    "variableName": "IncludeAssignmentRecord",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "LearnositySessionRawResult",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "UserName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "UserDisplayName",
  "storageKey": null,
  "args": null,
  "concreteType": "userLang",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Lang",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  (v3/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "UserDetail",
    "storageKey": null,
    "args": null,
    "concreteType": "user",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v5/*: any*/)
    ]
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "UserDetail",
    "storageKey": null,
    "args": null,
    "concreteType": "user",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuestionViewAllStudentsResultQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnLastAttemptHistoryByStudyGroup",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v6/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnFirstAttemptHistoryByStudyGroup",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v6/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "QuestionViewAllStudentsResultQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resourceSearch",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "Resources",
            "storageKey": null,
            "args": null,
            "concreteType": "resource",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnLastAttemptHistoryByStudyGroup",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v8/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "SelfLearnFirstAttemptHistoryByStudyGroup",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "plural": true,
                "selections": (v8/*: any*/)
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "QuestionViewAllStudentsResultQuery",
    "id": null,
    "text": "query QuestionViewAllStudentsResultQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n  $ResourceId: [String]!\n  $IncludeAssignmentRecord: Boolean\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      SelfLearnLastAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      SelfLearnFirstAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44753e32eb0efec235eda687b127cd4b';
module.exports = node;
